import styled from "styled-components";
import { palette } from "styled-theme";

const SessionSwitcherWrapper = styled.div`
  display: flex;
  /* padding: 10px; */

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette("iconBg", 0)};
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50%;
  }
  .badgeWrapper {
    position: absolute;
    left: 60px;
    background-color: ${palette("surfaceColor", 0)};
    height: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid ${palette("text", 1)};

    span {
      color: ${palette("secondary", 0)};
    }
  }
`;

export default SessionSwitcherWrapper;
