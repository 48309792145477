import {
  CIRCLES_FETCHED,
  PLANS_FETCHED,
  USER_FETCHED,
  ACTIVE_PLAN_FETCHED
} from "../../constants";
const INITIAL_STATE = {
  circlesFetched: false,
  userFetched: false,
  plansFetched: false,
  activePlanFetched: false
};
const fetch = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CIRCLES_FETCHED:
      return {
        ...state,
        circlesFetched: action.value
      };
    case PLANS_FETCHED:
      return {
        ...state,
        plansFetched: action.value
      };
    case USER_FETCHED:
      return {
        ...state,
        userFetched: action.value
      };
    case ACTIVE_PLAN_FETCHED:
      return {
        ...state,
        activePlanFetched: action.value
      };
    default:
      return state;
  }
};
export default fetch;
