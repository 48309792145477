import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { appActions } from "../../redux/reducers/app/reducer";
import Profile from "../../components/settings/profile";
import Account from "../../components/settings/account";
import PaymentSettings from "../../components/settings/paymentSettings";
import PaymentMethod from "../../components/settings/paymentMethod";
import SettingsMenu from "../../components/settings/settingsMenu";
import Usage from "../../components/settings/usage";
import Privacy from "../../components/settings/privacy";
import Terms from "../../components/settings/terms";
import Entities from "../../components/settings/entities/entities";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import Topbar from "../Topbar/Topbar";
import { withTheme } from "styled-components";
import { AppLocale } from "../../dashApp";
import SettingsWrapper from "./style";
import { getDirection } from "../../redux/selectors/user";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MobileView from "./mobileView";
const { Content } = Layout;

const Settings = props => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("profile");
  const view = useSelector(state => state.App.view);

  const direction = useSelector(state => getDirection(state));
  const locale = useSelector(state => state.LanguageSwitcher.language.locale);
  const data = useSelector(state => state.Firebase.userData);
  const { theme, match } = props;
  const { url } = match;
  const currentAppLocale = AppLocale[locale];

  const changeCategory = category => {
    setCategory(category);
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Layout style={{ height: "100vh" }}>
        <Debounce time="1000" handler="onResize">
          <WindowSizeListener
            onResize={windowSize =>
              dispatch({
                type: appActions.TOGGLE_ALL,
                height: windowSize.windowHeight,
                width: windowSize.windowWidth
              })
            }
          />
        </Debounce>
        <Topbar url={url} data-rtl={direction} />
        {view === "MobileView" ? (
          <Layout
            style={{
              flexDirection: "row",
              overflowX: "hidden",
              marginTop: "64px"
            }}
          >
            <MobileView direction={direction} data={data} />
          </Layout>
        ) : (
          <Layout
            style={{
              flexDirection: "row",
              overflowX: "hidden",
              marginTop: "64px"
            }}
          >
            <SettingsMenu
              changeCategory={changeCategory}
              direction={direction}
            />
            <Layout style={{ background: theme.palette["background"][0] }}>
              <SettingsWrapper data-rtl={direction}>
                <div className="settingsContainer">
                  <Content className="settingsContent">
                    {category === "profile" ? (
                      <Profile direction={direction} data={data} />
                    ) : null}

                    {category === "account" ? (
                      <Account direction={direction} data={data} />
                    ) : null}
                    {category === "entities" ? (
                      <Entities direction={direction} data={data} />
                    ) : null}
                    {category === "paymentSettings" ? (
                      <PaymentSettings direction={direction} data={data} />
                    ) : null}
                    {category === "paymentMethod" ? (
                      <PaymentMethod direction={direction} data={data} />
                    ) : null}
                    {category === "usage" ? (
                      <Usage direction={direction} data={data} />
                    ) : null}
                    {category === "privacy" ? (
                      <Privacy direction={direction} data={data} />
                    ) : null}
                    {category === "terms" ? (
                      <Terms direction={direction} data={data} />
                    ) : null}
                  </Content>
                </div>
              </SettingsWrapper>
            </Layout>
          </Layout>
        )}
      </Layout>
    </IntlProvider>
  );
};

Settings.propTypes = {
  match: PropTypes.object,
  theme: PropTypes.object
};

export default withTheme(Settings);
