import { FIRESTORE_LISTEN_REQUESTED, eventTypes } from "../../constants";
import firebase from "firebase/app";
import "firebase/firestore";
import { put } from "redux-saga/effects";

// listens when user has been added to a circle or removed; handles also initial load; listens to add/delete/leave circle events
export function* setCircleSessionsIds(data, circleId, metaType) {
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    const change = data.snapshot.docChanges()[j].doc.data();
    const type = data.snapshot.docChanges()[j].type;

    const circleSessionsRef = firebase
      .firestore()
      .collection("sessions")
      .doc(change.sessionId);
    // .where("id", "==", change.sessionId);
    // .where("sessionStatus", "==", "open");

    // const circleSessionsRef = firebase
    //   .firestore()
    //   .collection("sessions")
    //   .where("invitedCircles", "array-contains", change.circleId)
    //   .where("sessionStatus", "==", "open");

    if (type === eventTypes.ADDED || type === eventTypes.MODIFIED) {
      yield put({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: circleSessionsRef, id: circleId },
        meta: { type: "circleSessions" }
      });
    } else if (type === "removed") {
    }
  }
}
