import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DropezoneWrapper from "./dropzone.style";
import { Upload, Button, message } from "antd";
import IntlMessages from "./intlMessages";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.onload = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isIMAGE = file.type.includes("image");
  if (!isIMAGE) {
    message.error("You can only upload image file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isIMAGE && isLt2M;
}

const Dropzone = props => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(null);
  const { uri, addImage } = props;

  useEffect(() => {
    setImageUrl(uri);
  }, [uri]);

  const handleChange = info => {
    getBase64(info.file.originFileObj, url => {
      addImage(info.file.originFileObj, url);
      setImageUrl(url);
      setLoading(false);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <DropezoneWrapper
      className="dropzone-wrapper"
      initial={imageUrl ? true : false}
    >
      {imageUrl ? (
        <Upload
          accept="image/*"
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          <img src={imageUrl} alt="avatar" />
          <Button size="small" style={{ margin: "0 1em" }} type="link">
            <IntlMessages id="app.buttons.change" />
          </Button>
        </Upload>
      ) : (
        <Upload
          accept="image/*"
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload>
      )}
    </DropezoneWrapper>
  );
};

Dropzone.propTypes = {
  uri: PropTypes.string,
  addImage: PropTypes.func
};

export default Dropzone;
