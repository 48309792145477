import React from "react";
import PropTypes from "prop-types";
import ContactDetailWrapper from "./contactDetail.style";

const ContactDetail = props => {
  const { icon, text, direction } = props;

  return (
    <ContactDetailWrapper className="contactDetail" data-rtl={direction}>
      <div className="contactIcon">{icon} </div>
      {props.large ? <p style={{ fontSize: "20px" }}>{text}</p> : <p>{text}</p>}
    </ContactDetailWrapper>
  );
};

ContactDetail.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  direction: PropTypes.string,
  large: PropTypes.bool
};

export default ContactDetail;
