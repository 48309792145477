import React, { useReducer, useEffect, useCallback } from "react";
import "nprogress/nprogress.css";
import Loading from "../containers/App/Loading";

export default function asyncComponent(importComponent) {
  const initialState = { comp: null };

  function reducer(state, action) {
    switch (action.type) {
      case "loaded":
        return { comp: action.comp };
      default:
        return { comp: null };
    }
  }

  const AsyncComponent = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const getComponent = useCallback(async () => {
      const { default: Component } = await importComponent();
      dispatch({ type: "loaded", comp: Component });
    }, []);

    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        getComponent();
      }
      return () => (isMounted = false);
    }, [getComponent]);

    let Comp = state.comp;
    const Component = Comp !== null ? <Comp {...props} /> : <div />;
    return state.comp === null ? <Loading /> : Component;
  };
  return AsyncComponent;
}
