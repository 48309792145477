import React from "react";
import ContentHolder from "../utility/contentHolder";
import { Input } from "antd";
import { Form, Tooltip, Select } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import countryCodes from "./countryCodes";
import usStates from "./usStates";
import caStates from "./caStates";
import payoutType from "./payoutType";
import payoutBaseCurrency from "./payoutBaseCurrency";
import bankAccountClass from "./bankAccountClass";
import bankAccountType from "./bankAccountType";
const FormItem = Form.Item;
const Option = Select.Option;

class PayoutInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payoutType: "",
      baseCurrency: "",
      bankName: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      nameOnAccount: "",
      bankAccountClass: "",
      bankAccountType: "",
      bankId: "",
      bankAccountId: "",
      iban: "",
      swiftBic: ""
    };
    this.handlePayoutTypeChange = this.handlePayoutTypeChange.bind(this);
    this.handleBaseCurrencyChange = this.handleBaseCurrencyChange.bind(this);
    this.handleBankNameChange = this.handleBankNameChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleNameOnAccountChange = this.handleNameOnAccountChange.bind(this);
    this.handleBankAccountClassChange = this.handleBankAccountClassChange.bind(
      this
    );
    this.handleBankAccountTypeChange = this.handleBankAccountTypeChange.bind(
      this
    );
    this.handleBankIdChange = this.handleBankIdChange.bind(this);
    this.handleBankAccountIdChange = this.handleBankAccountIdChange.bind(this);
    this.handleIbanChange = this.handleIbanChange.bind(this);
    this.handleSwiftBicChange = this.handleSwiftBicChange.bind(this);
  }
  formRef = React.createRef();

  handlePayoutTypeChange(value) {
    this.setState({ payoutType: value });
    this.props.handlePayout_payoutTypeChange(value);
  }

  handleBaseCurrencyChange(value) {
    this.setState({ baseCurrency: value });
    this.props.handlePayout_baseCurrencyChange(value);
  }

  handleBankNameChange(event) {
    this.setState({ bankName: event.target.value });
    this.props.handlePayout_bankNameChange(event.target.value);
  }

  handleAddressChange(event) {
    this.setState({ address: event.target.value });
    this.props.handlePayout_addressChange(event.target.value);
  }

  handleCountryChange(value) {
    this.setState({ country: value });
    this.props.handlePayout_countryChange(value);
  }

  handleStateChange(value) {
    this.setState({ state: value });
    this.props.handlePayout_stateChange(value);
  }

  handleCityChange(event) {
    this.setState({ state: event.target.value });
    this.props.handlePayout_cityChange(event.target.value);
  }

  handleZipChange(event) {
    this.setState({ zip: event.target.value });
    this.props.handlePayout_zipChange(event.target.value);
  }

  handleNameOnAccountChange(event) {
    this.setState({ nameOnAccount: event.target.value });
    this.props.handlePayout_nameOnAccountChange(event.target.value);
  }

  handleBankAccountClassChange(value) {
    this.setState({ bankAccountClass: value });
    this.props.handlePayout_bankAccountClassChange(value);
  }

  handleBankAccountTypeChange(value) {
    this.setState({ bankAccountType: value });
    this.props.handlePayout_bankAccountTypeChange(value);
  }

  handleBankIdChange(event) {
    this.setState({ bankId: event.target.value });
    this.props.handlePayout_bankIdChange(event.target.value);
  }

  handleBankAccountIdChange(event) {
    this.setState({ bankAccountId: event.target.value });
    this.props.handlePayout_bankAccountIdChange(event.target.value);
  }

  handleIbanChange(event) {
    this.setState({ iban: event.target.value });
    this.props.handlePayout_ibanChange(event.target.value);
  }

  handleSwiftBicChange(event) {
    this.setState({ swiftBic: event.target.value });
    this.props.handlePayout_swiftBicChange(event.target.value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state && nextProps.validationCount > 0) {
      const value = { payoutInfoValidation: false };
      this.props.isValidated(value);
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validationCount !== this.state.validationCount) {
      this.validateFields();
    }
    if (prevState.vendorDetails !== this.state.vendorDetails) {
      const payoutInfo = this.props.vendorDetails.payoutInfo;
      if (payoutInfo) {
        this.setState({
          payoutType: payoutInfo.payoutType,
          baseCurrency: payoutInfo.baseCurrency,
          bankName: payoutInfo.bankName,
          address: payoutInfo.address,
          country: payoutInfo.country,
          state: payoutInfo.state,
          city: payoutInfo.city,
          zip: payoutInfo.zip,
          nameOnAccount: payoutInfo.nameOnAccount,
          bankAccountClass: payoutInfo.bankAccountClass,
          bankAccountType: payoutInfo.bankAccountType,
          bankId: payoutInfo.bankId,
          bankAccountId: payoutInfo.bankAccountId,
          iban: payoutInfo.iban,
          swiftBic: payoutInfo.swiftBic
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.validationCount !== prevState.validationCount) {
      return { validationCount: nextProps.validationCount };
    } else if (nextProps.vendorDetails !== prevState.vendorDetails) {
      return { vendorDetails: nextProps.vendorDetails };
    } else return null;
  }

  validateFields() {
    this.formRef.current
      .validateFields()
      .then(values => {
        const value = { payoutInfoValidation: true };
        this.props.isValidated(value);
      })
      .catch(errorInfo => {
        const value = { payoutInfoValidation: false };
        this.props.isValidated(value);
      });
  }

  componentDidMount() {
    const { vendorDetails } = this.props;
    const payoutInfo = vendorDetails.payoutInfo;
    if (payoutInfo) {
      this.setState({
        payoutType: payoutInfo.payoutType,
        baseCurrency: payoutInfo.baseCurrency,
        bankName: payoutInfo.bankName,
        address: payoutInfo.address,
        country: payoutInfo.country,
        state: payoutInfo.state,
        city: payoutInfo.city,
        zip: payoutInfo.zip,
        nameOnAccount: payoutInfo.nameOnAccount,
        bankAccountClass: payoutInfo.bankAccountClass,
        bankAccountType: payoutInfo.bankAccountType,
        bankId: payoutInfo.bankId,
        bankAccountId: payoutInfo.bankAccountId,
        iban: payoutInfo.iban,
        swiftBic: payoutInfo.swiftBic
      });
    }
  }
  onChange = e => {};
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      }
    };

    const countriesOptions = [];
    for (let i = 0; i < countryCodes.length; i++) {
      countriesOptions.push(
        <Option key={i} value={countryCodes[i].code}>
          {countryCodes[i].name}
        </Option>
      );
    }

    const usStatesOptions = [];
    for (let i = 0; i < usStates.length; i++) {
      usStatesOptions.push(
        <Option key={i} value={usStates[i].abbreviation}>
          {usStates[i].name}
        </Option>
      );
    }

    const caStatesOptions = [];
    for (let i = 0; i < caStates.length; i++) {
      caStatesOptions.push(
        <Option key={i} value={caStates[i].abbreviation}>
          {caStates[i].name}
        </Option>
      );
    }

    const stateOptions =
      this.state.country === "US"
        ? usStatesOptions
        : this.state.country === "CA"
        ? caStatesOptions
        : null;

    const payoutBaseCurrencyOptions = [];
    for (let i = 0; i < payoutBaseCurrency.length; i++) {
      payoutBaseCurrencyOptions.push(
        <Option key={i} value={payoutBaseCurrency[i].code}>
          {payoutBaseCurrency[i].name}
        </Option>
      );
    }

    const payoutTypeOptions = [];
    for (let i = 0; i < payoutType.length; i++) {
      payoutTypeOptions.push(
        <Option key={i} value={payoutType[i].code}>
          {payoutType[i].name}
        </Option>
      );
    }

    const bankAccountTypeOptions = [];
    for (let i = 0; i < bankAccountType.length; i++) {
      bankAccountTypeOptions.push(
        <Option key={i} value={bankAccountType[i].code}>
          {bankAccountType[i].name}
        </Option>
      );
    }

    const bankAccountClassOptions = [];
    for (let i = 0; i < bankAccountClass.length; i++) {
      bankAccountClassOptions.push(
        <Option key={i} value={bankAccountClass[i].code}>
          {bankAccountClass[i].name}
        </Option>
      );
    }

    const ibanRequired =
      this.state.payoutType === "CHAPS" || this.state.payoutType === "SEPA"
        ? true
        : false;
    return (
      <ContentHolder direction={this.props.direction}>
        <Form
          ref={this.formRef}
          initialValues={{
            payoutType: this.state.payoutType,
            baseCurrency: this.state.baseCurrency,
            bankName: this.state.bankName,
            address: this.state.address,
            country: this.state.country,
            state: this.state.state,
            city: this.state.city,
            zip: this.state.zip,
            nameOnAccount: this.state.nameOnAccount,
            bankAccountClass: this.state.bankAccountClass,
            bankAccountType: this.state.bankAccountType,
            bankId: this.state.bankId,
            bankAccountId: this.state.bankAccountId,
            iban: this.state.iban,
            swiftBic: this.state.swiftBic
          }}
        >
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Payout type&nbsp;
                <Tooltip title="Method of payout to vendor">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="payoutType"
            rules={[{ required: true, message: "Please input your Country!" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handlePayoutTypeChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {payoutTypeOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Base Currency&nbsp;
                <Tooltip title="Payout currency for vendor's bank account">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="baseCurrency"
            rules={[{ required: true, message: "Please input your Country!" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleBaseCurrencyChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {payoutBaseCurrencyOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Bank Name&nbsp;
                <Tooltip title="Name of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="bankName"
            rules={[
              {
                required: true,
                message: "bank name is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleBankNameChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Address&nbsp;
                <Tooltip title="Street address of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="address"
            rules={[
              {
                required: true,
                message: "address is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleAddressChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Country&nbsp;
                <Tooltip title="Country of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="country"
            rules={[
              {
                required: true,
                message: "country is required"
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleCountryChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {countriesOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                State&nbsp;
                <Tooltip title="What is your company State?">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="state"
            rules={[
              {
                required: true,
                message: "Please input your company State!",
                whitespace: true
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your State"
              optionFilterProp="children"
              onChange={this.handleStateChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {stateOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                City&nbsp;
                <Tooltip title="City of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="city"
            rules={[
              {
                required: true,
                message: "city is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleCityChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Zip&nbsp;
                <Tooltip title="Zip/Postal code of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="zip"
            rules={[
              {
                required: false,
                message: "zip/postal code is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleZipChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Name On Account&nbsp;
                <Tooltip title="Name on bank account for vendor">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="nameOnAccount"
            rules={[
              {
                required: this.state.country === "US",
                message: "name on bank account is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleNameOnAccountChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Class of bank account&nbsp;
                <Tooltip title="Type of bank account">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="bankAccountClass"
            rules={[
              {
                required: true,
                message: "type of bank account is required"
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleBankAccountClassChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {bankAccountClassOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Type of bank account&nbsp;
                <Tooltip title="Account type">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="bankAccountType"
            rules={[
              {
                required: true,
                message: "account type is required"
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleBankAccountTypeChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {bankAccountTypeOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                bank Id&nbsp;
                <Tooltip title="Bank ID number (ie. Routing number)">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="bankId"
            rules={[
              {
                required: true,
                message: "Bank ID number is required"
              }
            ]}
          >
            <Input onChange={this.handleBankIdChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                account number&nbsp;
                <Tooltip title="Vendor bank account number">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="bankAccountId"
            rules={[
              {
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: "valid E-mail is required"
              }
            ]}
          >
            <Input onChange={this.handleBankAccountIdChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Iban&nbsp;
                <Tooltip title="Vendor International Bank Account Number (IBAN)">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="iban"
            rules={[
              {
                required: ibanRequired,
                message:
                  "iban is required if is the type of bank account is CHAPS or SEPA"
              }
            ]}
          >
            <Input onChange={this.handleIbanChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                swiftBic&nbsp;
                <Tooltip title="The Bank Identifier Code of vendor bank">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="swiftBic"
            rules={[
              {
                required: false,
                message: "swift identifier Code is required"
              }
            ]}
          >
            <Input onChange={this.handleSwiftBicChange} />
          </FormItem>
        </Form>
      </ContentHolder>
    );
  }
}

export default PayoutInfo;
