import React, { Component } from "react";
import PropTypes from "prop-types";
import MapGL, { NavigationControl, FlyToInterpolator } from "react-map-gl";
import circle from "@turf/circle";
import bbox from "@turf/bbox";
import { lineString as makeLineString } from "@turf/helpers";
import WebMercatorViewport from "@math.gl/web-mercator";
import { withTheme } from "styled-components";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { css } from "@emotion/core";
import GeofencesCardWrapper from "./geoFencesCardMap.style";
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const mapWrapperVariants = {
  show: {
    opacity: 1,
    transition: { duration: 1 }
  },
  hide: { opacity: 0, transition: { duration: 0 } }
};

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
`;

class GeoFencesCardMap extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showMap: false,
      mapStyle:
        this.props.theme.name === "lightTheme"
          ? "mapbox://styles/mapbox/light-v9"
          : "mapbox://styles/mapbox/dark-v9",
      viewport: {
        latitude: 37.805,
        longitude: -122.447,
        zoom: 12,
        bearing: 0,
        pitch: 60
      },
      marker: {
        latitude: 37.805,
        longitude: -122.447
      }
    };
    this.onLoad = this.onLoad.bind(this);
    this._onViewportChange = this._onViewportChange.bind(this);
  }

  static propTypes = {
    geofences: PropTypes.array,
    theme: PropTypes.object,
    view: PropTypes.string,
    height: PropTypes.number
  };

  componentDidMount() {
    const { geofences } = this.props;
    this._isMounted = true;
    if (geofences) {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: geofences[0].location.latitude,
          longitude: geofences[0].location.longitude
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _onViewportChange = viewport => {
    if (this._isMounted) {
      this.setState({ viewport });
    }
  };

  onLoad(event) {
    const { geofences } = this.props;
    let boundriesCoorddinates = [];

    if (this._isMounted && geofences) {
      const map = event.target;
      geofences.forEach(geofence => {
        var center = [geofence.location.longitude, geofence.location.latitude];
        var options = {
          steps: 100,
          units: "kilometers",
          properties: { foo: "bar" }
        };
        var poligon = circle(center, geofence.radius, options);
        const coordinates = poligon.geometry.coordinates;
        boundriesCoorddinates = boundriesCoorddinates.concat(coordinates[0]);
        this.setLayers(map, geofence, coordinates, center);
      });
      if (boundriesCoorddinates.length > 1) {
        this.setBoundries(boundriesCoorddinates);
      }

      this.setState({
        showMap: true
      });
    }
  }

  setBoundries(boundriesCoorddinates) {
    var line = makeLineString(boundriesCoorddinates);
    const [minLng, minLat, maxLng, maxLat] = bbox(line);
    const viewport = new WebMercatorViewport({
      ...this.state.viewport,
      width: this.map._width,
      height: this.map._height
    });
    const { longitude, latitude, zoom } = viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { padding: 40 }
    );
    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: "auto"
      }
    });
  }

  setLayers(map, geofence, coordinates, center) {
    const { theme } = this.props;

    map.addLayer({
      id: geofence.identifier,
      type: "fill",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coordinates
          }
        }
      },
      layout: {},
      paint: {
        "fill-color": theme.palette["primary"][0],
        "fill-opacity": 0.1
      }
    });

    map.addLayer({
      id: "outerLine" + geofence.identifier,
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coordinates[0]
          }
        }
      },
      layout: {},
      paint: {
        "line-color": theme.palette["primary"][0],
        "line-width": 1
      }
    });

    map.addLayer({
      id: "point" + geofence.identifier,
      type: "circle",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center
          }
        }
      },
      layout: {},
      paint: {
        "circle-color": "#ffffff",
        "circle-radius": 6
      }
    });

    map.addLayer({
      id: "topPoint" + geofence.identifier,
      type: "circle",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: center
          }
        }
      },
      layout: {},
      paint: {
        "circle-color": theme.palette["primary"][0],
        "circle-radius": 3
      }
    });
  }

  render() {
    const { theme, view, height } = this.props;
    const { viewport, mapStyle } = this.state;
    const navStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "10px"
    };
    const pose = this.state.showMap === true ? "show" : "hide";
    return (
      <GeofencesCardWrapper>
        <motion.div
          style={{ width: "100%", height: "100%" }}
          animate={pose}
          variants={mapWrapperVariants}
        >
          <MapGL
            ref={map => {
              this.map = map;
            }}
            {...viewport}
            visible={true}
            onLoad={this.onLoad}
            width="100%"
            height={view === "MobileView" ? height - 360 : "100%"}
            preventStyleDiffing={true}
            mapStyle={mapStyle}
            onViewportChange={this._onViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            <div className="nav" style={navStyle}>
              <NavigationControl onViewportChange={this._onViewportChange} />
            </div>
          </MapGL>
        </motion.div>
        {!this.state.showMap ? (
          <ClipLoader
            loading={true}
            color={theme.palette["primary"][0]}
            css={override}
          />
        ) : null}
      </GeofencesCardWrapper>
    );
  }
}

export default withTheme(GeoFencesCardMap);
