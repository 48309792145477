import { ADD_PLAN, ACTIVE_PLAN_FETCHED } from "../../constants";
import { put } from "redux-saga/effects";
// import PlansApi from '../../api/PlansApi';

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setActivePlanUpdate(data, subscriptionId, metaType) {
  const plan = data.snapshot.data();
  yield put({
    type: ACTIVE_PLAN_FETCHED,
    value: true
  });
  if (plan !== undefined) {
    yield put({ type: ADD_PLAN, data: plan });
    // PlansApi.setUserPlan(userData);
  }
}
