import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../utility/intlMessages";

const ChatTopContainer = props => {
  const { sessionsMembers } = props;
  const owner = sessionsMembers.filter(member => member.owner === true)[0];
  const ownerName = owner ? owner.fullName : "";
  const ownerPhoto = owner && owner.photoUrl ? owner.photoUrl.uri : "";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={ownerPhoto} alt="" />
      <div>
        <h2>{ownerName}</h2>
        <p>
          {sessionsMembers.length}{" "}
          {<IntlMessages id="app.sessions.participants" />}
        </p>
      </div>
    </div>
  );
};

ChatTopContainer.propTypes = {
  sessionsMembers: PropTypes.array
};

export default ChatTopContainer;
