import styled from "styled-components";
import { palette } from "styled-theme";

const ControlWrapper = styled.div`
  padding: ${(props) =>
    props["data-rtl"] === "rtl" ? "5px 10px 10px 5px" : "5px 5px 10px 10px"};
  min-width: 300px;
  width: fit-content;
  .mapActionsContainer,
  .mapFiltersContainer,
  .mapParticipantsContainer {
    border: 1px solid ${palette("border", 0)};
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
  }
  .label {
    color: ${palette("text", 0)} !important;
  }
  .respondersList {
    padding: 0px !important;
  }
`;

export default ControlWrapper;
