import styled from "styled-components";

const CategoryInnerVertical = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  margin-top: ${props => (props["withHeader"] === true ? "60px" : "none")};
`;

export default CategoryInnerVertical;
