import { createSelector } from "reselect";

const activeCircleId = state => state.LocalState.activeCircleId;
const activeMemberProfile = state => state.Firebase.activeMemberProfile.private;
const circleMembers = state => {
  return state.Firebase.members &&
    state.LocalState.activeCircleId &&
    state.Firebase.members[state.LocalState.activeCircleId]
    ? state.Firebase.members[state.LocalState.activeCircleId]
    : [];
};

const circleResponders = state => {
  return state.Firebase.members &&
    state.LocalState.activeCircleId &&
    state.Firebase.members[state.LocalState.activeCircleId]
    ? state.Firebase.members[state.LocalState.activeCircleId].filter(
        contact => contact.responder === true
      )
    : [];
};

const activeCircle = state =>
  state.Firebase.circles.filter(
    circle => circle.id === state.LocalState.activeCircleId
  )[0];

export const getActiveCircle = createSelector([activeCircle], activeCircle => {
  return activeCircle;
});

export const getSelectedContact = createSelector(
  [circleMembers, activeCircleId, activeMemberProfile],
  (circleMembers, activeCircleId, activeMemberProfile) => {
    return activeMemberProfile && activeCircleId
      ? circleMembers.filter(
          contact => contact.id === activeMemberProfile.id
        )[0]
      : null;
  }
);

export const getSelectedResponder = createSelector(
  [circleResponders, activeCircleId, activeMemberProfile],
  (circleResponders, activeCircleId, activeMemberProfile) => {
    return activeMemberProfile && activeCircleId
      ? circleResponders.filter(
          contact => contact.id === activeMemberProfile.id
        )[0]
      : null;
  }
);

export const getCircleMembers = createSelector(
  [circleMembers],
  circleMembers => {
    return circleMembers;
  }
);

export const getCircleResponders = createSelector(
  [circleResponders],
  circleResponders => {
    return circleResponders;
  }
);

export const getCircleMembersCount = createSelector(
  [circleMembers],
  circleMembers => {
    return circleMembers.length.toString();
  }
);

export const getCircleRespondersCount = createSelector(
  [circleResponders],
  circleResponders => {
    return circleResponders.length.toString();
  }
);

export const getActiveCircleId = createSelector(
  [activeCircle],
  activeCircle => {
    return activeCircle ? activeCircle.id : null;
  }
);

export const getGroupType = createSelector([activeCircle], activeCircle => {
  return activeCircle ? activeCircle.groupType : null;
});

export const getContinuousTracking = createSelector(
  [circleResponders, activeCircleId, activeMemberProfile],
  (circleResponders, activeCircleId, activeMemberProfile) => {
    const selectedResponder =
      activeMemberProfile && activeCircleId
        ? circleResponders.filter(
            contact => contact.id === activeMemberProfile.id
          )[0]
        : null;
    return selectedResponder &&
      selectedResponder.continuousTrackingRequest === true
      ? true
      : false;
  }
);
