import {
  UPDATE_ACTIVE_PROFILE_PRIVATE_INFO,
  UPDATE_ACTIVE_PROFILE_MEDICAL_INFO,
  RESET_ACTIVE_PROFILE
} from "../../constants";

const INITIAL_STATE = {
  private: {},
  medical: {}
};

function activeMemberProfile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ACTIVE_PROFILE_PRIVATE_INFO:
      return {
        ...state,
        private: action.value
      };
    case UPDATE_ACTIVE_PROFILE_MEDICAL_INFO:
      return {
        ...state,
        medical: action.value
      };
    case RESET_ACTIVE_PROFILE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default activeMemberProfile;
