import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/store";
import { connect } from "react-redux";
import App from "./containers/App/App";
import Console from "./containers/Console/Console";
import Consent from "./containers/Consent/consent";
import Session from "./containers/Session/Session";
import NewCircle from "./containers/CircleCreation/index";
import Settings from "./containers/Settings/Settings";
import VendorDetails from "./containers/Vendor/VendorDetails";
import Plans from "./containers/Plans/Plans";
import asyncComponent from "./helpers/AsyncFunc";
import RestrictedRouteWrapper from "./restrictedRouteWrapper";

const RestrictedRoute = ({
  component: Component,
  isLoggedIn,
  fetched,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <RestrictedRouteWrapper {...props} component={Component} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ isLoggedIn, localState, fetched }) => {
  return (
    <ConnectedRouter history={history}>
      <Route
        exact
        path={"/"}
        component={asyncComponent(() =>
          localState.uid
            ? import("./containers/Consent/consent")
            : import("./containers/Page/signin")
        )}
      />
      <Route
        exact
        path={"/404"}
        component={asyncComponent(() => import("./containers/Page/404"))}
      />
      <Route
        exact
        path={"/500"}
        component={asyncComponent(() => import("./containers/Page/500"))}
      />
      <Route
        exact
        path={"/signin"}
        component={asyncComponent(() => import("./containers/Page/signin"))}
      />
      <Route
        exact
        path={"/signup"}
        component={asyncComponent(() => import("./containers/Page/signup"))}
      />
      <Route
        exact
        path={"/forgotpassword"}
        component={asyncComponent(() =>
          import("./containers/Page/forgotPassword")
        )}
      />
      <Route
        exact
        path={"/resetpassword"}
        component={asyncComponent(() =>
          import("./containers/Page/resetPassword")
        )}
      />
      <Route
        exact
        path={"/terms"}
        component={asyncComponent(() =>
          import("./containers/Consent/termsAndConditions")
        )}
      />
      <Route
        exact
        path={"/privacy"}
        component={asyncComponent(() =>
          import("./containers/Consent/privacyPolicy")
        )}
      />

      <Route path={"/" + localState.uid + "/consent"} component={Consent} />
      <RestrictedRoute
        path={"/" + localState.uid + "/console"}
        component={Console}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/dashboard/" + localState.activeCircleId}
        component={App}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/session/" + localState.activeSessionId}
        component={Session}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={
          "/" +
          localState.uid +
          "/resolvedSession/" +
          localState.activeResolvedSessionId
        }
        component={Session}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/create"}
        component={NewCircle}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/settings"}
        component={Settings}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/vendorDetails"}
        component={VendorDetails}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path={"/" + localState.uid + "/plans"}
        component={Plans}
        isLoggedIn={isLoggedIn}
      />
    </ConnectedRouter>
  );
};

export default connect(state => ({
  localState: state.LocalState,
  fetched:
    state.Firebase.fetch.circlesFetched === true &&
    state.Firebase.fetch.plansFetched === true &&
    state.Firebase.fetch.userFetched === true &&
    state.Firebase.fetch.activePlanFetched === true
      ? true
      : false,
  isLoggedIn:
    state.Auth.idToken !== null && state.Auth.idToken !== "secret token"
      ? true
      : false
}))(PublicRoutes);
