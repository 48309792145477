import styled from "styled-components";
import { palette } from "styled-theme";

const RespondersInviteListWrapper = styled.div`
  /* height: 100%;
  display: flex; */
  .respondersTopContainer {
    h2 {
      margin: 0;
    }
    .iconWrapper {
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
      width: 40px;
      height: 40px;
      background-color: ${palette("surfaceColor", 0)};
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        font-size: 18px;
        color: ${palette("text", 0)};
      }
    }
  }
`;

export default RespondersInviteListWrapper;
