import styled from "styled-components";
import {palette} from "styled-theme";

const ChatInputWrapper = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  z-index: 999;
  padding: 5px 0;
  background-color: ${palette("surfaceColor", 0)};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid ${palette("border", 0)};
  .chat-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .chat-input-textfield {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    padding-left: 10px;
    textarea {
      background-color: ${palette("surfaceColor", 0)};
      width: 100%;
      border: none;
      min-height: 1.2em;
      font-size: 1em;
      line-height: 1.2em;
      border: none;
      touch-action: none;
      // border-color: #BDBDBD;
      resize: none;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    textarea:focus {
      outline-width: 0;
    }
  }
  .chat-input-camera {
    display: table-cell;
    width: 60px;
    button {
      height: 36px !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .chat-input-send.ant-btn {
    margin: 0 0.5em;
    background: @app-green-color;
    border-color: @app-green-color;
    &:hover {
      background: @app-green-color;
      border-color: @app-green-color;
      opacity: 0.8;
    }
    &[disabled] {
      background-color: #f7f7f7;
      border-color: ${palette("border", 0)};
    }
    // padding: 0 0.7em;

    // display: table-cell;
    //     width: 50px;
  }
`;

export default ChatInputWrapper;
