import * as React from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { motion } from "framer-motion";
import { MenuToggle } from "./menuToggle";
import { Navigation } from "./navigation";
import { SessionNavigation } from "../../session/mobile/sessionNavigation";

export const MotionMenu = props => {
  const {
    height,
    toggleMotionMenu,
    motionMenuOpened,
    motionMenuOverflowMode,
    zIndex,
    url,
    current,
    type,
    showSessionMap,
    showInviteList,
    showParticipants,
    showCallerInfo,
    showCallerRouteMap,
    showChat,
    currentView
  } = props;
  const containerRef = useRef(null);
  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 30px 30px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      },
      height: "100vh"
      // backgroundColor: "#ffffff"
    }),
    closed: {
      clipPath: "circle(20px at 30px 30px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      },
      height: "100vh"
      // backgroundColor: "#D7DADF"
    }
  };
  return (
    <motion.nav
      style={{
        overflow: motionMenuOverflowMode,
        zIndex: zIndex
      }}
      initial={false}
      animate={motionMenuOpened ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className="motionNav"
    >
      <motion.div className="background" variants={sidebar} />
      {type === "mobile" ? (
        <SessionNavigation
          url={url}
          toggle={toggleMotionMenu}
          current={current}
          showSessionMap={showSessionMap}
          showInviteList={showInviteList}
          showParticipants={showParticipants}
          showCallerInfo={showCallerInfo}
          showCallerRouteMap={showCallerRouteMap}
          showChat={showChat}
          currentView={currentView}
        />
      ) : (
        <Navigation url={url} toggle={toggleMotionMenu} current={current} />
      )}
      <MenuToggle toggle={() => toggleMotionMenu()} />
    </motion.nav>
  );
};

MotionMenu.propTypes = {
  height: PropTypes.number,
  toggleMotionMenu: PropTypes.func,
  motionMenuOpened: PropTypes.bool,
  motionMenuOverflowMode: PropTypes.string,
  url: PropTypes.string,
  current: PropTypes.string,
  type: PropTypes.string,
  showSessionMap: PropTypes.func,
  showInviteList: PropTypes.func,
  showParticipants: PropTypes.func,
  showCallerInfo: PropTypes.func,
  showCallerRouteMap: PropTypes.func,
  showChat: PropTypes.func,
  currentView: PropTypes.string,
  zIndex: PropTypes.number
};
