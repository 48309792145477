import { createSelector } from "reselect";

const plans = state => state.LocalState.plans;
const plan = state => state.Firebase.activePlan;

const alerts = state =>
  state.Firebase.alerts &&
  state.LocalState.activeCircleId &&
  state.Firebase.alerts[state.LocalState.activeCircleId] &&
  state.Firebase.alerts[state.LocalState.activeCircleId].length > 0
    ? state.Firebase.alerts[state.LocalState.activeCircleId]
    : [];

const circleAlerts = (state, props) =>
  state.Firebase.alerts &&
  props.circle.id &&
  state.Firebase.alerts[props.circle.id] &&
  state.Firebase.alerts[props.circle.id].length > 0
    ? state.Firebase.alerts[props.circle.id]
    : [];

const selectedAlert = state =>
  state.Firebase.alerts &&
  state.LocalState.activeCircleId &&
  state.LocalState.selectedAlert &&
  state.Firebase.alerts[state.LocalState.activeCircleId] &&
  state.Firebase.alerts[state.LocalState.activeCircleId].length > 0
    ? state.Firebase.alerts[state.LocalState.activeCircleId].filter(
        alert => alert.alertId === state.LocalState.selectedAlert
      )[0]
    : null;

export const getAlerts = createSelector([alerts], alerts => {
  return alerts;
});

export const getAlertsCount = createSelector([alerts], alerts => {
  return alerts.length.toString();
});

export const getSelectedAlert = createSelector(
  [selectedAlert],
  selectedAlert => {
    return selectedAlert;
  }
);

export const makeGetCircleAlertsCount = () => {
  return createSelector([circleAlerts], circleAlerts => {
    return circleAlerts.length.toString();
  });
};

export const isAllowedToUseAlerts = createSelector(
  [plans, plan],
  (plans, plan) => {
    const planName = plan && plan.planName ? plan.planName : "free"; //temporary]
    const restrictions = plans[planName];
    if (restrictions !== undefined) {
      return (
        restrictions.allowedToUseInstantAlerts &&
        restrictions.allowedToUseGeographicAlerts
      );
    }
    return false;
  }
);
