import React from "react";
import Stat from "../../components/overview/stat";
import OpenSessionStat from "../../components/overview/openSessionStat";
import GeoFencesCard from "../../components/overview/geoFencesCard";
import CategoryCenter from "../../components/layout/categoryCenter.style";
import CategoryInner from "../../components/layout/categoryInner.style";
import CategoryOuter from "../../components/layout/categoryOuter.style";
import CategoryRight from "../../components/layout/categoryRight.style";
import { useSelector } from "react-redux";

const Overview = () => {
  const activeCircleId = useSelector(state => state.LocalState.activeCircleId);
  const openSessions = useSelector(state => state.Firebase.openSessions);
  const sessionsMembers = useSelector(state => state.Firebase.participants);
  const circles = useSelector(state => state.Firebase.circles);
  const members = useSelector(state => state.Firebase.members);

  const geoFences = useSelector(state => state.Firebase.geoFences);
  const localState = useSelector(state => state.LocalState);
  const userSettings = useSelector(state => state.Firebase.userData.settings);

  const direction =
    userSettings && userSettings.language && userSettings.language.direction
      ? userSettings.language.direction
      : "ltr";
  var array = geoFences[activeCircleId] ? geoFences[activeCircleId] : [];
  let geoFenceList = [];
  if (array.length === 1) {
    geoFenceList.push(array[0]);
  } else if (array.length > 1) {
    for (let i = 0; i < 2; i++) {
      const geoFence = array[i];
      geoFenceList.push(geoFence);
    }
  }

  return (
    <CategoryOuter>
      <CategoryInner screen={"overview"}>
        <CategoryCenter style={{ height: "auto" }} flex={true}>
          <GeoFencesCard direction={direction} />
        </CategoryCenter>
        <CategoryRight
          data-rtl={direction}
          screen={"overview"}
          style={{
            backgroundColor: "transparent",
            border: "none",
            flexDirection: "column"
          }}
        >
          <OpenSessionStat direction={direction} />
          <Stat
            direction={direction}
            localState={localState}
            activeCircleId={activeCircleId}
            openSessions={openSessions}
            sessions={openSessions[activeCircleId]}
            sessionsMembers={sessionsMembers}
            circles={circles}
            members={members}
          />
        </CategoryRight>
      </CategoryInner>
    </CategoryOuter>
  );
};

export default Overview;
