import {
  eventTypes,
  ADD_CIRCLE_MEMBER,
  REMOVE_CIRCLE_MEMBER,
  CIRCLE_MEMBERS_FETCH_SUCCESS
} from "../../constants";
import Backend from "../../../api/Backend";
import { store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";
import { eventChannel, buffers } from "redux-saga";
import { put, call, take } from "redux-saga/effects";

export function getMemberSnap(ref) {
  const listener = eventChannel(emit => {
    ref.get().then(MemberSnap => {
      emit({
        snapshot: MemberSnap
      });
    });
    return () => {};
  }, buffers.expanding(1));
  return listener;
}

// listens when circle members are added, removed or modified
export function* setCircleMemberUpdate(data, circleId, metaType) {
  let allMembers = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j];
    // listens when circle members are added
    if (change.type === eventTypes.ADDED) {
      let member = change.doc.data() || {};

      const members = store.getState().Firebase.members[circleId];
      const previous = members.filter(item => item.id === member.id)[0];
      if (previous !== undefined && previous.name !== undefined) {
        member["name"] = previous.name;
        member["photoUrl"] = previous.photoUrl;
        //if there is more then one change, it is initial load
        if (data.snapshot.docChanges().length > 1) {
          allMembers.push(member);
          if (counter === data.snapshot.docChanges().length) {
            yield put({
              type: CIRCLE_MEMBERS_FETCH_SUCCESS,
              circleId: circleId,
              circleMembers: allMembers
            });
          }
        } else {
          yield put({
            type: ADD_CIRCLE_MEMBER,
            circleId: circleId,
            member: member
          });
        }
      } else {
        const ref = firebase
          .firestore()
          .collection("users")
          .doc(member.id);
        const chan = yield call(getMemberSnap, ref);
        try {
          const userData = yield take(chan);
          if (
            userData.snapshot &&
            userData.snapshot.data() &&
            userData.snapshot.data().general_info
          ) {
            member["name"] = userData.snapshot.data().general_info.fullName;
            member["photoUrl"] = userData.snapshot.data().general_info.photoUrl;
          }
          //if there is more then one change, it is initial load
          if (data.snapshot.docChanges().length > 1) {
            allMembers.push(member);
            if (counter === data.snapshot.docChanges().length) {
              yield put({
                type: CIRCLE_MEMBERS_FETCH_SUCCESS,
                circleId: circleId,
                circleMembers: allMembers
              });
            }
          } else {
            yield put({
              type: ADD_CIRCLE_MEMBER,
              circleId: circleId,
              member: member
            });
          }

          if (
            member.id === Backend.uid &&
            member.responder === true &&
            member.trackLocation === true
          ) {
            // this.startTrackingResponderLocation(circleId)
          }
        } finally {
          chan.close();
        }
      }
    } else if (change.type === eventTypes.MODIFIED) {
      // listens when circle members are modified
      const members = store.getState().Firebase.members[circleId];
      const previous = members.filter(
        member => member.id === change.doc.data().id
      )[0];
      const user = change.doc.data() || {};
      user["name"] = previous.name;
      user["photoUrl"] = previous.photoUrl;
      yield put({ type: ADD_CIRCLE_MEMBER, circleId: circleId, member: user });
    } else if (change.type === eventTypes.REMOVED) {
      yield put({
        type: REMOVE_CIRCLE_MEMBER,
        id: change.doc.data().id,
        circleId: circleId
      });
    }
  }
}
