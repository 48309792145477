import styled from "styled-components";
import { palette } from "styled-theme";

const ModalMapWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: ${palette("surfaceColor", 0)};
  .modalInnerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${(props) =>
      props["type"] === "mobile" ? "0px" : "0 12px 12px 0"};
    .mapContainer {
      width: 100%;
      flex-direction: column;
      display: flex;
      .mapControlContainer {
        justify-content: flex-end;
        height: 50px;
        width: 100%;
        background: ${palette("surfaceColor", 0)};
        display: flex;
        align-items: center;
      }
      p {
        color: ${palette("lightText", 0)};
      }
      .ant-btn {
        color: ${palette("lightText", 0)};
        background-color: ${palette("surfaceColor", 0)};
      }
    }
  }
  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default ModalMapWrapper;
