import React, { Component } from "react";
import PropTypes from "prop-types";
import MapGL, {
  Marker,
  Popup,
  NavigationControl,
  FlyToInterpolator,
} from "react-map-gl";
import Control from "../mapBoxControl/control";
import "mapbox-gl/dist/mapbox-gl.css";
import { onChangeViewport } from "../../redux/reducers/mapBox/index";
import { connect } from "react-redux";
import MarkerPin from "./markerPin";
import OwnerPin from "./ownerPin";
import PopUpInfo from "./popUpInfo";
import SessionMapWrapper from "./sessionMap.style";
import { Popover, Button } from "antd";
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const navStyle = {
  position: "absolute",
  top: 10,
  right: 40,
};

class SessionMapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponders: true,
      showOwner: true,
      selectedResponder: null,
      ownerLocation: {},
      visible: false,
      app: null,
      viewport: {
        latitude: 37.7751,
        longitude: -122.4193,
        zoom: 11,
        bearing: 0,
        pitch: 0,
        width: "100%",
        height: 500,
      },
    };
    this._onMapClicked = this._onMapClicked.bind(this);
    this.setRespondersVisibility = this.setRespondersVisibility.bind(this);
    this.setOwnerVisibility = this.setOwnerVisibility.bind(this);
    this.focusOnCaller = this.focusOnCaller.bind(this);
    this.focusOnParticipant = this.focusOnParticipant.bind(this);
    this.onMapStyleChange = this.onMapStyleChange.bind(this);
  }

  static propTypes = {
    mapStyle: PropTypes.string,
    session: PropTypes.object,
    locations: PropTypes.array,
    ownerLocation: PropTypes.object,
    app: PropTypes.object,
    members: PropTypes.array,
    direction: PropTypes.string,
    width: PropTypes.number,
    profileId: PropTypes.string,
    showInvite: PropTypes.bool,
    showCallerInfo: PropTypes.bool,
    showChat: PropTypes.bool,
    activeParticipants: PropTypes.array,
    showProfileFromPopup: PropTypes.func,
    setProfileId: PropTypes.func,
    type: PropTypes.string,
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  componentDidMount() {
    this.setViewport();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.session !== this.state.session) {
      this.setViewport();
    }

    if (
      this.state.ownerLocation !== prevState.ownerLocation &&
      prevState.ownerLocation.location === undefined
    ) {
      this.setViewport();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.app !== prevState.app) {
      return {
        viewport: {
          ...prevState.viewport,
          height: nextProps.app.height - 33,
          width: "100%",
        },
      };
    }

    if (nextProps.session !== prevState.session) {
      return { session: nextProps.session };
    } else if (nextProps.profileId !== prevState.profileId) {
      return { profileId: nextProps.profileId };
    } else if (nextProps.showInvite !== prevState.showInvite) {
      return { showInvite: nextProps.showInvite };
    } else if (nextProps.showCallerInfo !== prevState.showCallerInfo) {
      return { showCallerInfo: nextProps.showCallerInfo };
    } else if (nextProps.showChat !== prevState.showChat) {
      return { showChat: nextProps.showChat };
    } else if (nextProps.ownerLocation !== prevState.ownerLocation) {
      return { ownerLocation: nextProps.ownerLocation };
    } else return null;
  }

  setViewport() {
    if (this.props.ownerLocation && this.props.ownerLocation.location) {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: this.props.ownerLocation.location.latitude,
          longitude: this.props.ownerLocation.location.longitude,
        },
      });
    }
  }

  onMapStyleChange(value) {
    this.setState({ style: value, visible: false });
  }

  focusOnCaller() {
    const ownerMarker = this.props.locations.filter(
      (location) => location.userId === this.props.session.ownerId
    )[0];
    this.setState({ visible: false });
    this._goToViewport(
      ownerMarker.location.longitude,
      ownerMarker.location.latitude,
      17
    );
  }

  focusOnParticipant(uid) {
    const responder = this.props.locations.filter(
      (location) => location.userId === uid
    )[0];
    this.setState({ selectedResponder: responder, visible: false });
    if (responder && responder.location) {
      this._goToViewport(
        responder.location.longitude,
        responder.location.latitude,
        17
      );
    }
  }

  _goToViewport = (longitude, latitude, zoom) => {
    this._onViewportChange({
      longitude,
      latitude,
      zoom: zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: "auto",
    });
  };

  _onViewportChange = (viewport) =>
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    });

  _onMapClicked() {
    this.setState({ selectedResponder: null });
  }

  setRespondersVisibility(value) {
    this.setState({ showResponders: value, visible: false });
  }

  setOwnerVisibility(value) {
    this.setState({ showOwner: value, visible: false });
  }

  _renderMemberMarker = (marker) => {
    let that = this;
    const member = this.props.members.filter(
      (member) => member.id === marker.userId
    )[0];
    const ownerMarker = this.props.locations.filter(
      (location) => location.userId === this.props.session.ownerId
    )[0];
    return marker.location ? (
      <Marker
        key={marker.userId}
        longitude={marker.location.longitude}
        latitude={marker.location.latitude}
      >
        <MarkerPin
          ownerMarker={ownerMarker}
          marker={marker}
          member={member}
          size={20}
          onClick={() => {
            setTimeout(function () {
              that.setState({ selectedResponder: marker });
            }, 500);
            this._goToViewport(
              marker.location.longitude,
              marker.location.latitude,
              14
            );
          }}
        />
      </Marker>
    ) : null;
  };

  _renderOwnerMarker = () => {
    const ownerMarker = this.props.locations.filter(
      (location) => location.userId === this.props.session.ownerId
    )[0];
    if (ownerMarker) {
      return (
        <Marker
          key={ownerMarker.userId}
          longitude={ownerMarker.location.longitude}
          latitude={ownerMarker.location.latitude}
        >
          <OwnerPin
            direction={this.props.direction}
            size={20}
            onClick={() => {
              this.setState({ selectedResponder: null });
              this._goToViewport(
                ownerMarker.location.longitude,
                ownerMarker.location.latitude,
                14
              );
            }}
          />
        </Marker>
      );
    } else {
      return null;
    }
  };

  _renderPopup() {
    const { showProfileFromPopup, setProfileId, type } = this.props;
    const { selectedResponder } = this.state;
    const ownerMarker = this.props.locations.filter(
      (location) => location.userId === this.props.session.ownerId
    )[0];

    return (
      selectedResponder && (
        <Popup
          anchor={"bottom"}
          offsetLeft={0}
          offsetTop={-70}
          closeOnClick={false}
          closeButton={false}
          longitude={selectedResponder.location.longitude}
          latitude={selectedResponder.location.latitude}
          onClose={() => this.setState({ selectedResponder: null })}
        >
          <PopUpInfo
            ownerMarker={ownerMarker}
            marker={selectedResponder}
            members={this.props.members}
            direction={this.props.direction}
            setProfileId={setProfileId}
            showProfileFromPopup={
              type === "mobile" ? setProfileId : showProfileFromPopup
            }
          />
        </Popup>
      )
    );
  }

  render() {
    const {
      viewport,
      showResponders,
      showOwner,
      selectedResponder,
    } = this.state;
    const { locations, type } = this.props;
    const { mapStyle, members, activeParticipants } = this.props;
    const responders = locations.filter(
      (location) => location.userId !== this.props.session.ownerId
    );
    const ownerMarker = locations.filter(
      (location) => location.userId === this.props.session.ownerId
    )[0];
    return (
      <SessionMapWrapper className="sessionMapControl">
        <MapGL
          ref={(map) => {
            this.map = map;
          }}
          {...viewport}
          className="sessionMap"
          mapStyle={this.state.style}
          preventStyleDiffing={true}
          reuseMaps={true}
          onViewportChange={this._onViewportChange}
          onClick={this._onMapClicked}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          {showResponders === true
            ? responders.map(this._renderMemberMarker)
            : null}
          {showOwner === true ? this._renderOwnerMarker() : null}
          {this._renderPopup()}
          <div className="nav" style={navStyle}>
            <NavigationControl onViewportChange={this._onViewportChange} />
          </div>
        </MapGL>
        {type !== "mobile" ? (
          <Control
            direction={this.props.direction}
            sessionId={this.props.session ? this.props.session.sessionId : null}
            members={members}
            ownerMarker={ownerMarker}
            locations={locations}
            selectedId={selectedResponder ? selectedResponder.userId : null}
            focusOnCaller={this.focusOnCaller}
            focusOnParticipant={this.focusOnParticipant}
            setRespondersVisibility={this.setRespondersVisibility}
            setOwnerVisibility={this.setOwnerVisibility}
            activeParticipants={activeParticipants}
            onMapStyleChange={this.onMapStyleChange}
          />
        ) : (
          <Popover
            content={
              <Control
                direction={this.props.direction}
                sessionId={
                  this.props.session ? this.props.session.sessionId : null
                }
                members={members}
                ownerMarker={ownerMarker}
                locations={locations}
                selectedId={selectedResponder ? selectedResponder.userId : null}
                focusOnCaller={this.focusOnCaller}
                focusOnParticipant={this.focusOnParticipant}
                setRespondersVisibility={this.setRespondersVisibility}
                setOwnerVisibility={this.setOwnerVisibility}
                activeParticipants={activeParticipants}
                onMapStyleChange={this.onMapStyleChange}
              />
            }
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            placement="bottomRight"
          >
            <Button
              style={{
                position: "absolute",
                top: "-52px",
                zIndex: "100",
                right: "10px",
                padding: "0px 20px",
                height: "42px",
                margin: 0,
              }}
              className="light"
              type="primary"
            >
              Options
            </Button>
          </Popover>
        )}
      </SessionMapWrapper>
    );
  }
}

function mapStateToProps(state) {
  const mapStyle = state.MapBox.mapStyle;
  return {
    mapStyle,
    app: state.App,
  };
}

const actions = {
  onChangeViewport,
};

export default connect(mapStateToProps, actions)(SessionMapBox);
