import styled from "styled-components";
import { palette } from "styled-theme";

const CategoryOuter = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  min-height: 0px;
  height: 100%;
  background-color: ${palette("background", 0)};

  .addButton {
    position: absolute;
    bottom: 20px;
    right: ${props => (props["data-rtl"] === "rtl" ? "auto" : "20px")};
    left: ${props => (props["data-rtl"] === "rtl" ? "20px" : "auto")};
    padding: 10px;
    height: auto;
    border-radius: 60px !important;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }
`;

export default CategoryOuter;
