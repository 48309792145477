import styled, { css, keyframes } from "styled-components";
import { palette } from "styled-theme";

const outerPulse = keyframes`
0% {
  transform: scale(1);
  opacity:0.8;
}
100% {
  transform: scale(5);
  opacity:0;
}
`;

const innerPulse = keyframes`
0% {
  transform: scale(1)
}
50% {
  transform: scale(1.3)
}
100% {
  transform: scale(1)
}
`;

const outerPulseRule = css`
  ${outerPulse} 3s infinite;
`;

const innerPulseRule = css`
  ${innerPulse} 3s infinite;
`;

const OwnerPinWrapper = styled.div`
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 13px;
  border: 1px solid ${palette("primary", 0)} !important;
  display: flex;
  transform: translate(-13px, -13px);
  .callerOuterPulse {
    background-color: ${palette("primary", 0)} !important;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    position: absolute;
    animation: ${outerPulseRule};
  }
  .callerInnerPulse {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: ${palette("primary", 0)} !important;
    animation: ${innerPulseRule};
  }
`;

export default OwnerPinWrapper;
