import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const contentWrapperVariants = {
  initial: {
    opacity: 0
  },
  show: {
    opacity: 1,
    transition: { duration: 1 }
  },
  hide: { opacity: 0, transition: { duration: 1 } }
};

const ImageContainer = props => {
  const [renderContent, setRenderContent] = useState(false);
  const { disableanimation, src, children } = props;

  useEffect(() => {
    if (src) {
      setRenderContent(true);
    }
  }, [src]);

  const pose =
    renderContent === true || disableanimation === "true" ? "show" : "hide";
  return children ? (
    <motion.div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        width: "100%",
        height: "100%"
      }}
      initial={"initial"}
      animate={pose}
      variants={contentWrapperVariants}
    >
      {children}
    </motion.div>
  ) : (
    <motion.div
      initial={"initial"}
      animate={pose}
      variants={contentWrapperVariants}
    >
      <img {...props} alt="" />
    </motion.div>
  );
};

ImageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disableanimation: PropTypes.string,
  src: PropTypes.string
};

export default ImageContainer;
