import {
  IS_SIGNED,
  SET_UID,
  SET_CIRCLES_DASHBOARD_CATEGORY,
  SET_CIRCLE_SNACK_BAR,
  SET_SNACK_BAR,
  SHOW_CALLER_ROUTE,
  SHOW_CHAT,
  SHOW_RESPONDERS_LOCATIONS,
  SET_CHAT_CLASS,
  SET_SESSION_INFO_MODAL,
  SET_PROFILE_MODAL,
  SET_GEO_FENCE_MODAL,
  SET_SESSION_DETAILS_CLASS,
  SET_ACTIVE_CIRCLE,
  SET_ACTIVE_SESSION,
  SET_ACTIVE_RESOLVED_SESSION,
  SET_ACTIVE_MEMBER_ID,
  SET_ACTIVE_RESPONDER_ID,
  SET_ACTIVE_PENDING_MEMBER_ID,
  SET_ACTIVE_GEO_FENCE_ID,
  SET_NEW_ALERT_VISIBILTY,
  FOCUS_ON_CALLER,
  SET_ANIMATE_MEMBERS,
  SET_ANIMATE_RESPONDERS,
  SET_SHOW_NEW_MEMBER,
  SET_SHOW_SEARCH_MEMBER,
  SET_SHOW_NEW_RESPONDER,
  SET_SHOW_SEARCH_RESPONDER,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  ADD_PLANS,
  ADD_PLAN,
  FILTER_BUCKET_ATTRIBUTE,
  FILTER_TAG_ATTRIBUTE,
  SET_SELECTED_ALERT_ID,
  COMPOSE_ALERT
} from "../../constants";
const INITIAL_STATE = {
  uid: "",
  newAlertVisibilty: false,
  isSigned: false,
  dashboardPreviousCategory: "",
  dashboardCategory: "overview",
  showCallerRoute: true,
  showChat: true,
  showRespondersLocations: true,
  chatClass: "initial",
  activeCircleId: "",
  activeSessionId: null,
  activeResolvedSessionId: null,
  activeSessionOwnerId: "",
  activeSessionCategory: "",
  activeMemberId: "",
  activeResponderId: "",
  activePendingMemberId: "",
  activeGeoFenceId: "",
  sessionDetailsClass: "initial",
  focusOnCaller: false,
  sessionInfoModal: {
    visible: false
  },
  profileModal: {
    visible: false,
    locked: false
  },
  geoFenceModal: {
    visible: false,
    id: null
  },
  snackBar: {
    visible: false,
    text: "",
    autoHide: true,
    label: ""
  },
  circleSnackBar: {
    visible: false,
    text: "",
    autoHide: true,
    label: ""
  },
  animateMembers: null,
  animateResponders: null,
  showNewMember: false,
  showSearchMember: false,
  showNewResponder: false,
  showSearchResponder: false,
  newCircleSuccess: null,
  newCircleFailure: null,
  deleteCircleSuccess: null,
  deleteCircleFailure: null,
  tag: undefined,
  selectedAlert: null,
  composeAlert: false,
  replyMail: false,
  searchString: "",
  bucket: "circleAlert"
};
const LocalState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UID:
      return {
        ...state,
        uid: action.id
      };
    case IS_SIGNED:
      return {
        ...state,
        isSigned: action.isSigned
      };
    case SET_CIRCLES_DASHBOARD_CATEGORY:
      return {
        ...state,
        dashboardPreviousCategory: state.dashboardCategory,
        dashboardCategory: action.category
      };
    case SET_SESSION_DETAILS_CLASS:
      return {
        ...state,
        sessionDetailsClass: action.class
      };
    case SHOW_CALLER_ROUTE:
      return {
        ...state,
        showCallerRoute: action.show
      };
    case SHOW_CHAT:
      return {
        ...state,
        showChat: action.show
      };
    case SHOW_RESPONDERS_LOCATIONS:
      return {
        ...state,
        showRespondersLocations: action.show
      };
    case SET_CHAT_CLASS:
      return {
        ...state,
        chatClass: action.chatClass
      };
    case FOCUS_ON_CALLER:
      return {
        ...state,
        focusOnCaller: action.value
      };
    case SET_SNACK_BAR:
      return {
        ...state,
        snackBar: {
          visible: action.visible,
          text: action.text,
          autoHide: action.autoHide,
          action: action.action
        }
      };
    case SET_CIRCLE_SNACK_BAR:
      return {
        ...state,
        circleSnackBar: {
          visible: action.visible,
          text: action.text,
          autoHide: action.autoHide,
          action: action.action
        }
      };
    case SET_SESSION_INFO_MODAL:
      return {
        ...state,
        sessionInfoModal: {
          visible: action.visible,
          category: action.category
        }
      };
    case SET_PROFILE_MODAL:
      return {
        ...state,
        profileModal: {
          visible: action.visible,
          locked: action.locked
        }
      };
    case SET_GEO_FENCE_MODAL:
      return {
        ...state,
        geoFenceModal: {
          visible: action.visible,
          id: action.id
        }
      };
    case SET_ACTIVE_CIRCLE:
      return {
        ...state,
        activeCircleId: action.circleId
      };
    case SET_ACTIVE_SESSION:
      return {
        ...state,
        activeSessionId: action.sessionId,
        activeSessionCategory: action.sessionCategory
      };
    case SET_ACTIVE_RESOLVED_SESSION:
      return {
        ...state,
        activeResolvedSessionId: action.sessionId,
        activeSessionCategory: action.sessionCategory
      };
    case SET_NEW_ALERT_VISIBILTY:
      return {
        ...state,
        newAlertVisibilty: action.value
      };
    case SET_ACTIVE_MEMBER_ID:
      return {
        ...state,
        activeMemberId: action.id
      };
    case SET_ACTIVE_RESPONDER_ID:
      return {
        ...state,
        activeResponderId: action.id
      };
    case SET_ACTIVE_PENDING_MEMBER_ID:
      return {
        ...state,
        activePendingMemberId: action.id
      };
    case SET_ACTIVE_GEO_FENCE_ID:
      return {
        ...state,
        activeGeoFenceId: action.id
      };
    case SET_ANIMATE_MEMBERS:
      return {
        ...state,
        animateMembers: action.value
      };
    case SET_ANIMATE_RESPONDERS:
      return {
        ...state,
        animateResponders: action.value
      };

    case SET_SHOW_NEW_MEMBER:
      return {
        ...state,
        showNewMember: action.value
      };

    case SET_SHOW_SEARCH_MEMBER:
      return {
        ...state,
        showSearchMember: action.value
      };

    case SET_SHOW_NEW_RESPONDER:
      return {
        ...state,
        showNewResponder: action.value
      };

    case SET_SHOW_SEARCH_RESPONDER:
      return {
        ...state,
        showSearchResponder: action.value
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        newCircleSuccess: action.payload
      };
    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        newCircleFailure: action.payload
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteCircleSuccess: action.payload
      };
    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        deleteCircleFailure: action.payload
      };
    case ADD_PLANS:
      return {
        ...state,
        plans: action.data
      };
    case ADD_PLAN:
      return {
        ...state,
        plan: action.data
      };

    case FILTER_BUCKET_ATTRIBUTE:
      return {
        ...state,
        bucket: action.bucket,
        selectedAlert: null,
        composeAlert: false,
        replyMail: false
      };

    case FILTER_TAG_ATTRIBUTE:
      return {
        ...state,
        tag: action.tag,
        selectedAlert: null,
        composeAlert: false,
        replyMail: false
      };

    case SET_SELECTED_ALERT_ID:
      return {
        ...state,
        selectedAlert: action.alertId,
        replyMail: false
      };

    case COMPOSE_ALERT:
      return {
        ...state,
        composeAlert: action.composeAlert,
        replyMail: false
      };

    default:
      return state;
  }
};
export default LocalState;
