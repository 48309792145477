import React, { useState } from "react";
import PropTypes from "prop-types";
import CategorySider from "../../components/layout/categorySider.style";
import CategoryCenter from "../../components/layout/categoryCenter.style";
import CategoryInner from "../../components/layout/categoryInner.style";
import CategoryOuter from "../../components/layout/categoryOuter.style";
import NewCircleWrapper from "./newCircleWrapper.style";
import SiderStep from "../../components/circleCreation/siderStep";
import { getDirection } from "../../redux/selectors/user";
import { Layout, Button } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import Topbar from "../Topbar/Topbar";
import { AppLocale } from "../../dashApp";
import { withTheme } from "styled-components";
import { appActions } from "../../redux/reducers/app/reducer";
import GeneralDetails from "../../components/circleCreation/generalDetails";
import Dropzone from "../../components/circleCreation/dropzone";
import CircleType from "../../components/circleCreation/circleType";
import JoinPolicy from "../../components/circleCreation/joinPolicy";
import RespondersPolicy from "../../components/circleCreation/respondersPolicy";
import Resizer from "react-image-file-resizer";
import CirclesApi from "../../api/CirclesApi";
import { PlusOutlined } from "@ant-design/icons";
import { store as notificationsStore } from "react-notifications-component";
import {
  SET_ACTIVE_CIRCLE,
  SET_CIRCLES_DASHBOARD_CATEGORY,
} from "../../redux/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const NewCircle = (props) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [name, setName] = useState("");
  const [description, setDescription] = useState(null);
  const [emergency, setEmergency] = useState(false);
  const [joinPolicy, setJoinPolicy] = useState("open");
  const [respondersAssignPolicy, setRespondersAssignPolicy] = useState(
    "everyone"
  );
  const [numberOfAssignResponders, setNumberOfAssignResponderse] = useState(3);
  const [sizedPic, setSizedPic] = useState(null);
  const [imageName, setImageName] = useState(null);

  const { match, history } = props;

  const { url } = match;

  const direction = useSelector((state) => getDirection(state));
  const locale = useSelector((state) => state.LanguageSwitcher.language.locale);
  const uid = useSelector((state) => state.Auth.uid);
  const currentAppLocale = AppLocale[locale];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const setCircleName = (value) => {
    setName(value);
  };

  const setCircleDescription = (value) => {
    setDescription(value);
  };

  const setCircleEmergency = (value) => {
    setEmergency(value);
  };

  const onJoinPolicyChange = (value) => {
    setJoinPolicy(value);
  };

  const onResponderPolicyChange = (value) => {
    setRespondersAssignPolicy(value);
  };

  const onResponderNumberChange = (value) => {
    setNumberOfAssignResponderse(value);
  };

  const addImage = (image) => {
    const imageName = image.name;
    Resizer.imageFileResizer(
      image,
      612,
      612,
      "JPEG",
      100,
      0,
      (sizedUri) => {
        setImageName(imageName);
        setSizedPic(sizedUri);
      },
      "base64"
    );
    // this.setState({image: image});
  };

  const handleSubmit = () => {
    const limit = 50;
    CirclesApi.submitNewCircle(
      description,
      name,
      imageName,
      sizedPic,
      emergency,
      joinPolicy,
      respondersAssignPolicy,
      numberOfAssignResponders,
      limit,
      function (id) {
        if (id !== null) {
          notificationsStore.addNotification({
            title: "Success!",
            message: "Circle was creates successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });

          setTimeout(() => {
            redirectToCircle(id);
          }, 3000);
        } else {
          notificationsStore.addNotification({
            title: "Circle was not created",
            message: "plaese try again",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      }
    );
  };

  const redirectToCircle = (id) => {
    dispatch({ type: SET_ACTIVE_CIRCLE, circleId: id });
    dispatch({
      type: SET_CIRCLES_DASHBOARD_CATEGORY,
      category: "overview",
    });
    dispatch({
      type: appActions.CHANGE_CURRENT,
      current: "overview",
    });

    history.push(`/${uid}/dashboard/${id}/overview`);
  };

  const general = current === 1 ? "inProcess" : "completed";
  const photo =
    current < 2 ? "initial" : current === 2 ? "inProcess" : "completed";
  const type =
    current < 3 ? "initial" : current === 3 ? "inProcess" : "completed";
  const join =
    current < 4 ? "initial" : current === 4 ? "inProcess" : "completed";

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Layout style={{ height: "100vh" }}>
        <Debounce time="1000" handler="onResize">
          <WindowSizeListener
            onResize={(windowSize) => {
              dispatch({
                type: appActions.TOGGLE_ALL,
                height: windowSize.windowHeight,
                width: windowSize.windowWidth,
              });
            }}
          />
        </Debounce>
        <Topbar url={url} />
        <NewCircleWrapper>
          <CategoryOuter data-rtl={direction}>
            <CategorySider className="sider">
              <div className="siderHeader">
                <h1>New Circle</h1>
              </div>
              <SiderStep text="General details" number="1" status={general} />
              <SiderStep text="Circle Photo" number="2" status={photo} />
              <SiderStep text="Circle Type" number="3" status={type} />
              <SiderStep text="Join Policy" number="4" status={join} />
            </CategorySider>

            <CategoryInner>
              <CategoryCenter className="componentWrapper">
                {current === 1 ? (
                  <GeneralDetails
                    handleSubmit={handleSubmit}
                    name={name}
                    description={description}
                    setCircleName={setCircleName}
                    setCircleDescription={setCircleDescription}
                  />
                ) : null}
                {current === 2 ? (
                  <Dropzone uri={sizedPic} addImage={addImage} />
                ) : null}

                {current === 3 ? (
                  <CircleType
                    setCircleEmergency={setCircleEmergency}
                    emergency={emergency}
                  />
                ) : null}
                {current === 4 ? (
                  <JoinPolicy
                    joinPolicy={joinPolicy}
                    onJoinPolicyChange={onJoinPolicyChange}
                  />
                ) : null}

                <div className="steps-action">
                  {current > 1 && (
                    <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                      Previous
                    </Button>
                  )}
                  {current < 4 && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => next()}
                      disabled={current === 1 && name.length < 3}
                      className={"light"}
                    >
                      Next
                    </Button>
                  )}
                  {current === 4 && (
                    <Button type="primary" onClick={() => handleSubmit()}>
                      Done
                    </Button>
                  )}
                </div>
              </CategoryCenter>
            </CategoryInner>
          </CategoryOuter>
        </NewCircleWrapper>
      </Layout>
    </IntlProvider>
  );
};

NewCircle.propTypes = {
  direction: PropTypes.string,
  toggleAll: PropTypes.func,
  locale: PropTypes.string,
  match: PropTypes.object,
  changeCurrent: PropTypes.func,
  history: PropTypes.object,
  userUid: PropTypes.string,
};

export default withTheme(NewCircle);

// const policy =
//   current < 5 ? "initial" : current === 5 ? "inProcess" : "completed";
//
//   <SiderStep text="Responders Policy" number="5" status={policy} />
//
//   {current === 5 ? (
//     <RespondersPolicy
//       respondersAssignPolicy={respondersAssignPolicy}
//       numberOfAssignResponders={numberOfAssignResponders}
//       onResponderPolicyChange={onResponderPolicyChange}
//       onResponderNumberChange={onResponderNumberChange}
//     />
//   ) : null}
