import englishLang from "./image/flag/uk.svg";
import hebrewLang from "./image/flag/israel.svg";
import themes from "./settings/themes";

export default {
  apiUrl: "http://yoursite.com/api/"
};
const siteConfig = {
  siteName: "Looka",
  siteIcon: "ion-md-flash",
  footerText: "Looka ©2020"
};

const themeConfig = {
  topbar: "lightTheme",
  sidebar: "lightTheme",
  layout: "lightTheme",
  theme: "lightTheme"
};
const language = "english";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const mapboxConfig = {
  tileLayer: "",
  accessToken: "",
  id: "",
  maxZoom: "",
  defaultZoom: "",
  center: []
};

const languageConfig = {
  defaultLanguage: language,
  options: [
    {
      languageId: "hebrew",
      locale: "he",
      text: "Hebrew",
      icon: hebrewLang,
      direction: "rtl"
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
      direction: "ltr"
    },
    {
      languageId: "arabic",
      locale: "ar",
      text: "Arabic",
      icon: englishLang,
      direction: "rtl"
    }
  ]
};

export function getCurrentTheme(store) {
  const theme = store.getState().LocalState.theme;
  console.log(theme);
  if (theme && theme === "light") {
    return themes["lightTheme"];
  }
  return themes["darkTheme"];
}

export function getCurrentLanguage(lang) {
  let selecetedLanguage = languageConfig.options[0];
  languageConfig.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export {
  siteConfig,
  themeConfig,
  language,
  firebaseConfig,
  mapboxConfig,
  languageConfig
};
