import {
  eventTypes,
  SESSION_TYPING_EVENTS_FETCH_SUCCESS,
  ADD_SESSION_CHAT_TYPING_EVENT
} from "../../constants";
import { put } from "redux-saga/effects";

export function* setSessionTypingEventsInitial(
  value,
  sessionId,
  key,
  metaType
) {
  let arr = [];
  const keys = Object.keys(value);
  for (var i = 0; i < keys.length; i++) {
    const eventKey = keys[i];
    const item = value[eventKey];
    item["userId"] = eventKey;
    arr.push(item);
  }
  yield put({
    type: SESSION_TYPING_EVENTS_FETCH_SUCCESS,
    sessionId: sessionId,
    typingEvents: arr
  });
}

export function* setSessionTypingEventsUpdate(data, metaType) {
  let item = data.value;
  let sessionId = data.id;
  switch (data.eventType) {
    //listens to session chat new typing event
    case eventTypes.CHILD_ADDED:
      item["userId"] = data.key;
      yield put({
        type: ADD_SESSION_CHAT_TYPING_EVENT,
        sessionId: sessionId,
        userId: data.key,
        event: item
      });
      break;
    //listens to session chat update typing event
    case eventTypes.CHILD_CHANGED:
      item["userId"] = data.key;
      yield put({
        type: ADD_SESSION_CHAT_TYPING_EVENT,
        sessionId: sessionId,
        userId: data.key,
        event: item
      });
      break;
    case eventTypes.CHILD_REMOVED:
      break;
    default:
      return;
  }
}
