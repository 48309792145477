import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../utility/intlMessages";
import OpenSessionStatWrapper from "./openSessionStat.style";
import { AlertOutlined } from "@ant-design/icons";
import { getOpenSessionsCount } from "../../redux/selectors/sessions";
import { useSelector } from "react-redux";

const OpenSessionStat = props => {
  const { direction } = props;
  const openSessionsCount = useSelector(state => getOpenSessionsCount(state));

  return (
    <OpenSessionStatWrapper data-rtl={direction}>
      <div className="widgetContainer">
        <div className="iconWrapper">
          <AlertOutlined />
        </div>
        <div className="contentWrapper">
          <h3 className="contentNumber">{openSessionsCount}</h3>
          <p className="contentLabel">
            {<IntlMessages id="app.overview.active" />}
          </p>
        </div>
      </div>
    </OpenSessionStatWrapper>
  );
};

OpenSessionStat.propTypes = {
  direction: PropTypes.string,
  openSessionsCount: PropTypes.string
};

export default OpenSessionStat;
