import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { message as antMessage } from "antd";
import SessionParticipantWrapper from "./sessionParticipantWrapper.style";
import Backend from "../../api/Backend";
import moment from "moment";
import IntlMessages from "../utility/intlMessages";
import Image from "../utility/image";
import SessionComponentWrapper from "../animations/sessionComponentWrapper";
import SessionComponentCloseButton from "../animations/sessionComponentCloseButton";
import {
  WomanOutlined,
  ManOutlined,
  MobileOutlined,
  HomeOutlined,
  UserOutlined
} from "@ant-design/icons";

import ContactDetail from "../contacts/contactDetail";

const SessionParticipantProfile = props => {
  const { direction, profileId, type } = props;
  const [profile, setProfile] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (profileId !== null) {
      const profile = Backend.getParticipantDetails(profileId, false);

      Promise.all([profile])
        .then(function(values) {
          setProfile(values[0]);
          setShow(true);
        })
        .catch(function() {
          antMessage.error(
            "There was an error during getting the user details"
          );
        });
    } else if (profileId === null) {
      setProfile(null);
      setShow(false);
    }
  }, [profileId]);

  const hideProfile = () => {
    setProfile(null);
    setShow(false);
  };

  var ageDate =
    profile && profile.birthday
      ? new Date(profile.birthday.seconds * 1000)
      : null;
  const age = ageDate ? moment(ageDate).format("ll") : "";
  const name = profile && profile.fullName ? profile.fullName : "";
  return (
    <SessionComponentWrapper show={show} type={type}>
      <SessionParticipantWrapper type={type} data-rtl={direction}>
        <div className="participantContainer">
          <div className="isoContactCard" data-rtl={direction}>
            <div key="1" className="isoContactCardHead">
              <div className="isoPersonImage">
                {profile && profile.photoUrl ? (
                  <Image
                    alt="#"
                    imagesrc={profile.photoUrl.uri}
                    disableanimation={"false"}
                  />
                ) : (
                  <UserOutlined />
                )}
              </div>
            </div>
            <div key="2" className="isoContactInfoWrapper">
              <div>
                <h1 style={{ margin: 0 }}>{name}</h1>
                <p>{age}</p>
              </div>
              <div className="guaContactRole">
                <p>
                  <IntlMessages id="app.sessions.online" />
                </p>
              </div>
            </div>
            {profile && (profile.address || profile.gender || profile.phone) ? (
              <div className="contactDetailsContainer">
                {profile.address ? (
                  <ContactDetail
                    text={profile.address}
                    icon={<HomeOutlined />}
                  />
                ) : null}
                {profile.gender ? (
                  <ContactDetail
                    text={profile.gender}
                    icon={
                      profile.gender === "Male" ? (
                        <ManOutlined />
                      ) : (
                        <WomanOutlined />
                      )
                    }
                  />
                ) : null}
                {profile.phone ? (
                  <ContactDetail
                    text={profile.phone}
                    icon={<MobileOutlined />}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {type !== "mobile" ? (
          <SessionComponentCloseButton show={show} onClick={hideProfile} />
        ) : null}
      </SessionParticipantWrapper>
    </SessionComponentWrapper>
  );
};

SessionParticipantProfile.propTypes = {
  direction: PropTypes.string,
  profileId: PropTypes.string,
  type: PropTypes.string
};

export default SessionParticipantProfile;
