import styled from "styled-components";

const MobilePageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  width: ${props => (props["settings"] === true ? "auto" : "100%")};
  height: 60px;
  background-color: ${props => props.bg} !important;
  box-shadow: ${props =>
    props["settings"] === true
      ? "none"
      : "0 2px 10px rgba(0, 0, 0, 0.16) !important"};
  .header {
    display: flex;
  }
  .rightComponent {
    display: flex;
  }
  .ant-page-header {
    padding: 0;
  }
`;

export default MobilePageHeaderWrapper;
