import styled from "styled-components";

const ActionNotificationWrapper = styled.div`
  .notification-item {
    margin-bottom: 0;
    min-width: 30%;
  }
  .btnContainer {
    padding: 1.5em 0 1em 0;
    justify-content: flex-end;
    display: flex;
    .dangerBtn {
      margin: 0 0.5em;
    }
    .ant-btn {
      color: rgba(255, 255, 255, 1);
      background-color: #dc3545;
      border-color: rgba(255, 255, 255, 0.7) !important;

      &:hover,
      &:focus {
        background-color: #dc3545;
        border-color: rgba(255, 255, 255, 1) !important;
      }
    }
    .ant-btn-danger {
      color: #ff4d4f !important;
      background-color: #fff !important;
      border-color: #fff !important;
    }
  }
`;

export default ActionNotificationWrapper;
