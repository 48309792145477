import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import DashApp from "./dashApp";
// import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.less";
import { Provider } from "react-redux";
import { Button, notification } from "antd";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import lightTheme from "./settings/themes/lightTheme";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DashApp />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

const openNotification = () => {
  const key = `open${Date.now()}`;
  const bg = {
    backgroundColor: lightTheme.palette["primary"][0],
    borderColor: lightTheme.palette["primary"][0],
  };
  const btn = (
    <Button
      type="primary"
      size="default"
      icon="reload"
      style={bg}
      onClick={() => {
        window.location.reload();
      }}
    >
      Refresh
    </Button>
  );
  notification.open({
    message: "Pending Update",
    description:
      "There is a pending update. Refresh to get the current version.",
    duration: 0,
    icon: (
      <SafetyCertificateOutlined
        style={{ color: lightTheme.palette["text"][2] }}
      />
    ),
    btn,
    key,
  });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    console.log("on update");

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        console.log("statechange");
        console.log(event);

        if (event.target.state === "activated") {
          openNotification();
          console.log("activated");

          console.log("openNotification");

          // window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      console.log("SKIP_WAITING");
    }
  },
});
