import { UPDATE_SESSION_OWNER_LOCATION, ADD_SESSION } from "../../constants";
const INITIAL_STATE = {};
const ownersLocations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SESSION:
      return {
        ...state,
        [action.id]: state[action.id] !== undefined ? state[action.id] : []
      };

    case UPDATE_SESSION_OWNER_LOCATION:
      return {
        ...state,
        [action.sessionId]: [
          ...state[action.sessionId].filter(
            location => location.id !== action.location.id
          ),
          action.location
        ]
      };

    default:
      return state;
  }
};

export default ownersLocations;
