import { createSelector } from "reselect";
import themes from "../../../settings/themes";

const direction = state =>
  state.Firebase.userData.settings &&
  state.Firebase.userData.settings.language &&
  state.Firebase.userData.settings.language.direction
    ? state.Firebase.userData.settings.language.direction
    : "ltr";

const theme = state =>
  state.Firebase.userData.settings && state.Firebase.userData.settings.theme
    ? state.Firebase.userData.settings.theme === "light"
      ? themes["lightTheme"]
      : themes["darkTheme"]
    : themes["lightTheme"];

const isEntity = state =>
  state.Firebase.userData.settings && state.Firebase.userData.settings.isEntity
    ? true
    : false;

export const getDirection = createSelector([direction], direction => {
  return direction;
});

export const getCurrentTheme = createSelector([theme], theme => {
  return theme;
});

export const getIsEntity = createSelector([isEntity], isEntity => {
  return isEntity;
});
