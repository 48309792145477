import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PopUpInfoWrapper from "./popUpInfo.style";
import { getDistance, convertDistance } from "geolib";
import ActionButton from "../utility/actionButton";

const PopUpInfo = props => {
  const {
    showProfileFromPopup,
    marker,
    ownerMarker,
    members,
    direction
  } = props;
  const [distance, setDistance] = useState(null);

  const showProfile = () => {
    showProfileFromPopup(marker.userId);
  };

  useEffect(() => {
    if (ownerMarker && ownerMarker.location && marker && marker.location) {
      const meters = getDistance(
        {
          latitude: ownerMarker.location.latitude,
          longitude: ownerMarker.location.longitude
        },
        {
          latitude: marker.location.latitude,
          longitude: marker.location.longitude
        }
      );
      const distance = convertDistance(meters, "km"); // 14.2
      setDistance(distance);
    }
  }, [ownerMarker, marker]);

  const member = members.filter(member => member.id === marker.userId)[0];
  return (
    <PopUpInfoWrapper>
      <div className="imgWrapper">
        {member.photoUrl ? <img alt="user" src={member.photoUrl.uri} /> : null}

        <span className="userActivity online" />
      </div>
      <div className="popUpDetails">
        <h1 className="popUpName">{member.fullName}</h1>
        <p className="popUpDistance">{distance} kilometers</p>
        <ActionButton
          direction={direction}
          title={"show profile"}
          onClick={showProfile}
          flat={true}
        />
      </div>
    </PopUpInfoWrapper>
  );
};
PopUpInfo.propTypes = {
  ownerMarker: PropTypes.object,
  marker: PropTypes.object,
  members: PropTypes.array,
  showProfileFromPopup: PropTypes.func,
  direction: PropTypes.string
};

export default PopUpInfo;
