import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = props => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        props.toggle();
        props.func();
      }}
      className={props.active ? "active" : ""}
    >
      <div className="nav-item">
        <div className="nav-icon">{props.icon}</div>
        <div className="nav-text">{props.text}</div>
      </div>
    </motion.li>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  toggle: PropTypes.func,
  changeCurrent: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.object,
  active: PropTypes.bool,
  icon: PropTypes.object,
  func: PropTypes.func
};
