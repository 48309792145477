import styled from "styled-components";

const CategoryInner = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  /* height: calc(100vh - 68px); */
  overflow: auto;
  @media only screen and (max-width: 1200px) {
    flex-direction: ${props =>
      props["screen"] === "overview" ? "column-reverse" : "column"};
  }
`;

export default CategoryInner;
