import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { BarLoader } from "react-spinners";
import {
  BubbleImgLeftWrapper,
  BubbleLeftWrapper,
  BubbleRightWrapper,
  BubbleImgRightWrapper,
  UpdateMessage
} from "./messages.style";
import Image from "../utility/image";
import { motion } from "framer-motion";

var moment = require("moment");

const messageVariants = {
  open: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      x: { type: "tween", duration: 0.4 },
      ease: "easeIn"
    }
  },
  start: { opacity: 0, x: "100%", scale: 1 },
  closed: { opacity: 1, x: 0, scale: 1 }
};

export const BubbleLeft = props => {
  var message = props.message ? props.message : {};
  const ownerClass = props.isOwner ? "owner" : "";
  return (
    <BubbleLeftWrapper
      data-rtl={props.direction}
      className={"chat-bubble-left-container"}
    >
      <div className={ownerClass}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
          }}
        >
          {message.user.avatar ? (
            <div className="isoAvatar">
              {message.user.avatar ? (
                <Image
                  alt="#"
                  imagesrc={message.user.avatar}
                  disableanimation={"false"}
                  color={"red"}
                />
              ) : (
                ""
              )}
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="chat-bubble-left">
              <div className="chat-bubble-text">{message.text}</div>
              <div className="chat-bubble-date">
                {moment(message.createdAt).format("LT")}
              </div>
            </div>
            <div className="chat-bubble-name">{message.user.name}</div>
          </div>
        </div>
      </div>
    </BubbleLeftWrapper>
  );
};

BubbleLeft.propTypes = {
  message: PropTypes.object,
  direction: PropTypes.string,
  isOwner: PropTypes.bool
};

export const BubbleImgLeft = props => {
  var message = props.message ? props.message : {};
  const ownerClass = props.isOwner ? "owner" : "";
  return (
    <BubbleImgLeftWrapper
      data-rtl={props.direction}
      className={"chat-bubble-left-container"}
    >
      <div className={ownerClass}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
          }}
        >
          {message.user.avatar ? (
            <div className="isoAvatar">
              {message.user.avatar ? (
                <Image
                  alt="#"
                  imagesrc={message.user.avatar}
                  disableanimation={"false"}
                />
              ) : (
                ""
              )}
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="chat-bubble-left">
              <img
                onClick={() => props.onImageClick(message)}
                alt="#"
                src={message.image}
                disableanimation={"false"}
                className="chat-bubble-img"
              />
            </div>
            <div className="chat-bubble-name">{message.user.name}</div>
          </div>
        </div>
      </div>
    </BubbleImgLeftWrapper>
  );
};

BubbleImgLeft.propTypes = {
  message: PropTypes.object,
  direction: PropTypes.string,
  onImageClick: PropTypes.func,
  isOwner: PropTypes.bool
};

export const BubbleRight = props => {
  var message = props.message ? props.message : {};
  const now = new Date().getTime();
  var messageDate = Date.parse(message.createdAt);
  return (
    <BubbleRightWrapper
      data-rtl={props.direction}
      className="chat-bubble-right-container"
    >
      <motion.div
        className="chat-bubble-right"
        animate={messageDate + 2000 > now ? "open" : "closed"}
        initial={messageDate + 2000 > now ? "start" : "closed"}
        variants={messageVariants}
      >
        <div className="chat-bubble-text">{message.text}</div>
      </motion.div>
    </BubbleRightWrapper>
  );
};

BubbleRight.propTypes = {
  message: PropTypes.object,
  direction: PropTypes.string
};

export const BubbleImgRight = props => {
  var message = props.message ? props.message : {};
  const now = new Date().getTime();
  var messageDate = Date.parse(message.createdAt);
  const override = css`
    bottom: 0;
    width: 100% !important;
    position: absolute;
  `;
  return (
    <BubbleImgRightWrapper
      data-rtl={props.direction}
      className="chat-bubble-right-container"
    >
      <motion.div
        className="chat-bubble-right"
        style={{ backgroundColor: "transparent" }}
        animate={messageDate + 2000 > now ? "open" : "closed"}
        initial={messageDate + 2000 > now ? "start" : "closed"}
        variants={messageVariants}
      >
        <div style={{ margin: 0 }} className="chat-bubble-right">
          <div
            className="chat-bubble-img"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <img
              onClick={() => props.onImageClick(message)}
              alt="#"
              src={message.image}
              className="chat-bubble-img"
            />
            {message.uploading === true ? (
              <BarLoader css={override} color={"#fd4c20"} loading={true} />
            ) : null}
          </div>
        </div>
      </motion.div>
    </BubbleImgRightWrapper>
  );
};

BubbleImgRight.propTypes = {
  message: PropTypes.object,
  direction: PropTypes.string,
  onImageClick: PropTypes.func
};

export const Update = props => {
  let message = "";
  switch (props.message.type) {
    case "helpCreateUpdate":
      message =
        props.message.name +
        " called for help at " +
        moment(props.message.createdAt).format("LT");
      break;
    case "memberJoinUpdate":
      message = props.message.name + " joined the session";
      break;
    case "memberLeaveUpdate":
      message = props.message.name + " left";
      break;
    case "endSessionUpdate":
      message =
        props.message.name +
        " has ended the session.\nThe session will no longer be active in 5 minutes.";
      break;
    case "sessionTimeOutUpdate":
      message =
        "The session ended due to time limit at: " +
        moment(props.message.createdAt).format("LT");
      break;
    default:
      break;
  }
  return (
    <UpdateMessage data-rtl={props.direction}>
      <p className="chat-update">{message}</p>
    </UpdateMessage>
  );
};

Update.propTypes = {
  message: PropTypes.object,
  direction: PropTypes.string,
  ownerId: PropTypes.string,
  sessionId: PropTypes.string
};
