import styled from "styled-components";
import { palette } from "styled-theme";

const EmptyStateWrapper = styled.div`
  width: 100%;
  .emptyStatePage {
    height: 100%;
    background-color: ${props => props.bg};
    padding: 2em;
    border-radius: 10px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .ant-empty-description {
      font-weight: 400;
      margin: 10px 0 5px 0;
      line-height: 1.2;
      color: ${palette("text", 0)};
      font-size: 1.3em;
    }

    .emptyStateContent {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        order: 2;
        margin-top: 20px;
        align-items: center;
        text-align: center;
      }

      h1 {
        font-weight: 700;
        line-height: 1;
        margin: 0 0 25px;
      }

      h3 {
        font-weight: 400;
        margin: 10px 0 5px 0;
        line-height: 1.2;
      }

      p {
        font-weight: 400;
        margin: 0 0 10px;
        max-width: 400px;
      }
    }

    .ant-btn-primary {
      padding: 10px 20px;
      height: auto;
      border-radius: 20px;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    }

    .emptyStateImage {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 60px;
      // height: 500px;

      @media only screen and (max-width: 767px) {
        // margin-left: 0;
      }
      svg {
        /* width: 70px; */
        fill: ${palette("lightText", 1)};
        /* height: 100px; */
      }
      img {
        max-height: 100%;
        width: 70px;
      }
    }
  }
`;

export default EmptyStateWrapper;
