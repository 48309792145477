import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Modal } from "antd";
import PlansWrapper from "./style";
import { connect } from "react-redux";
import Headline from "../../components/plans/headline";
import Ico from "../../components/plans/icon";
import Line from "../../components/plans/line";
import Payment from "../Payment/payment";
import { withTheme } from "styled-components";
import { Layout } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import Topbar from "../Topbar/Topbar";
import { AppLocale } from "../../dashApp";
import PaymentApi from "../../api/PaymentApi";
import { toggleAll } from "../../redux/reducers/app/reducer";
import base64 from "base-64";

const { Content } = Layout;

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selected: null,
      growingLoading: false,
      premiumLoading: false,
      personalLoading: false
    };
    this.premiumClicked = this.premiumClicked.bind(this);
    this.growingClicked = this.growingClicked.bind(this);
    this.personalClicked = this.personalClicked.bind(this);
    this.setGrowingLoading = this.setGrowingLoading.bind(this);
    this.setPremiumLoading = this.setPremiumLoading.bind(this);
    this.setPersonalLoading = this.setPersonalLoading.bind(this);
  }

  static propTypes = {
    userSettings: PropTypes.object,
    match: PropTypes.object,
    vaultedData: PropTypes.object,
    locale: PropTypes.string,
    toggleAll: PropTypes.func
  };

  premiumClicked() {
    const { vaultedData } = this.props;
    const data = vaultedData.data;
    let that = this;
    if (
      data &&
      data["paymentSources"] &&
      data["paymentSources"]["creditCardInfo"] &&
      data["paymentSources"]["creditCardInfo"].length > 0
    ) {
      this.setPremiumLoading(true);
      PaymentApi.changePlan("premium", function() {
        that.setPremiumLoading(false);
      });
    } else {
      this.setState({
        visible: true,
        selected: "premium"
      });
    }
  }

  growingClicked() {
    const { vaultedData } = this.props;
    const data = vaultedData.data;
    let that = this;
    if (
      data &&
      data["paymentSources"] &&
      data["paymentSources"]["creditCardInfo"] &&
      data["paymentSources"]["creditCardInfo"].length > 0
    ) {
      this.setGrowingLoading(true);
      PaymentApi.createGrowingSubscription(
        data["vaultedShopperId"],
        function() {
          that.setGrowingLoading(false);
        }
      );
    } else {
      this.setState({
        visible: true,
        selected: "growing"
      });
    }
  }

  personalClicked() {
    let that = this;
    this.setPersonalLoading(true);
    PaymentApi.changePlan("personal", function() {
      that.setPersonalLoading(false);
    });
  }

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  loadBlueSnap(plan) {
    const existingScript = document.getElementById("bluesnap");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://sandbox.bluesnap.com/web-sdk/4/bluesnap.js"; // URL for the third-party library being loaded.
      script.id = "bluesnap"; // e.g., googleMaps or stripe
      document.body.appendChild(script);

      script.onload = scr => {
        this.launchBlueSnap();
      };
    } else {
      this.launchBlueSnap();
    }
  }

  launchBlueSnap() {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://sandbox.bluesnap.com/services/2/payment-fields-tokens"; //
    return fetch(proxyurl + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${base64.encode(
          "API_15667253253521542497580"
        )}:Avshalom2`
      }
    })
      .then(response => {
        var token = response.headers
          .get("Location")
          .replace(
            "https://sandbox.bluesnap.com/services/2/payment-fields-tokens/",
            ""
          );
        var jsonData = {
          token: token,
          title: "BlueSnap Example",
          description: "This is description for example...",
          amount: "150",
          currency: "EUR",
          buttonLabel: "Click to buy",
          billingDetails: true,
          includeEmail: true,
          language: "EN",
          shopperData: {
            firstname: "Someone",
            lastname: "JustExample"
          }
        };

        window.bluesnap.embeddedCheckoutSetup(jsonData, function(
          eCheckoutResult
        ) {
          if (eCheckoutResult.code === 1) {
            // On Success
            console.log(eCheckoutResult);
            // Your code here...
            // This where you need to process the payment via an API request from your server
          } else {
            // On Error (code=10 - invalid jsonData, any other codes are errors, on error
            if (eCheckoutResult.info) {
              // var errors = eCheckoutResult.info.errors; // will be undefined if no errors have occurred
              // var warnings = eCheckoutResult.info.warnings; // will be undefined if no warnings have occurred
            }
          }
          window.bluesnap.embeddedCheckoutClose();
        });

        window.bluesnap.embeddedCheckoutOpen();
      })
      .catch(error => {
        console.error(error);
      });
  }

  setGrowingLoading = value => {
    this.setState({ growingLoading: value });
  };
  setPremiumLoading = value => {
    this.setState({ premiumLoading: value });
  };
  setPersonalLoading = value => {
    this.setState({ personalLoading: value });
  };
  render() {
    const { growingLoading, premiumLoading, personalLoading } = this.state;
    const { userSettings, locale, activePlan, theme } = this.props;
    const { url } = this.props.match;
    const currentAppLocale = AppLocale[locale];
    const direction =
      userSettings && userSettings.language && userSettings.language.direction
        ? userSettings.language.direction
        : "ltr";

    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Layout>
          <Debounce time="1000" handler="onResize">
            <WindowSizeListener
              onResize={windowSize =>
                this.props.toggleAll(
                  windowSize.windowWidth,
                  windowSize.windowHeight
                )
              }
            />
          </Debounce>
          <Topbar
            url={url}
            showMenuBtn={false}
            data-rtl={direction}
            showLogo={true}
          />
          <Layout
            style={{
              flexDirection: "row",
              overflowX: "hidden",
              marginTop: "64px"
            }}
          >
            <Layout style={{ backgroundColor: theme.palette["background"][0] }}>
              <Content className="isomorphicContent">
                <PlansWrapper data-rtl={direction}>
                  <Row
                    type="flex"
                    className="plansContainer"
                    // justify="space-between"
                    align="middle"
                    gutter={24}
                  >
                    <Col span={6} className="firstCol">
                      <Row className="headline">
                        <Headline content={" "} />
                      </Row>
                      <Row>
                        <Line content={"Public circles"} />
                      </Row>
                      <Row>
                        <Line content={"Private circles"} />
                      </Row>
                      <Row>
                        <Line content={"Public circles responders"} />
                      </Row>
                      <Row>
                        <Line content={"Private circles responders"} />
                      </Row>
                      <Row>
                        <Line content={"Circle members"} />
                      </Row>
                      <Row>
                        <Line content={"Messaging"} />
                      </Row>
                      <Row>
                        <Line content={"Tracking"} />
                      </Row>
                      <Row>
                        <Line content={"Nearby circles appearance"} />
                      </Row>
                      <Row>
                        <Line content={"Track caller route"} />
                      </Row>
                      <Row>
                        <Line content={"Geofences"} />
                      </Row>
                      <Row>
                        <Line content={"Instant alerts"} />
                      </Row>
                      <Row>
                        <Line content={"Geographic targeted alerts"} />
                      </Row>
                      <Row className="headline">
                        <Headline content={" "} />
                      </Row>
                      <Row className="button" />
                    </Col>
                    <Col span={6}>
                      <Row className="headline">
                        <Headline content={"Personal"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Line content={"1"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Line content={"3"} />
                      </Row>
                      <Row>
                        <Line content={"50"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row className="headline">
                        <Headline content={"FREE"} />
                      </Row>
                      <Row className="button">
                        <Button
                          type="primary"
                          onClick={this.personalClicked}
                          disabled={activePlan.planName === "personal"}
                          loading={personalLoading}
                        >
                          {activePlan.planName === "personal"
                            ? "Current Plan"
                            : "Select"}
                        </Button>
                      </Row>
                    </Col>
                    <Col span={6} className="growing">
                      <Row className="headline">
                        <Headline content={"Growing Plan"} />
                      </Row>
                      <Row>
                        <Line content={"1"} />
                      </Row>
                      <Row>
                        <Line content={"5"} />
                      </Row>
                      <Row>
                        <Line content={"5"} />
                      </Row>
                      <Row>
                        <Line content={"5"} />
                      </Row>
                      <Row>
                        <Line content={"100"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row>
                        <Ico name={"close"} />
                      </Row>
                      <Row className="headline">
                        <Headline content={"$45/month"} />
                      </Row>
                      <Row className="button">
                        <Button
                          type="primary"
                          onClick={this.growingClicked}
                          disabled={activePlan.planName === "growing"}
                          loading={growingLoading}
                        >
                          {activePlan.planName === "growing"
                            ? "Current Plan"
                            : "Select"}
                        </Button>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row className="headline">
                        <Headline content={"Premium Plan"} />
                      </Row>
                      <Row>
                        <Line content={"unlimited"} />
                      </Row>
                      <Row>
                        <Line content={"unlimited"} />
                      </Row>
                      <Row>
                        <Line content={"$3/responder/month"} />
                      </Row>
                      <Row>
                        <Line content={"$3/responder/month"} />
                      </Row>
                      <Row>
                        <Line content={"$0.5/member/month"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Ico name={"check"} />
                      </Row>
                      <Row>
                        <Line content={"$5/geofence/month"} />
                      </Row>
                      <Row>
                        <Line content={"$1/alert"} />
                      </Row>
                      <Row>
                        <Line content={"$1/alert/month"} />
                      </Row>
                      <Row className="headline">
                        <Headline content={"Pay As You Go"} />
                      </Row>
                      <Row className="button">
                        <Button
                          type="primary"
                          onClick={this.premiumClicked}
                          disabled={activePlan.planName === "premium"}
                          loading={premiumLoading}
                        >
                          {activePlan.planName === "premium"
                            ? "Current Plan"
                            : "Select"}
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </PlansWrapper>
              </Content>
            </Layout>
          </Layout>
          <Modal
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            visible={this.state.visible}
            footer={null}
            closable={false}
            width={350}
            bodyStyle={{
              backgroundColor: theme.palette["background"][0],
              padding: 0
            }}
          >
            <Payment
              plan={this.state.selected}
              onDismiss={this.handleCancel}
              setGrowingLoading={this.setGrowingLoading}
            />
          </Modal>
        </Layout>
      </IntlProvider>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    ...state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    localState: state.LocalState,
    activePlan: state.Firebase.activePlan,
    vaultedData: state.Firebase.vaultedData
  }),
  { toggleAll }
)(withTheme(Plans));
