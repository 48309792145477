import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout, Modal } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import { appActions } from "../../redux/reducers/app/reducer";
import SessionNavBar from "../../components/session/sessionNavBar";
import SessionMapBox from "../../components/mapBox/sessionMap";
import CallerRouteMap from "../../components/mapBox/maps/callerRouteMap";
import Chat from "../../components/chat/Chat";
import SessionParticipants from "../../components/session/sessionParticipants";
import CallerInfo from "../../components/session/callerInfo";
import SessionParticipantProfile from "../../components/session/sessionParticipantProfile";
import RespondersInviteList from "../../components/session/respondersInviteList";
import { AppLocale } from "../../dashApp";
import SessionWrapper from "./style";
import { withTheme } from "styled-components";
import { ADD_RESOLVED_SESSION } from "../../redux/constants";
import Backend from "../../api/Backend";
import SessionsApi from "../../api/SessionsApi";
import { message as antMessage } from "antd";
import {
  makeGetActiveSession,
  makeGetActiveId,
  makeGetMembers,
  makeGetMessages,
  makeGetLocations,
  makeGetOwnerLocation,
  makeGetOwnerLocations,
  makeGetActiveParticipants,
  makeGetPendingParticipants
} from "../../redux/selectors/session";
import { getDirection } from "../../redux/selectors/user";
import MobileView from "./mobileView";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const { Sider } = Layout;

const Session = props => {
  const dispatch = useDispatch();
  const [profileId, setProfileId] = useState(null);
  const [showInvite, setShowInvite] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [showCallerInfo, setShowCallerInfo] = useState(false);
  const [routeModalVisible, setRouteModalVisible] = useState(false);
  // const [session, setSession] = useState(null);

  const getActiveSession = makeGetActiveSession();
  const getActiveId = makeGetActiveId();
  const getMembers = makeGetMembers();
  const getActiveParticipants = makeGetActiveParticipants();
  const getPendingParticipants = makeGetPendingParticipants();
  const getMessages = makeGetMessages();
  const getLocations = makeGetLocations();
  const getOwnerLocation = makeGetOwnerLocation();
  const getOwnerLocations = makeGetOwnerLocations();
  const session = useSelector(state => getActiveSession(state, props));
  const activeId = useSelector(state => getActiveId(state, props));
  const members = useSelector(state => getMembers(state, props));
  const activeParticipants = useSelector(state =>
    getActiveParticipants(state, props)
  );
  const direction = useSelector(state => getDirection(state));
  const pendingParticipants = useSelector(state =>
    getPendingParticipants(state, props)
  );
  const messages = useSelector(state => getMessages(state, props));
  const userData = useSelector(state => state.Firebase.userData);
  const locations = useSelector(state => getLocations(state, props));
  const localState = useSelector(state => state.LocalState);
  const height = useSelector(state => state.App.height);
  const view = useSelector(state => state.App.view);
  const ownerLocation = useSelector(state => getOwnerLocation(state, props));
  const ownerLocations = useSelector(state => getOwnerLocations(state, props));
  const locale = useSelector(state => state.LanguageSwitcher.language.locale);
  const sessionChatTypingEvents = useSelector(
    state => state.Firebase.chatTypingEvents
  );

  const currentAppLocale = AppLocale[locale];
  const { history, location } = props;
  useEffect(() => {
    const existingScript = document.getElementById("intercom");
    if (existingScript) {
      window.Intercom("update", {
        hide_default_launcher: true
      });
    }
    if (session === null) {
      const fetchedSession = Backend.getSessionDetails(activeId);
      Promise.all([fetchedSession])
        .then(function(values) {
          const snap = values[0];
          snap["sessionId"] = activeId;
          if (!location.state["active"]) {
            dispatch({
              type: ADD_RESOLVED_SESSION,
              circleId: localState.activeCircleId,
              session: snap
            });
            SessionsApi.loadResolvedSessionLocations(activeId);
            SessionsApi.loadResolvedSessionMembers(activeId);
            SessionsApi.loadResolvedSessionMessages(activeId);
          }
        })
        .catch(function() {
          antMessage.error(
            "There was an error during getting the session details"
          );
        });
    }
    return () => {
      const existingScript = document.getElementById("intercom");
      if (existingScript) {
        window.Intercom("update", {
          hide_default_launcher: false
        });
      }
    };
  }, [activeId, session, localState.activeCircleId, location.state, dispatch]);

  const showProfileFromPopup = id => {
    setShowParticipants(false);
    setShowInvite(false);
    setShowChat(false);
    setShowCallerInfo(false);
    setProfileId(id);
  };

  const setId = id => {
    setProfileId(id);
    setShowInvite(false);
  };

  const changeInviteVisibility = () => {
    setShowParticipants(false);
    setShowInvite(!showInvite);
    setShowChat(false);
    setShowCallerInfo(false);
    setProfileId(null);
  };

  const changeParticipantsVisibility = () => {
    setShowParticipants(!showParticipants);
    setShowInvite(false);
    setShowChat(false);
    setShowCallerInfo(false);
    setProfileId(null);
  };

  const changeChatVisibility = () => {
    setShowParticipants(false);
    setShowInvite(false);
    setShowChat(!showChat);
    setShowCallerInfo(false);
    setProfileId(null);
  };

  const changeCallerInfoVisibility = () => {
    setShowParticipants(false);
    setShowInvite(false);
    setShowChat(false);
    setShowCallerInfo(!showCallerInfo);
    setProfileId(null);
  };

  const showCallerRouteMap = () => {
    setRouteModalVisible(true);
  };

  const hideCallerRouteMap = () => {
    setRouteModalVisible(false);
  };

  return view === "MobileView" ? (
    <MobileView
      direction={direction}
      session={session}
      userData={userData}
      height={height}
      localState={localState}
      sessionChatTypingEvents={sessionChatTypingEvents}
      members={members}
      messages={messages}
      locations={locations}
      ownerLocation={ownerLocation}
      ownerLocations={ownerLocations}
      activeParticipants={activeParticipants}
      pendingParticipants={pendingParticipants}
      currentAppLocale={currentAppLocale}
      setProfileId={setId}
      profileId={profileId}
    />
  ) : (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Layout style={{ height: "100vh" }}>
        <Debounce time="1000" handler="onResize">
          <WindowSizeListener
            onResize={windowSize =>
              dispatch({
                type: appActions.TOGGLE_ALL,
                height: windowSize.windowHeight,
                width: windowSize.windowWidth
              })
            }
          />
        </Debounce>

        <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
          <SessionWrapper data-rtl={direction}>
            <div
              className="isomorphicContent session"
              style={{
                //  padding: "70px 0 0",
                flexShrink: "0",
                height: "100%"
              }}
            >
              <Layout className="sessionContainer">
                <Sider width="100px">
                  <SessionNavBar
                    history={history}
                    showInviteList={changeInviteVisibility}
                    showParticipants={changeParticipantsVisibility}
                    showCallerInfo={changeCallerInfoVisibility}
                    showCallerRouteMap={showCallerRouteMap}
                    showChat={changeChatVisibility}
                    session={session}
                    showInviteState={showInvite}
                    showCallerInfoState={showCallerInfo}
                    showChatState={showChat}
                    showParticipantsState={showParticipants}
                    showCallerRouteState={routeModalVisible}
                  />
                </Sider>
                <RespondersInviteList
                  show={showInvite}
                  showInviteList={changeInviteVisibility}
                  sessionMembers={members}
                  direction={direction}
                />
                <Chat
                  show={showChat}
                  showChat={changeChatVisibility}
                  direction={direction}
                  height={height}
                  session={session}
                  listType={session ? session.sessionType : null}
                  sessionChatTypingEvents={
                    session && sessionChatTypingEvents[session.sessionId]
                      ? sessionChatTypingEvents[session.sessionId]
                      : []
                  }
                  sessionsMembers={members}
                  sessionId={session ? session.sessionId : null}
                  messages={messages}
                  ownerId={session ? session.creator : null}
                />
                <SessionParticipants
                  show={showParticipants}
                  showParticipants={changeParticipantsVisibility}
                  direction={direction}
                  activeParticipants={activeParticipants}
                  pendingParticipants={pendingParticipants}
                  height={height}
                  session={session}
                  ownerLocation={ownerLocation}
                  setProfileId={setId}
                  profileId={profileId}
                />

                <SessionParticipantProfile
                  profileId={profileId}
                  setProfileId={setId}
                />

                <CallerInfo
                  direction={direction}
                  show={showCallerInfo}
                  showCallerInfo={changeCallerInfoVisibility}
                  height={height}
                  ownerId={session ? session.ownerId : null}
                  session={session}
                  showInviteList={changeInviteVisibility}
                  history={history}
                  location={location}
                />
                <Layout className="sessionCenterContainer">
                  <SessionMapBox
                    direction={direction}
                    localState={localState}
                    userData={userData}
                    session={session}
                    members={members}
                    activeParticipants={activeParticipants}
                    locations={locations}
                    ownerLocation={ownerLocation}
                    ownerLocations={ownerLocations}
                    profileId={profileId}
                    showInvite={showInvite}
                    showCallerInfo={showCallerInfo}
                    showChat={showChat}
                    showProfileFromPopup={showProfileFromPopup}
                  />
                </Layout>

                <Modal
                  destroyOnClose
                  style={{ padding: 0 }}
                  bodyStyle={{ padding: 0 }}
                  maskStyle={{ height: "100%" }}
                  width="90%"
                  height="90%"
                  centered
                  visible={routeModalVisible}
                  footer={null}
                  title={null}
                  closable={false}
                  onCancel={() => hideCallerRouteMap()}
                >
                  <CallerRouteMap
                    hideModal={hideCallerRouteMap}
                    height={height}
                    direction={direction}
                    session={session}
                    profileId={profileId}
                  />
                </Modal>
              </Layout>
            </div>
          </SessionWrapper>
        </Layout>
      </Layout>
    </IntlProvider>
  );
};

Session.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withTheme(Session);
