import styled from "styled-components";
import { palette } from "styled-theme";

const LogoWrapper = styled.div`
  margin: ${props =>
    props["data-rtl"] === "rtl" ? "0 0 0 10px" : "0 10px 0 0"};
  border-right: ${props =>
    props["data-rtl"] === "rtl" ? "none" : "0px solid #ff814e"};
  border-left: ${props =>
    props["data-rtl"] === "rtl" ? "0px solid #ff814e" : "none"};
  .logoContainer {
    display: flex;
    align-items: center;
    flex-direction: ${props =>
      props["data-rtl"] === "rtl" ? "row-reverse" : "row"};

    .LogoNameWrapper {
      display: flex;
      a {
        height: fit-content;
      }
      h3 {
        margin-bottom: 0;
        padding: 0;
        font-family: Myfont;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0;
        color: ${palette("primary", 0)} !important;
      }
      p {
        margin: 5px 0 0 5px;
      }
    }
  }
`;

export { LogoWrapper };
