import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { appActions } from "../../../redux/reducers/app/reducer";
import { useDispatch } from "react-redux";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = props => {
  const dispatch = useDispatch();
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        props.toggle();
        dispatch({
          type: appActions.CHANGE_CURRENT,
          current: props.link
        });
      }}
      className={props.active ? "active" : ""}
    >
      <Link to={`${props.url}/${props.link}`}>
        <div className="nav-item">
          <div className="nav-icon">{props.icon}</div>
          <div className="nav-text">{props.text}</div>
        </div>
      </Link>
    </motion.li>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  toggle: PropTypes.func,
  changeCurrent: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.object,
  active: PropTypes.bool,
  icon: PropTypes.object
};
