import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, Checkbox, Button } from "antd";
import firebase from "firebase/app";
import IntlMessages from "../../components/utility/intlMessages";
import Backend from "../../api/Backend";
import AuthApi from "../../api/AuthApi";
import ConsentWrapper from "./consent.style";
import { useSelector } from "react-redux";

const FormItem = Form.Item;

const Consent = props => {
  const { history } = props;
  const [checked, setChecked] = useState(false);
  let formRef = React.createRef();
  const user = firebase.auth().currentUser;
  const name = useSelector(state => state.Auth.name);

  const onChange = e => {
    setChecked(e.target.checked);
  };

  const handleSubmit = e => {
    e.preventDefault();
    formRef.current
      .validateFields()
      .then(() => {
        proceedAfterConsent();
      })
      .catch(() => {});
  };

  const proceedAfterConsent = () => {
    AuthApi.proceedAfterConsent(user, history);
  };

  const noConsent = () => {
    AuthApi.noConsent();
  };

  const checkAgreement = (rule, value, callback) => {
    if (value !== true) {
      callback("You must agree with terms and conditions");
    }
    callback();
  };
  const fullName = user && user.displayName ? user.displayName : null;

  return (
    <ConsentWrapper>
      <Form onFinish={handleSubmit} ref={formRef}>
        <div className="signUpTerms">
          {fullName ? <h1>Hello, {fullName}</h1> : null}
          <h1 style={{ paddingBottom: "2em" }}>
            Before proceeding, we need your consent
          </h1>
          <FormItem
            valuePropName="checked"
            rules={[{ validator: checkAgreement }]}
          >
            <Checkbox onChange={onChange}>
              I agree with{" "}
              <Link to="/terms" className="signUpLink">
                <IntlMessages id="app.consent.terms" />
              </Link>{" "}
              <IntlMessages id="app.general.and" />{" "}
              <Link to="/privacy" className="signUpLink">
                <IntlMessages id="app.consent.privacy" />
              </Link>
            </Checkbox>
          </FormItem>
          <div className="btnContainer">
            <Button className="cancelBtn" onClick={noConsent}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={!checked}>
              Next
            </Button>
          </div>
        </div>
      </Form>
    </ConsentWrapper>
  );
};

Consent.propTypes = {
  history: PropTypes.object
};

export default Consent;
