import { ADD_ONLINE_SESSION, REMOVE_ONLINE_SESSION } from "../../constants";

const INITIAL_STATE = {};

function openSessions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ONLINE_SESSION:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined
            ? [
                ...state[action.circleId].filter(
                  session => session.sessionId !== action.session.sessionId
                ),
                action.session
              ]
            : [action.session]
      };

    case REMOVE_ONLINE_SESSION:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined
            ? state[action.circleId].filter(
                session => session.sessionId !== action.sessionId
              )
            : []
      };

    default:
      return state;
  }
}

export default openSessions;
