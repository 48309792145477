import styled from "styled-components";
import { palette } from "styled-theme";

const MapControlWrapper = styled.div`
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 10px;
  .section {
    border: 1px solid ${palette("border", 0)};
    padding: 18px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .mapActionsContainer {
    padding: 10px 0;
  }
  .ant-slider-handle,
  .ant-slider-dot-active {
    border-color: ${palette("text", 1)} !important;
  }
  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px #788ce5;
  }
  .ant-slider-track {
    background-color: ${palette("primary", 0)} !important;
  }
  h2 {
    padding-top: 10px;
    text-align: center;
    margin: 0;
    color: ${palette("text", 0)};
  }
  p {
    margin: 0;
    color: ${palette("lightText", 0)};
  }
  .routeButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    .buttonContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default MapControlWrapper;
