/* global localStorage */

import { store } from "../redux/store";
import {
  SET_UID,
  ADD_USER_DATA,
  UPDATE_ACTIVE_PROFILE_PRIVATE_INFO,
  UPDATE_ACTIVE_PROFILE_MEDICAL_INFO,
  CHANGE_LANGUAGE,
  FIRESTORE_LISTEN_REQUESTED,
  FIRESTORE_UNLISTEN_REQUESTED,
  FIREBASE_UNLISTEN_REQUESTED,
  UPDATE_SESSION_MESSAGE_PROGRESS,
  RESET_ACTIVE_PROFILE
} from "../redux/constants";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import VendorApi from "./VendorApi";
import { firebaseConfig } from "../config.js";
import { getCurrentLanguage } from "../config.js";
import authAction from "../redux/reducers/auth/actions";
import { persistor } from "../redux/store";
import { store as notificationsStore } from "react-notifications-component";

//onst settings = {/* your settings... */ timestampsInSnapshots: true};
firebase.initializeApp(firebaseConfig);
//firebase.firestore().settings(settings);

export const firebaseInstance = firebase;
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;

class Backend {
  uid = "";
  ui = null;
  user = null;
  email: "";
  logged = false;
  userName = "";
  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // firebase.database.enableLogging(true);
        store.dispatch({
          type: SET_UID,
          id: user.uid
        });
        this.user = user;
        this.uid = user.uid;
        this.userName = user.displayName;
        this.email = user.email;
        this.logged = true;
        this.userPresence(user.uid);
        this.loadLanguage();
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("usersCircles")
              .doc(user.uid)
              .collection("circles"),
            id: user.uid
          },
          meta: {
            type: "userCircles"
          }
        });
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("users")
              .doc(user.uid),
            id: user.uid
          },
          meta: {
            type: "userData"
          }
        });
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("vaultedShoppers")
              .doc(user.uid),
            id: user.uid
          },
          meta: {
            type: "vaultedData"
          }
        });
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("usersActivePlan")
              .doc(user.uid),
            id: user.uid
          },
          meta: {
            type: "activePlan"
          }
        });

        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("usersSubscriptions")
              .doc(user.uid)
              .collection("subscriptions"),
            id: user.uid
          },
          meta: {
            type: "userSubscriptions"
          }
        });
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("constants")
              .doc("plans"),
            id: user.uid
          },
          meta: {
            type: "plans"
          }
        });
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: {
            ref: firebase
              .firestore()
              .collection("usersEntities")
              .doc(user.uid)
              .collection("entities")
          },
          meta: {
            type: "userEntities"
          }
        });

        // CirclesApi.addRemoveCircleListener( user.uid )
        // this.userChangesListener( user.uid ) //also loads initial data
        // SessionsApi.addRemoveSessionListener(user.uid);
        VendorApi.vendorDetailsListener(user.uid);
      } else {
        this.logged = false;
        console.log("logged out");
      }
    });
  }

  userPresence(uid) {
    const userStatusDatabaseRef = firebase.database().ref(`/status/${uid}`);
    const isOfflineForDatabase = {
      state: "offline",
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };
    const isOnlineForDatabase = {
      state: "online",
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };
    firebase
      .database()
      .ref(".info/connected")
      .on("value", function(snapshot) {
        if (snapshot.val() === false) {
          return;
        }
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });
  }

  loadLanguage() {
    firebase
      .firestore()
      .collection("users")
      .doc(this.uid)
      .get()
      .then(userSnapshot => {
        if (userSnapshot.exists) {
          var settings = userSnapshot.data().settings;
          if (settings && settings.language) {
            document.documentElement.dir = settings.language.direction;
            const selected = getCurrentLanguage(settings.language.languageId);
            store.dispatch({
              type: CHANGE_LANGUAGE,
              language: selected
            });
          }
        }
      });
  }

  setAccountPlan = plan => {
    firebase
      .database()
      .ref("users/" + this.uid + "/business_info/plan")
      .set(plan)
      .then(() => {});
  };

  isAllowdToAddPrivateCircles(plan) {
    const circles = store.getState().userCircles.circles;
    const circlesCount = circles.filter(
      circle => circle.groupType === "private"
    ).length;
    let response;

    switch (plan) {
      case "personal":
        response = circlesCount >= 1 ? false : true;
        return response;

      case "growing":
        response = circlesCount >= 5 ? false : true;
        return response;

      case "premium":
        return true;

      default:
        return true;
    }
  }

  isAllowdToAddPublicCircles(plan) {
    const circles = store.getState().userCircles.circles;
    const circlesCount = circles.filter(circle => circle.groupType === "public")
      .length;
    let response;

    switch (plan) {
      case "personal":
        return false;

      case "growing":
        response = circlesCount >= 1 ? false : true;
        return response;

      case "premium":
        return true;

      default:
        return true;
    }
  }

  isAllowdToAddResponders(plan) {
    const circles = store.getState().userCircles.circles;
    let count = 0;
    circles.forEach(circle => {
      circle.members.forEach(member => {
        if (member.responder) {
          count += 1;
        }
      });
    });
    let response;
    switch (plan) {
      case "personal":
        response = count >= 3 ? false : true;
        return response;
      case "growing":
        response = count >= 10 ? false : true;
        return response;
      case "premium":
        return true;
      default:
        return true;
    }
  }

  isAllowdToAddMembers(plan) {
    const circles = store.getState().userCircles.circles;
    let count = 0;
    circles.forEach(circle => {
      circle.members.forEach(member => {
        if (member.responder !== true) {
          count += 1;
        }
      });
    });
    let response;
    switch (plan) {
      case "personal":
        response = count >= 50 ? false : true;
        return response;
      case "growing":
        response = count >= 200 ? false : true;
        return response;
      case "premium":
        return true;
      default:
        return true;
    }
  }

  deleteAccount = () => {
    firebase
      .auth()
      .currentUser.delete()
      .catch(function(error) {
        if (error.code === "auth/requires-recent-login") {
          // The user's credential is too old. She needs to sign in again.
          firebase
            .auth()
            .signOut()
            .then(function() {
              // The timeout allows the message to be displayed after the UI has
              // changed to the signed out state.
              setTimeout(function() {
                alert("Please sign in again to delete your account.");
              }, 1);
            });
        }
      });
  };

  getUserDetailsByPhone(number, callback) {
    var ref = firebase
      .firestore()
      .collection("registeredMobilePhones")
      .doc(number);
    ref.get().then(function(phoneSnapshot) {
      if (phoneSnapshot.exists) {
        var val = phoneSnapshot.data();
        const userId = val.userId;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then(userSnapshot => {
            var details = userSnapshot.data().general_info;
            details["userId"] = userId;
            callback(details);
          });
      } else {
        callback(null);
      }
    });
  }

  getUserDetailsById(id, callback) {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then(userSnapshot => {
        var details = userSnapshot.data().general_info;
        details["userId"] = id;
        callback(details);
      });
  }

  //profile
  getParticipantDetails = (id, includePrivate) => {
    return new Promise(resolve => {
      firebase
        .firestore()
        .collection("users")
        .doc(id)
        .get()
        .then(userSnapshot => {
          const data = includePrivate
            ? userSnapshot.data()
            : userSnapshot.data().general_info;
          resolve(data);
        });
    });
  };

  getSessionDetails = id => {
    return new Promise(resolve => {
      firebase
        .firestore()
        .collection("sessions")
        .doc(id)
        .get()
        .then(sessionSnapshot => {
          const data = sessionSnapshot.data();
          resolve(data);
        });
    });
  };

  loadMemberProfile = (id, isOwner) => {
    store.dispatch({
      type: RESET_ACTIVE_PROFILE
    });
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then(userSnapshot => {
        var details = userSnapshot.data().general_info;
        details["id"] = id;
        store.dispatch({
          type: UPDATE_ACTIVE_PROFILE_PRIVATE_INFO,
          value: details
        });
        if (isOwner) {
          store.dispatch({
            type: UPDATE_ACTIVE_PROFILE_MEDICAL_INFO,
            value: userSnapshot.data().medical_info
          });
        }
      });
  };

  //set

  setUserLanguage(details) {
    firebase
      .firestore()
      .collection("users")
      .doc(this.uid)
      .set(
        {
          settings: {
            language: details
          }
        },
        {
          merge: true
        }
      );
  }

  updateAccount = (
    userName,
    imageName,
    sizedPic,
    organizationName,
    brief,
    website,
    language,
    country,
    theme,
    text
  ) => {
    let that = this;
    let batch = firebase.firestore().batch();
    let ref = firebase
      .firestore()
      .collection("users")
      .doc(this.uid);
    if (userName) {
      batch.update(ref, {
        "general_info.fullName": userName
      });
    }
    if (organizationName) {
      batch.update(ref, {
        "business_info.organizationName": organizationName
      });
    }
    if (brief) {
      batch.update(ref, {
        "business_info.brief": brief
      });
    }
    if (website) {
      batch.update(ref, {
        "business_info.website": website
      });
    }
    if (language) {
      batch.update(ref, {
        "settings.language": language
      });
    }
    if (country) {
      batch.update(ref, {
        "general_info.country": country
      });
    }
    if (theme) {
      batch.update(ref, {
        "settings.theme": theme
      });
    }
    batch.commit().then(function() {
      console.log(text);

      notificationsStore.addNotification({
        // title: "Success!",
        message: text,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      if (sizedPic !== null && imageName !== null) {
        const uploadPath = "/users/" + that.uid;
        const urlRef = firebase
          .firestore()
          .collection("users")
          .doc(that.uid);
        that.uploadSizedPhoto(uploadPath, urlRef, imageName, sizedPic);
      }
    });
  };

  //user listeners

  // listens when there is a change in one of the user circles details (location, name, photoUrl)
  userChangesListener = id => {
    const ref = firebase
      .firestore()
      .collection("users")
      .doc(id);
    ref.onSnapshot(snapshot => {
      //get changes including initial load
      if (snapshot.exists) {
        const data = snapshot.data();
        store.dispatch({
          type: ADD_USER_DATA,
          data: data
        });
      }
    });
  };

  //entity

  async getEntityInfo(id, callback) {
    const usersSessionssRef = firebase
      .firestore()
      .collection("usersSessions")
      .doc(id)
      .collection("userSessions");

    const circlesRef = firebase
      .firestore()
      .collection("usersCircles")
      .doc(id)
      .collection("circles");

    const sessionsSnap = await usersSessionssRef.get();
    const circlesSnap = await circlesRef.get();

    const closedSessions = sessionsSnap
      .docChanges()
      .filter(session => session.sessionStatus === "closed").length;

    const openSessions = sessionsSnap
      .docChanges()
      .filter(session => session.sessionStatus !== "closed").length;

    const circles = circlesSnap.docChanges().length;
    const val = {
      closedSessions: closedSessions,
      openSessions: openSessions,
      circles: circles
    };
    callback(val);
  }

  //storage

  uploadSizedPhoto(uploadPath, urlRef, imageName, photo) {
    if (photo !== undefined) {
      const path = uploadPath + "/" + imageName;

      var listRef = firebase.storage().ref(uploadPath);

      // Find all the prefixes and items.
      let counter = 0;
      listRef
        .listAll()
        .then(function(res) {
          if (res.items.length === 0) {
            firebase
              .storage()
              .ref(path)
              .putString(photo, "data_url");
          } else {
            res.items.forEach(function(itemRef) {
              counter++;
              itemRef.delete().then(() => {
                if (counter === res.items.length) {
                  firebase
                    .storage()
                    .ref(path)
                    .putString(photo, "data_url");
                }
              });
            });
          }
        })
        .catch(function(error) {
          console.log("error deleting old storage files: ", error);
        });
    }
  }

  uploadChatPhoto(path, photo, sessionId, messageId, updateProgress) {
    return new Promise(resolve => {
      var uploadTask = firebase
        .storage()
        .ref(path)
        .putString(photo, "data_url");

      uploadTask.on(
        "state_changed",
        function(snapshot) {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes;
          console.log("Upload is " + progress + "done");
          if (updateProgress) {
            store.dispatch({
              type: UPDATE_SESSION_MESSAGE_PROGRESS,
              sessionId: sessionId,
              messageId: messageId,
              uploadProgress: progress
            });
          }

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              console.log("Upload is running");
          }
        },
        function(error) {
          // Handle unsuccessful uploads
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  logout = () => {
    this.detachListeners();
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          localStorage.clear();
          persistor.flush();
          persistor.purge();
        },
        error => {}
      );
    store.dispatch({ type: authAction.LOGOUT });
  };
  signUp(userName, email, password) {}

  detachListeners = () => {
    const circles = store.getState().Firebase.circles;
    const sessionsCircles = store.getState().Firebase.openSessions;
    const sessions = Object.keys(sessionsCircles).map(k => sessionsCircles[k]);
    circles.forEach(circle => {
      const circleId = circle.id;
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circles" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleMembers" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleSessions" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleSesionsPriority" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleJoinRequests" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleSessionsIds" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleGeoFences" }
      });

      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "circleAlerts" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "instantAlerts" }
      });
      store.dispatch({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: circleId,
        meta: { type: "scheduledAlerts" }
      });
    });

    sessions.forEach(circleSessions => {
      circleSessions.forEach(session => {
        const sessionId = session.sessionId;
        store.dispatch({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessions" }
        });
        store.dispatch({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionMembers" }
        });
        store.dispatch({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionLocations" }
        });
        store.dispatch({
          type: FIREBASE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionMessages" }
        });
        store.dispatch({
          type: FIREBASE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionTypingEvents" }
        });
      });
    });

    const userId = this.uid;
    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "userCircles" }
    });

    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "userData" }
    });
    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "vaultedData" }
    });
    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "activePlan" }
    });
    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "userSubscriptions" }
    });

    store.dispatch({
      type: FIRESTORE_UNLISTEN_REQUESTED,
      id: userId,
      meta: { type: "plans" }
    });
  };
}

export default new Backend();
