import styled from "styled-components";
import { palette } from "styled-theme";
import { borderRadius } from "../../settings/style-util";

const BubbleLeftWrapper = styled.div`
  display: flex;
  padding: 0.5em 0;
  .owner {
    .chat-bubble-left {
      background-color: ${palette("secondary", 0)};
      .chat-bubble-text {
        color: ${palette("onDarkBgText", 0)};
      }
      .chat-bubble-date {
        color: #ffc6d4;
      }
    }
  }
  .chat-bubble-name {
    color: ${palette("lightText", 0)};
    padding: 0.2em 0.5em 0 0em;
    margin: 0 10px;
  }
  .isoAvatar {
    width: 30px;
    margin-left: 6px;
    height: 30px;
    border: 1px solid ${palette("border", 0)};
    background-color: ${palette("lightBg", 0)};
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    flex-shrink: 0;
    overflow: hidden;
    ${borderRadius("50%")};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .chat-bubble-left {
    background-color: ${palette("background", 0)};
    margin: 0 10px;
    z-index: 1;
    border-radius: 0.5em 0.5em 0.5em 0;
    color: ${palette("text", 0)};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    .chat-bubble-text {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      padding: 16px 16px 8px 16px;
      font-weight: 400;
    }
    .chat-bubble-date {
      display: inline-block;
      float: right;
      padding: 0 16px 8px 16px;
      color: ${palette("lightText", 0)};
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;

const BubbleImgLeftWrapper = styled.div`
  display: flex;
  padding: 0.5em 0;
  .owner {
    .chat-bubble-left {
      background-color: ${palette("secondary", 0)};
      .chat-bubble-date {
        color: #ffc6d4;
      }
    }
  }
  .chat-bubble-name {
    color: ${palette("lightText", 0)};
    padding: 0.2em 0.5em 0 0em;
    margin: 0 10px;
  }
  .isoAvatar {
    width: 30px;
    margin-left: 6px;
    height: 30px;
    border: 1px solid ${palette("border", 0)};
    background-color: ${palette("lightBg", 0)};
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    flex-shrink: 0;
    overflow: hidden;
    ${borderRadius("50%")};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .chat-bubble-left {
    background-color: ${palette("background", 0)};
    margin: 0 10px;
    border-radius: 0.5em 0.5em 0.5em 0;
    color: ${palette("text", 0)};
    margin-left: -25px;
    z-index: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    .chat-bubble-date {
      color: ${palette("lightText", 0)};
    }
    .chat-bubble-img {
      width: 250px;
      object-fit: contain;
      border-radius: 5px;
      display: flex;
      cursor: pointer;
    }
  }
`;

const BubbleRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0;
  .chat-bubble-right {
    background-color: ${palette("primary", 0)};
    margin: 0 10px;
    border-radius: 0.5em;
    .chat-bubble-text {
      color: ${palette("onDarkBgText", 0)};
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      padding: 16px;
      font-weight: 400;
    }
    .chat-bubble-date {
      display: inline-block;
      float: right;
      padding: 0 1em 0.5em 1em;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      &.right {
        color: #cbe8ff;
      }
    }
  }
`;

const BubbleImgRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0;
  .chat-bubble-right {
    background-color: ${palette("selected", 0)};
    margin: 0 10px;
    border-radius: 0.5em;
    .chat-bubble-img {
      width: 250px;
      object-fit: contain;
      border-radius: 5px;
      display: flex;
      cursor: pointer;
    }
    .chat-bubble-date {
      display: inline-block;
      float: right;
      padding: 0 1em 0.5em 1em;
      color: @app-light-gray;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      &.right {
        color: #cbe8ff;
      }
    }
  }
`;

const UpdateMessage = styled.div`
  display: "flex";
  justifycontent: "center";
  .chat-update {
    text-align: center;
    color: ${palette("lightText", 0)};
    padding: 5px 10px;
    border-radius: 10px;
    line-height: 18px;
  }
`;

export {
  BubbleLeftWrapper,
  BubbleImgLeftWrapper,
  BubbleRightWrapper,
  BubbleImgRightWrapper,
  UpdateMessage,
};
