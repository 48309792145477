import antdAr from "antd/lib/locale-provider/ar_EG";
import arMessages from "../locales/ar_001.json";
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const ArLang = {
  messages: {
    ...arMessages
  },
  antd: antdAr,
  locale: "ar-EG"
};
export default ArLang;
