import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import ChatTopContainer from "./ChatTopContainer";
import Carousel, { Modal, ModalGateway } from "react-images";
import { withTheme } from "styled-components";
import "emoji-mart/css/emoji-mart.css";
import data from "emoji-mart/data/messenger.json";
import { NimblePicker } from "emoji-mart";
import { motion } from "framer-motion";
import SessionComponentWrapper from "../animations/sessionComponentWrapper";
import SessionComponentCloseButton from "../animations/sessionComponentCloseButton";
const topContainerVariants = {
  start: {
    opacity: 1
  },
  open: {
    opacity: 1,
    transition: { delay: 0.3, duration: 0.5 }
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: { duration: 0 }
  }
};

const emojiPickerVariants = {
  enter: {
    height: "360px",
    transition: {
      default: { delay: 0, duration: 0.3 }
    }
  },
  exit: {
    height: 0,
    transition: {
      duration: 0.3
    }
  }
};

const emojiPickerInnerVariants = {
  enter: {
    opacity: 1,
    transition: {
      delay: 0.25
    }
  },
  exit: {
    opacity: 0
  }
};

const Chat = props => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  // const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([]);
  const [emojiPickerVisibility, setEmojiPickerVisibility] = useState(false);
  const [message, setMessage] = useState("");
  const { messages } = props;

  useEffect(() => {
    const imgMessages = messages
      ? messages.filter(message => message.image !== undefined)
      : [];
    let filteredImages = [];
    imgMessages.forEach(item => {
      const temp = {
        src: item.image
      };
      filteredImages.push(temp);
    });
    setImages(filteredImages);
  }, [messages]);

  const onImageClick = message => {
    console.log(message);
    // const temp = images.filter(item => item.src === message.image)[0];
    // const index = images.indexOf(temp);
    // setCurrentImage(index);
    setLightboxIsOpen(true);
  };

  const toggleModal = () => {
    setLightboxIsOpen(!lightboxIsOpen);
  };

  const handleTextChange = textMessage => {
    setMessage(textMessage);
  };

  const addEmoji = e => {
    if (e.unified.length <= 5) {
      let emojiPic = String.fromCodePoint(`0x${e.unified}`);
      setMessage(message + emojiPic);
    } else {
      let sym = e.unified.split("-");
      let codesArray = [];
      sym.forEach(el => codesArray.push("0x" + el));
      let emojiPic = String.fromCodePoint(...codesArray);
      setMessage(message + emojiPic);
    }
  };

  const showEmojiPicker = value => {
    setEmojiPickerVisibility(value);
  };

  const {
    sessionId,
    height,
    sessionChatTypingEvents,
    sessionsMembers,
    session,
    show,
    showChat,
    type
  } = props;
  const isOpen = session && session.sessionStatus !== "closed";
  const theme = props.theme;
  const ownerId = session && session.ownerId ? session.ownerId : "";

  return (
    <SessionComponentWrapper show={props.show} type={type}>
      <div className="sessionLeftContainer">
        <div className="chatTopContainer">
          <motion.div
            initial="start"
            animate={props.show ? "open" : "closed"}
            variants={topContainerVariants}
          >
            <ChatTopContainer
              sessionsMembers={sessionsMembers}
              direction={props.direction}
            />
          </motion.div>
        </div>
        <div
          className="chat-container"
          style={{
            height: type === "mobile" ? "100%" : height - 199,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column"
          }}
        >
          <ChatMessages
            direction={props.direction}
            onImageClick={onImageClick}
            height={height}
            sessionChatTypingEvents={sessionChatTypingEvents}
            sessionsMembers={sessionsMembers}
            sessionId={sessionId}
            messages={messages}
            showEmojiPicker={emojiPickerVisibility}
            ownerId={ownerId}
          />
          <motion.div
            key="emojiPickerModal"
            animate={emojiPickerVisibility ? "enter" : "exit"}
            variants={emojiPickerVariants}
            style={{
              overflow: emojiPickerVisibility ? "unset" : "hidden"
            }}
          >
            <motion.div
              key="emojiPickerInnnerModal"
              animate={emojiPickerVisibility ? "enter" : "exit"}
              variants={emojiPickerInnerVariants}
            >
              <NimblePicker
                darkMode={theme.name === "darkTheme" ? true : false}
                set="messenger"
                data={data}
                onSelect={addEmoji}
                showPreview={false}
                showSkinTones={false}
                style={{
                  width: "100%",
                  border: "1px solid #ffffff"
                }}
              />
            </motion.div>
          </motion.div>
          {isOpen ? (
            <ChatInput
              showEmojiPicker={showEmojiPicker}
              handleTextChange={handleTextChange}
              message={message}
              direction={props.direction}
              sessionId={props.sessionId}
            />
          ) : null}

          <ModalGateway>
            {lightboxIsOpen ? (
              <Modal onClose={toggleModal}>
                <Carousel views={images} />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
      {type !== "mobile" ? (
        <SessionComponentCloseButton show={show} onClick={showChat} />
      ) : null}
    </SessionComponentWrapper>
  );
};

Chat.propTypes = {
  sessionsMembers: PropTypes.array,
  sessionId: PropTypes.string,
  direction: PropTypes.string,
  sessionChatTypingEvents: PropTypes.array,
  messages: PropTypes.array,
  listType: PropTypes.string,
  height: PropTypes.number,
  lightboxIsOpen: PropTypes.bool,
  session: PropTypes.object,
  theme: PropTypes.object,
  show: PropTypes.bool,
  showChat: PropTypes.func,
  type: PropTypes.string
};

export default withTheme(Chat);
