import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const NewCircleHeader = props => {
  const { children } = props;
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className="header"
    >
      {children}
    </motion.div>
  );
};

NewCircleHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default NewCircleHeader;
