import { createSelector } from "reselect";

const activeGeoFenceId = state => state.LocalState.activeGeoFenceId;
const plans = state => state.LocalState.plans;
const plan = state => state.Firebase.activePlan;

const geofences = state =>
  state.Firebase.geoFences &&
  state.LocalState.activeCircleId &&
  state.Firebase.geoFences[state.LocalState.activeCircleId] &&
  state.Firebase.geoFences[state.LocalState.activeCircleId].length > 0
    ? state.Firebase.geoFences[state.LocalState.activeCircleId]
    : [];

const circleGeofences = (state, props) =>
  state.Firebase.geoFences &&
  props.circle.id &&
  state.Firebase.geoFences[props.circle.id] &&
  state.Firebase.geoFences[props.circle.id].length > 0
    ? state.Firebase.geoFences[props.circle.id]
    : [];

export const getGeofences = createSelector([geofences], geofences => {
  return geofences;
});

export const getGeofencesCount = createSelector([geofences], geofences => {
  return geofences.length.toString();
});

export const getActiveGeofence = createSelector(
  [geofences, activeGeoFenceId],
  (geofences, activeGeoFenceId) => {
    const activeGeofence =
      geofences && geofences.length > 0
        ? activeGeoFenceId
          ? geofences.filter(item => item.identifier === activeGeoFenceId)[0]
          : geofences[0]
        : null;
    return activeGeofence;
  }
);

export const getActiveGeofenceForMobile = createSelector(
  [geofences, activeGeoFenceId],
  (geofences, activeGeoFenceId) => {
    const activeGeofence =
      geofences && geofences.length > 0
        ? activeGeoFenceId
          ? geofences.filter(item => item.identifier === activeGeoFenceId)[0]
          : null
        : null;
    return activeGeofence;
  }
);

export const isAllowedToUseGeoFences = createSelector(
  [plans, plan],
  (plans, plan) => {
    const planName = plan && plan.planName ? plan.planName : "free"; //temporary]
    const restrictions = plans[planName];
    if (restrictions !== undefined) {
      return restrictions.allowedToUseGeofences;
    }
    return false;
  }
);

export const makeGetCircleGeofencesCount = () => {
  return createSelector([circleGeofences], circleGeofences => {
    return circleGeofences.length.toString();
  });
};
