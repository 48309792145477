import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MapGL, { NavigationControl } from "react-map-gl";
import { lineString as makeLineString } from "@turf/helpers";
import "mapbox-gl/dist/mapbox-gl.css";
import { ClipLoader } from "react-spinners";
import RouteMapControl from "../components/routeMapControl";
import SessionsApi from "../../../api/SessionsApi";
import ModalMapWrapper from "./modalMapWrapper.style";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";
import { Popover, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const navStyle = {
  position: "absolute",
  top: 10,
  right: 40,
  // padding: "10px",
};

const mapWrapperVariants = {
  show: {
    opacity: 1,
    height: "100%",
    transition: { duration: 2 },
  },
  hide: { opacity: 1, height: "100%" },
};

const CallerRouteMap = (props) => {
  const { session, height, type, hideModal, theme } = props;
  let mapRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: 37.7751,
    longitude: -122.4193,
    zoom: 13,
    bearing: 0,
    pitch: 0,
    width: "100%",
    height: type === "mobile" ? height - 132 : height - 180,
  });
  const [route, setRoute] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [times, setTimes] = useState([]);
  const [timesValue, setTimesValue] = useState(0);
  const [speedValue, setSpeedValue] = useState(50);
  const [play, setPlay] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mapStyle, setMapStyle] = useState(null);

  useEffect(() => {
    setViewport();
    window.addEventListener("resize", _resize);
    _resize(height);
    SessionsApi.getSessionOwnerRoute(
      session.sessionId,
      session.ownerId,
      function (coordinates, times) {
        if (coordinates) {
          const r = makeLineString(coordinates);
          setRoute(r);
          setCoordinates(coordinates);
          setTimes(times);
          setViewport({
            ...viewport,
            latitude: coordinates[0][1],
            longitude: coordinates[0][0],
          });
        }
      }
    );

    return function cleanup() {
      window.removeEventListener("resize", _resize);
    };
  }, []);

  useEffect(() => {
    var geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [coordinates[0]],
          },
        },
      ],
    };
    let map = null;
    if (mapRef.current !== null) {
      map = mapRef.getMap();
      // on a regular basis, add more coordinates from the saved list and update the map
      var i = timesValue;
      // setup the viewport
      map.jumpTo({ center: coordinates[i], zoom: 13 });
      map.setPitch(50);
      const arr = coordinates.slice(0, timesValue);
      geojson.features[0].geometry.coordinates = arr;
      if (map.getSource("line-animation") === undefined) {
        map.addLayer({
          id: "line-animation",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#F44336",
            "line-width": 3,
            "line-opacity": 0.8,
          },
        });
      } else {
        map.getSource("line-animation").setData(geojson);
      }
    }
    var timer = window.setInterval(function () {
      if (
        i < coordinates.length &&
        map &&
        map.getSource("line-animation") !== undefined &&
        play
      ) {
        geojson.features[0].geometry.coordinates.push(coordinates[i]);
        map.getSource("line-animation").setData(geojson);
        map.panTo(coordinates[i]);
        setTimesValue(i);
        i++;
        if (i === coordinates.length) {
          map.flyTo({
            center: coordinates[i - 1],
            zoom: 16,
            bearing: 0,
            speed: 0.9, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing: function (t) {
              return t;
            },
          });
        }
        if (!play) {
          window.clearInterval(timer);
        }
      } else {
        window.clearInterval(timer);
        setPlay(false);
      }
    }, 1000 / (speedValue + 1));

    return function cleanup() {
      window.clearInterval(timer);
    };
  }, [play]);

  const updateTimesValue = (value) => {
    setTimesValue(value);
    updatLine();
  };

  const updatLine = () => {
    var geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [coordinates[0]],
          },
        },
      ],
    };
    const arr = coordinates.slice(0, timesValue);
    geojson.features[0].geometry.coordinates = arr;
    let map = mapRef.getMap();
    if (map.getSource("line-animation") === undefined) {
      map.addLayer({
        id: "line-animation",
        type: "line",
        source: {
          type: "geojson",
          data: geojson,
        },
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#F44336",
          "line-width": 3,
          "line-opacity": 0.8,
        },
      });
    } else {
      map.getSource("line-animation").setData(geojson);
    }
    map.easeTo({ center: coordinates[timesValue], zoom: 13 });
  };

  const handleVisibleChange = (val) => {
    setVisible(val);
  };

  const updateSpeedValue = (val) => {
    setSpeedValue(val);
  };

  const updatePlayStatus = () => {
    setPlay(!play);
    setVisible(false);
  };

  const onLoad = () => {
    setShowMap(true);
  };

  const onMapStyleChange = (val) => {
    setMapStyle(val);
  };

  const _resize = (h) => {
    setViewport({
      ...viewport,
      width: "100%",
      height: type === "mobile" ? h - 132 : h - 180,
    });
  };

  const _onViewportChange = (val) => {
    setViewport(val);
  };

  const pose = showMap === true ? "show" : "hide";
  return (
    <ModalMapWrapper type={type}>
      {route ? (
        <div className="modalInnerContainer">
          {type !== "mobile" ? (
            <RouteMapControl
              session={session}
              times={times}
              speedValue={speedValue}
              timesValue={timesValue}
              play={play}
              setTimesValue={updateTimesValue}
              setSpeedValue={updateSpeedValue}
              setPlayStatus={updatePlayStatus}
              onMapStyleChange={onMapStyleChange}
            />
          ) : (
            <Popover
              content={
                <RouteMapControl
                  session={session}
                  times={times}
                  speedValue={speedValue}
                  timesValue={timesValue}
                  play={play}
                  setTimesValue={updateTimesValue}
                  setSpeedValue={updateSpeedValue}
                  setPlayStatus={updatePlayStatus}
                  onMapStyleChange={onMapStyleChange}
                />
              }
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
              placement="bottomRight"
            >
              <Button
                style={{
                  position: "absolute",
                  top: "-52px",
                  zIndex: "100",
                  right: "10px",
                  padding: "0px 20px",
                  height: "42px",
                  margin: 0,
                }}
                className="light"
                type="primary"
              >
                Options
              </Button>
            </Popover>
          )}

          <motion.div
            className="mapContainer"
            animate={pose}
            variants={mapWrapperVariants}
          >
            {type !== "mobile" ? (
              <div className="mapControlContainer">
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    hideModal();
                  }}
                />
              </div>
            ) : null}
            <MapGL
              ref={(map) => {
                mapRef = map;
              }}
              {...viewport}
              mapStyle={mapStyle}
              preventStyleDiffing={true}
              reuseMaps={true}
              onViewportChange={_onViewportChange}
              onLoad={onLoad}
              mapboxApiAccessToken={TOKEN}
            >
              <div className="nav" style={navStyle}>
                <NavigationControl onViewportChange={_onViewportChange} />
              </div>
            </MapGL>
          </motion.div>
        </div>
      ) : (
        <div className="loaderContainer" style={{ height: height - 118 }}>
          <ClipLoader loading={true} color={theme.palette["primary"][0]} />
        </div>
      )}
    </ModalMapWrapper>
  );
};

CallerRouteMap.propTypes = {
  session: PropTypes.object,
  height: PropTypes.number,
  hideModal: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(CallerRouteMap);
