import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Popover } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { SET_ACTIVE_SESSION } from "../../redux/constants";
import TopbarDropdownWrapper from "../topbar/topbarDropdown.style";
import SessionSwitcherWrapper from "./sessionSwitcher.style";
import { withTheme } from "styled-components";
import { BellOutlined } from "@ant-design/icons";
import { getTotalOpenSessions } from "../../redux/selectors/session";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const SessionSwitcher = props => {
  const { session, direction } = props;
  const openSeesions = useSelector(state => getTotalOpenSessions(state));
  const localState = useSelector(state => state.LocalState);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const content = (
    <TopbarDropdownWrapper
      className="topbarCircles withImg"
      data-rtl={direction}
    >
      <div className="isoDropdownBody">
        {openSeesions.map(session => (
          <Link
            to={{
              pathname: `/${localState.uid}/session/${session.sessionId}`,
              state: { active: true }
            }}
            key={session.sessionId}
          >
            <div
              className="isoDropdownListItem"
              key={session.sessionId}
              onClick={() => {
                dispatch({
                  type: SET_ACTIVE_SESSION,
                  sessions: openSeesions,
                  sessionId: session.sessionId,
                  sessionCategory: "open"
                });
              }}
            >
              <div className="isoImgWrapper">
                <img
                  alt="#"
                  src={session.photoUrl ? session.photoUrl.uri : ""}
                />
              </div>
              <div className="isoListContent">
                <div className="isoListHead">
                  <h5>{session.ownerName}</h5>
                  <span className="isoDate">
                    {moment(session.startedAt.seconds * 1000).fromNow()}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <p>
                    part of <strong>{session.circleName}</strong>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover
      content={content}
      placement={session === true ? "rightTop" : "bottomRight"}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayClassName="topbarNotification"
    >
      <SessionSwitcherWrapper>
        <div className="iconContainer">
          <BellOutlined />
        </div>
        <div className="badgeWrapper">
          <span>{openSeesions.length}</span>
        </div>
      </SessionSwitcherWrapper>
    </Popover>
  );
};

SessionSwitcher.propTypes = {
  session: PropTypes.bool,
  localState: PropTypes.object,
  sessionsMembers: PropTypes.object,
  openSessions: PropTypes.array,
  direction: PropTypes.string
};

export default withTheme(SessionSwitcher);
