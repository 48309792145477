import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import List from "./list";
import EntityDetails from "./entityDetails";
import IntlMessages from "../../utility/intlMessages";
import CategoryOuter from "../../layout/categoryOuter.style";
import { makeGetActiveEntity } from "../../../redux/selectors/entities";
import { useSelector } from "react-redux";
import { withTheme } from "styled-components";
import CategoryInnerVertical from "../../layout/categoryInnerVertical.style";
import MobilePageHeader from "../../utility/mobilePageHeader";
import moment from "moment";

const MobileView = props => {
  const {
    direction,
    entities,
    userData,
    activeEntity,
    view,
    onEntitySelect,
    theme,
    onBack,
    onCreateClicked
  } = props;

  const getActiveEntity = makeGetActiveEntity();
  const activeItem = useSelector(state => getActiveEntity(state, props));

  const goBack = () => {
    onBack();
  };

  return (
    <CategoryOuter
      data-rtl={direction}
      style={{
        backgroundColor: theme.palette["surfaceColor"][0]
      }}
    >
      <MobilePageHeader
        settings={true}
        title={
          activeItem &&
          activeItem.general_info &&
          activeItem.general_info.fullName
            ? activeItem.general_info.fullName
            : ""
        }
        subTitle={
          activeItem && activeItem.createdAt
            ? moment(activeItem.createdAt.seconds * 1000).fromNow()
            : ""
        }
        onBack={activeEntity ? goBack : null}
      />
      {entities && !activeEntity && entities.length > 0 ? (
        <CategoryInnerVertical
          style={{
            width: "100%",
            overflow: "hidden"
          }}
        >
          <List
            direction={direction}
            entities={entities}
            userData={userData}
            activeEntity={activeEntity}
            view={view}
            onEntitySelect={onEntitySelect}
          />
        </CategoryInnerVertical>
      ) : (
        <CategoryInnerVertical
          style={{
            width: "100%",
            overflow: "hidden"
          }}
          withHeader={true}
        >
          {entities && activeEntity ? (
            <div style={{ width: "100%" }}>
              <EntityDetails
                theme={theme}
                direction={direction}
                activeEntity={activeEntity}
                entities={entities}
                mobile={true}
              />
            </div>
          ) : null}
        </CategoryInnerVertical>
      )}
      {!activeEntity ? (
        <Button
          onClick={onCreateClicked}
          type="primary"
          className={"addButton"}
        >
          <IntlMessages id="app.buttons.createEntity" />
        </Button>
      ) : null}
    </CategoryOuter>
  );
};

MobileView.propTypes = {
  direction: PropTypes.string,
  entities: PropTypes.array,
  userData: PropTypes.object,
  activeEntity: PropTypes.string,
  view: PropTypes.string,
  onEntitySelect: PropTypes.func,
  theme: PropTypes.object,
  onBack: PropTypes.func,
  onCreateClicked: PropTypes.func
};

export default withTheme(MobileView);
