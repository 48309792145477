import styled from "styled-components";
import { palette } from "styled-theme";

const GeoFencesCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 17px;
    margin: 0;
    align-items: center;
    h3 {
      font-size: 24px;
      margin-bottom: 0;
      padding: 0 0.2em;
    }
  }
  .row {
    padding: 0 10px !important;
    width: 100%;
    display: flex;
    .col {
      padding: 0px 10px 20px 10px;
    }
  }
  .geoFenceCardContainer {
    height: calc(100vh - 350px);
    background-color: ${palette("surfaceColor", 0)};
    width: 100%;
  }
`;

export default GeoFencesCardWrapper;
