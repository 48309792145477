import Enlang from "./entries/en-US";
import Helang from "./entries/he-IL";
import Arlang from "./entries/ar-001";

const AppLocale = {
  en: Enlang,
  he: Helang,
  ar: Arlang
};
export default AppLocale;
