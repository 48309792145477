import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import IntlMessages from "../utility/intlMessages";
import NewCircleHeader from "../animations/newCircleHeader";
import NewCircleItem from "../animations/newCircleItem";
import NewCircleContent from "../animations/newCircleContent";
const FormItem = Form.Item;
const { TextArea } = Input;

const GeneralDetails = props => {
  const [form] = Form.useForm();

  const { name, description } = props;

  useEffect(() => {
    form.setFieldsValue({
      circleName: name,
      circleDescription: description
    });
  }, [name, description, form]);

  const handeleNameChanged = event => {
    props.setCircleName(event.target.value);
  };
  const handeleDescriptionChanged = event => {
    props.setCircleDescription(event.target.value);
  };

  return (
    <div className="componentInner">
      <NewCircleHeader>
        <h1>General Details</h1>
        <p>Choose circle name and provide description</p>
      </NewCircleHeader>

      <Form className="detailsForm" form={form}>
        <NewCircleContent>
          <NewCircleItem itemKey={1}>
            <FormItem
              className="detailsFormItem"
              label={<IntlMessages id="app.settings.circleName" />}
              name="circleName"
              rules={[
                {
                  required: true,
                  message: "Please input the circle name!"
                }
              ]}
            >
              <Input onChange={handeleNameChanged} />
            </FormItem>
          </NewCircleItem>
          <NewCircleItem itemKey={2}>
            <FormItem
              label="circle description"
              className="detailsFormItem"
              name="circleDescription"
              rules={[
                {
                  message: "Please input the circle description!"
                }
              ]}
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={handeleDescriptionChanged}
              />
            </FormItem>
          </NewCircleItem>
        </NewCircleContent>
      </Form>
    </div>
  );
};

GeneralDetails.propTypes = {
  setCircleName: PropTypes.func,
  setCircleDescription: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string
};

export default GeneralDetails;
