import React from "react";
import PropTypes from "prop-types";
import GeoFencesCardMap from "../mapBox/maps/geoFencesCardMap";
import NakedMap from "../mapBox/maps/nakedMap";
import GeoFencesCardWrapper from "./geoFencesCardWrapper.style";
import {
  getGeofencesCount,
  getGeofences
} from "../../redux/selectors/geofences";
import { useSelector } from "react-redux";

const GeoFencesCard = () => {
  const geofencesCount = useSelector(state => getGeofencesCount(state));
  const geofences = useSelector(state => getGeofences(state));
  const height = useSelector(state => state.App.height);
  const view = useSelector(state => state.App.view);

  return (
    <GeoFencesCardWrapper className="geofenceMapCard" id="map">
      {geofencesCount !== "0" ? (
        <GeoFencesCardMap
          overview={true}
          geofences={geofences}
          height={height}
          view={view}
        />
      ) : (
        <NakedMap />
      )}
    </GeoFencesCardWrapper>
  );
};

GeoFencesCard.propTypes = {
  dirction: PropTypes.string,
  geofences: PropTypes.array,
  geofencesCount: PropTypes.string,
  height: PropTypes.number,
  view: PropTypes.string
};

export default GeoFencesCard;
