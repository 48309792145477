import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const NewCircleContent = props => {
  const { children } = props;
  return (
    <motion.nav initial={true} animate={"open"}>
      <motion.ul variants={variants}>{children}</motion.ul>
    </motion.nav>
  );
};

NewCircleContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default NewCircleContent;
