import React, { useState } from "react";
import ActionNotificationWrapper from "./actionNotificationWrapper.style";
import { Button } from "antd";
import PropTypes from "prop-types";

const ActionNotification = props => {
  const [loading, setLoading] = useState(false);
  const { approve, decline, id, type, title, message } = props;

  return (
    <ActionNotificationWrapper>
      <div
        style={{ minWidth: window.innerWidth / 3 }}
        className={`notification-item notification-${type} n-child`}
      >
        <div className="notification-content">
          <p className="notification-title">{title}</p>
          <p className="notification-message">{message}</p>
          <div className="btnContainer">
            <Button className="dangerBtn" onClick={() => decline(id)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              type="danger"
              onClick={() => {
                setLoading(true);
                approve(id);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </ActionNotificationWrapper>
  );
};

ActionNotification.propTypes = {
  approve: PropTypes.func,
  decline: PropTypes.func,
  id: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
};

export default ActionNotification;
