import React from "react";
import { PropTypes } from "prop-types";
import { ContactCardWrapper } from "../../contacts/contactCard.style";
import ContactDetail from "../../contacts/contactDetail";
import Image from "../../utility/image";
import { makeGetActiveEntity } from "../../../redux/selectors/entities";
import { useSelector } from "react-redux";
import moment from "moment";
import { motion } from "framer-motion";
import {
  UserOutlined,
  AlertOutlined,
  SafetyOutlined,
  MailOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { useIntl } from "react-intl";

const header = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1
  }
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const EntityDetails = props => {
  const { direction, activeEntity, theme, mobile } = props;
  const intl = useIntl();
  const getActiveEntity = makeGetActiveEntity();
  const activeItem = useSelector(state => getActiveEntity(state, props));
  const userDetails =
    activeItem && activeItem.general_info ? activeItem.general_info : {};
  const email =
    activeItem && activeItem.settings && activeItem.settings.entityEmail
      ? activeItem.settings.entityEmail
      : "";
  const openSessions =
    activeItem && activeItem.openSessions
      ? activeItem.openSessions +
        " " +
        intl.formatMessage({
          id: "app.sidebar.active"
        })
      : "";
  const closedSessions =
    activeItem && activeItem.closedSessions
      ? activeItem.closedSessions +
        " " +
        intl.formatMessage({
          id: "app.sidebar.resolved"
        })
      : "";
  const circles =
    activeItem && activeItem.circles
      ? activeItem.circles +
        " " +
        intl.formatMessage({
          id: "app.sidebar.circles"
        })
      : "";

  let details = [
    {
      key: 1,
      icon: <MailOutlined />,
      text: email
    },

    {
      key: 2,
      icon: <AlertOutlined />,
      text: openSessions
    },
    {
      key: 3,
      icon: <SafetyOutlined />,
      text: closedSessions
    },
    {
      key: 4,
      icon: <TeamOutlined />,
      text: circles
    }
  ];

  const name = userDetails && userDetails.fullName ? userDetails.fullName : "";
  const created =
    activeItem && activeItem.createdAt
      ? moment(activeItem.createdAt.seconds * 1000).fromNow()
      : "";

  return (
    <ContactCardWrapper className="isoContactCard" data-rtl={direction}>
      <div className="leftContainer">
        <motion.div
          variants={header}
          initial="hidden"
          animate={userDetails !== undefined ? "visible" : "hidden"}
          className="header"
        >
          {mobile ? (
            <div>
              {userDetails && userDetails.photoUrl ? (
                <Image
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px"
                  }}
                  alt="#"
                  imagesrc={userDetails.photoUrl.uri}
                  disableanimation={"false"}
                />
              ) : userDetails ? (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px",
                    backgroundColor: theme.palette["iconBg"][0],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <UserOutlined style={{ fontSize: "30px" }} />
                </div>
              ) : (
                <div>
                  <h1 style={{ margin: 0 }}>{name}</h1>
                  <p>created at {created}</p>
                </div>
              )}
            </div>
          ) : null}
        </motion.div>
        {userDetails ? (
          <motion.ul
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
          >
            {details.map(detail => (
              <motion.li key={detail.key} className="item" variants={item}>
                <ContactDetail
                  large={true}
                  text={detail.text}
                  icon={detail.icon}
                />
              </motion.li>
            ))}
          </motion.ul>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          padding: "0 0 0 60px",
          justifyContent: "flex-end"
        }}
      >
        {!mobile ? (
          <div key="1" style={{ paddingTop: "84px" }}>
            {userDetails && userDetails.photoUrl ? (
              <Image
                style={{
                  width: "150px",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px"
                }}
                alt="#"
                imagesrc={userDetails.photoUrl.uri}
                disableanimation={"false"}
              />
            ) : userDetails ? (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "10px",
                  backgroundColor: theme.palette["iconBg"][0],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <UserOutlined style={{ fontSize: "30px" }} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </ContactCardWrapper>
  );
};

EntityDetails.propTypes = {
  direction: PropTypes.string,
  activeEntity: PropTypes.string,
  theme: PropTypes.object,
  mobile: PropTypes.bool
};

export default EntityDetails;
