import {
  eventTypes,
  ADD_CIRCLE_PENDING_REQUEST,
  CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS,
  REMOVE_CIRCLE_PENDING_REQUEST
} from "../../constants";
import Backend from "../../../api/Backend";
import firebase from "firebase/app";
import "firebase/firestore";
import { eventChannel, buffers } from "redux-saga";
import { put, call, take } from "redux-saga/effects";

export function getMemberSnap(ref) {
  const listener = eventChannel(emit => {
    ref.get().then(MemberSnap => {
      emit({
        snapshot: MemberSnap
      });
    });
    return () => {};
  }, buffers.expanding(1));
  return listener;
}

// listens when circle join requests are added, removed or modified
export function* setCircleJoinRequestUpdate(data, circleId, metaType) {
  let requests = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j];
    let request = change.doc.data() || {};
    // listens when circle join requests are added
    if (
      change.type === eventTypes.ADDED ||
      change.type === eventTypes.MODIFIED
    ) {
      let member = {};
      const ref = firebase
        .firestore()
        .collection("users")
        .doc(request.userId);
      const chan = yield call(getMemberSnap, ref);
      try {
        const userData = yield take(chan);
        if (userData.snapshot && userData.snapshot.data().general_info) {
          member = userData.snapshot.data().general_info;
        }
        member["userId"] = request.userId;
        //if there is more then one change, it is initial load
        if (data.snapshot.docChanges().length > 1) {
          requests.push(member);
          if (counter === data.snapshot.docChanges().length) {
            yield put({
              type: CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS,
              circleId: circleId,
              requests: requests
            });
          }
        } else {
          yield put({
            type: ADD_CIRCLE_PENDING_REQUEST,
            circleId: circleId,
            value: member
          });
        }
        if (
          member.id === Backend.uid &&
          member.responder === true &&
          member.trackLocation === true
        ) {
        }
      } finally {
        chan.close();
      }
    } else if (change.type === eventTypes.REMOVED) {
      yield put({
        type: REMOVE_CIRCLE_PENDING_REQUEST,
        circleId: circleId,
        id: request.userId
      });
    }
  }
}
