import {
  // ADD_SESSION,
  ADD_SESSION_MEMBER,
  SESSION_MEMBERS_FETCH_SUCCESS,
  REMOVE_SESSION_MEMBER,
  ADD_SESSION_MEMBER_PRESENCE
} from "../../constants";

const INITIAL_STATE = {};

function participants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_MEMBERS_FETCH_SUCCESS:
      return {
        ...state,
        [action.sessionId]: action.sessionMembers
      };

    case ADD_SESSION_MEMBER:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId]
          ? [
              ...state[action.sessionId].filter(
                member => member.id !== action.member.id
              ),
              action.member
            ]
          : [action.member]
      };

    case ADD_SESSION_MEMBER_PRESENCE:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId]
          ? state[action.sessionId].map(member =>
              member.id === action.userId
                ? {
                    ...member,
                    onlineStatus: action.item
                  }
                : member
            )
          : []
      };

    case REMOVE_SESSION_MEMBER:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId].filter(
          member => member.id !== action.id
        )
      };
    default:
      return state;
  }
}

export default participants;
