import {
  ADD_CIRCLE_GEO_FENCES,
  CIRCLE_GEO_FENCES_FETCH_SUCCESS,
  REMOVE_CIRCLE_GEO_FENCES,
  ADD_CIRCLE
} from "../../constants";

const INITIAL_STATE = {};

function geoFences(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined ? state[action.circleId] : []
      };
    case ADD_CIRCLE_GEO_FENCES:
      return {
        ...state,
        [action.circleId]: [
          ...state[action.circleId].filter(
            geoFence => geoFence.identifier !== action.value.identifier
          ),
          action.value
        ]
      };

    case CIRCLE_GEO_FENCES_FETCH_SUCCESS:
      return {
        ...state,
        [action.circleId]: action.geoFences
      };

    case REMOVE_CIRCLE_GEO_FENCES:
      return {
        ...state,
        [action.circleId]: state[action.circleId].filter(
          geoFence => geoFence.identifier !== action.identifier
        )
      };
    default:
      return state;
  }
}

export default geoFences;
