import { ADD_ENTITY, REMOVE_ENTITY, eventTypes } from "../../constants";
import firebase from "firebase/app";
import "firebase/firestore";
import { eventChannel, buffers } from "redux-saga";
import { put, call, take } from "redux-saga/effects";

export function getEntitySnap(ref) {
  const listener = eventChannel(emit => {
    ref.get().then(EntitySnap => {
      emit({
        snapshot: EntitySnap
      });
    });
    return () => {};
  }, buffers.expanding(1));
  return listener;
}

// listens when user has been added to a circle or removed; handles also initial load; listens to add/delete/leave circle events
export function* setUserEntities(data, metaType) {
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    const change = data.snapshot.docChanges()[j].doc.data();
    const type = data.snapshot.docChanges()[j].type;
    if (type === eventTypes.ADDED || type === eventTypes.MODIFIED) {
      const ref = firebase
        .firestore()
        .collection("users")
        .doc(change.entityId);
      const chan = yield call(getEntitySnap, ref);
      try {
        const userData = yield take(chan);
        if (userData.snapshot && userData.snapshot.data()) {
          const entity = userData.snapshot.data();
          entity["entityId"] = change.entityId;
          entity["createdAt"] = change.createdAt;
          yield put({
            type: ADD_ENTITY,
            entity: entity
          });
        }
      } finally {
        chan.close();
      }
    } else if (type === "removed") {
      yield put({ type: REMOVE_ENTITY, id: change.entityId });
    }
  }
}
