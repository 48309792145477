import React, { useState } from "react";
import CategoryCenter from "../../layout/categoryCenter.style";
import CategoryInnerVertical from "../../layout/categoryInnerVertical.style";
import CategoryOuter from "../../layout/categoryOuter.style";
import IntlMessages from "../../utility/intlMessages";
import EntitiesView from "./entitiesView";
import CreateEntity from "./createEntity";
import { useSelector } from "react-redux";

const Entities = () => {
  const [showCreateEntity, setShowCreateEntity] = useState(false);
  const data = useSelector(state => state.Firebase.userData);
  const view = useSelector(state => state.App.view);

  const onCreateClicked = () => {
    setShowCreateEntity(true);
  };

  const hideCreate = () => {
    setShowCreateEntity(false);
  };

  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter
          style={{
            flexDirection: "column",
            padding: "20px",
            margin: view === "MobileView" ? "0" : "10px",
            borderWidth: view === "MobileView" ? "0" : "1px"
          }}
        >
          {view !== "MobileView" ? (
            <div className="title">
              <h1 className="fieldTitle">
                <IntlMessages id="app.settings.entities" />
              </h1>
            </div>
          ) : null}

          {showCreateEntity ? (
            <CreateEntity hideCreate={hideCreate} data={data} />
          ) : (
            <EntitiesView onCreateClicked={onCreateClicked} />
          )}
        </CategoryCenter>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

export default Entities;
