import {
  ADD_USER_DATA,
  USER_FETCHED,
  FIRESTORE_LISTEN_REQUESTED
} from "../../constants";
import { put } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/firestore";
// import PlansApi from '../../api/PlansApi';

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setUserDataUpdate(data, sessionId, metaType) {
  yield put({
    type: USER_FETCHED,
    value: true
  });
  const userData = data.snapshot.data();
  if (userData !== undefined) {
    yield put({ type: ADD_USER_DATA, data: userData });

    if (userData && userData.settings && userData.settings.entityOwner) {
      yield put({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: {
          ref: firebase
            .firestore()
            .collection("usersActivePlan")
            .doc(userData.settings.entityOwner),
          id: userData.settings.entityOwner
        },
        meta: {
          type: "activePlan"
        }
      });
    }
    // PlansApi.setUserPlan(userData);
  }
}
