import {
  eventTypes,
  ADD_CIRCLE_ALERT,
  REMOVE_CIRCLE_ALERT
} from "../../constants";
import { store } from "../../store";
import firebase from "firebase/app";

// listens when circle geofences are added, removed or modified
export function setCircleAlertsUpdate(data, circleId, metaType) {
  data.snapshot.docChanges().forEach(change => {
    const alertId = change.doc.data().alertId;
    if (
      change.type === eventTypes.ADDED ||
      change.type === eventTypes.MODIFIED
    ) {
      firebase
        .firestore()
        .collection("alerts")
        .doc(alertId)
        .get()
        .then(alertSnapshot => {
          var alert = alertSnapshot.data();
          alert["alertId"] = alertId;
          store.dispatch({
            type: ADD_CIRCLE_ALERT,
            circleId: circleId,
            value: alert
          });
        });
    }
    if (change.type === eventTypes.REMOVED) {
      store.dispatch({
        type: REMOVE_CIRCLE_ALERT,
        circleId: circleId,
        alertId: alertId
      });
    }
  });
}
