import React from "react";
import { PropTypes } from "prop-types";
import IntlMessages from "../utility/intlMessages";
import SessionParticipantsWrapper from "./sessionParticipants.style";
import { TeamOutlined } from "@ant-design/icons";
import SessionComponentWrapper from "../animations/sessionComponentWrapper";
import SessionComponentCloseButton from "../animations/sessionComponentCloseButton";
import ContactListWrapper from "../utility/contactList.style";
import { UserOutlined } from "@ant-design/icons";

const SessionParticipants = props => {
  const {
    activeParticipants,
    pendingParticipants,
    height,
    direction,
    setProfileId,
    show,
    showParticipants,
    type,
    profileId
  } = props;

  const hide = () => {
    showParticipants(false);
  };

  const singleContact = contact => {
    const activeClass = profileId === contact.id ? "active" : "";
    const onlineClass =
      contact.status === "active" ? (
        contact.onlineStatus && contact.onlineStatus.state === "online" ? (
          <IntlMessages id="app.sessions.online" />
        ) : (
          <IntlMessages id="app.sessions.offline" />
        )
      ) : (
        ""
      );
    const setProfile = () => {
      setProfileId(contact.id);
    };
    return (
      <div
        key={contact.id}
        className={`${activeClass} contactItem`}
        onClick={setProfile}
      >
        <div className="contactAvatarContainer">
          {contact.photoUrl ? (
            <img alt="#" src={contact.photoUrl.uri} />
          ) : (
            <UserOutlined />
          )}
          {contact.status === "active" ? (
            <span className={`${onlineClass} activity`} />
          ) : null}
        </div>
        <div className="contactDetailsContainer" style={{ width: "100%" }}>
          <div className="primaryDetail">
            <h3>{contact.fullName ? contact.fullName : "No Name"}</h3>
            {contact.status === "active" ? (
              contact.onlineStatus &&
              contact.onlineStatus.state === "online" ? (
                <p>
                  <IntlMessages id="app.sessions.online" />
                </p>
              ) : (
                <p>
                  <IntlMessages id="app.sessions.offline" />
                </p>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <SessionComponentWrapper show={show} type={type}>
      <div className="sessionLeftContainer">
        <SessionParticipantsWrapper data-rtl={direction}>
          <div className="participantsTopContainer">
            <div className="iconWrapper">
              <TeamOutlined />
            </div>

            <h2>{<IntlMessages id="app.sessions.participants" />}</h2>
          </div>
          <div
            style={{
              maxHeight: height - 199,
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px"
            }}
            className="sessionParticipantsContainer"
          >
            <div className="contactsWrapper">
              {activeParticipants && activeParticipants.length > 0 ? (
                <ContactListWrapper data-rtl={direction}>
                  {activeParticipants.map(contact => singleContact(contact))}
                </ContactListWrapper>
              ) : (
                <span className="isoNoResultMsg">
                  {<IntlMessages id="app.members.noContacts" />}
                </span>
              )}
            </div>
            {pendingParticipants && pendingParticipants.length > 0 ? (
              <div className="contactsWrapper">
                <p className="sectionLabel">
                  <IntlMessages id="app.sessions.pendingMembers" />
                </p>
                <ContactListWrapper data-rtl={direction}>
                  {pendingParticipants.map(contact => singleContact(contact))}
                </ContactListWrapper>
              </div>
            ) : null}
          </div>
          {type !== "mobile" ? (
            <SessionComponentCloseButton show={show} onClick={hide} />
          ) : null}
        </SessionParticipantsWrapper>
      </div>
    </SessionComponentWrapper>
  );
};

SessionParticipants.propTypes = {
  activeParticipants: PropTypes.array,
  pendingParticipants: PropTypes.array,
  height: PropTypes.number,
  direction: PropTypes.string,
  setProfileId: PropTypes.func,
  show: PropTypes.bool,
  showParticipants: PropTypes.func,
  type: PropTypes.string,
  profileId: PropTypes.string
};

export default SessionParticipants;
