import React from "react";
import { ContentHolderWrapper } from "./contentHolder.style";
import PropTypes from "prop-types";

const ContentHolder = props => {
  return (
    <ContentHolderWrapper data-rtl={props.direction} style={props.style}>
      {props.children}
    </ContentHolderWrapper>
  );
};

ContentHolder.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  style: PropTypes.object
};
export default ContentHolder;
