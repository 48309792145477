import React, { useState } from "react";
import PropTypes from "prop-types";
import CategoryInnerVertical from "../../components/layout/categoryInnerVertical.style";
import MobileOuter from "../../components/layout/mobileOuter.style";
import Topbar from "../../components/session/mobile//topbar";
import SessionParticipantProfile from "../../components/session/sessionParticipantProfile";
import Chat from "../../components/chat/Chat";
import SessionMapBox from "../../components/mapBox/sessionMap";
import CallerRouteMap from "../../components/mapBox/maps/callerRouteMap";
import SessionParticipants from "../../components/session/sessionParticipants";
import CallerInfo from "../../components/session/callerInfo";
import RespondersInviteList from "../../components/session/respondersInviteList";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import MobileViewWrapper from "./mobileView.style";
import MobilePageHeader from "../../components/utility/mobilePageHeader";
import IntlMessages from "../../components/utility/intlMessages";
import { useIntl } from "react-intl";
import { appActions } from "../../redux/reducers/app/reducer";
import { useDispatch } from "react-redux";

const MobileView = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState("sessionMap");

  const showSessionMap = () => {
    setCurrentView("sessionMap");
  };
  const showInviteList = () => {
    setCurrentView("inviteList");
  };
  const showParticipants = () => {
    setCurrentView("participants");
  };
  const showCallerInfo = () => {
    setCurrentView("callerInfo");
  };
  const showChat = () => {
    setCurrentView("chat");
  };

  const showCallerRouteMap = () => {
    setCurrentView("callerRouteMap");
  };
  const {
    direction,
    session,
    userData,
    height,
    localState,
    sessionChatTypingEvents,
    members,
    messages,
    locations,
    ownerLocation,
    ownerLocations,
    activeParticipants,
    pendingParticipants,
    currentAppLocale,
    setProfileId,
    profileId
  } = props;
  const title =
    currentView === "sessionMap" ? (
      <IntlMessages id="app.sessions.map" />
    ) : currentView === "chat" ? (
      <IntlMessages id="app.sessions.chat" />
    ) : currentView === "callerInfo" ? (
      <IntlMessages id="app.sessions.callerInfo" />
    ) : currentView === "callerRouteMap" ? (
      <IntlMessages id="app.sessions.callerRoute" />
    ) : currentView === "inviteList" ? (
      <IntlMessages id="app.sessions.inviteResponders" />
    ) : currentView === "participants" && profileId === null ? (
      <IntlMessages id="app.sessions.participants" />
    ) : currentView === "participants" && profileId !== null ? (
      "participant"
    ) : null;

  const subTitle =
    currentView === "chat"
      ? members.length +
        " " +
        intl.formatMessage({
          id: "app.sessions.participants"
        })
      : null;

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <MobileViewWrapper data-rtl={direction}>
        <Debounce time="1000" handler="onResize">
          <WindowSizeListener
            onResize={windowSize =>
              dispatch({
                type: appActions.TOGGLE_ALL,
                height: windowSize.windowHeight,
                width: windowSize.windowWidth
              })
            }
          />
        </Debounce>

        <MobileOuter data-rtl={direction}>
          <Topbar
            currentView={currentView}
            showSessionMap={showSessionMap}
            showInviteList={showInviteList}
            showParticipants={showParticipants}
            showCallerInfo={showCallerInfo}
            showCallerRouteMap={showCallerRouteMap}
            showChat={showChat}
          />
          <div
            style={{
              width: "100%",
              position: "absolute",
              top: "64px",
              zIndex: 100
            }}
          >
            <MobilePageHeader
              title={title}
              subTitle={subTitle}
              onBack={
                (currentView === "participants" ||
                  currentView === "sessionMap") &&
                profileId !== null
                  ? () => setProfileId(null)
                  : null
              }
            />
          </div>
          <CategoryInnerVertical
            style={{
              width: "100%",
              position: "absolute",
              top: "64px",
              bottom: 0
            }}
            withHeader={true}
          >
            {currentView === "sessionMap" && profileId === null ? (
              <SessionMapBox
                type={"mobile"}
                direction={direction}
                localState={localState}
                userData={userData}
                session={session}
                members={members}
                activeParticipants={activeParticipants}
                locations={locations}
                ownerLocation={ownerLocation}
                ownerLocations={ownerLocations}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            ) : null}
            {currentView === "chat" ? (
              <Chat
                show={currentView === "chat"}
                type={"mobile"}
                showChat={showChat}
                direction={direction}
                height={height}
                session={session}
                listType={session ? session.sessionType : null}
                sessionChatTypingEvents={
                  session && sessionChatTypingEvents[session.sessionId]
                    ? sessionChatTypingEvents[session.sessionId]
                    : []
                }
                sessionsMembers={members}
                sessionId={session ? session.sessionId : null}
                messages={messages}
                ownerId={session ? session.creator : null}
              />
            ) : null}
            {currentView === "inviteList" ? (
              <RespondersInviteList
                show={currentView === "inviteList"}
                type={"mobile"}
                showInviteList={showInviteList}
                sessionMembers={members}
                direction={direction}
              />
            ) : null}

            {currentView === "participants" && profileId === null ? (
              <SessionParticipants
                show={currentView === "participants"}
                type={"mobile"}
                showParticipants={showParticipants}
                direction={direction}
                activeParticipants={activeParticipants}
                pendingParticipants={pendingParticipants}
                height={height}
                session={session}
                ownerLocation={ownerLocation}
                setProfileId={setProfileId}
                profileId={profileId}
              />
            ) : null}

            {(currentView === "participants" || currentView === "sessionMap") &&
            profileId !== null ? (
              <SessionParticipantProfile
                type={"mobile"}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            ) : null}

            {currentView === "callerInfo" ? (
              <CallerInfo
                direction={direction}
                show={currentView === "callerInfo"}
                type={"mobile"}
                showCallerInfo={showCallerInfo}
                height={height}
                ownerId={session ? session.ownerId : null}
                session={session}
                showInviteList={showInviteList}
                history={props.history}
                location={props.location}
              />
            ) : null}

            {currentView === "callerRouteMap" ? (
              <CallerRouteMap
                height={height}
                show={currentView === "callerRouteMap"}
                type={"mobile"}
                direction={direction}
                session={session}
                profileId={profileId}
              />
            ) : null}
          </CategoryInnerVertical>
        </MobileOuter>
      </MobileViewWrapper>
    </IntlProvider>
  );
};

MobileView.propTypes = {
  direction: PropTypes.string,
  session: PropTypes.object,
  userData: PropTypes.object,
  height: PropTypes.number,
  localState: PropTypes.object,
  sessionChatTypingEvents: PropTypes.object,
  members: PropTypes.array,
  messages: PropTypes.array,
  locations: PropTypes.array,
  ownerLocation: PropTypes.object,
  ownerLocations: PropTypes.array,
  activeParticipants: PropTypes.array,
  pendingParticipants: PropTypes.array,
  profileId: PropTypes.string,
  setProfileId: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  currentAppLocale: PropTypes.object
};

export default MobileView;
