import {ADD_PLANS} from "../../constants";
const INITIAL_STATE = {
  plans: {}
};
const plans = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PLANS:
      return {
        ...state,
        plans: action.data
      };

    default:
      return state;
  }
};
export default plans;
