import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LogoWrapper } from "./logo.style";
import minifylogo from "../../icons/lookalogosmall.png";
import largelogo from "../../icons/lookalogo.png";
import largelogodark from "../../icons/lookalogodark.png";
import { useSelector } from "react-redux";
import { getCurrentTheme } from "../../redux/selectors/user";

const Logo = props => {
  const { collapsed, localState, direction } = props;
  const theme = useSelector(state => getCurrentTheme(state));
  return (
    <LogoWrapper data-rtl={direction} className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to={`/${localState.uid}/console`}>
              <img src={minifylogo} alt={"logo"} style={{ width: "25px" }} />
            </Link>
          </h3>
        </div>
      ) : (
        <Link to={`/${localState.uid}/console`}>
          <img
            src={theme.name === "lightTheme" ? largelogo : largelogodark}
            alt={"logo"}
            style={{ height: "40px" }}
          />
        </Link>
      )}
    </LogoWrapper>
  );
};

Logo.propTypes = {
  direction: PropTypes.string,
  localState: PropTypes.object,
  collapsed: PropTypes.bool
};

export default Logo;
