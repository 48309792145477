import {
  ADD_ONLINE_SESSION,
  FIRESTORE_LISTEN_REQUESTED,
  FIREBASE_LISTEN_REQUESTED
} from "../../constants";
import firebase from "firebase/app";
import { put } from "redux-saga/effects";

// listens when circle geofences are added, removed or modified
export function* setCircleSessionsUpdate(data, circleId, metaType) {
  const session = data.snapshot.data();
  const sessionId = data.snapshot.id;

  // listens when online sessions are added
  session["sessionId"] = sessionId;
  yield put({
    type: ADD_ONLINE_SESSION,
    circleId: circleId,
    session: session
  });

  //active sessions should be listened to track ongoing changes

  yield put({
    type: FIRESTORE_LISTEN_REQUESTED,
    payload: {
      ref: firebase
        .firestore()
        .collection("sessions")
        .doc(sessionId),
      id: sessionId
    },
    meta: { type: "sessions", circleId: circleId }
  });
  yield put({
    type: FIRESTORE_LISTEN_REQUESTED,
    payload: {
      ref: firebase
        .firestore()
        .collection("sessionsLocations")
        .doc(sessionId)
        .collection("sessionLocations"),
      id: sessionId
    },
    meta: { type: "sessionLocations" }
  });
  yield put({
    type: FIRESTORE_LISTEN_REQUESTED,
    payload: {
      ref: firebase
        .firestore()
        .collection("sessionsMembers")
        .doc(sessionId)
        .collection("sessionMembers"),
      id: sessionId
    },
    meta: { type: "sessionMembers" }
  });
  yield put({
    type: FIREBASE_LISTEN_REQUESTED,
    payload: {
      ref: firebase
        .database()
        .ref("sessionMessages/" + sessionId)
        .orderByChild("createdAt"),
      id: sessionId
    },
    meta: { type: "sessionMessages" }
  });
  yield put({
    type: FIREBASE_LISTEN_REQUESTED,
    payload: {
      ref: firebase
        .database()
        .ref("sessionChatTypingEvent/" + sessionId)
        .orderByChild("timestamp"),
      id: sessionId
    },
    meta: { type: "sessionTypingEvents" }
  });

  // if (type === eventTypes.MODIFIED) {
  //    if (change.sessionStatus === "closed") {
  //      yield put({
  //        type: REMOVE_ONLINE_SESSION,
  //        circleId: circleId,
  //        sessionId: sessionId
  //      });
  //
  //      yield put({
  //        type: FIRESTORE_UNLISTEN_REQUESTED,
  //        id: sessionId,
  //        meta: {type: "sessionMembers"}
  //      });
  //      yield put({
  //        type: FIRESTORE_UNLISTEN_REQUESTED,
  //        id: sessionId,
  //        meta: {type: "sessionLocations"}
  //      });
  //      yield put({
  //        type: FIREBASE_UNLISTEN_REQUESTED,
  //        id: sessionId,
  //        meta: {type: "sessionMessages"}
  //      });
  //      yield put({
  //        type: FIREBASE_UNLISTEN_REQUESTED,
  //        id: sessionId,
  //        meta: {type: "sessionTypingEvents"}
  //      });
  //    }
  //  }
}
