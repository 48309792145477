import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import Overview from "../Overview/index";

const AppRouter = props => {
  const { url } = props;
  return (
    <Switch>
      <Route exact path={`${url}/overview`} component={Overview} />
      <Route
        exact
        path={`${url}/members`}
        component={asyncComponent(() => import("../CircleMembers/index.js"))}
      />
      <Route
        exact
        path={`${url}/responders`}
        component={asyncComponent(() => import("../CircleResponders/index.js"))}
      />
      <Route
        exact
        path={`${url}/geofences`}
        component={asyncComponent(() => import("../CircleGeoFences/index.js"))}
      />
      <Route
        exact
        path={`${url}/pendingRequests`}
        component={asyncComponent(() =>
          import("../CirclePendingRequests/index.js")
        )}
      />
      <Route
        exact
        path={`${url}/activeSessions`}
        component={asyncComponent(() => import("../ActiveSessions/index.js"))}
      />
      <Route
        exact
        path={`${url}/resolvedSessions`}
        component={asyncComponent(() => import("../ResolvedSessions/index.js"))}
      />
      <Route
        exact
        path={`${url}/settings`}
        component={asyncComponent(() => import("../CircleSettings/index.js"))}
      />
      <Route
        exact
        path={`${url}/alerts`}
        component={asyncComponent(() => import("../Alerts/index.js"))}
      />
    </Switch>
  );
};

AppRouter.propTypes = {
  url: PropTypes.string
};

export default AppRouter;
