import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { MenuItem } from "./menuItem";
import {
  GlobalOutlined,
  TeamOutlined,
  SettingOutlined,
  SafetyOutlined,
  EnvironmentOutlined,
  BellOutlined,
  AlertOutlined,
  MedicineBoxOutlined,
  HourglassOutlined
} from "@ant-design/icons";
import IntlMessages from "../../utility/intlMessages";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    width: "100%"
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = props => (
  <motion.ul variants={variants} className="nav-list">
    {items.map(item => (
      <MenuItem
        active={props.current === item.link}
        toggle={props.toggle}
        key={item.link}
        link={item.link}
        icon={item.icon}
        text={item.text}
        url={props.url}
      />
    ))}
  </motion.ul>
);

const items = [
  {
    link: "overview",
    icon: <GlobalOutlined />,
    text: <IntlMessages id="app.sidebar.overview" />
  },
  {
    link: "members",
    icon: <TeamOutlined />,
    text: <IntlMessages id="app.sidebar.members" />
  },
  {
    link: "responders",
    icon: <MedicineBoxOutlined />,
    text: <IntlMessages id="app.sidebar.responders" />
  },
  {
    link: "alerts",
    icon: <BellOutlined />,
    text: <IntlMessages id="app.sidebar.alerts" />
  },
  {
    link: "geofences",
    icon: <EnvironmentOutlined />,
    text: <IntlMessages id="app.sidebar.geoFences" />
  },
  {
    link: "pendingRequests",
    icon: <HourglassOutlined />,
    text: <IntlMessages id="app.sidebar.pending" />
  },
  {
    link: "activeSessions",
    icon: <AlertOutlined />,
    text: <IntlMessages id="app.sidebar.active" />
  },
  {
    link: "resolvedSessions",
    icon: <SafetyOutlined />,
    text: <IntlMessages id="app.sidebar.resolved" />
  },
  {
    link: "settings",
    icon: <SettingOutlined />,
    text: <IntlMessages id="app.sidebar.settings" />
  }
];

Navigation.propTypes = {
  url: PropTypes.string,
  toggle: PropTypes.func,
  current: PropTypes.string
};
