import React from "react";
import PropTypes from "prop-types";
import PaymentWrapper from "./style";
import PaymentApi from "../../api/PaymentApi";
import { Img } from "react-image";
import countryCodes from "./countryCodes";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Form, Input, Row, Col, Button, Typography, Select } from "antd";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { motion } from "framer-motion";
import {
  CloseOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;
const override = css`
  position: relative;
  left: -50%;
`;

const contentWrapperVariants = {
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
  hide: { opacity: 0 },
};

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ccn: null,
      cvv: null,
      exp: null,
      cardType: "Default",
      cardUrl: "https://files.readme.io/d1a25b4-generic-card.png",
      cardData: null,
      showPayment: false,
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      address: "",
      city: "",
      country: "",
      zip: "",
      nameOnCard: "",
      ccType: "",
      last4Digits: "",
      expire: "",
      issuingCountry: "",
      renderContent: false,
      loading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  static propTypes = {
    form: PropTypes.object,
    onDismiss: PropTypes.func,
  };
  formRef = React.createRef();

  loadHostedFieldsScript() {
    let that = this;
    PaymentApi.loadHostedFieldsScript(this, function (token) {
      if (token !== null) {
        that.setState({
          renderContent: true,
        });
        console.log(token);
      }
    });
  }

  validateDetails = (e) => {
    e.preventDefault();
    this.formRef.current
      .validateFields([
        "email",
        "firstName",
        "lastName",
        "companyName",
        "address",
        "city",
        "country",
        "zip",
      ])
      .then((values) => {
        this.setState(
          {
            showPayment: true,
          },
          this.loadHostedFieldsScript
        );
      })
      .catch((errorInfo) => {});
  };

  validateCard = (e) => {
    e.preventDefault();
    const { ccn, cvv, exp } = this.state;
    this.formRef.current
      .validateFields(["nameOnCard"])
      .then((values) => {
        if (ccn === null && cvv === null && exp === null) {
          this.submitCredentials();
        }
      })
      .catch((errorInfo) => {});
  };

  submitCredentials = () => {
    let that = this;
    this.setState({ loading: true });
    window.bluesnap.submitCredentials(function (result) {
      if (null === result.cardData) {
        var errorArray = result.error;
        for (let i = 0; i < errorArray.length; i++) {
          that.setState({
            [errorArray[i].tagId]: errorArray[i].errorDescription,
            loading: false,
          });
        }
      } else {
        const vaultedShopperId = this.props.vaultedData.vaultedShopperId;
        if (vaultedShopperId !== undefined) {
          PaymentApi.updateVaultedShopperData(
            that.state,
            vaultedShopperId,
            function (data) {
              that.changePLan(data);
            }
          );
        } else {
          PaymentApi.createVaultedShopper(that.state, function (data) {
            that.changePLan(data);
          });
        }
        //handel resulte and pftoken
      }
    });
  };

  changePLan(data) {
    if (this.props.plan === "premium") {
      PaymentApi.changePlan("premium");
    } else {
      this.props.setGrowingLoading(true);
      PaymentApi.createGrowingSubscription(data.vaultedShopperId, function (
        result
      ) {
        this.props.setGrowingLoading(false);
      });
    }

    this.setState({ loading: false });
    this.onCloseClicked();
  }

  onBackClicked = () => {
    this.setState({
      showPayment: false,
    });
  };

  onCloseClicked = () => {
    this.props.onDismiss();
    this.setState({
      showPayment: false,
    });
  };

  // onChange: (e) => this.handleChange(e, 'note')

  handleInputChange = (e, name) => {
    const { value } = e.target;
    this.setState({ [name]: value });
    this.formRef.current.setFieldsValue({
      [name]: value,
    });
  };

  handleCountryChange = (value) => {
    this.setState({ country: value });
    this.formRef.current.setFieldsValue({
      country: value,
    });
  };

  // changeImpactedElement(tagId, removeClass, addClass) {
  //   removeClass = removeClass || "";
  //   addClass = addClass || "";
  //   ("[data-bluesnap=" + tagId + "]")
  //     .removeClass(removeClass)
  //     .addClass(addClass);
  // }

  render() {
    const {
      showPayment,
      email,
      firstName,
      lastName,
      address,
      city,
      country,
      zip,
      nameOnCard,
      companyName,
      renderContent,
      loading,
    } = this.state;
    const pose = renderContent === true ? "show" : "hide";
    const theme = this.props.theme;
    const countriesOptions = [];
    for (let i = 0; i < countryCodes.length; i++) {
      countriesOptions.push(
        <Option key={i} value={countryCodes[i].abbreviation}>
          {countryCodes[i].country}
        </Option>
      );
    }
    return (
      <PaymentWrapper>
        <div className="topContainer">
          <div>
            <Title level={3}>Looka</Title>
            <p>Premium Plan</p>
          </div>

          {showPayment ? (
            <div className="topLeftButton" onClick={this.onBackClicked}>
              <Button shape="circle" icon={<ArrowLeftOutlined />} />
            </div>
          ) : null}
          <div className="topRightButton" onClick={this.onCloseClicked}>
            <Button shape="circle" icon={<CloseOutlined />} />
          </div>
        </div>
        <Form
          ref={this.formRef}
          className="pricing-form"
          initialValues={{
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            address: address,
            city: city,
            country: country,
            zip: zip,
            nameOnCard: nameOnCard,
          }}
        >
          {!showPayment ? (
            <div>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  onChange={(e) => this.handleInputChange(e, "email")}
                />
              </Form.Item>

              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="First name"
                      onChange={(e) => this.handleInputChange(e, "firstName")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last name"
                      onChange={(e) => this.handleInputChange(e, "lastName")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="companyName">
                <Input
                  placeholder="Company name"
                  onChange={(e) => this.handleInputChange(e, "companyName")}
                />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input your billing address!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder="Address"
                  onChange={(e) => this.handleInputChange(e, "address")}
                />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input your city!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder="City"
                  onChange={(e) => this.handleInputChange(e, "city")}
                />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please input your country!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      // style={{width: 200}}
                      placeholder="Country"
                      optionFilterProp="children"
                      onChange={this.handleCountryChange}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countriesOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="zip"
                    rules={[
                      {
                        required: true,
                        message: "Please input your zip!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Zip"
                      onChange={(e) => this.handleInputChange(e, "zip")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="buttonContainer">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.validateDetails}
                >
                  Payment Info <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div className="outer">
              <motion.div
                className="cardDetails"
                animate={pose}
                variants={contentWrapperVariants}
              >
                <Form.Item
                  label="Name on card"
                  name="nameOnCard"
                  rules={[
                    {
                      required: true,
                      message: "Please input the name on your card!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Full name"
                    onChange={(e) => this.handleInputChange(e, "nameOnCard")}
                  />
                </Form.Item>
                <Row className="cardNumberContainer">
                  <div className="cardNumber">
                    <Form.Item label="Card Number">
                      <div
                        className="form-control"
                        id="card-number"
                        data-bluesnap="ccn"
                      />
                      <div className={"ant-form-explain has-error"}>
                        {this.state.ccn}
                      </div>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Img src={this.state.cardUrl} />
                  </Form.Item>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Exp. Date">
                      <div
                        className="form-control"
                        id="exp-date"
                        data-bluesnap="exp"
                      />
                      <div className={"ant-form-explain has-error"}>
                        {this.state.exp}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Security Code">
                      <div
                        className="form-control"
                        id="cvv"
                        data-bluesnap="cvv"
                      />
                      <div className={"ant-form-explain has-error"}>
                        {this.state.cvv}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item className="buttonContainer">
                  <Button
                    type="primary"
                    onClick={this.validateCard}
                    loading={loading}
                  >
                    Add Card
                  </Button>
                </Form.Item>
              </motion.div>
              {this.state.renderContent === true ? null : (
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                  }}
                >
                  <ClipLoader
                    loading={true}
                    color={theme.palette["primary"][0]}
                    css={override}
                  />
                </div>
              )}
            </div>
          )}
        </Form>
      </PaymentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vaultedData: state.Firebase.vaultedData,
  };
};

export default connect(mapStateToProps)(withTheme(Payment));
