import styled from "styled-components";
import {palette} from "styled-theme";

const VendorDetailsWrapper = styled.div`
  width: 100%;
  padding: 20px 0 0 0;

  .settingsContent {
    margin: 0 0 0 16px;
    min-width: 65%;
    min-height: 280px;
    .ant-layout-content {
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1) !important;
      transition: all 0.3s ease;
      padding: 6px 0 0 0;
    }
    .ant-switch-checked,
    .ant-tag.ant-tag-checkable.ant-tag-checkable-checked,
    .ant-tag.ant-tag-checkable:hover,
    .ant-radio-inner:after,
    ::selection {
      background-color: ${palette("secondary", 0)} !important;
    }
    .ant-radio-wrapper:hover .ant-radio-inner,
    .ant-radio-wrapper.ant-radio-wrapper-checked:hover
      .ant-radio.ant-radio-checked
      .ant-radio-inner,
    .ant-radio-checked .ant-radio-inner,
    .ant-radio-inner:hover,
    .ant-radio-inner:after {
      border-color: ${palette("secondary", 0)} !important;
    }

    .ant-form-item-label label .anticon {
      color: #cdcdcd;
    }
    .ant-form-item-required:before {
      color: ${palette("secondary", 0)} !important;
    }
    .fieldTitle {
      color: ${palette("text", 0)};
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      display: block;
      margin-bottom: 8px;
    }
    .fieldSubTitle {
      font-size: 13px;
    }
    .fieldSpacer {
      width: "100%";
      height: 20px;
    }
    .categoryTitle {
      color: ${palette("secondary", 0)} !important;
      font-size: 16px;
      line-height: 20px;
      margin: 1em 0 2em 0 !important;
      padding-bottom: 0.5em;
      cursor: default;
      color: #0f1c44;
      border-bottom: 1px solid #e3e3e3;
    }
  }
`;

export default VendorDetailsWrapper;
