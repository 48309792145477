import React, { Component } from "react";
import MapGL, { NavigationControl } from "react-map-gl";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { css } from "@emotion/core";
import { withTheme } from "styled-components";
import GeofencesCardWrapper from "./geoFencesCardMap.style";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const mapWrapperVariants = {
  show: {
    opacity: 1,
    width: "100%",
    height: "100%",
    transition: { duration: 2 }
  },
  hide: { opacity: 0, width: "100%", height: "100%" }
};

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
`;

class NakedMap extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showMap: false,
      mapStyle: "mapbox://styles/mapbox/light-v9",
      viewport: {
        latitude: 37.805,
        longitude: -122.447,
        zoom: 2,
        bearing: 0,
        pitch: 30
      }
    };
    this.onLoad = this.onLoad.bind(this);
    this._onViewportChange = this._onViewportChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onLoad() {
    if (this._isMounted) {
      this.setState({
        showMap: true
      });
    }
  }

  _onViewportChange = viewport => {
    if (this._isMounted) {
      this.setState({ viewport });
    }
  };

  render() {
    const { viewport, mapStyle } = this.state;
    const navStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "10px"
    };
    const theme = this.props.theme;
    const pose = this.state.showMap === true ? "show" : "hide";
    return (
      <GeofencesCardWrapper>
        <motion.div
          style={{ width: "100%", height: "100%" }}
          animate={pose}
          variants={mapWrapperVariants}
        >
          <MapGL
            ref={map => {
              this.map = map;
            }}
            {...viewport}
            visible={true}
            width="100%"
            height="100%"
            onLoad={this.onLoad}
            preventStyleDiffing={true}
            mapStyle={mapStyle}
            onViewportChange={this._onViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            <div className="nav" style={navStyle}>
              <NavigationControl onViewportChange={this._onViewportChange} />
            </div>
          </MapGL>
        </motion.div>
        {!this.state.showMap ? (
          <ClipLoader
            loading={true}
            color={theme.palette["primary"][0]}
            css={override}
          />
        ) : null}
      </GeofencesCardWrapper>
    );
  }
}

export default withTheme(NakedMap);
