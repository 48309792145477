import styled from "styled-components";
import { palette } from "styled-theme";

const ContactDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  .contactIcon {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: ${palette("iconBg", 0)};
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 24px;
    }
  }
  p {
    margin: 0;
    padding: 0 10px;
    color: ${palette("text", 0)};
  }
`;

export default ContactDetailWrapper;
