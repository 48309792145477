// import initialState from '../store/initialState';
import { ADD_PLAN } from "../../constants";
const INITIAL_STATE = {
  plan: {}
};
const activePlan = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PLAN:
      return action.data;

    default:
      return state;
  }
};
export default activePlan;
