import styled from "styled-components";
import { palette } from "styled-theme";

const SessionWrapper = styled.div`
   width: 100%;

  .participantsTopContainer,
  .chatTopContainer,
  .sessionCallerTopContiner,
  .respondersTopContainer {
    height: 67px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid ${palette("border", 0)};
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: ${palette("surfaceColor", 0)} !important;
    .iconWrapper{
      background-color: ${palette("iconBg", 0)} !important;
    }
    h2,
    p {
      margin: 0;
    }
  }
  .ant-modal-body {
    height: 100%;
  }
  .session {
    .ant-tabs-nav-scroll {
      background-color: ${palette("surfaceColor", 0)};
      padding-top: 5px;
    }
    .ant-tabs {
      border-radius: 5px;
    }
    .ant-tabs-bar {
      margin: 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: ${palette("secondary", 0)};
      font-weight: 600;
    }
    .ant-tabs-ink-bar {
      background-color: ${palette("secondary", 0)};
      display: ${props =>
        props["data-rtl"] === "rtl" ? "none !important" : "block"};
    }
    .ant-tabs-tab {
      color: ${palette("secondary", 0)};
      &:hover {
        color: ${palette("secondary", 0)};
      }
    }
  }

  .sessionLeftContainer {
    border-radius: 5px;
    background-color: ${palette("surfaceColor", 0)} !important;
    border: 1px solid ${palette("border", 0)};
    background: transparent;
    transition: all 0.3s ease;
    margin: ${props =>
      props["data-rtl"] === "rtl" ? "10px 10px 10px 0" : "10px 0 10px 10px"};
    .chatTopContainer {
      svg {
        fill: #ffffff;
        width: 30px;
        stroke-width: 2px;
        stroke: #ffffff;
        margin: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
      }
      img {
        width: 40px;
        border: 1px solid ${palette("border", 0)};
        border-radius: 20px;
        height: 40px;
        object-fit: cover;
         margin: ${props =>
           props["data-rtl"] === "rtl" ? "0 0 0 10px" : "0 10px 0 0"};
      }
      .chatParImages {
        display: flex;
        flex-direction: row;
        img {
          height: 20px;
          width: 20px;
          border-radius: 10px;
        }
      }
    }
    .chat-messages-inner  {
      flex-grow: 1;
      padding:  0.5em;
      overflow-y: scroll;
      background-color: ${palette("surfaceColor", 0)};
      border-bottom:  1px solid;
      border-color: ${palette("border", 0)};
    }

    .chat-messages-inner ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .emoji-mart-anchor-icon {
      color:#858585 !important;
    }
  }

  .dismissBtnContainer {
    width: 60px;
    height: 60px;
    background-color: ${palette("secondary", 0)};
    border-radius: 30px;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1);
    transition: all 0.3s ease;
    margin: 10px 10px 10px 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
    align-items: center;
    justify-content: center;
    display: flex;
    i {
      font-size: 34px;
      color: ${palette("surfaceColor", 0)};
    }
  }

  .sessionCenterContainer {
    border-radius: 5px;
     border: 1px solid ${palette("border", 0)};
    transition: all 0.3s ease;
    margin: 10px 10px 10px 10px;
    background-color: ${palette("surfaceColor", 0)};
    padding: 5px;
  }

  .sessionRightContainer {
    height: 100%;
    border-radius: 5px;
    border: 1px solid ${palette("border", 0)};
    transition: all 0.3s ease;
    margin: ${props =>
      props["data-rtl"] === "rtl" ? "0px 0 0px 10px" : "0px 10px 0px 0"};
    background-color: ${palette("surfaceColor", 0)};
  }

  .sidebar.session {
    .ant-menu-item {
      margin-bottom: 1px;
    }
    .ant-menu-item-selected {
       border-left: 3px solid #fff;
    }
  }
  .session {
    .isoImgWrapper {
      width: 50px;
       display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #fbfbfb;
      .isoBorderRadius(50%);

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }

      .userActivity {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        bottom: 0;
        right: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "5px")};
        left: ${props => (props["data-rtl"] === "rtl" ? "5px" : "inherit")};
        border: 1px solid #ffffff;
        background-color: #40ce86;
        border-radius: 5px;
      }
    }
    .isoSessionCallerInfos {
      display: flex;
      padding-left: 1em;
      justify-content: center;
      flex-direction: column;
      .isoSessionCallerName {
        font-size: 14px;
        font-weight: 500;
        color: ${palette("text", 0)};
        line-height: 1.5;
        min-width: 80px;
        position: relative;
        margin: 0;
      }
      .isoSessionCallerDetails {
        font-size: 14px;
        font-weight: 400;
        color: ${palette("lightText", 1)};
        line-height: 1.5;
        margin: 0;
      }
    }
  }
  .sessionContainer{
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${palette("background", 0)} !important;
    .ant-layout-sider {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
      background:  ${palette("surfaceColor", 0)} !important;
      border-right: 1px solid,
      border-color: ${palette("border", 0)} !important;
    }
  }

  .sessionPriorityLabel {
    text-align: right;
  }
  .sidebar.session {
    .ant-menu-item {
      padding: 0 !important;
      .menuItem {
        justify-content: center;
        width: 100%;
        i {
          margin: 0;
          width: auto;
        }
      }
    }
  }
`;

export default SessionWrapper;
