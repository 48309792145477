import styled from "styled-components";
import { palette } from "styled-theme";

const PaymentMethodWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  .cardContainer {
    background-color: ${palette("surfaceColor", 0)};
    position: relative;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
    border-radius: 5px;
    .cardDetails {
      padding: 2em
      display: flex;
      img {
        padding: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 20px" : "0 20px 0 0"};
       }
    }
    .cardActions {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid ${palette("border", 0)};
      padding: 1em;
    }
  }

  .outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .addMethodContainer {
      margin: 2em;
      padding: 30px;
      border-radius: 10px;
      display: inline-block;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
      .pricing-form {
        .cardDetails {
          display: flex;
          flex-direction: column;
          .ant-form-item-label {
            min-width: 105px;
          }
          .cardDetailsContainer {
            display: flex;
            .cardDetailsInner {
              display: flex;
              flex: 1;
              margin-right: 20px;
              flex-direction: column;
              .cardDetailsRow {
                display: flex;
                justify-content: space-between;
                .ant-row {
                  display: flex;
                  margin: 0;
                  .formItem {
                    margin: 0 0 10px 0;
                  }
                }
                &.secret {
                  .ant-input {
                    width: 60px;
                  }
                }
              }
            }
            .cardDetailsImage {
              img {
                height: 92px;
              }
              .ant-form-item {
                justify-content: flex-end;
              }
            }
          }
          .buttonContainer {
            display: flex;
            padding: 20px 0 0 0;
            justify-content: center;
          }
        }
        .ant-form-item-children {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-form-explain {
          color: red;
          padding-right: 10px;
        }
      }
    }
  }

  .addMethodBtn {
    width: 100%;
    height: 100%;
    min-height: 185px;
    background-color: ${palette("surfaceColor", 0)};
    i {
      color: ${palette("primary", 0)};
    }
    span {
      color: ${palette("primary", 0)};
    }
    border-color: ${palette("border", 0)};
    &:hover {
      border-color: ${palette("primary", 0)};
      background-color: ${palette("lightBg", 0)};
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0 0 !important;
    min-height: 0 !important;
  }
`;

export default PaymentMethodWrapper;
