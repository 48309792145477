import React from "react";
import PropTypes from "prop-types";
import PaymentSettingsWrapper from "./paymentSettings.style";
import PaymentApi from "../../api/PaymentApi";
import { connect } from "react-redux";
import countryCodes from "./countryCodes";
import { Form, Input, Button, Select } from "antd";
import IntlMessages from "../utility/intlMessages";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";
const { Option } = Select;

class PaymentSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ccn: null,
      cvv: null,
      exp: null,
      cardType: "Default",
      cardUrl: "https://files.readme.io/d1a25b4-generic-card.png",
      cardData: null,
      showPayment: false,
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      address: "",
      city: "",
      country: "",
      zip: "",
      nameOnCard: "",
      ccType: "",
      last4Digits: "",
      expire: "",
      issuingCountry: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  static propTypes = {
    form: PropTypes.object,
    onDismiss: PropTypes.func,
    vaultedData: PropTypes.object,
    direction: PropTypes.string
  };
  formRef = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vaultedData !== prevState.vaultedData) {
      return { vaultedData: nextProps.vaultedData };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vaultedData !== this.state.vaultedData) {
      this.updateFields(this.state.vaultedData);
    }
  }

  componentDidMount() {
    this.updateFields(this.props.vaultedData);
  }

  updateFields(data) {
    const updatedEmail = data.email;
    const updatedFirstName = data.firstName;
    const updatedLastName = data.lastName;
    const updatedCompanyName = data.companyName;
    const updatedAddress = data.address;
    const updatedCity = data.city;
    const updatedCountry = data.country;
    const updatedZip = data.zip;
    this.setState({
      email: updatedEmail,
      firstName: updatedFirstName,
      lastName: updatedLastName,
      companyName: updatedCompanyName,
      address: updatedAddress,
      city: updatedCity,
      country: updatedCountry,
      zip: updatedZip
    });
    this.formRef.current.setFieldsValue({
      email: updatedEmail,
      firstName: updatedFirstName,
      lastName: updatedLastName,
      companyName: updatedCompanyName,
      address: updatedAddress,
      city: updatedCity,
      country: updatedCountry,
      zip: updatedZip
    });
  }

  loadHostedFieldsScript() {
    PaymentApi.loadHostedFieldsScript(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields([
        "email",
        "firstName",
        "lastName",
        "companyName",
        "address",
        "city",
        "country",
        "zip"
      ])
      .then(values => {
        const vaultedShopperId = this.props.vaultedData.vaultedShopperId;
        if (vaultedShopperId !== undefined) {
          PaymentApi.updateVaultedShopperData(
            values,
            vaultedShopperId,
            function(data) {
              console.log("updated vaulted shopper successfully");
            }
          );
        } else {
          PaymentApi.createVaultedShopper(values, function(callback) {
            console.log("created vaulted shopper successfully");
          });
        }
      })
      .catch(errorInfo => {});
  };

  onDeclineClicked = () => {
    this.updateFields(this.props.vaultedData);
  };

  handleInputChange = (e, name) => {
    const { value } = e.target;
    this.setState({ [name]: value });
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  };

  handleCountryChange = value => {
    this.setState({ country: value });
    this.formRef.current.setFieldsValue({
      country: value
    });
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      address,
      city,
      country,
      zip,
      companyName
    } = this.state;

    const data = this.props.vaultedData;
    const isSame =
      email === data.email &&
      firstName === data.firstName &&
      lastName === data.lastName &&
      companyName === data.companyName &&
      address === data.address &&
      city === data.city &&
      country === data.country &&
      zip === data.zip;

    const countriesOptions = [];
    for (let i = 0; i < countryCodes.length; i++) {
      countriesOptions.push(
        <Option key={i} value={countryCodes[i].abbreviation}>
          {countryCodes[i].country}
        </Option>
      );
    }

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    return (
      <CategoryOuter>
        <CategoryInnerVertical>
          <CategoryCenter style={{ flexDirection: "column", padding: "20px" }}>
            <div className="title">
              <h1 className="fieldTitle">
                <IntlMessages id="app.settings.paymentSettings" />
              </h1>
            </div>

            <PaymentSettingsWrapper>
              <div className="innerContainer">
                <Form
                  ref={this.formRef}
                  layout="vertical" // onSubmit={this.validateDetails}
                  className="pricing-form"
                  initialValues={{
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    companyName: companyName,
                    address: address,
                    city: city,
                    country: country,
                    zip: zip
                  }}
                >
                  <div className="formFields">
                    <Form.Item
                      label={<IntlMessages id="app.settings.email" />}
                      {...formItemLayout}
                      hasFeedback
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!"
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!"
                        }
                      ]}
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "email")}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<IntlMessages id="app.settings.firstName" />}
                      {...formItemLayout}
                      hasFeedback
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "firstName")}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<IntlMessages id="app.settings.lastName" />}
                      {...formItemLayout}
                      hasFeedback
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "lastName")}
                      />
                    </Form.Item>

                    <Form.Item
                      label={<IntlMessages id="app.settings.companyName" />}
                      {...formItemLayout}
                      name="companyName"
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "companyName")}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<IntlMessages id="app.settings.address" />}
                      {...formItemLayout}
                      hasFeedback
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your billing address!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "address")}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<IntlMessages id="app.settings.city" />}
                      {...formItemLayout}
                      hasFeedback
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please input your city!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input
                        onChange={e => this.handleInputChange(e, "city")}
                      />
                    </Form.Item>

                    <Form.Item
                      label={<IntlMessages id="app.settings.country" />}
                      {...formItemLayout}
                      hasFeedback
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please input your country!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={this.handleCountryChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countriesOptions}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={<IntlMessages id="app.settings.zip" />}
                      {...formItemLayout}
                      hasFeedback
                      style={{ marginBottom: "0px" }}
                      name="zip"
                      rules={[
                        {
                          required: true,
                          message: "Please input your zip!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input onChange={e => this.handleInputChange(e, "zip")} />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </PaymentSettingsWrapper>
          </CategoryCenter>
          <div className="buttonsContainer">
            <Button onClick={this.onDeclineClicked}>
              <IntlMessages id="app.buttons.cancel" />
            </Button>
            <Button
              style={{ margin: "0 1em" }}
              disabled={isSame}
              onClick={this.handleSubmit}
              type="primary"
            >
              <IntlMessages id="app.buttons.submit" />
            </Button>
          </div>
        </CategoryInnerVertical>
      </CategoryOuter>
    );
  }
}

const mapStateToProps = state => {
  return {
    vaultedData: state.Firebase.vaultedData.data
  };
};

export default connect(mapStateToProps)(PaymentSettings);
