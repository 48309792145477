import styled from "styled-components";
import { palette } from "styled-theme";

const CategoryRight = styled.div`
  background-color: ${palette("surfaceColor", 0)};
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${palette("border", 0)};
  border-radius: 10px;
  margin: ${props =>
    props["data-rtl"] === "rtl" ? "10px  0px 10px 10px" : "10px 10px 10px 0px"};

  @media only screen and (max-width: 1200px) {
    margin: ${props =>
      props["screen"] === "overview"
        ? "10px 10px 0px 10px;"
        : "0 10px 10px 10px"};
  }
`;

export default CategoryRight;
