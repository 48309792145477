import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "./languageProvider";
import { getCurrentLanguage, languageConfig } from "./config.js";
import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/reducers/auth/boot";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useSelector } from "react-redux";
import { getDirection, getCurrentTheme } from "./redux/selectors/user";

const currentAppLocale =
  AppLocale[
    getCurrentLanguage(languageConfig.defaultLanguage || "english").locale
  ];

const DashApp = () => {
  const direction = useSelector(state => getDirection(state));
  const theme = useSelector(state => getCurrentTheme(state));

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <DashAppHolder>
              <PublicRoutes history={history} />
              <ReactNotification />
            </DashAppHolder>
          </ThemeProvider>
        </Provider>
      </IntlProvider>
    </ConfigProvider>
  );
};
Boot()
  .then(() => {
    DashApp();
  })
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
