import {
  eventTypes,
  ADD_SESSION_PRIORITY,
  CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS
} from "../../constants";
import { put } from "redux-saga/effects";

// listens when circle geofences are added, removed or modified
export function* setCircleSessionPriorityUpdate(data, circleId, metaType) {
  let priorities = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j].doc.data();
    const type = data.snapshot.docChanges()[j].type;
    // listens when circle geofences are added
    if (type === eventTypes.ADDED || type === eventTypes.MODIFIED) {
      yield put({
        type: ADD_SESSION_PRIORITY,
        circleId: circleId,
        value: change
      });
      //if there is more then one change, it is initial load
      if (data.snapshot.docChanges().length > 1) {
        priorities.push(change);
        if (counter === data.snapshot.docChanges().length) {
          yield put({
            type: CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS,
            circleId: circleId,
            priorities: priorities
          });
        }
      } else {
        yield put({
          type: ADD_SESSION_PRIORITY,
          circleId: circleId,
          value: change
        });
      }
    }
  }
}
