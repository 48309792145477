import { createSelector } from "reselect";

const currentActiveEntity = (state, props) =>
  state.Firebase.entities.filter(
    item => item.entityId === props.activeEntity
  )[0];

export const makeGetActiveEntity = () => {
  return createSelector([currentActiveEntity], currentActiveEntity => {
    return currentActiveEntity;
  });
};
