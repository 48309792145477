import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";
import "moment/locale/he";
import "moment/locale/en-ca";
import ContactListWrapper from "../../utility/contactList.style";
import { UserOutlined } from "@ant-design/icons";

const List = props => {
  const { onEntitySelect, entities, direction } = props;

  const singleContact = contact => {
    const { userData, activeEntity } = props;

    const activeClass = activeEntity === contact.entityId ? "active" : "";
    const onSelect = () => {
      onEntitySelect(contact);
    };
    if (userData.settings) {
      if (userData.settings.language) {
        const loc =
          userData.settings.language.locale === "en"
            ? "en-ca"
            : userData.settings.language.locale;
        moment.locale(loc);
      }
    }
    const time = moment(contact.createdAt.seconds * 1000).fromNow();
    return (
      <div
        key={contact.entityId}
        className={`${activeClass} contactItem`}
        onClick={onSelect}
      >
        <div className="contactAvatarContainer">
          {contact.general_info && contact.general_info.photoUrl ? (
            <img alt="#" src={contact.general_info.photoUrl.uri} />
          ) : (
            <UserOutlined />
          )}
        </div>
        <div className="contactDetailsContainer" style={{ width: "100%" }}>
          <div className="primaryDetail">
            <h3>
              {contact.general_info && contact.general_info.fullName
                ? contact.general_info.fullName
                : "No Name"}
            </h3>
            <p>{time}</p>
          </div>
        </div>
      </div>
    );
  };

  const sorted = entities.sort((a, b) => b.createdAt - a.createdAt);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      {sorted && sorted.length > 0 ? (
        <ContactListWrapper data-rtl={direction} className="respondersList">
          {sorted.map(contact => singleContact(contact))}
        </ContactListWrapper>
      ) : null}
    </div>
  );
};

List.propTypes = {
  activeEntity: PropTypes.string,
  entities: PropTypes.array,
  userData: PropTypes.object,
  direction: PropTypes.string,
  onEntitySelect: PropTypes.func
};

export default List;
