import styled from "styled-components";
import { palette } from "styled-theme";

const MobileViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: row;
  min-height: 0px;
  height: 100%;
  position: fixed;
  overflow: hidden;

  .participantsTopContainer,
  .chatTopContainer,
  .sessionCallerTopContiner,
  .respondersTopContainer {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 5;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
    border: none !important;
    border-radius: 0 !important;
    height: 67px;
    background-color: ${palette("background", 0)} !important;
  }
  .sessionParticipantsContainer,
  .sessionInnerContainer,
  .chat-container {
    z-index: 4;
    margin-top: 10px;
    border-radius: 0px !important;
    width: 100%;
  }
  .sessionLeftContainer {
    border-radius: 5px;
    background-color: ${palette("surfaceColor", 0)} !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .chatTopContainer {
    display: none;
    align-items: center;
    flex-direction: row;
    padding: 0.5em;
    svg {
      fill: #ffffff;
      width: 30px;
      stroke-width: 2px;
      stroke: #ffffff;
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
    }
    h2,
    p {
      margin: 0;
    }
    h2 {
    }
    p {
    }
    img {
      width: 40px;
      border: 1px solid ${palette("border", 0)};
      border-radius: 20px;
      height: 40px;
      object-fit: cover;
      margin: 0 1em;
    }
    .chatParImages {
      display: flex;
      flex-direction: row;
      img {
        height: 20px;
        width: 20px;
        border-radius: 10px;
      }
    }
  }
  .chat-messages-inner {
    flex-grow: 1;
    padding: 0 10px;
    overflow-y: scroll;
    background-color: ${palette("surfaceColor", 0)};
    border-bottom: 1px solid;
    border-color: ${palette("border", 0)};
  }

  .chat-messages-inner ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .isoSessionCallerInfos {
    display: flex;
    padding-left: 1em;
    justify-content: center;
    flex-direction: column;
    .isoSessionCallerName {
      font-size: 14px;
      font-weight: 500;
      color: ${palette("text", 0)};
      line-height: 1.5;
      min-width: 80px;
      position: relative;
      margin: 0;
    }
    .isoSessionCallerDetails {
      font-size: 14px;
      font-weight: 400;
      color: ${palette("lightText", 1)};
      line-height: 1.5;
      margin: 0;
    }
  }

  .dismissBtnContainer {
    width: 60px;
    height: 60px;
    background-color: ${palette("secondary", 0)};
    border-radius: 30px;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1);
    transition: all 0.3s ease;
    margin: 10px 10px 10px 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
    align-items: center;
    justify-content: center;
    display: flex;
    i {
      font-size: 34px;
      color: ${palette("surfaceColor", 0)};
    }
  }
`;

export default MobileViewWrapper;
