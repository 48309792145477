import {
  SET_NEW_GROUP_TYPE,
  SET_NEW_GROUP_NAME,
  SET_NEW_GROUP_LIMIT,
  SET_NEW_GROUP_PHOTOURL,
  RESET_NEW_GROUP_DETAILS,
  SET_MODAL_STATUS
} from "../../constants";

const initialState = {
  users: [],
  openPublicModal: false,
  openPrivateModal: false
};

const newCircleDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_STATUS:
      return {
        ...state,
        openPrivateModal: action.privateModalStatus,
        openPublicModal: action.publicModalStatus
      };
    case SET_NEW_GROUP_TYPE:
      return {
        ...state,
        groupType: action.groupType
      };
    case SET_NEW_GROUP_NAME:
      return {
        ...state,
        name: action.name
      };
    case SET_NEW_GROUP_LIMIT:
      return {
        ...state,
        limit: action.limit
      };
    case SET_NEW_GROUP_PHOTOURL:
      return {
        ...state,
        photoUrl: action.photoUrl
      };
    case RESET_NEW_GROUP_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default newCircleDetails;
