// import { Map } from 'immutable';

export const appActions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT"
};
function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}
const initState = {
  collapsed: window.innerWidth > 1000 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  width: window.innerWidth,
  openDrawer: false,
  openKeys: [],
  current: "1"
};
// const initState = new Map({
//   collapsed: window.innerWidth > 1220 ? false : true,
//   view: getView(window.innerWidth),
//   height: window.innerHeight,
//   openDrawer: false,
//   openKeys: [],
//   current: '1'
// });
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case appActions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed
      };
    case appActions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };

    case appActions.TOGGLE_ALL:
      if (action.height !== state.height || action.width !== state.width) {
        const height = action.height ? action.height : state.height;
        const width = action.width ? action.width : state.width;
        const view = getView(width);
        const collapsed = view !== "DesktopView";
        return {
          ...state,
          collapsed: collapsed,
          view: view,
          height: height,
          width: width
        };
      }
      break;

    case appActions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys
      };

    case appActions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current
      };

    default:
      return state;
  }
  return state;
}

export function toggleCollapsed(windowSize) {
  return {
    type: appActions.COLLPSE_CHANGE
  };
}
export function toggleAll(width, height) {
  const view = getView(width);
  const collapsed = view !== "DesktopView";
  return {
    type: appActions.TOGGLE_ALL,
    collapsed,
    view,
    width,
    height
  };
}
export function toggleOpenDrawer() {
  return {
    type: appActions.COLLPSE_OPEN_DRAWER
  };
}
export function changeOpenKeys(openKeys) {
  return {
    type: appActions.CHANGE_OPEN_KEYS,
    openKeys
  };
}
export function changeCurrent(current) {
  return {
    type: appActions.CHANGE_CURRENT,
    current
  };
}
