import styled from "styled-components";
import { palette } from "styled-theme";

const CategorySider = styled.div`
  display: flex;
  height: ${props =>
    props["settings"] === true ? "auto" : "calc(100vh - 69px)"};
  max-height: ${props =>
    props["settings"] === true ? "calc(100vh - 187px)" : "calc(100vh - 69px)"};

  background-color: ${palette("surfaceColor", 0)};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border: 1px solid ${palette("border", 0)};
  border-left-width: ${props => (props["data-rtl"] === "rtl" ? "1px" : "0")};
  border-right-width: ${props => (props["data-rtl"] === "rtl" ? "0" : "1px")};
  border-top-width: 0;
  border-bottom-width: 0;
  width: 320px;
  overflow: hidden;
  overflow-y: auto;
  justify-content: flex-start;
  .siderBottomButtonContainer {
    padding: 10px;
    border-top: 1px solid ${palette("border", 0)};
  }
`;

export default CategorySider;
