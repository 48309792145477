import styled from "styled-components";
import { palette } from "styled-theme";

const ConsentWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background: ${palette("background", 0)};
  display: flex;
  flex-direction: column;
  .signUpTerms {
    background-color: ${palette("surfaceColor", 0)} !important;
    padding: 2em 4em;
    span {
      font-weight: 200;
    }
    .signUpLink span {
      color: ${palette("primary", 0)} !important;
    }
    a {
      color: ${palette("primary", 0)} !important;
      text-decoration: underline;
    }
    .btnContainer {
      padding-top: 1em;
      display: flex;
      justify-content: flex-end;
      .cancelBtn {
        margin: 0 1em;
      }
    }
  }
`;

export default ConsentWrapper;
