import { createSelector } from "reselect";

const activeId = (state, props) => {
  return props.location.state["active"]
    ? state.LocalState.activeSessionId
    : state.LocalState.activeResolvedSessionId;
};

const session = (state, props) => {
  const activeId = props.location.state["active"]
    ? state.LocalState.activeSessionId
    : state.LocalState.activeResolvedSessionId;
  const sessions = props.location.state["active"]
    ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      : []
    : state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    ? state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    : [];
  const session =
    sessions && sessions.length > 0
      ? sessions.filter(item => item.sessionId === activeId)[0]
      : null;
  return session;
};

const sessionMembers = (state, props) => {
  const activeId = props.location.state["active"]
    ? state.LocalState.activeSessionId
    : state.LocalState.activeResolvedSessionId;
  const sessions = props.location.state["active"]
    ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      : []
    : state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    ? state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    : [];
  const session =
    sessions && sessions.length > 0
      ? sessions.filter(item => item.sessionId === activeId)[0]
      : null;
  const members =
    session && state.Firebase.participants[session.sessionId]
      ? state.Firebase.participants[session.sessionId]
      : [];
  return members;
};

const activeParticipants = (state, props) => {
  const activeId = props.location.state["active"]
    ? state.LocalState.activeSessionId
    : state.LocalState.activeResolvedSessionId;
  const sessions = props.location.state["active"]
    ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      : []
    : state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    ? state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    : [];
  const session =
    sessions && sessions.length > 0
      ? sessions.filter(item => item.sessionId === activeId)[0]
      : null;
  const members =
    session && state.Firebase.participants[session.sessionId]
      ? state.Firebase.participants[session.sessionId]
      : [];
  return members.filter(
    user => user.status === "active" || user.status === "notActive"
  );
};

const nonActiveParticipants = (state, props) => {
  const activeId = props.location.state["active"]
    ? state.LocalState.activeSessionId
    : state.LocalState.activeResolvedSessionId;
  const sessions = props.location.state["active"]
    ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      ? state.Firebase.openSessions[state.LocalState.activeCircleId]
      : []
    : state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    ? state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    : [];
  const session =
    sessions && sessions.length > 0
      ? sessions.filter(item => item.sessionId === activeId)[0]
      : null;
  const members =
    session && state.Firebase.participants[session.sessionId]
      ? state.Firebase.participants[session.sessionId]
      : [];
  return members.filter(user => user.status === "invited");
};
const openSessions = state => state.Firebase.openSessions;
const circles = state => state.Firebase.circles;
const sessionLocations = state => state.Firebase.membersLocations;
const sessionOwnerLocations = state => state.Firebase.ownersLocations;
const sessionMessages = state => state.Firebase.messages;

export const makeGetActiveSession = () => {
  return createSelector([session], session => {
    return session;
  });
};

export const makeGetActiveId = () => {
  return createSelector([activeId], activeId => {
    return activeId;
  });
};

export const makeGetMembers = () => {
  return createSelector([sessionMembers], sessionMembers => {
    return sessionMembers;
  });
};

export const makeGetActiveParticipants = () => {
  return createSelector([activeParticipants], activeParticipants => {
    return activeParticipants;
  });
};

export const makeGetPendingParticipants = () => {
  return createSelector([nonActiveParticipants], nonActiveParticipants => {
    return nonActiveParticipants;
  });
};

export const makeGetMessages = () => {
  return createSelector(
    [sessionMessages, session],
    (sessionMessages, session) => {
      return session && sessionMessages[session.sessionId]
        ? sessionMessages[session.sessionId]
        : [];
    }
  );
};

export const makeGetLocations = () => {
  return createSelector(
    [sessionLocations, session],
    (sessionLocations, session) => {
      const val =
        session && sessionLocations[session.sessionId]
          ? sessionLocations[session.sessionId]
          : null;
      const locations = val ? Object.keys(val).map(k => val[k]) : [];
      return locations;
    }
  );
};

export const makeGetOwnerLocation = () => {
  return createSelector(
    [sessionLocations, session],
    (sessionLocations, session) => {
      const val =
        session && sessionLocations[session.sessionId]
          ? sessionLocations[session.sessionId]
          : null;
      const ownerId = session ? session.ownerId : null;
      const ownerLocation =
        val && ownerId
          ? val.filter(location => location.userId === ownerId)[0]
          : {};
      return ownerLocation;
    }
  );
};

export const getTotalOpenSessions = createSelector(
  [openSessions, circles],
  (openSessions, circles) => {
    let totalSessions = [];
    const keys = Object.keys(openSessions);
    for (var i = 0; i < keys.length; i++) {
      let circleId = keys[i];
      const arr = openSessions[circleId];
      for (var j = 0; j < arr.length; j++) {
        const temp = arr[j];
        let ids = [];
        let circleNames = "";
        const invitedCircles = temp["invitedCircles"];
        for (var k = 0; k < invitedCircles.length; k++) {
          const invitedCircleId = invitedCircles[k].id;
          const invitedCircle = circles.filter(
            circle => circle.id === invitedCircleId
          )[0];
          if (invitedCircle) {
            ids.push(invitedCircle.id);
            circleNames =
              circleNames === ""
                ? circleNames + invitedCircle.name
                : circleNames + ", " + invitedCircle.name;
          }
        }

        temp["circleIds"] = ids;
        temp["circleName"] = circleNames;
        totalSessions.push(temp);
      }
    }
    var unique = totalSessions.filter(
      (session, index, self) =>
        self.findIndex(t => t.sessionId === session.sessionId) === index
    );
    return unique;
  }
);

export const makeGetOwnerLocations = () => {
  return createSelector(
    [sessionOwnerLocations, session],
    (sessionOwnerLocations, session) => {
      return session && sessionOwnerLocations[session.sessionId]
        ? sessionOwnerLocations[session.sessionId]
        : [];
    }
  );
};
