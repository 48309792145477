import React from "react";
import PropTypes from "prop-types";
import { Img } from "react-image";
import ImageContainer from "./imageContainer";
import { ClipLoader } from "react-spinners";

const Image = (props) => {
  return (
    <Img
      {...props}
      src={props.imagesrc}
      loader={
        props.disableloader === "true" ? null : (
          <ClipLoader loading={true} color={props.color} />
        )
      }
      container={(children) => {
        return (
          <ImageContainer
            disableanimation={props.disableanimation}
            src={props.imagesrc}
          >
            {children}
          </ImageContainer>
        );
      }}
    />
  );
};

Image.propTypes = {
  imagesrc: PropTypes.string,
  disableloader: PropTypes.string,
  color: PropTypes.string,
  disableanimation: PropTypes.string,
};

export default Image;
