import React, { useState } from "react";
import PropTypes from "prop-types";
import Profile from "../../components/settings/profile";
import Account from "../../components/settings/account";
import PaymentSettings from "../../components/settings/paymentSettings";
import PaymentMethod from "../../components/settings/paymentMethod";
import SettingsMenu from "../../components/settings/settingsMenu";
import Usage from "../../components/settings/usage";
import Privacy from "../../components/settings/privacy";
import Terms from "../../components/settings/terms";
import Entities from "../../components/settings/entities/entities";
import CategoryCenter from "../../components/layout/categoryCenter.style";
import CategoryInnerVertical from "../../components/layout/categoryInnerVertical.style";
import CategoryOuter from "../../components/layout/categoryOuter.style";
import MobilePageHeader from "../../components/utility/mobilePageHeader";
import { useIntl } from "react-intl";

const MobileView = props => {
  const intl = useIntl();
  const [category, setCategory] = useState(null);
  const onBack = () => {
    setCategory(null);
  };

  const changeCategory = category => {
    setCategory(category);
  };
  const { direction, data } = props;
  const title = intl.formatMessage({
    id:
      category === "profile"
        ? "app.settings.profile"
        : category === "account"
        ? "app.settings.account"
        : category === "entities"
        ? "app.settings.entities"
        : category === "paymentSettings"
        ? "app.settings.paymentSettings"
        : category === "usage"
        ? "app.settings.usage"
        : category === "privacy"
        ? "app.settings.privacy"
        : category === "terms"
        ? "app.settings.terms"
        : "app.sidebar.settings"
  });

  return (
    <CategoryOuter data-rtl={direction}>
      <MobilePageHeader
        title={title}
        onBack={category !== null ? onBack : null}
      />
      <CategoryInnerVertical withHeader={true}>
        <CategoryCenter nonStrech={true}>
          {category === null ? (
            <SettingsMenu
              type={"mobile"}
              changeCategory={changeCategory}
              direction={direction}
            />
          ) : null}
          {category === "profile" ? (
            <Profile direction={direction} data={data} />
          ) : null}
          {category === "account" ? (
            <Account direction={direction} data={data} />
          ) : null}
          {category === "entities" ? (
            <Entities direction={direction} data={data} />
          ) : null}
          {category === "paymentSettings" ? (
            <PaymentSettings direction={direction} data={data} />
          ) : null}
          {category === "paymentMethod" ? (
            <PaymentMethod direction={direction} data={data} />
          ) : null}
          {category === "usage" ? (
            <Usage direction={direction} data={data} />
          ) : null}
          {category === "privacy" ? (
            <Privacy direction={direction} data={data} />
          ) : null}
          {category === "terms" ? (
            <Terms direction={direction} data={data} />
          ) : null}
        </CategoryCenter>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

MobileView.propTypes = {
  direction: PropTypes.string,
  data: PropTypes.object
};

export default MobileView;
