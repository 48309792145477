import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  SET_ACTIVE_CIRCLE,
  SET_CIRCLES_DASHBOARD_CATEGORY
} from "../../redux/constants";
import { makeGetOpenSessionsCount } from "../../redux/selectors/sessions";
import { makeGetCircleGeofencesCount } from "../../redux/selectors/geofences";
import { makeGetCircleAlertsCount } from "../../redux/selectors/alerts";
import { ItemWrapper } from "./circleList.style";
import {
  TeamOutlined,
  EnvironmentOutlined,
  BellOutlined,
  AlertOutlined
} from "@ant-design/icons";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import IntlMessages from "../utility/intlMessages";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const ListItem = props => {
  const dispatch = useDispatch();
  const [animate, setAnimate] = useState("hidden");
  const getCircleAlertsCount = makeGetCircleAlertsCount();
  const getCircleGeofencesCount = makeGetCircleGeofencesCount();
  const getOpenSessionsCount = makeGetOpenSessionsCount();
  const alertsCount = useSelector(state => getCircleAlertsCount(state, props));
  const geofencesCount = useSelector(state =>
    getCircleGeofencesCount(state, props)
  );
  const openSessionsCount = useSelector(state =>
    getOpenSessionsCount(state, props)
  );

  useEffect(() => {
    setAnimate("visible");
  }, []);

  const handleClick = id => {
    dispatch({ type: SET_ACTIVE_CIRCLE, circleId: id });
    dispatch({
      type: SET_CIRCLES_DASHBOARD_CATEGORY,
      category: "overview"
    });
    dispatch({
      type: "CHANGE_CURRENT",
      current: "overview"
    });
  };
  const { circle, direction, members, index } = props;
  const listClass = `isoSingleCard card grid`;
  const style = { zIndex: 100 - index };
  return (
    <ItemWrapper className={listClass} style={style} data-rtl={direction}>
      <Link
        to={
          "/" + props.localState.uid + "/dashboard/" + circle.id + "/overview"
        }
        onClick={() => {
          handleClick(circle.id);
        }}
      >
        <h1>{circle.name}</h1>
        {circle && alertsCount && geofencesCount && openSessionsCount ? (
          <div className="circleContent">
            <motion.ul
              className="circleCategories"
              variants={container}
              initial="hidden"
              animate={animate}
            >
              <motion.li key={1} className={"circleCategory"} variants={item}>
                <div className="categoryIcon">
                  <TeamOutlined />
                </div>
                <p className="categoryNumber">{members[circle.id].length}</p>
                <p>
                  <IntlMessages id="app.overview.members" />
                </p>
              </motion.li>

              <motion.li key={2} className={"circleCategory"} variants={item}>
                <div className="categoryIcon">
                  <BellOutlined />
                </div>
                <p className="categoryNumber">{alertsCount}</p>
                <p>
                  <IntlMessages id="app.overview.alerts" />
                </p>
              </motion.li>

              <motion.li key={3} className={"circleCategory"} variants={item}>
                <div className="categoryIcon">
                  <EnvironmentOutlined />
                </div>
                <p className="categoryNumber">{geofencesCount}</p>
                <p>
                  <IntlMessages id="app.overview.geoFences" />
                </p>
              </motion.li>

              <motion.li key={4} className={"circleCategory"} variants={item}>
                <div className="categoryIcon">
                  <AlertOutlined />
                </div>
                <p className="categoryNumber">{openSessionsCount}</p>
                <p>
                  <IntlMessages id="app.overview.active" />
                </p>
              </motion.li>
            </motion.ul>
            {circle.photoUrl ? (
              <img
                alt="#"
                src={circle.photoUrl ? circle.photoUrl.uri : ""}
                className="circleImg"
              />
            ) : (
              <div className="iconWrapper">
                <TeamOutlined />
              </div>
            )}
          </div>
        ) : null}

        <div className="membersAvatarsContainer">
          {members[circle.id].map(member =>
            member.photoUrl ? (
              <img
                key={member.id}
                alt={circle.name}
                className="memberAvatar"
                src={member.photoUrl.uri}
              />
            ) : (
              <div key={member.id} className="iconWrapper">
                <TeamOutlined />
              </div>
            )
          )}
        </div>
      </Link>
    </ItemWrapper>
  );
};

ListItem.propTypes = {
  localState: PropTypes.object,
  members: PropTypes.object,
  changeCurrent: PropTypes.func,
  index: PropTypes.number,
  direction: PropTypes.string,
  circle: PropTypes.object,
  openSessionsCount: PropTypes.string,
  geofencesCount: PropTypes.string,
  alertsCount: PropTypes.string
};

export default ListItem;
