import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { motion } from "framer-motion";
import { shuffle } from "lodash";

const spring = {
  type: "spring",
  damping: 90,
  stiffness: 300
};

const variants = {
  visible: { opacity: 1, transition: { duration: 0.1 } },
  hidden: { opacity: 0 }
};

const initialColors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF"];

const Loading = () => {
  const [colors, setColors] = useState([
    "#FF008C",
    "#D309E1",
    "#9C1AFF",
    "#7700FF"
  ]);

  useEffect(() => {
    setColors(shuffle(initialColors));

    let progressInterval = setInterval(() => {
      setColors(shuffle(initialColors));
    }, 1000);

    return function cleanup() {
      clearInterval(progressInterval);
    };
  }, []);
  return (
    <motion.div
      exit={{ opacity: 0 }}
      key={"1"}
      animate="visible"
      initial="hidden"
      variants={variants}
      style={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
      }}
    >
      <ul
        style={{
          position: "relative",
          display: "flex",
          width: "300px",
          height: "300px",
          flexWrap: "wrap",
          margin: 0,
          padding: 0
        }}
      >
        {colors.map(background => (
          <motion.li
            key={background}
            layoutTransition={spring}
            style={{
              background: background,
              borderRadius: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              width: "140px",
              height: "140px",
              listStyle: "none",
              padding: 0
            }}
          />
        ))}
      </ul>
    </motion.div>
  );
};

export default withTheme(Loading);
