import { getCurrentLanguage, languageConfig } from "../../../config.js";

import { CHANGE_LANGUAGE } from "../../constants";

const INITIAL_STATE = {
  isActivated: false,
  language: getCurrentLanguage(languageConfig.defaultLanguage || "english")
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };

    default:
      return state;
  }
}
