import styled from "styled-components";
import { palette } from "styled-theme";

const SessionMapWrapper = styled.div`
  padding: 0;
  flex: 1;
  display: flex;

  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
    border-radius: 10px;
    background-color: ${palette("surfaceColor", 0)} !important;
  }
`;

export default SessionMapWrapper;
