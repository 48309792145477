import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "../../settings/withDirection";

const TopbarUserWrapper = styled.div`
  background-color: ${palette("surfaceColor", 0)};
  padding: 10px;
  .topbarUserContainer {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    background-color: ${palette("iconBg", 0)};
    border-radius: 20px;
    .topbarUserImage {
      border-radius: 20px;
      width: 30px;
      height: 30px;
    }
    .topbarUserName {
      margin: 0px 10px;
      color: ${palette("text", 0)};
    }
  }
  .devider {
    width: 100%;
    height: 1px;
    background-color: ${palette("border", 0)};
    margin: 10px 0;
  }
  .itemContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    min-width: 350px;
    cursor: pointer;
    &:hover {
      background-color: ${palette("background", 0)};
    }
    .anticon {
      font-size: 18px;
      color: ${palette("text", 0)};
    }
    .userImage {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
    .itemLeft {
      .iconContainer {
        width: 36px;
        display: flex;
        align-items: center;
        height: 36px;
        justify-content: center;
        border-radius: 18px;
        background-color: ${palette("iconBg", 0)};
      }
    }
    .itemMiddle {
      padding: 0 20px;
      flex: 1;
      .primaryText {
        // font-weight: bold;
        font-size: 1.12em;
        color: ${palette("text", 0)};
      }
    }
    .itemRight {
      transform: ${props =>
        props["data-rtl"] === "rtl" ? "scaleX(-1)" : "scaleX(1)"};
    }
    p {
      color: ${palette("lightText", 0)};
      margin: 0;
      text-align: start;
    }
  }
`;

export default WithDirection(TopbarUserWrapper);
