import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";
import IntlMessages from "../../components/utility/intlMessages";
import NewCircleHeader from "../animations/newCircleHeader";
import NewCircleItem from "../animations/newCircleItem";
import NewCircleContent from "../animations/newCircleContent";
import { useIntl } from "react-intl";

const RadioGroup = Radio.Group;
const JoinPolicy = props => {
  const intl = useIntl();
  const onChange = e => {
    props.onJoinPolicyChange(e.target.value);
  };
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };
  return (
    <div className="componentInner">
      <NewCircleHeader>
        <h1>{<IntlMessages id="app.settings.joinHeadline" />}</h1>
        <p>{<IntlMessages id="app.settings.joinDescription" />}</p>
      </NewCircleHeader>
      <div style={{ padding: "10px 0 30px 0" }}>
        <RadioGroup onChange={onChange} value={props.joinPolicy}>
          <NewCircleContent>
            <NewCircleItem itemKey={1}>
              <Radio style={radioStyle} value="open">
                {intl.formatMessage({
                  id: "app.settings.open"
                })}
              </Radio>
            </NewCircleItem>
            <NewCircleItem itemKey={2}>
              <Radio style={radioStyle} value="askToJoin">
                {intl.formatMessage({
                  id: "app.settings.approval"
                })}
              </Radio>
            </NewCircleItem>
            <NewCircleItem itemKey={3}>
              <Radio style={radioStyle} value="closed">
                {intl.formatMessage({
                  id: "app.settings.closed"
                })}
              </Radio>
            </NewCircleItem>
          </NewCircleContent>
        </RadioGroup>
      </div>
    </div>
  );
};

JoinPolicy.propTypes = {
  onJoinPolicyChange: PropTypes.func,
  joinPolicy: PropTypes.string
};

export default JoinPolicy;
