/* global localStorage */
import { store } from "../redux/store";
import { CLEAR_STATE } from "../redux/constants";
import authAction from "../redux/reducers/auth/actions";
import firebase from "firebase/app";
import Backend from "./Backend";
import { store as notificationsStore } from "react-notifications-component";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";

class AuthApi {
  createUserWithEmailAndPassword = (email, password, name, history) => {
    let that = this;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        var token = result.user.refreshToken;
        var user = result.user;
        if (user) {
          const uid = user.uid;
          that.checkConsent(uid, token, history, name);
        } else {
          localStorage.removeItem("id_token");
          localStorage.removeItem("uid");
        }
      })
      .catch(function() {});
  };

  signInWithPopup = (googleProvider, history) => {
    let that = this;
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;
        if (user) {
          const uid = user.uid;
          that.checkConsent(uid, token, history, user.displayName);
        } else {
          localStorage.removeItem("id_token");
          localStorage.removeItem("uid");
        }
      })
      .catch(function() {});
  };

  signInWithEmailAndPassword(email, password, history) {
    let that = this;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(function(result) {
        var token = result.user.refreshToken;
        var user = result.user;

        if (user) {
          const uid = user.uid;
          that.checkConsent(uid, token, history, user.displayName);
        } else {
          localStorage.removeItem("id_token");
          localStorage.removeItem("uid");
        }
      })
      .catch(function(error) {
        console.log(error);

        // Handle error.
      });
  }

  sendPasswordResetEmail(email, history) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function() {
        history.push(`/signin`);
      })
      .catch(function() {});
  }

  checkConsent = (uid, token, history, name) => {
    store.dispatch({ type: CLEAR_STATE });
    localStorage.setItem("firebaseAuthInProgress", token);
    localStorage.setItem("uid", uid);

    const ref = firebase
      .firestore()
      .collection("usersConsent")
      .doc(uid)
      .collection("consents")
      .orderBy("timestamp", "desc")
      .limit(1);

    ref
      .get()
      .then(function(querySnapshot) {
        if (querySnapshot.empty) {
          history.push(`/${uid}/consent`);
        } else {
          store.dispatch({
            type: authAction.LOGIN_SUCCESS,
            token: token,
            uid: uid,
            name: name,
            consent: true
          });
          history.push(`/${uid}/console`);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  proceedAfterConsent = (user, history) => {
    const picture = user.photoURL;
    const uid = user.uid;
    const token = user.refreshToken;
    const name = user.displayName;
    const consentRef = firebase
      .firestore()
      .collection("usersConsent")
      .doc(uid)
      .collection("consents")
      .doc();

    const userRef = firebase
      .firestore()
      .collection("users")
      .doc(uid);

    const usersActivePlanRef = firebase
      .firestore()
      .collection("usersActivePlan")
      .doc(uid);
    let batch = firebase.firestore().batch();
    batch.set(
      userRef,
      {
        general_info: {
          fullName: name,
          photoUrl: { uri: picture }
        }
      },
      { merge: true }
    );

    batch.set(usersActivePlanRef, {
      planName: "personal",
      joinedAt: firebase.firestore.FieldValue.serverTimestamp()
    });

    batch.set(consentRef, {
      name: name,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });

    batch
      .commit()
      .then(function() {
        store.dispatch({
          type: authAction.LOGIN_SUCCESS,
          token: token,
          uid: uid,
          name: name,
          consent: true
        });
        history.push(`/${uid}/console`);
      })
      .catch(err => {
        console.log(err);
      });
  };

  noConsent = () => {
    Backend.logout();
  };

  createEntity = (email, password, name, info) => {
    const createEntity = firebase.functions().httpsCallable("createEntity");
    const data = {
      info: info,
      name: name,
      email: email,
      password: password,
      ownerUid: Backend.uid
    };
    createEntity(data)
      .then(() => {
        const text =
          "Entity with the name " + name + " was created successfully!";
        notificationsStore.addNotification({
          message: text,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        console.log("function executed successfully");
      })
      .catch(function(error) {
        const errorText = "Entity ywas not created. please try again.";
        notificationsStore.addNotification({
          message: errorText,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        console.error(error);
      });
  };
}

export default new AuthApi();
