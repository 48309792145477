import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";
import IntlMessages from "../utility/intlMessages";
import { withTheme } from "styled-components";
import ActionButton from "../utility/actionButton";

const MapStyleSelector = props => {
  const { theme, onMapStyleChange, direction } = props;
  const mapStyle =
    theme.name === "lightTheme"
      ? "mapbox://styles/mapbox/light-v9"
      : "mapbox://styles/mapbox/dark-v9";

  useEffect(() => {
    onMapStyleChange(mapStyle);
  }, []);

  const handleStyleChange = value => {
    onMapStyleChange(value.key);
  };
  const menu = (
    <Menu onClick={handleStyleChange}>
      <Menu.Item key={"mapbox://styles/mapbox/light-v9"}>
        <IntlMessages id="app.mapStyle.light" />
      </Menu.Item>
      <Menu.Item key={"mapbox://styles/mapbox/dark-v9"}>
        <IntlMessages id="app.mapStyle.dark" />
      </Menu.Item>
      <Menu.Item key={"mapbox://styles/mapbox/satellite-v9"}>
        <IntlMessages id="app.mapStyle.satellite" />
      </Menu.Item>
      <Menu.Item key={"mapbox://styles/mapbox/streets-v10"}>
        <IntlMessages id="app.mapStyle.streets" />
      </Menu.Item>
      <Menu.Item key={"mapbox://styles/mapbox/outdoors-v10"}>
        <IntlMessages id="app.mapStyle.outdoors" />
      </Menu.Item>
      <Menu.Item
        key={"mapbox://styles/mapbox/navigation-preview-day-v2"}
        text={"app.mapStyle.navigation"}
      >
        <IntlMessages id="app.mapStyle.navigation" />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <ActionButton
        direction={direction}
        title={<IntlMessages id="app.sessions.changeMapStyle" />}
        onClick={() => {
          console.log("");
        }}
        flat={true}
      />
    </Dropdown>
  );
};
MapStyleSelector.propTypes = {
  direction: PropTypes.string,
  onMapStyleChange: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme(MapStyleSelector);
