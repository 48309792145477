import { ADD_SUBSCRIPTION } from "../../constants";
import { put } from "redux-saga/effects";
// import PlansApi from '../../api/PlansApi';

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setUserSubscriptionsUpdate(data, id, metaType) {
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    const subscription = data.snapshot.docChanges()[j].doc.data();
    const subscriptionId = subscription.subscription.subscriptionId;
    yield put({
      type: ADD_SUBSCRIPTION,
      subscription: subscription,
      subscriptionId: subscriptionId
    });
  }
  // const subscription = data.snapshot.data();
  // if (subscription !== undefined) {
  //   yield put({
  //     type: ADD_SUBSCRIPTION,
  //     subscription: subscription,
  //     subscriptionId: subscriptionId
  //   });
  // PlansApi.setUserPlan(userData);
  // }
}
