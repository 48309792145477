import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import TermsWrapper from "./terms.style";
import { withTheme } from "styled-components";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";

const Terms = props => {
  const { theme } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.id = "terms";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter style={{ flexDirection: "column" }}>
          <TermsWrapper theme={theme.name}>
            <a
              href="https://www.iubenda.com/terms-and-conditions/78672341"
              className="iubenda-white no-brand iubenda-embed iub-body-embed"
              title="Terms and Conditions"
            >
              {" "}
            </a>
          </TermsWrapper>
        </CategoryCenter>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

Terms.propTypes = {
  theme: PropTypes.object
};

export default withTheme(Terms);
