import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { TopbarUser, CircleSwitcher } from "../../components/topbar";
import SessionSwitcher from "../../components/session/sessionSwitcher";
import TopbarWrapper from "./topbar.style";
import Logo from "../../components/utility/logo";
import { MotionMenu } from "../../components/topbar//motionMenu/menu";
import { getTotalOpenSessions } from "../../redux/selectors/session";
import { useSelector } from "react-redux";
const { Header } = Layout;

const Topbar = props => {
  const [motionMenuOpened, setMotionMenuOpened] = useState(false);
  const [motionMenuOverflowMode, setMotionMenuOverflowMode] = useState("auto");
  const [zIndex, setZIndex] = useState(1);
  const openSessions = useSelector(state => getTotalOpenSessions(state));
  const circles = useSelector(state => state.Firebase.circles);
  const height = useSelector(state => state.App.height);
  const current = useSelector(state => state.App.current);
  const view = useSelector(state => state.App.view);
  const userSettings = useSelector(state => state.Firebase.userData.settings);
  const localState = useSelector(state => state.LocalState);
  const { url } = props;

  const toggleMotionMenu = () => {
    setMotionMenuOpened(!motionMenuOpened);

    if (motionMenuOverflowMode === "auto") {
      setMotionMenuOverflowMode("visible");
      setZIndex(20000);
    } else {
      setTimeout(function() {
        setMotionMenuOverflowMode("auto");
        setZIndex(1);
      }, 1000);
    }
  };

  const direction =
    userSettings && userSettings.language && userSettings.language.direction
      ? userSettings.language.direction
      : "ltr";
  return (
    <TopbarWrapper data-rtl={direction}>
      <Header className={"isomorphicTopbar"}>
        <div className="isoLeft">
          {view !== "MobileView" ? (
            <Logo
              localState={localState}
              collapsed={false}
              direction={direction}
            />
          ) : (
            <MotionMenu
              current={current}
              url={url}
              height={height}
              toggleMotionMenu={toggleMotionMenu}
              motionMenuOpened={motionMenuOpened}
              motionMenuOverflowMode={motionMenuOverflowMode}
              zIndex={zIndex}
            />
          )}
        </div>
        <ul className="isoRight">
          {circles.length > 0 ? (
            <li>
              <CircleSwitcher direction={direction} />
            </li>
          ) : null}

          {openSessions.length > 0 ? (
            <li className="sessionSwitcher">
              <SessionSwitcher direction={direction} />
            </li>
          ) : null}

          <li>
            <TopbarUser />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
};

Topbar.propTypes = {
  url: PropTypes.string
};

export default Topbar;
