// import initialState from '../store/initialState';
import { ADD_USER_DATA } from "../../constants";
const INITIAL_STATE = {
  general_info: {
    address: "",
    birthday: "",
    phone: "",
    fullName: "",
    gender: "",
    photoUrl: ""
  },
  business_info: {
    organizationName: "",
    brief: "",
    location: "",
    website: ""
  },
  settings: {}
};
const userData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_USER_DATA:
      return {
        ...state,
        general_info: action.data.general_info
          ? action.data.general_info
          : INITIAL_STATE.general_info,
        business_info: action.data.business_info
          ? action.data.business_info
          : INITIAL_STATE.business_info,
        settings: action.data.settings
          ? action.data.settings
          : INITIAL_STATE.settings
      };

    default:
      return state;
  }
};
export default userData;
