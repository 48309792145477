import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius, boxShadow } from "../../settings/style-util";

const ContactCardWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
   h1{
    font-size:1.6em !important;
    height:33px;
  }
  p{
    height: 21px;
    margin:0;
  }
  .leftContainer {
    display:flex;
    flex-direction: column;
    min-height:394px;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;

    }
    .header{
      padding-bottom:20px;
    }
  }

  .rightContainer {
    display:flex;
    flex: 1;
    padding:0 0 0 60px;
    justify-content: flex-end;
    .ContactImageWrapper{
      padding-top: 84px;
      /* width: 100%; */
      /* max-height: calc(100vh - 328px); */
      /* max-height: 400px; */


    }
    img {
      width: 100%;
      max-height: 300px;
      max-width: 300px;
      border-radius:10px;
    }
  }

  /* @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }

  @media only screen and (min-width: 767px) and (max-width: 990px) {
    flex-direction: column;
  }

  .isoContactCardHead {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    flex-shrink: 0;
    margin-right: ${props =>
      props["data-rtl"] === "rtl" ? "inherit" : "50px"};
    margin-left: ${props => (props["data-rtl"] === "rtl" ? "50px" : "inherit")};
    flex-shrink: 0;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: 767px) and (max-width: 990px) {
      margin-bottom: 20px;
    }

    .isoPersonImage {
      width: 120px;
      height: 120px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 5px;
      .personIcon {
        border: 1px solid ${palette("primary", 0)};
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .anticon {
          color: ${palette("primary", 0)};
          font-size: 60px;
        }
      }

      .avatar-uploader {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .isoPersonName {
      font-size: 15px;
      font-weight: 500;
      color: ${palette("text", 0)};
      line-height: 1.5;
      margin: 0;
    }
  }

  .isoContactInfoWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;

    @media only screen and (max-width: 767px) {
      padding: 20px 0;
    }

    .guaContactRole {
      background-color: ${palette("lightBg", 1)};
      margin-top: 0;
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        padding: 0.2em 0.5em;
      }
    }

    .isoContactCardInfos {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      align-items: baseline;
      flex-direction: row;
      margin-bottom: 15px;

      @media only screen and (max-width: 430px) {
        flex-direction: column;
        margin-bottom: 20px;
      }

      .isoInfoLabel {
        font-size: 14px;
        font-weight: 500;
        color: ${palette("text", 0)};
        line-height: 1.5;
        margin: 0;
        margin-right: ${props =>
          props["data-rtl"] === "rtl" ? "inherit" : "15px"};
        margin-left: ${props =>
          props["data-rtl"] === "rtl" ? "15px" : "inherit"};
        text-align: ${props =>
          props["data-rtl"] === "rtl" ? "right" : "left"};
        min-width: 80px;
        position: relative;

        @media only screen and (max-width: 430px) {
          margin-bottom: 5px;
          margin-right: ${props =>
            props["data-rtl"] === "rtl" ? "inherit" : "0"};
          margin-left: ${props =>
            props["data-rtl"] === "rtl" ? "0" : "inherit"};
          padding-right: ${props =>
            props["data-rtl"] === "rtl" ? "inherit" : "10px"};
          padding-left: ${props =>
            props["data-rtl"] === "rtl" ? "10px" : "inherit"};
          min-width: 0;
        }

        &::after {
          content: ":";
          position: absolute;
          right: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          left: ${props => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoInfoDetails {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        text-align: ${props =>
          props["data-rtl"] === "rtl" ? "right" : "left"};
      }

      input {
        font-size: 14px;
        font-weight: 400;
        line-height: inherit;
        height: 36px;
        padding: 0 15px;
        margin: 0;
        border: 1px solid ${palette("border", 0)};
        outline: 0 !important;
        overflow: hidden;
        background-color: ${palette("surfaceColor", 0)};
        ${boxShadow("none")};
        ${borderRadius("3px")};
        ${transition()};

        &:focus {
          border-color: ${palette("primary", 0)};
          ${boxShadow("0 0 0 2px rgba(68, 130, 255, 0.2)")};
          outline: 0;
        }

        &:hover {
          border-color: ${palette("primary", 0)};
        }

        &::-webkit-input-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &:-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &::-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }
        &:-ms-input-placeholder {
          color: ${palette("grayscale", 0)};
        }
      }

      textarea {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        height: 210px;
        padding: 10px 15px;
        margin: 0;
        border: 1px solid ${palette("border", 0)};
        outline: 0 !important;
        background-color: ${palette("surfaceColor", 0)};
        ${boxShadow("none")};
        ${borderRadius("3px")};
        ${transition()};

        &:focus {
          border-color: ${palette("primary", 0)};
          ${boxShadow("0 0 0 2px rgba(68, 130, 255, 0.2)")};
          outline: 0;
        }

        &:hover {
          border-color: ${palette("primary", 0)};
        }

        &::-webkit-input-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &:-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &::-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }
        &:-ms-input-placeholder {
          color: ${palette("grayscale", 0)};
        }
      }
    }
  }
  .contactDetailsContainer {
    display: flex;
    margin-top: 2em;
    .contactDetail {
      display: flex;
      align-items: center;
      margin-right: 2em;
      p {
        margin: 0;
      }
      i {
        font-size: 20px;
        padding: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
        color: ${palette("title", 0)};
      }
    }
  } */
`;

export { ContactCardWrapper };
