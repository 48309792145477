import styled from "styled-components";
import { palette } from "styled-theme";

const SessionParticipantWrapper = styled.div`
  padding: ${props =>
    props["type"] === "mobile"
      ? "10px"
      : props["data-rtl"] === "rtl"
      ? "10px 10px 10px 0"
      : " 10px 0px 10px 10px"};
  .participantContainer {
    border-radius: 10px;
    border: 1px solid ${palette("border", 0)};
    /* box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1); */
    transition: all 0.3s ease;
    margin: 0px 10px 10px 0;
    /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important; */
    width: 100%;
    background-color: ${palette("surfaceColor", 0)};

    .isoContactCard {
      width: 100%;
      height: calc(100% - 145px);
      padding: 1em;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      @media only screen and (max-width: 767px) {
        padding: 10px;
      }

      @media only screen and (min-width: 767px) and (max-width: 990px) {
        flex-direction: column;
      }

      .isoContactCardHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-shrink: 0;

        @media only screen and (max-width: 600px) {
          margin-bottom: 20px;
        }

        @media only screen and (min-width: 767px) and (max-width: 990px) {
          margin-bottom: 20px;
        }

        .isoPersonImage {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 5px;
          .avatar-uploader {
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .isoContactInfoWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;

        @media only screen and (max-width: 767px) {
          padding: 20px 0;
        }

        h1 {
          font-size: 1.6em !important;
          height: 33px;
        }

        .guaContactRole {
          background-color: ${palette("lightBg", 1)};
          margin-top: 0;
          display: flex;
          width: fit-content;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
            padding: 0.2em 0.5em;
          }
        }
      }

      .contactDetailsContainer {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
      }
    }
  }
  .dismissBtnContainer {
    width: 60px;
    height: 60px;
    background-color: ${palette("secondary", 0)};
    border-radius: 30px;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1);
    transition: all 0.3s ease;
    margin: 10px 10px 10px 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
    align-items: center;
    justify-content: center;
    display: flex;
    i {
      font-size: 34px;
      color: ${palette("surfaceColor", 0)};
    }
  }
`;

export default SessionParticipantWrapper;
