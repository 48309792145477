import {
  UPDATE_SESSION_MEMBER_LOCATION,
  SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS,
  CREATE_SESSION_MEMBER_GROUP,
  UPDATE_HIDE_LOCATION_STATUS,
  ADD_SESSION
} from "../../constants";

const INITIAL_STATE = {};
const membersLocations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SESSION:
      return {
        ...state,
        [action.id]: state[action.id] !== undefined ? state[action.id] : []
      };

    case UPDATE_SESSION_MEMBER_LOCATION:
      return {
        ...state,
        [action.sessionId]:
          state[action.sessionId] !== undefined
            ? [
                ...state[action.sessionId].filter(
                  member => member.userId !== action.location.userId
                ),
                action.location
              ]
            : [action.location]
      };

    case SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS:
      return {
        ...state,
        [action.sessionId]: action.locations
      };

    case UPDATE_HIDE_LOCATION_STATUS:
      return {
        ...state,
        [action.sessionId]: {
          ...state[action.sessionId],
          [action.userId]: {
            ...state[action.sessionId][action.userId],
            hideLocation: action.hideLocationStatus
          }
        }
      };

    case CREATE_SESSION_MEMBER_GROUP:
      return {
        ...state,
        [action.sessionId]: []
      };

    default:
      return state;
  }
};

export default membersLocations;
