import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import CirclesList from "../../components/console/circlesList";
import EmptyState from "../../components/emptyState/emptyState";
import IntlMessages from "../../components/utility/intlMessages";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import { appActions } from "../../redux/reducers/app/reducer";
import Topbar from "../Topbar/Topbar";
import { AppLocale } from "../../dashApp";
import ConsoleStyleWrapper from "./console.style";
import { withTheme } from "styled-components";
import { getDirection } from "../../redux/selectors/user";
import firebase from "firebase/app";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

const Console = props => {
  const dispatch = useDispatch();
  const [animate, setAnimate] = useState(false);

  const { theme, history, match } = props;
  const { url } = match;
  const direction = useSelector(state => getDirection(state));
  const locale = useSelector(state => state.LanguageSwitcher.language.locale);
  const localState = useSelector(state => state.LocalState);
  const circles = useSelector(state => state.Firebase.circles);
  const currentAppLocale = AppLocale[locale];

  const loadIntercom = useCallback(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        window.Intercom("boot", {
          app_id: "fyq3wodw",
          email: user.email,
          user_id: user.uid,
          name: user.displayName,
          created_at: parseInt(new Date().getTime() / 1000),
          // custom_launcher_selector: "#my_custom_link",
          background_color: theme.palette["secondary"][0],
          action_color: theme.palette["primary"][0],
          hide_default_launcher: false
        });
      }
    });
  }, [theme.palette]);

  const loadIntercomScript = useCallback(() => {
    const existingScript = document.getElementById("intercom");
    if (!existingScript) {
      var script = document.createElement("script");
      script.id = "intercom";
      script.dangerouslySetInnerHTML = (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/fyq3wodw";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
      document.head.appendChild(script);
      loadIntercom();
      script.onload = () => {
        loadIntercom();
      };
    } else {
      loadIntercom();
    }
  }, [loadIntercom]);

  useEffect(() => {
    loadIntercomScript();
    setTimeout(function() {
      setAnimate(true);
    }, 500);
  }, [loadIntercomScript]);

  const onPress = () => {
    history.push(`/${localState.uid}/create`);
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConsoleStyleWrapper className="console" data-rtl={direction}>
        <Layout style={{ height: "100vh" }}>
          <Debounce time="1000" handler="onResize">
            <WindowSizeListener
              onResize={windowSize =>
                dispatch({
                  type: appActions.TOGGLE_ALL,
                  height: windowSize.windowHeight,
                  width: windowSize.windowWidth
                })
              }
            />
          </Debounce>
          <Topbar showMenuBtn={false} url={url} showLogo={true} />
          <Layout style={{ overflowX: "hidden", marginTop: "64px" }}>
            <Layout style={{ alignItems: "center" }}>
              {circles.length > 0 && animate ? (
                <CirclesList direction={direction} />
              ) : (
                <EmptyState
                  title={<IntlMessages id="app.console.noCirclesLael" />}
                  description={
                    <IntlMessages id="app.console.noCirclesDescription" />
                  }
                  direction={direction}
                  button={true}
                  buttonTitle={
                    <IntlMessages id="app.buttons.createNewCircle" />
                  }
                  onPress={onPress}
                />
              )}
            </Layout>
          </Layout>
        </Layout>
      </ConsoleStyleWrapper>
    </IntlProvider>
  );
};

Console.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
};

export default withTheme(Console);
