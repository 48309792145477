import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout } from "antd";
import {
  toggleCollapsed,
  changeCurrent
} from "../../../redux/reducers/app/reducer";
import TopbarWrapper from "./topbar.style";
import Logo from "../../utility/logo";
import { MotionMenu } from "../../topbar/motionMenu/menu";
const { Header } = Layout;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.toggleMotionMenu = this.toggleMotionMenu.bind(this);
    this.state = {
      motionMenuOpened: false,
      motionMenuOverflowMode: "auto",
      zIndex: 1
    };
  }

  static propTypes = {
    toggleCollapsed: PropTypes.func,
    changeCurrent: PropTypes.func,
    showMenuBtn: PropTypes.bool,
    openDrawer: PropTypes.bool,
    collapsed: PropTypes.bool,
    userSettings: PropTypes.object,
    localState: PropTypes.object,
    height: PropTypes.number,
    currentView: PropTypes.string,
    url: PropTypes.string,
    current: PropTypes.string,
    showSessionMap: PropTypes.func,
    showInviteList: PropTypes.func,
    showParticipants: PropTypes.func,
    showCallerInfo: PropTypes.func,
    showCallerRouteMap: PropTypes.func,
    showChat: PropTypes.func
  };

  toggleMotionMenu() {
    let that = this;
    this.setState({
      motionMenuOpened: !this.state.motionMenuOpened
    });

    if (this.state.motionMenuOverflowMode === "auto") {
      this.setState({
        motionMenuOverflowMode: "visible",
        zIndex: 20000
      });
    } else {
      setTimeout(function() {
        if (that.mounted) {
          that.setState({
            motionMenuOverflowMode: "auto",
            zIndex: 1
          });
        }
      }, 1000);
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      userSettings,
      localState,
      height,
      url,
      changeCurrent,
      current,
      showSessionMap,
      showInviteList,
      showParticipants,
      showCallerInfo,
      showCallerRouteMap,
      showChat,
      currentView
    } = this.props;

    const direction =
      userSettings && userSettings.language && userSettings.language.direction
        ? userSettings.language.direction
        : "ltr";

    return (
      <TopbarWrapper data-rtl={direction}>
        <Header className={"topbarInner"}>
          <div className="leftContainer">
            <MotionMenu
              changeCurrent={changeCurrent}
              current={current}
              url={url}
              height={height}
              toggleMotionMenu={this.toggleMotionMenu}
              motionMenuOpened={this.state.motionMenuOpened}
              motionMenuOverflowMode={this.state.motionMenuOverflowMode}
              zIndex={this.state.zIndex}
              type={"mobile"}
              showSessionMap={showSessionMap}
              showInviteList={showInviteList}
              showParticipants={showParticipants}
              showCallerInfo={showCallerInfo}
              showCallerRouteMap={showCallerRouteMap}
              showChat={showChat}
              currentView={currentView}
            />
          </div>
          <ul className="rightContainer">
            <Logo
              localState={localState}
              collapsed={false}
              direction={direction}
            />
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    ...state.LanguageSwitcher,
    height: state.App.height,
    current: state.App.current,
    userSettings: state.Firebase.userData.settings,
    localState: state.LocalState
  }),
  { toggleCollapsed, changeCurrent }
)(Topbar);
