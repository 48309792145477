import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Timer from "react-compound-timer";
import moment from "moment";
import CallerInfoWrapper from "./callerInfoWrapper.style";
import Image from "../utility/image";
import { message as antMessage } from "antd";
import Backend from "../../api/Backend";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";
import { HourglassOutlined } from "@ant-design/icons";
import SessionComponentWrapper from "../animations/sessionComponentWrapper";
import SessionComponentCloseButton from "../animations/sessionComponentCloseButton";

const modalVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.15, duration: 0.5 }
  },
  closed: {
    y: 100,
    opacity: 0,
    transition: { duration: 0.1 }
  }
};

const medicalDetailsVariants = {
  open: {
    x: "0%",

    transition: {
      ease: "easeInOut",
      staggerChildren: 0.07,
      delayChildren: 0.2
    }
  },
  closed: { x: 0, transition: { staggerChildren: 0.05, staggerDirection: -1 } }
};

const detailVariants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      x: { type: "tween" },
      ease: "easeIn"
    }
  },
  closed: { opacity: 0, x: 75 }
};

const basicDetailsVariants = {
  open: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.75
    }
  },
  closed: {
    opacity: 0
  }
};

const CallerInfo = props => {
  const [profile, setProfile] = useState(null);
  const {
    session,
    theme,
    height,
    direction,
    showCallerInfo,
    show,
    type
  } = props;

  useEffect(() => {
    if (session && session !== undefined) {
      const profile = Backend.getParticipantDetails(session.ownerId, true);
      Promise.all([profile])
        .then(function(values) {
          setProfile(values[0]);
        })
        .catch(function() {
          antMessage.error(
            "There was an error during getting the user details"
          );
        });
    }
  }, [session]);

  const general = profile && profile.general_info ? profile.general_info : null;
  const medical = profile && profile.medical_info ? profile.medical_info : null;
  var ageDate =
    general && general.birthday
      ? new Date(general.birthday.seconds * 1000)
      : null;
  const age = ageDate ? moment(ageDate).format("ll") : "";
  var startDate = session
    ? new Date(session.startedAt.seconds * 1000)
    : new Date();
  var endDate = new Date();
  var miliseconds = endDate.getTime() - startDate.getTime();
  const details = [
    {
      title: "Address",
      field: general && general.address ? general.address : "not listed"
    },
    {
      title: "Blood type",
      field: medical && medical.bloodType ? medical.bloodType : "not listed"
    },
    {
      title: "Height",
      field: medical && medical.height ? medical.height : "not listed"
    },
    {
      title: "Weight",
      field: medical && medical.weight ? medical.weight : "not listed"
    },
    {
      title: "Medications",
      field: medical && medical.medications ? medical.medications : "not listed"
    },
    {
      title: "Allergies",
      field: medical && medical.allergies ? medical.allergies : "not listed"
    }
  ];
  return (
    <SessionComponentWrapper show={show} type={type}>
      <div
        className="sessionLeftContainer"
        style={{
          borderWidth: show ? "1px" : "0px"
        }}
      >
        <CallerInfoWrapper height={height} data-rtl={direction}>
          {show ? (
            <motion.div
              animate={show ? "open" : "closed"}
              className="sessionCallerTopContiner"
              variants={modalVariants}
            >
              <div className="timerContainer">
                <div className="iconWrapper">
                  <HourglassOutlined />
                </div>
                <div>
                  <h2>{session ? session.ownerName : ""}</h2>

                  <Timer initialTime={miliseconds} lastUnit="h">
                    {() => (
                      <React.Fragment>
                        <motion.div
                          variants={basicDetailsVariants}
                          animate={show ? "open" : "closed"}
                        >
                          <p>
                            {<Timer.Hours />} hours <Timer.Minutes /> minutes{" "}
                            <Timer.Seconds /> seconds
                          </p>
                        </motion.div>
                      </React.Fragment>
                    )}
                  </Timer>
                </div>
              </div>
            </motion.div>
          ) : null}

          <div
            className="sessionInnerContainer"
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column"
            }}
          >
            <div className="sessionCallerImageContiner" data-rtl={direction}>
              <motion.div
                animate={show ? "open" : "closed"}
                className="sessionCallerImage"
                variants={modalVariants}
              >
                {session && session.photoUrl ? (
                  <Image
                    alt="#"
                    imagesrc={session.photoUrl.uri}
                    disableanimation={"false"}
                  />
                ) : (
                  ""
                )}
              </motion.div>

              <motion.div
                animate={show ? "open" : "closed"}
                className="sessionCallerGeneralDetails"
                variants={modalVariants}
              >
                <div>
                  <h1 style={{ margin: 0 }}>
                    {session ? session.ownerName : ""}
                  </h1>
                </div>
              </motion.div>

              <motion.div
                className="sessionCallerMedicalContainer"
                animate={show ? "open" : "closed"}
                variants={basicDetailsVariants}
              >
                <p>{age}</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {general && general.gender ? (
                    <div className="callerDetail">
                      <i
                        className={
                          profile.gender === "Male"
                            ? "ion-ios-male"
                            : "ion-ios-female"
                        }
                      />
                      <p>{general.gender}</p>
                    </div>
                  ) : null}

                  {general && general.phone ? (
                    <div className="callerDetail">
                      <i className={"ion-ios-phone-portrait"} />
                      <p>{general.phone}</p>
                    </div>
                  ) : null}
                </div>
              </motion.div>
              <motion.div
                variants={medicalDetailsVariants}
                className="detailsContainer"
                animate={show ? "open" : "closed"}
              >
                <ul>
                  {details.map(({ title, field }) => (
                    <motion.div key={title} variants={detailVariants}>
                      <div className="fieldTitle">
                        <p>{title}</p>
                      </div>
                      <p>{field}</p>
                    </motion.div>
                  ))}
                </ul>
              </motion.div>
              {profile ? null : (
                <div className="loaderContainer">
                  <ClipLoader
                    loading={true}
                    color={theme.palette["primary"][0]}
                  />
                </div>
              )}
            </div>
          </div>
        </CallerInfoWrapper>
      </div>
      {type !== "mobile" ? (
        <SessionComponentCloseButton show={show} onClick={showCallerInfo} />
      ) : null}
    </SessionComponentWrapper>
  );
};

CallerInfo.propTypes = {
  session: PropTypes.object,
  theme: PropTypes.object,
  height: PropTypes.number,
  direction: PropTypes.string,
  showCallerInfo: PropTypes.func,
  show: PropTypes.bool,
  type: PropTypes.string
};

export default withTheme(CallerInfo);
