import styled from "styled-components";

const DropezoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .avatar-uploader > .ant-upload,
  img {
    width: 128px;
    height: 128px;
    margin: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-width: ${props => (props["initial"] === true ? "0px" : " 1px")};
  }
`;

export default DropezoneWrapper;
