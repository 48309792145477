import {
  eventTypes,
  SESSION_MESSAGES_FETCH_SUCCESS,
  ADD_SESSION_MESSAGE
} from "../../constants";
import Backend from "../../../api/Backend";
import firebase from "firebase/app";
import "firebase/firestore";
import { put } from "redux-saga/effects";

export function* setSessionMessagesInitial(value, sessionId, key, metaType) {
  let arr = [];
  const keys = Object.keys(value);
  for (var i = 0; i < keys.length; i++) {
    let messageKey = keys[i];
    const item = value[messageKey];
    const newMessage = {
      _id: messageKey,
      text: item.text,
      name: item.name,
      user: {
        _id: item.user._id,
        name: item.user.name,
        avatar: item.user.avatar
      },
      system: item.system,
      createdAt: new Date(item.createdAt),
      image: item.image,
      type: item.type,
      status: item.status
    };
    if (
      item.system !== true &&
      item.status &&
      item.status[Backend.uid].status === "sent"
    ) {
      firebase
        .database()
        .ref(
          "sessionMessages/" +
            sessionId +
            "/" +
            messageKey +
            "/status/" +
            Backend.uid
        )
        .set({
          status: "received",
          timestamp: Date.now()
        });
    } else if (item.system !== true && item.status === undefined) {
      firebase
        .database()
        .ref(
          "sessionMessages/" +
            sessionId +
            "/" +
            messageKey +
            "/status/" +
            Backend.uid
        )
        .set({
          status: "received",
          timestamp: Date.now()
        });
    }
    arr.push(newMessage);
  }
  yield put({
    type: SESSION_MESSAGES_FETCH_SUCCESS,
    sessionId: sessionId,
    messages: arr
  });
}

export function* setSessionMessagesUpdate(data, metaType) {
  let item = data.value;
  let sessionId = data.id;
  switch (data.eventType) {
    //listens to session chat new messages
    case eventTypes.CHILD_ADDED:
      const newMessage = {
        _id: data.key,
        text: item.text,
        name: item.name,
        user: {
          _id: item.user._id,
          name: item.user.name,
          avatar: item.user.avatar
        },
        system: item.system,
        createdAt: new Date(item.createdAt),
        image: item.image,
        type: item.type,
        status: item.status
      };
      if (
        item.system !== true &&
        item.status &&
        item.status[Backend.uid].status === "sent"
      ) {
        firebase
          .database()
          .ref(
            "sessionMessages/" +
              sessionId +
              "/" +
              data.key +
              "/status/" +
              Backend.uid
          )
          .set({
            status: "received",
            timestamp: Date.now()
          });
      } else if (item.system !== true && item.status === undefined) {
        firebase
          .database()
          .ref(
            "sessionMessages/" +
              sessionId +
              "/" +
              data.key +
              "/status/" +
              Backend.uid
          )
          .set({
            status: "received",
            timestamp: Date.now()
          });
      }
      yield put({
        type: ADD_SESSION_MESSAGE,
        sessionId: sessionId,
        message: newMessage
      });
      break;
    //listens to session chat updated messages
    case eventTypes.CHILD_CHANGED:
      const updatedMessage = {
        _id: data.key,
        text: item.text,
        name: item.name,
        user: {
          _id: item.user._id,
          name: item.user.name,
          avatar: item.user.avatar
        },
        system: item.system,
        createdAt: new Date(item.createdAt),
        image: item.image,
        type: item.type,
        status: item.status
      };
      yield put({
        type: ADD_SESSION_MESSAGE,
        sessionId: sessionId,
        message: updatedMessage
      });
      break;
    case eventTypes.CHILD_REMOVED:
    default:
      return;
  }
}
