import React from "react";
import ContentHolder from "../utility/contentHolder";
import { Input } from "antd";
import { DatePicker, Form, Tooltip, Select } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import countryCodes from "./countryCodes";
const FormItem = Form.Item;
const Option = Select.Option;

class VendorPrincipal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      country: "",
      zip: "",
      dob: "",
      personalIdentificationNumber: "",
      driverLicenseNumber: "",
      passportNumber: "",
      email: ""
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handlePersonalIdentificationNumberChange = this.handlePersonalIdentificationNumberChange.bind(
      this
    );
    this.handleDriverLicenseNumberChange = this.handleDriverLicenseNumberChange.bind(
      this
    );
    this.handlePassportNumberChange = this.handlePassportNumberChange.bind(
      this
    );
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }
  formRef = React.createRef();

  handleFirstNameChange(event) {
    this.setState({ firstName: event.target.value });
    this.props.handlePrincipal_firstNameChange(event.target.value);
  }

  handleLastNameChange(event) {
    this.setState({ lastName: event.target.value });
    this.props.handlePrincipal_lastNameChange(event.target.value);
  }

  handleAddressChange(event) {
    this.setState({ address: event.target.value });
    this.props.handlePrincipal_addressChange(event.target.value);
  }

  handleCityChange(event) {
    this.setState({ city: event.target.value });
    this.props.handlePrincipal_cityChange(event.target.value);
  }

  handleCountryChange(value) {
    this.setState({ country: value });
    this.props.handlePrincipal_countryChange(value);
  }

  handleZipChange(event) {
    this.setState({ zip: event.target.value });
    this.props.handlePrincipal_zipChange(event.target.value);
  }

  handleDobChange(date, dateString) {
    this.setState({ dob: dateString });
    this.props.handlePrincipal_dobChange(dateString);
  }

  handlePersonalIdentificationNumberChange(event) {
    this.setState({ personalIdentificationNumber: event.target.value });
    this.props.handlePrincipal_personalIdentificationNumberChange(
      event.target.value
    );
  }

  handleDriverLicenseNumberChange(event) {
    this.setState({ driverLicenseNumber: event.target.value });
    this.props.handlePrincipal_driverLicenseNumberChange(event.target.value);
  }

  handlePassportNumberChange(event) {
    this.setState({ passportNumber: event.target.value });
    this.props.handlePrincipal_passportNumberChange(event.target.value);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
    this.props.handlePrincipal_emailChange(event.target.value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state && nextProps.validationCount > 0) {
      const value = { vendorPrincipalValidation: false };
      this.props.isValidated(value);
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validationCount !== this.state.validationCount) {
      this.validateFields();
    }
    if (prevState.vendorDetails !== this.state.vendorDetails) {
      const principal = this.props.vendorDetails.vendorPrincipal;
      if (principal) {
        this.setState({
          firstName: principal.firstName,
          lastName: principal.lastName,
          address: principal.address,
          city: principal.city,
          country: principal.country,
          zip: principal.zip,
          dob: principal.dob,
          personalIdentificationNumber: principal.personalIdentificationNumber,
          driverLicenseNumber: principal.driverLicenseNumber,
          passportNumber: principal.passportNumber,
          email: principal.email
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.validationCount !== prevState.validationCount) {
      return { validationCount: nextProps.validationCount };
    } else if (nextProps.vendorDetails !== prevState.vendorDetails) {
      return { vendorDetails: nextProps.vendorDetails };
    } else return null;
  }

  validateFields() {
    this.formRef.current.validateFields((err, values) => {
      if (!err) {
        const value = { vendorPrincipalValidation: true };
        this.props.isValidated(value);
      } else {
        const value = { vendorPrincipalValidation: false };
        this.props.isValidated(value);
      }
    });
  }

  componentDidMount() {
    const { vendorDetails } = this.props;
    const principal = vendorDetails.vendorPrincipal;
    if (principal) {
      this.setState({
        firstName: principal.firstName,
        lastName: principal.lastName,
        address: principal.address,
        city: principal.city,
        country: principal.country,
        zip: principal.zip,
        dob: principal.dob,
        personalIdentificationNumber: principal.personalIdentificationNumber,
        driverLicenseNumber: principal.driverLicenseNumber,
        passportNumber: principal.passportNumber,
        email: principal.email
      });
    }
  }

  onChange = e => {
    this.setState({
      value: e.target.value
    });
    this.props.onJoinPolicyChange(e.target.value);
  };
  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      }
    };
    const countriesOptions = [];
    for (let i = 0; i < countryCodes.length; i++) {
      countriesOptions.push(
        <Option key={i} value={countryCodes[i].code}>
          {countryCodes[i].name}
        </Option>
      );
    }
    return (
      <ContentHolder direction={this.props.direction}>
        <Form
          ref={this.formRef}
          initialValues={{
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            address: this.state.address,
            city: this.state.city,
            country: this.state.country,
            zip: this.state.zip,
            dob: this.state.dob,
            personalIdentificationNumber: this.state
              .personalIdentificationNumber,
            driverLicenseNumber: this.state.driverLicenseNumber,
            passportNumber: this.state.passportNumber,
            email: this.state.email
          }}
        >
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                First Name&nbsp;
                <Tooltip title="First name of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="firstName"
            rules={[
              {
                required: true,
                message: "First Name is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleFirstNameChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Last Name&nbsp;
                <Tooltip title="Last name of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="lastName"
            rules={[
              {
                required: true,
                message: "Last Name is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleLastNameChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Address&nbsp;
                <Tooltip title="Street address of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="address"
            rules={[
              {
                required: true,
                message: "Address is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleAddressChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                City&nbsp;
                <Tooltip title="City of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="city"
            rules={[
              {
                required: true,
                message: "City is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleCityChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Country&nbsp;
                <Tooltip title="Country of the vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="country"
            rules={[{ required: true, message: "Country is required" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleCountryChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {countriesOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Zip&nbsp;
                <Tooltip title="Zip/Postal code of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="zip"
            rules={[
              {
                required: true,
                message: "Zip/Postal code is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleZipChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Date of birth&nbsp;
                <Tooltip title="Date of birth for vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="dob"
            rules={[{ required: true, message: "Birthday is required" }]}
          >
            <DatePicker onChange={this.handleDobChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Identification Number&nbsp;
                <Tooltip title="In the US, this value is the last four digits of the principal owner's social security number. In countries outside of the US, this value is the full National Identification Number of the principal owner.">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="personalIdentificationNumber"
            rules={[
              {
                required: true,
                message: "Identification Number is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handlePersonalIdentificationNumberChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Driver License Number&nbsp;
                <Tooltip title="Driver license number of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="driverLicenseNumber"
            rules={[
              {
                required: this.state.country === "US",
                message: "Driver License Number is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleDriverLicenseNumberChange} />
          </FormItem>

          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Passport Number&nbsp;
                <Tooltip title="Passport number of vendor principal owner">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="passportNumber"
            rules={[
              {
                required: this.state.country !== "US",
                message: "Passport number is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handlePassportNumberChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Email&nbsp;
                <Tooltip title="What is your company email?">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="email"
            rules={[
              {
                type: "email",
                message: "Email is required"
              },
              {
                required: true,
                message: "Please input your E-mail!"
              }
            ]}
          >
            <Input onChange={this.handleEmailChange} />
          </FormItem>
        </Form>
      </ContentHolder>
    );
  }
}

export default VendorPrincipal;
