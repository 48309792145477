import { store } from "../redux/store";
import {
  CREATE_GROUP_FAILURE,
  LOAD_CIRCLE_PENDING_REQUESTS
} from "../redux/constants";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import Backend from "./Backend";
import { GeoCollectionReference, GeoFirestore } from "geofirestore";
import { store as notificationsStore } from "react-notifications-component";

class CirclesApi {
  getPendingRequests = (circle, circleId) => {
    let pendingUsers = [];
    let membersCount = 0;
    firebase
      .database()
      .ref("circlesJoinRequests/" + circleId)
      .once("value", snap => {
        if (snap.exists()) {
          snap.forEach(item => {
            membersCount++;
            firebase
              .database()
              .ref("users/" + item.key + "/general_info")
              .once("value", snapshot => {
                const temp = snapshot.val();
                temp["id"] = item.key;
                pendingUsers.push(temp);
                if (membersCount === snap.numChildren()) {
                  store.dispatch({
                    type: LOAD_CIRCLE_PENDING_REQUESTS,
                    circleId: circleId,
                    value: pendingUsers
                  });
                }
              });
          });
        } else {
          store.dispatch({
            type: LOAD_CIRCLE_PENDING_REQUESTS,
            circleId: circleId,
            value: {}
          });
        }
      });
  };
  //actions
  // CirclesApi.submitNewCircle(circleType, circleName, circleImg, responders, limit)
  submitNewCircle = (
    description,
    name,
    imageName,
    sizedPic,
    emergency,
    joinPolicy,
    respondersAssignPolicy,
    numberOfAssignResponders,
    limit,
    callback
  ) => {
    let members = [];
    let count = 0;
    const admin = {
      id: Backend.uid,
      role: "admin",
      responder: true,
      owner: true
    };
    members.push(admin);
    const creator = store.getState().Firebase.userData;
    if (creator.settings && creator.settings.isEntity) {
      const entityOwner = {
        id: creator.settings.entityOwner,
        role: "admin",
        responder: true,
        owner: true
      };
      members.push(entityOwner);
    }

    let batch = firebase.firestore().batch();
    let usersCirclesBatch = firebase.firestore().batch();

    let newPublicCircleRef = firebase
      .firestore()
      .collection("publicCircles")
      .doc();
    batch.set(newPublicCircleRef, {
      creator: Backend.uid,
      groupType: "public",
      description: description,
      limit: limit,
      name: name,
      nameLowerCase: name.toLowerCase(),
      emergency: emergency,
      joinPolicy: joinPolicy,
      respondersAssignPolicy: respondersAssignPolicy,
      numberOfAssignResponders: numberOfAssignResponders,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    });
    members.forEach(member => {
      count++;
      const userCirclesRef = firebase
        .firestore()
        .collection("usersCircles")
        .doc(member.id)
        .collection("circles")
        .doc(newPublicCircleRef.id);
      const circleMembersRef = firebase
        .firestore()
        .collection("circlesMembers")
        .doc(newPublicCircleRef.id)
        .collection("members")
        .doc(member.id);
      usersCirclesBatch.set(userCirclesRef, {
        circleId: newPublicCircleRef.id,
        type: "public",
        circlePath: "publicCircles"
      });
      batch.set(circleMembersRef, {
        id: member.id,
        responder: member.responder === true ? true : false,
        role: member.role === undefined ? "member" : member.role,
        owner: member.owner ? true : false,
        circlePath: "publicCircles"
      });
      if (members.length === count) {
        batch
          .commit()
          .then(function() {
            usersCirclesBatch
              .commit()
              .then(function() {
                const key = newPublicCircleRef.id;
                callback(key);
                var uploadPath = "/publicCircles/" + key;
                if (sizedPic) {
                  Backend.uploadSizedPhoto(
                    uploadPath,
                    newPublicCircleRef,
                    imageName,
                    sizedPic
                  );
                }
              })
              .catch(err => {
                callback(null);
                store.dispatch({ type: CREATE_GROUP_FAILURE, payload: err });
              });
          })
          .catch(err => {
            callback(null);
            store.dispatch({ type: CREATE_GROUP_FAILURE, payload: err });
          });
      }
    });
  };

  //delete circle
  deleteCircle(groupId, circleType, id, notificationsStore, history, userUid) {
    const reference =
      circleType === "private" ? "privateCircles" : "publicCircles";
    let deletedBatch = firebase.firestore().batch();
    const userCircleRef = firebase
      .firestore()
      .collection("usersCircles")
      .doc(userUid)
      .collection("circles")
      .doc(groupId);
    const circleRef = firebase
      .firestore()
      .collection(reference)
      .doc(groupId);
    deletedBatch.delete(userCircleRef);
    deletedBatch.delete(circleRef);
    deletedBatch
      .commit()
      .then(() => {
        notificationsStore.addNotification({
          title: "Success!",
          message: "Circle was deleted successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: false
          }
        });

        setTimeout(() => {
          history.push(`/${userUid}/console`);
        }, 500);

        // callback(true);
      })
      .catch(err => {
        notificationsStore.addNotification({
          title: "Circle was not deleted",
          message: "plaese try again",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
      });
  }
  /**
   * Delete a collection, in batches of batchSize. Note that this does
   * not recursively delete subcollections of documents in the collection
   */
  deleteCollection(db, collectionRef, batchSize, callback) {
    var that = this;
    var query = collectionRef.orderBy("__name__").limit(batchSize);

    return new Promise(function(resolve, reject) {
      that.deleteQueryBatch(db, query, batchSize, resolve, reject);
    }).then(() => {
      callback(true);
    });
  }

  deleteQueryBatch(db, query, batchSize, resolve, reject) {
    var that = this;
    query
      .get()
      .then(snapshot => {
        // When there are no documents left, we are done
        if (snapshot.size === 0) {
          resolve();
          return 0;
        }

        // Delete documents in a batch
        var batch = db.batch();
        snapshot.docs.forEach(function(doc) {
          batch.delete(doc.ref);
        });

        return batch.commit().then(function() {
          return snapshot.size;
        });
      })
      .then(function(numDeleted) {
        if (numDeleted <= batchSize) {
          resolve();
          return;
        }

        // Recurse on the next process tick, to avoid
        // exploding the stack.
        process.nextTick(function() {
          that.deleteQueryBatch(db, query, batchSize, resolve, reject);
        });
      })
      .catch(reject);
  }

  updateCircleDetails = (
    type,
    id,
    circleName,
    circleDescription,
    imageName,
    circlePhoto,
    responderPolicy,
    joinPolicy,
    emergency,
    categories,
    numberOfInvitedResponders,
    text
  ) => {
    var circleType = type === "private" ? "privateCircles" : "publicCircles";
    let batch = firebase.firestore().batch();
    let ref = firebase
      .firestore()
      .collection(circleType)
      .doc(id);
    if (circleName !== null) {
      batch.update(ref, { name: circleName });
    }
    if (circleDescription !== null) {
      batch.update(ref, { description: circleDescription });
    }
    if (responderPolicy !== null) {
      batch.update(ref, { respondersAssignPolicy: responderPolicy });
    }
    if (joinPolicy !== null) {
      batch.update(ref, { joinPolicy: joinPolicy });
    }
    if (emergency !== null) {
      batch.update(ref, { emergency: emergency });
    }
    if (categories !== null) {
      batch.update(ref, { categories: categories });
    }
    if (numberOfInvitedResponders !== null) {
      batch.update(ref, {
        numberOfAssignResponders: numberOfInvitedResponders
      });
    }
    batch.commit().then(function() {
      notificationsStore.addNotification({
        // title: "Success!",
        message: text,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });

      if (circlePhoto !== null) {
        var uploadPath = "/publicCircles/" + id;
        const urlRef = firebase
          .firestore()
          .collection(circleType)
          .doc(id);
        Backend.uploadSizedPhoto(uploadPath, urlRef, imageName, circlePhoto);
      }
    });
  };
  setCircleLocation = (circleId, lat, lng, address, callback) => {
    const firestore = firebase.firestore();
    const geofirestore: GeoFirestore = new GeoFirestore(firestore);
    const geocollection: GeoCollectionReference = geofirestore.collection(
      "publicCirclesLocations"
    );
    geocollection.doc(circleId).set({
      circleId: circleId,
      coordinates: new firebase.firestore.GeoPoint(lat, lng)
    });

    firebase
      .firestore()
      .collection("publicCircles")
      .doc(circleId)
      .set(
        {
          location: {
            lat: lat,
            lng: lng
          },
          address: address
        },
        { merge: true }
      )
      .then(() => {
        callback(true);
      })
      .catch(err => {
        callback(false);
        console.log(err);
      });
  };

  changeCircleMemberField(field, value, circleId, userId, text) {
    firebase
      .firestore()
      .collection("circlesMembers")
      .doc(circleId)
      .collection("members")
      .doc(userId)
      .set(
        {
          [field]: value
        },
        { merge: true }
      )
      .then(() => {
        notificationsStore.addNotification({
          // title: "Success!",
          message: text,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      })
      .catch(() => {
        notificationsStore.addNotification({
          title: "an error occured",
          message: "plaese try again",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      });
  }

  removeCircleMember = (
    userId,
    groupType,
    groupPath,
    userPath,
    groupId,
    text
  ) => {
    const groupRef = firebase
      .firestore()
      .collection("circlesMembers")
      .doc(groupId)
      .collection("members")
      .doc(userId);
    let userRef = firebase
      .firestore()
      .collection("usersCircles")
      .doc(userId)
      .collection("circles")
      .doc(groupId);
    let batch = firebase.firestore().batch();
    batch.delete(groupRef);
    batch.delete(userRef);
    batch
      .commit()
      .then(() => {
        notificationsStore.addNotification({
          // title: "Success!",
          message: text,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      })
      .catch(() => {
        notificationsStore.addNotification({
          title: "an error occured",
          message: "plaese try again",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      });
  };

  addCircleMember = (id, groupType, userId, responder, role, text) => {
    let batch = firebase.firestore().batch();
    const userCirclesRef = firebase
      .firestore()
      .collection("usersCircles")
      .doc(userId)
      .collection("circles")
      .doc(id);
    const circleMembersRef = firebase
      .firestore()
      .collection("circlesMembers")
      .doc(id)
      .collection("members")
      .doc(userId);
    batch.set(userCirclesRef, {
      circleId: id,
      type: groupType,
      circlePath: "publicCircles"
    });
    batch.set(circleMembersRef, {
      id: userId,
      responder: responder,
      role: role,
      circlePath: "publicCircles"
    });
    batch
      .commit()
      .then(() => {
        notificationsStore.addNotification({
          // title: "Success!",
          message: text,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      })
      .catch(() => {
        notificationsStore.addNotification({
          title: "Member was not added",
          message: "plaese try again",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      });
  };

  setSessionPriority(circleId, sessionId, priority) {
    const newPriority = {
      sessionId: sessionId,
      circleId: circleId,
      priority: priority
    };
    firebase
      .firestore()
      .collection("sessionsPriority")
      .doc(circleId)
      .collection("sessionPriority")
      .doc(sessionId)
      .set(newPriority);
  }

  handlePendingRequest = (id, action, circleId, text) => {
    let batch = firebase.firestore().batch();
    const userCirclesRef = firebase
      .firestore()
      .collection("usersCircles")
      .doc(id)
      .collection("circles")
      .doc(circleId);
    const circleMembersRef = firebase
      .firestore()
      .collection("circlesMembers")
      .doc(circleId)
      .collection("members")
      .doc(id);
    const pendingRef = firebase
      .firestore()
      .collection("circlesJoinRequests")
      .doc(circleId)
      .collection("requests")
      .doc(id);
    batch.delete(pendingRef);
    if (action === "confirm") {
      batch.set(userCirclesRef, {
        circleId: circleId,
        type: "public",
        circlePath: "publicCircles"
      });
      batch.set(circleMembersRef, {
        id: id,
        responder: false,
        role: "member",
        circlePath: "publicCircles"
      });
    }
    batch
      .commit()
      .then(() => {
        notificationsStore.addNotification({
          // title: "Success!",
          message: text,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      })
      .catch(() => {
        notificationsStore.addNotification({
          title: "an error occured",
          message: "plaese try again",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      });
  };
}
export default new CirclesApi();
