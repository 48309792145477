import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/sagas";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers/reducers";
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];
const appReducer = createRootReducer(history);
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT" || action.type === "CLEAR_STATE") {
    state = undefined;
  }

  return appReducer(state, action);
};

const config = {
  key: "root", // key is required
  storage, // storage is now required
  blacklist: ["Firebase"]
};

const persistReducers = persistReducer(config, rootReducer);
export default function configureStore(preloadedState) {
  const store = createStore(
    persistReducers, // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}
const store = configureStore();
let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
export { store, persistor };
