import React from "react";
import PropTypes from "prop-types";
import OwnerPinWrapper from "./ownerPin.style";

const OwnerPin = props => {
  const { onClick, direction } = props;
  return (
    <OwnerPinWrapper
      data-rtl={direction}
      onClick={onClick}
      className={"annotationCallerContainer"}
      style={{ cursor: "pointer" }}
    >
      <div className={"callerOuterPulse"} />
      <div className={"callerInnerPulse"} />
    </OwnerPinWrapper>
  );
};

OwnerPin.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string
};

export default OwnerPin;
