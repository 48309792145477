import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";
import Backend from "../../api/Backend";
import TypingWrapper from "./typingComponent.style";
import Image from "../utility/image";

const TypingComponent = props => {
  const [chatTypingEvents, setChatTypingEvents] = useState([]);
  const { sessionChatTypingEvents, sessionsMembers } = props;
  useEffect(() => {
    const previousUserEvent = chatTypingEvents.filter(
      user => user.userId === Backend.uid
    );
    const nextUserEvent = sessionChatTypingEvents.filter(
      user => user.userId === Backend.uid
    );
    setChatTypingEvents(sessionChatTypingEvents);
    //if current user is ty
    if (
      previousUserEvent[0] &&
      nextUserEvent[0] &&
      previousUserEvent[0] !== nextUserEvent[0]
    ) {
      return;
    } else {
      setTimeout(() => {
        // this.forceUpdate();
      }, 2600);
    }
  }, [sessionChatTypingEvents, chatTypingEvents]);

  let isTyping = false;
  let source = null;
  var sortedChatTypingEvents = sessionChatTypingEvents
    ? sessionChatTypingEvents.sort(function(a, b) {
        if (a.timestamp > b.timestamp) return -1;
        if (a.timestamp < b.timestamp) return 1;
        return 0;
      })
    : [];
  if (sortedChatTypingEvents.length > 0) {
    if (
      sortedChatTypingEvents[0].userId !== Backend.uid &&
      sortedChatTypingEvents[0].send !== true &&
      sortedChatTypingEvents[0].timestamp + 2500 > Date.now()
    ) {
      const user = sessionsMembers.filter(
        user => user.id === sortedChatTypingEvents[0].userId
      )[0];
      source = user && user.photoUrl ? user.photoUrl : null;
      isTyping = true;
    } else {
      isTyping = false;
    }
  }

  return (
    <TypingWrapper className="isTyping">
      {isTyping ? (
        <div className="chat-bubble-left-container">
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="isoAvatar">
                {source !== null ? (
                  <Image
                    alt="#"
                    imagesrc={source.uri}
                    disableanimation={"false"}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="chat-bubble-left">
                <div style={{ padding: "0.5em" }}>
                  <Dots size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </TypingWrapper>
  );
};

TypingComponent.propTypes = {
  sessionsMembers: PropTypes.array,
  sessionChatTypingEvents: PropTypes.array
};

export default TypingComponent;

//
// export default class TypingComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       sessionChatTypingEvents: null
//     };
//   }
//
//   static propTypes = {
//     sessionsMembers: PropTypes.array,
//     sessionChatTypingEvents: PropTypes.array
//   };
//
//   componentDidUpdate(prevProps, prevState) {
//     if (
//       prevState.sessionChatTypingEvents !== this.state.sessionChatTypingEvents
//     ) {
//       const previousUserEvent = this.props.sessionChatTypingEvents.filter(
//         user => user.userId === Backend.uid
//       );
//       const nextUserEvent = this.state.sessionChatTypingEvents.filter(
//         user => user.userId === Backend.uid
//       );
//       //if current user is ty
//       if (
//         previousUserEvent[0] &&
//         nextUserEvent[0] &&
//         previousUserEvent[0] !== nextUserEvent[0]
//       ) {
//         return;
//       } else {
//         setTimeout(() => {
//           this.forceUpdate();
//         }, 2600);
//       }
//     }
//   }
//
//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (
//       nextProps.sessionChatTypingEvents !== prevState.sessionChatTypingEvents
//     ) {
//       return {sessionChatTypingEvents: nextProps.sessionChatTypingEvents};
//     } else return null;
//   }
//
//   render() {
//     let isTyping = false;
//     let source = null;
//     var sortedChatTypingEvents = this.props.sessionChatTypingEvents
//       ? this.props.sessionChatTypingEvents.sort(function(a, b) {
//           if (a.timestamp > b.timestamp) return -1;
//           if (a.timestamp < b.timestamp) return 1;
//           return 0;
//         })
//       : [];
//     if (sortedChatTypingEvents.length > 0) {
//       if (
//         sortedChatTypingEvents[0].userId !== Backend.uid &&
//         sortedChatTypingEvents[0].send !== true &&
//         sortedChatTypingEvents[0].timestamp + 2500 > Date.now()
//       ) {
//         const user = this.props.sessionsMembers.filter(
//           user => user.id === sortedChatTypingEvents[0].userId
//         )[0];
//         source = user && user.photoUrl ? user.photoUrl : null;
//         isTyping = true;
//       } else {
//         isTyping = false;
//       }
//     }
//
//     return (
//       <TypingWrapper className="isTyping">
//         {isTyping ? (
//           <div className="chat-bubble-left-container">
//             <div>
//               <div style={{display: "flex", flexDirection: "row"}}>
//                 <div className="isoAvatar">
//                   {source !== null ? (
//                     <Image
//                       alt="#"
//                       imagesrc={source.uri}
//                       disableanimation={"false"}
//                     />
//                   ) : (
//                     ""
//                   )}
//                 </div>
//
//                 <div className="chat-bubble-left">
//                   <div style={{padding: "0.5em"}}>
//                     <Dots size={20} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : null}
//       </TypingWrapper>
//     );
//   }
// }
