import styled from "styled-components";

const PaymentSettingsWrapper = styled.div`
  width: 100%;
  .formFields {
    max-width: 500px;
  }
`;

export default PaymentSettingsWrapper;
