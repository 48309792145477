import {
  FIRESTORE_UNLISTEN_REQUESTED,
  FIREBASE_UNLISTEN_REQUESTED,
  REMOVE_ONLINE_SESSION
} from "../../constants";
import "firebase/firestore";
import { put } from "redux-saga/effects";

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setSessionsUpdate(data, sessionId, metaType) {
  const session = data.snapshot.data();
  const circleId = metaType.circleId;
  if (session !== undefined) {
    if (session.sessionStatus === "closed") {
      yield put({
        type: REMOVE_ONLINE_SESSION,
        circleId: circleId,
        sessionId: sessionId
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: sessionId,
        meta: { type: "sessionMembers" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: sessionId,
        meta: { type: "sessionLocations" }
      });
      yield put({
        type: FIREBASE_UNLISTEN_REQUESTED,
        id: sessionId,
        meta: { type: "sessionMessages" }
      });
      yield put({
        type: FIREBASE_UNLISTEN_REQUESTED,
        id: sessionId,
        meta: { type: "sessionTypingEvents" }
      });
    }
  }

  // else if(updatedSession === undefined || updatedSession.updated !== true){
  //   //check if closed sessions got last update
  //   SessionsApi.getClosedSessionDetails(sessionId)
  // }
}
