import styled from "styled-components";
import { palette, font } from "styled-theme";

const DashAppHolder = styled.div`
display:flex;
font-family: ${font("primary", 0)};
color: ${palette("lightText", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
input,
textarea,
div,
img,
h2,
h3,
h4 {
  color: ${palette("text", 0)};
}

h4,
h5,
h6,
a,
p,
li,
input {
  color: ${palette("lightText", 0)};
}

h1 {
  color: ${palette("title", 0)};
  font-size: 18px;
}

h3 {
  color: ${palette("title", 0)};
  font-size: 16px;
}

.ant-modal-content {
   background-color: red;
}

.ant-row:not(.ant-form-item) {
  ${"" /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}
.ant-page-header-heading-title{
  max-width:calc(100vw - 200px);
  white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.ant-form-item-label > label,
.ant-typography {
  color: ${palette("text", 0)};
}

.ant-input,
.ant-select-selection,
.ant-input-number-input {
  background-color: ${palette("surfaceColor", 0)} !important;
  color: ${palette("text", 0)};
}

.ant-select-arrow {
  color: ${palette("lightText", 0)};
}
.ant-btn {
  color: ${palette("lightText", 0)};
  background-color: ${palette("surfaceColor", 0)};
  padding: 10px 20px;
  border-radius: 5px;
  height: fit-content;
  /* border-color: ${palette("border", 0)}; */
  &.light {
    background-color: ${palette("background", 0)};
    color: ${palette("text", 0)};
    border-color: ${palette("border", 0)};
    /* border:none; */
    &:hover,
    &:focus {
      background-color: ${palette("background", 0)};
      color: ${palette("text", 0)};
      border-color: ${palette("secondary", 0)};
    }
  }

  &:hover,
  &:focus {
    background-color: ${palette("surfaceColor", 0)};
  }
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin: 0 8px;
}

.ant-btn-block {
  background-color: ${palette("surfaceColor", 0)};
  color: ${palette("text", 0)};
  &:hover,
  &:focus {
    background-color: ${palette("surfaceColor", 0)};
  }
}
.ant-btn-primary {
  color: #ffffff;
  background-color: ${palette("primary", 0)};
  border-color: ${palette("primary", 0)};
  &:hover,
  &:focus {
    background-color: ${palette("primary", 0)};
    border-color: ${palette("primary", 0)};
  }
  &.light {
    background-color: ${palette("background", 0)};
    box-shadow: none;
    border:1px solid ${palette("border", 0)};
    &:hover,
    &:focus {
       border-color: ${palette("secondary", 0)};
    }
  }
}


.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover {
  color: ${palette("disabledColor", 0)};
  background-color: ${palette("disabledBg", 0)};
  border-color: ${palette("border", 0)};
}

.ant-btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  &:hover,
  &:focus {
    background-color: #ff4d4f;
  }
}

.ant-switch {
  background-color: ${palette("border", 0)};
}
.ant-switch-checked{
  background-color: ${palette("secondary", 0)};

}
.ant-upload.ant-upload-select-picture-card{
  background-color: transparent;
}

.ant-radio-button-wrapper {
  color: ${palette("lightText", 0)};
  line-height: 30px;
  background-color: ${palette("surfaceColor", 0)};
  border: 1px solid ${palette("border", 0)};
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: ${palette("surfaceColor", 0)};
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid ${palette("border", 0)};
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: ${palette("border", 0)};
}
.ant-input-number-handler-wrap{
background-color: ${palette("surfaceColor", 0)};
border-left: 1px solid ${palette("border", 0)};
}
.ant-input-number-handler:active{
background-color: ${palette("surfaceColor", 0)};
}
.ql-toolbar.ql-snow, .ql-container.ql-snow {
border: 1px solid ${palette("border", 0)};
}
.ql-editor.ql-blank::before {
color: ${palette("lightText", 0)};
}
.ql-editor p {
color: ${palette("text", 0)};
}
.ant-input-number-handler-down {
  border-top: 1px solid ${palette("border", 0)};
}
.ant-layout, .ant-input-number{
  background-color: ${palette("surfaceColor", 0)};
}
.ant-menu-inline {
  border: none;
}
.ant-layout-header {
  padding: 0 ;
}

.ant-row > div {
  padding: 0;
}
.ant-table {
  color: ${palette("lightText", 0)};
}
.ant-table-placeholder {
  color: ${palette("lightText", 0)};
  background-color: ${palette("surfaceColor", 0)};
  border-top: 1px solid ${palette("border", 0)};
  border-bottom: 1px solid ${palette("border", 0)};
}
.ant-empty-normal {
  color: ${palette("lightText", 0)};
}
.ant-table-thead > tr {
  > th {
    color: ${palette("text", 0)};
    background-color: ${palette("background", 0)};
    border-bottom: 1px solid ${palette("border", 0)};
  }
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid ${palette("border", 0)};
}
.ant-table .ant-table-row:hover {
  background-color: red;
}
.ant-pagination-item-link {
  background-color: ${palette("lightBg", 0)} !important;
  color: ${palette("lightText", 0)} !important;
  border-color: ${palette("border", 0)} !important;
}
.ant-pagination-item {
  background-color: ${palette("surfaceColor", 0)};
  border: 1px solid ${palette("border", 0)} !important;
  a {
    color: ${palette("lightText", 0)} !important;
  }
}
.ant-pagination-item-active a {
  color: ${palette("secondary", 0)} !important;
}
.ant-input {
  font-size: 14px;
  font-weight: 400;
  color: ${palette("lightText", 0)};
}
.ant-radio-wrapper {
  color: ${palette("text", 0)};
}
.ant-tag,
.ant-form-item {
  color: ${palette("lightText", 0)};
}
.ant-select-dropdown {
  ul {
    padding: 0;
  }
}
.ant-select-selection {
  background-color: ${palette("surface", 0)};
  border: 1px solid ${palette("border", 0)};
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: ${palette("primary", 0)};
}

.ant-slider-mark-text-active {
  color: ${palette("text", 0)} !important;
}

.ant-popover-inner-content {
  padding:0px !important;
  background-color: ${palette("surface", 0)};
}
.popoverOverlay{
  backgrond-color:"green"
}
.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.intercom-namespace .intercom-launcher-frame.intercom-launcher-frame-shadow {
  background-color: ${palette("primary", 0)}!important;
}
`;

export default DashAppHolder;
