import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UsageWrapper from "./usage.style";
import { Select, Button, Table } from "antd";
import PaymentApi from "../../api/PaymentApi";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";
import IntlMessages from "../utility/intlMessages";
import { message } from "antd";

const { Option } = Select;

const Usage = props => {
  const { direction } = props;
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);
  const [months, setMonths] = useState([]);
  const [month, setMonth] = useState(null);
  const [usage, setUsage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const y = PaymentApi.getUsageYears();
    const m = PaymentApi.getUsageMonths();
    setYears(y);
    setMonths(m);
    setYear(y[0]);
    setMonth(m[0]);
  }, []);

  const onMonthChange = value => {
    setMonth(value);
  };
  const onYearChange = value => {
    setYear(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setUsage([]);
    setLoading(true);
    let monthValue = 0;
    switch (month) {
      case "January":
        monthValue = 0;
        break;
      case "February":
        monthValue = 1;
        break;
      case "March":
        monthValue = 2;
        break;
      case "April":
        monthValue = 3;
        break;
      case "May":
        monthValue = 4;
        break;
      case "June":
        monthValue = 5;
        break;
      case "July":
        monthValue = 6;
        break;
      case "August":
        monthValue = 7;
        break;
      case "September":
        monthValue = 8;
        break;
      case "October":
        monthValue = 9;
        break;
      case "November":
        monthValue = 10;
        break;
      case "December":
        monthValue = 11;
        break;
      default:
        monthValue = 0;
    }

    var start = new Date(0);
    start.setHours(0);
    start.setFullYear(year);
    start.setMonth(monthValue);
    var end = new Date(start);
    end.setMonth(start.getMonth() + 1);
    PaymentApi.getUsageBetweenDates(start, end);

    const usage = PaymentApi.getUsageBetweenDates(start, end);
    Promise.all([usage])
      .then(function(values) {
        if (values[0]) {
          setUsage(values[0]);
          setLoading(false);
        } else {
          message.info("There was no usage for the given month");
          setLoading(false);
        }
      })
      .catch(function(error) {
        message.error("There was an error during getting your usage");
      });
  };

  const columns = [
    {
      title: <IntlMessages id="app.useage.date" />,
      dataIndex: "date",
      key: "date"
    },
    {
      title: <IntlMessages id="app.useage.geoFences" />,
      dataIndex: "totalGeoFences",
      key: "totalGeoFences"
    },
    {
      title: <IntlMessages id="app.useage.instantAlerts" />,
      dataIndex: "totalInstantAlerts",
      key: "totalInstantAlerts"
    },
    {
      title: <IntlMessages id="app.useage.privateMembers" />,
      dataIndex: "totalPrivateMembers",
      key: "totalPrivateMembers"
    },
    {
      title: <IntlMessages id="app.useage.privateResponders" />,
      dataIndex: "totalPrivateResponders",
      key: "totalPrivateResponders"
    },
    {
      title: <IntlMessages id="app.useage.publicMembers" />,
      dataIndex: "totalPublicMembers",
      key: "totalPublicMembers"
    },
    {
      title: <IntlMessages id="app.useage.publicResponders" />,
      dataIndex: "totalPublicResponders",
      key: "totalPublicResponders"
    },
    {
      title: <IntlMessages id="app.useage.scheduleAlerts" />,
      dataIndex: "totalScheduleAlerts",
      key: "totalScheduleAlerts"
    }
  ];
  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter style={{ flexDirection: "column", padding: "20px" }}>
          <div className="title">
            <h1 className="fieldTitle">
              <IntlMessages id="app.settings.usage" />
            </h1>
          </div>

          <UsageWrapper data-rtl={direction}>
            <div className="innerContainer" style={{ flexDirection: "column" }}>
              <div className="selectContainer">
                <Select
                  value={month}
                  style={{ width: 120 }}
                  onChange={onMonthChange}
                >
                  {months.map(month => (
                    <Option key={month}>{month}</Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 120 }}
                  value={year}
                  onChange={onYearChange}
                >
                  {years.map(year => (
                    <Option key={year}>{year}</Option>
                  ))}
                </Select>
                <Button onClick={handleSubmit} type="primary" loading={loading}>
                  <IntlMessages id="app.buttons.submit" />
                </Button>
              </div>
              <Table dataSource={usage} columns={columns} />
            </div>
          </UsageWrapper>
        </CategoryCenter>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

Usage.propTypes = {
  direction: PropTypes.string
};

export default Usage;
