import React from "react";
import PropTypes from "prop-types";
import SessionSwitcher from "./sessionSwitcher";
import SessionNavBarWrapper from "./sessionNavBar.style";
import Backend from "../../api/Backend";
import SessionApi from "../../api/SessionsApi";
import Logo from "../logo/logo";
import { message as antMessage } from "antd";
import { withTheme } from "styled-components";
import {
  AuditOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  MessageOutlined,
  UserAddOutlined,
  PoweroffOutlined
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import { store as notificationsStore } from "react-notifications-component";
import ActionNotification from "../notifications/actionNotification";
import { useSelector } from "react-redux";

const SessionNavBar = props => {
  const {
    session,
    showInviteList,
    showInviteState,
    showParticipants,
    showParticipantsState,
    showChat,
    showChatState,
    showCallerInfo,
    showCallerInfoState,
    showCallerRouteMap,
    showCallerRouteState,
    history,
    theme
  } = props;

  const userSettings = useSelector(state => state.Firebase.userData.settings);
  const localState = useSelector(state => state.LocalState);

  const showLeaveAlert = () => {
    notificationsStore.addNotification({
      content: ({ id }) => (
        <ActionNotification
          type={"danger"}
          id={id}
          approve={id => {
            notificationsStore.removeNotification(id);
            leaveSession();
          }}
          decline={id => {
            notificationsStore.removeNotification(id);
          }}
          title={"Are you sure you want to leave the session?"}
          message={"Once you leave, you will not be able to join again!"}
        />
      ),
      dismiss: {
        click: false,
        touch: false,
        duration: 0,
        waitForAnimation: true
      },
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "flipInX"],
      animationOut: ["animated", "flipOutX"]
    });
  };

  const leaveSession = async () => {
    const activeCircleId = localState.activeCircleId;
    const deleted = SessionApi.leaveSession(session.sessionId);
    Promise.all([deleted])
      .then(function(values) {
        if (values[0]) {
          history.push(
            `/${Backend.uid}/dashboard/${activeCircleId}/resolvedSessions`
          );
        }
      })
      .catch(function() {
        antMessage.error("There was an error during getting the user details");
      });
  };

  const direction =
    userSettings && userSettings.language && userSettings.language.direction
      ? userSettings.language.direction
      : "ltr";
  const isOpen = session && session.sessionStatus !== "closed";
  const themeName = theme.name;
  const bg =
    themeName === "lightTheme"
      ? theme.palette["background"][0]
      : theme.palette["lightBg"][0];
  return (
    <SessionNavBarWrapper data-rtl={direction} bg={bg}>
      <div className="logo">
        <Link to={`/${localState.uid}/console`}>
          <Logo />
        </Link>
      </div>
      <ul>
        <li className="isoNotify">
          <SessionSwitcher direction={direction} session={true} />
        </li>
        {isOpen ? (
          <li
            onClick={showInviteList}
            className={showInviteState ? "active" : ""}
          >
            <div className="isoIconWrapper">
              <UserAddOutlined />
            </div>
          </li>
        ) : null}

        <li
          onClick={showParticipants}
          className={showParticipantsState ? "active" : ""}
        >
          <div className="isoIconWrapper">
            <TeamOutlined />
          </div>
        </li>
        <li onClick={showChat} className={showChatState ? "active" : ""}>
          <div className="isoIconWrapper">
            <MessageOutlined />
          </div>
        </li>
        <li
          onClick={showCallerInfo}
          className={showCallerInfoState ? "active" : ""}
        >
          <div className="isoIconWrapper">
            <AuditOutlined />
          </div>
        </li>
        <li
          onClick={showCallerRouteMap}
          className={showCallerRouteState ? "active" : ""}
        >
          <div className="isoIconWrapper">
            <EnvironmentOutlined />
          </div>
        </li>
        {isOpen ? (
          <li onClick={showLeaveAlert} className="isoLeave">
            <div className="isoIconWrapper">
              <PoweroffOutlined />
            </div>
          </li>
        ) : null}
      </ul>
    </SessionNavBarWrapper>
  );
};

SessionNavBar.propTypes = {
  session: PropTypes.object,
  showInviteList: PropTypes.func,
  showInviteState: PropTypes.bool,
  showParticipants: PropTypes.func,
  showParticipantsState: PropTypes.bool,
  showChat: PropTypes.func,
  showChatState: PropTypes.bool,
  showCallerInfo: PropTypes.func,
  showCallerInfoState: PropTypes.bool,
  showCallerRouteMap: PropTypes.func,
  showCallerRouteState: PropTypes.bool,
  history: PropTypes.object,
  theme: PropTypes.object
};

export default withTheme(SessionNavBar);
