import {
  eventTypes,
  ADD_CIRCLE_GEO_FENCES,
  REMOVE_CIRCLE_GEO_FENCES,
  CIRCLE_GEO_FENCES_FETCH_SUCCESS
} from "../../constants";
import { put } from "redux-saga/effects";

// listens when circle geofences are added, removed or modified
export function* setCircleGeoFenceUpdate(data, circleId, metaType) {
  let allGeofences = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j].doc.data();
    const identifier = data.snapshot.docChanges()[j].doc.id;
    const type = data.snapshot.docChanges()[j].type;

    // listens when circle geofences are added
    if (type === eventTypes.ADDED || type === eventTypes.MODIFIED) {
      const geofence = change;
      geofence["identifier"] = identifier;
      //if there is more then one change, it is initial load
      if (data.snapshot.docChanges().length > 1) {
        allGeofences.push(geofence);
        if (counter === data.snapshot.docChanges().length) {
          yield put({
            type: CIRCLE_GEO_FENCES_FETCH_SUCCESS,
            circleId: circleId,
            geoFences: allGeofences
          });
        }
      } else {
        yield put({
          type: ADD_CIRCLE_GEO_FENCES,
          circleId: circleId,
          value: geofence
        });
      }
    } else if (type === eventTypes.REMOVED) {
      yield put({
        type: REMOVE_CIRCLE_GEO_FENCES,
        identifier: identifier,
        circleId: circleId
      });
    }
  }
}
