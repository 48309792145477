import {
  UPDATE_SESSION_MESSAGE_PROGRESS,
  CREATE_SESSION_MESSAGES_GROUP,
  RESET_SESSIONS_MESSAGES,
  ADD_SESSION_MESSAGE,
  REMOVE_SESSION_MESSAGE,
  SESSION_MESSAGES_FETCH_SUCCESS,
  UPDATE_SESSION_MESSAGE_SENT,
  UPDATE_SESSION_MESSAGE_UPLOADED,
  ADD_SESSION
} from "../../constants";
const INITIAL_STATE = {};
const messages = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SESSION:
      return {
        ...state,
        [action.id]: state[action.id] !== undefined ? state[action.id] : []
      };
    case SESSION_MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        [action.sessionId]: action.messages
      };
    case ADD_SESSION_MESSAGE:
      return {
        ...state,
        [action.sessionId]: [
          ...state[action.sessionId].filter(
            message => message._id !== action.message._id
          ),
          action.message
        ]
      };

    case REMOVE_SESSION_MESSAGE:
      return {
        ...state,
        [action.sessionId]: [
          ...state[action.sessionId].filter(
            message => message._id !== action.messageId
          )
        ]
      };

    case UPDATE_SESSION_MESSAGE_PROGRESS:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId].map(message =>
          message._id === action.messageId
            ? {
                ...message,
                uploadProgress: action.uploadProgress
              }
            : message
        )
      };
    case UPDATE_SESSION_MESSAGE_SENT:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId].map(message =>
          message._id === action.messageId
            ? {
                ...message,
                sent: action.sent
              }
            : message
        )
      };
    case UPDATE_SESSION_MESSAGE_UPLOADED:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId].map(message =>
          message._id === action.messageId
            ? {
                ...message,
                received: action.received
              }
            : message
        )
      };
    case CREATE_SESSION_MESSAGES_GROUP:
      return {
        ...state,
        [action.sessionId]: []
      };
    case RESET_SESSIONS_MESSAGES:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
export default messages;
