import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import PrivacyWrapper from "./privacy.style";
import { withTheme } from "styled-components";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";

const Privacy = props => {
  const { theme } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.id = "privacy";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter style={{ flexDirection: "column" }}>
          <PrivacyWrapper theme={theme.name}>
            <a
              href="https://www.iubenda.com/privacy-policy/78672341"
              className="iubenda-white no-brand iubenda-embed iub-body-embed"
              title="Privacy Policy"
            >
              {" "}
            </a>
          </PrivacyWrapper>
        </CategoryCenter>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

Privacy.propTypes = {
  theme: PropTypes.object
};

export default withTheme(Privacy);
