import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const dismissButtonVariants = {
  open: {
    scale: 1,
    opacity: 1,
    transition: { duration: 0.15, delay: 0.3 }
  },
  closed: {
    scale: 0,
    opacity: 0,
    transition: { delay: 0.1, duration: 0 }
  }
};

const SessionComponentCloseButton = props => {
  const { show, onClick } = props;
  return (
    <motion.div
      variants={dismissButtonVariants}
      animate={show ? "open" : "closed"}
      className="dismissBtnContainer"
      onClick={onClick}
    >
      <i className="ion-ios-close" />
    </motion.div>
  );
};

SessionComponentCloseButton.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func
};

export default SessionComponentCloseButton;
