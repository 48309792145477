import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
const { Text } = Typography;

const Line = props => {
  const { content } = props;
  return <Text>{content}</Text>;
};

Line.propTypes = {
  content: PropTypes.string
};

export default Line;
