import {
  FIREBASE_OBJECT_LISTEN_REQUESTED,
  eventTypes,
  metaTypes
} from "../constants";
import { setSessionMembersPresenceUpdate } from "./sessions/sessionMembersPresenceSagas";
import { eventChannel, buffers } from "redux-saga";
import { take, call, fork, flush } from "redux-saga/effects";

export function createEventChannel(id, ref) {
  const listener = eventChannel(emit => {
    ref.on("value", snap => {
      emit({
        id: id,
        eventType: eventTypes.VALUE,
        key: snap.key,
        value: snap.val()
      });
    });
    return () => {
      ref.off();
    };
  }, buffers.expanding(1));
  return listener;
}

export function* getDataAndListenToChannel(ref, id, metaType) {
  const chan = yield call(createEventChannel, id, ref);
  try {
    try {
      const snap = yield call([ref, ref.once], "value");
      yield flush(chan);
      const val = snap.val();
      const value = val ? val : {};
      switch (metaType) {
        case metaTypes.sessionMembersPresence:
          yield call(
            setSessionMembersPresenceUpdate,
            value,
            id,
            snap.key,
            metaType
          );
          break;
        default:
          return;
      }
    } catch (error) {}
    while (true) {
      const data = yield take(chan);
      switch (metaType) {
        case metaTypes.sessionMembersPresence:
          yield call(
            setSessionMembersPresenceUpdate,
            data.value,
            data.id,
            data.key,
            metaType
          );
          break;
        default:
          return;
      }
    }
  } finally {
    chan.close();
  }
}

export function* firebaseObjectWatchListener(metaType) {
  while (true) {
    const listenRequestAction = yield take(FIREBASE_OBJECT_LISTEN_REQUESTED);
    if (listenRequestAction.meta.type === metaType) {
      yield fork(
        getDataAndListenToChannel,
        listenRequestAction.payload.ref,
        listenRequestAction.payload.id,
        metaType
      );
    }
  }
}
