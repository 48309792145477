import styled from "styled-components";
import { palette } from "styled-theme";

const ConsoleStyleWrapper = styled.div`
  .ant-layout {
    background: ${palette("background", 0)} !important;
  }
  .circle-card {
    padding: 2em 0;
    h3 {
      font-weight: 500;
      text-align: center;
      font-size: 1.4em;
    }
    p {
      text-align: center;
    }
  }
`;

export default ConsoleStyleWrapper;
