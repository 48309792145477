import React from "react";
import PropTypes from "prop-types";
import Dropzone from "../utility/dropzone";
import NewCircleHeader from "../animations/newCircleHeader";
import NewCircleItem from "../animations/newCircleItem";
import NewCircleContent from "../animations/newCircleContent";

const Drop = props => {
  const addImage = file => {
    props.addImage(file);
  };

  return (
    <div className="componentInner">
      <NewCircleHeader>
        <h1>Circle photo</h1>
        <p>Add photo to your circle, so member will be able to recognize it</p>
      </NewCircleHeader>
      <div style={{ padding: "10px 0 30px 0" }}>
        <NewCircleContent>
          <NewCircleItem itemKey={1}>
            <Dropzone uri={props.uri} addImage={addImage} />
          </NewCircleItem>
        </NewCircleContent>
      </div>
    </div>
  );
};

Drop.propTypes = {
  addImage: PropTypes.func,
  uri: PropTypes.string
};

export default Drop;
