import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  initial: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const NewCircleItem = props => {
  const { children, itemKey } = props;
  return (
    <motion.li key={itemKey} variants={variants} initial={"initial"}>
      {children}
    </motion.li>
  );
};

NewCircleItem.propTypes = {
  itemKey: PropTypes.number,
  children: PropTypes.object
};

export default NewCircleItem;
