import styled from "styled-components";
import { palette } from "styled-theme";

const SessionNavBarWrapper = styled.div`
   {
    .logo {
      background: ${palette("surfaceColor", 0)};
      height: 80px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid ${palette("border", 0)};
    }
    ul {
      flex-direction: column;
      padding: 0;
      li {
        list-style-type: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        &.isoNotify {
          border-bottom: 1px solid ${palette("border", 0)};
        }
        &.isoLeave {
          padding-top: 1em;
          border-top: 1px solid ${palette("border", 0)};
        }
        &.active {
          background-color: ${palette("onSecondaryBg", 0)};
          .isoIconWrapper {
            background: ${palette("secondary", 0)};
            .anticon {
              color: #ffffff !important;
            }
          }
        }

        .isoIconWrapper {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${palette("iconBg", 0)};
          border-radius: 20px;
          .anticon {
            font-size: 18px;
            color: ${palette("text", 0)} !important;
          }

          .badgeWrapper {
            position: absolute;
            right: 15px;
            margin-top: -15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid ${palette("border", 0)};
            background-color: ${palette("surfaceColor", 0)};
            width: 25px;
            height: 25px;
            border-radius: 20px;
          }
        }
      }
    }
  }
`;

export default SessionNavBarWrapper;
