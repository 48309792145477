import React from "react";
import PropTypes from "prop-types";
import Image from "../utility/image";
import { withTheme } from "styled-components";

const MarkerPin = props => {
  const { member, onClick, theme } = props;
  const pinStyle = {
    fill: "#d00",
    stroke: "none"
  };

  const height = 62;
  const width = 47;
  return (
    <div
      style={{
        cursor: "pointer",
        transform: `translate(${-width / 2}px,${-height}px)`
      }}
      onClick={onClick}
    >
      <svg
        height={height}
        width={width}
        style={{ ...pinStyle, position: "absolute" }}
        className="svg-light svg-light--red"
        viewBox="0 0 47 62"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-0.0450631868,23.2602268 C-0.0450631868,28.4922598 1.69858516,33.3156041 4.64331593,37.2038351 L23.4492555,62.0272289 L42.2550659,37.2038351 C45.2000549,33.3156041 46.9434451,28.4923876 46.9434451,23.2602268 C46.9434451,10.4151052 36.4235797,0 23.4491264,0 C10.4748022,0 -0.0450631868,10.4151052 -0.0450631868,23.2602268 Z"
          id="Path"
          stroke="#FFFFFF"
          strokeWidth="0"
          fill={theme.palette["primary"][0]}
        />
        <path
          d="M23.4491264,38.7668742 C14.7994478,38.7668742 7.78650549,31.8240247 7.78650549,23.2602268 C7.78650549,14.6966845 14.7994478,7.75332371 23.4491264,7.75332371 C32.0990632,7.75332371 39.1117473,14.6966845 39.1117473,23.2602268 C39.1117473,31.8241526 32.0991923,38.7668742 23.4491264,38.7668742 L23.4491264,38.7668742 Z"
          id="Shape"
          fill="#FFFFFF"
        />
      </svg>
      <Image
        style={{
          width: 39,
          height: 39,
          borderRadius: 22,
          position: "absolute",
          left: 4,
          top: 4
        }}
        alt="user"
        imagesrc={member && member.photoUrl ? member.photoUrl.uri : ""}
        disableanimation={"false"}
      />
    </div>
  );
};

MarkerPin.propTypes = {
  onClick: PropTypes.func,
  member: PropTypes.object,
  theme: PropTypes.object
};

export default withTheme(MarkerPin);
