import React from "react";
import PropTypes from "prop-types";
import { Slider, Button } from "antd";
import moment from "moment";
import MapControlWrapper from "./mapControlWrapper.style";
import MapStyleSelector from "../../mapBoxControl/mapStyleSelector";
import IntlMessages from "../../utility/intlMessages";
import { PauseOutlined, CaretRightOutlined } from "@ant-design/icons";

const RouteMapControl = (props) => {
  const {
    setTimesValue,
    setSpeedValue,
    setPlayStatus,
    timesValue,
    speedValue,
    play,
    onMapStyleChange,
    times,
  } = props;

  const onTimesChange = (value) => {
    setTimesValue(value);
  };

  const onSpeedChange = (value) => {
    setSpeedValue(value);
  };
  const time = {
    0: {
      label: <p>time</p>,
    },
  };
  const speed = {
    0: {
      label: <p>speed</p>,
    },
  };
  const timeText = times[timesValue]
    ? moment(times[timesValue].seconds * 1000).format("LT")
    : "";
  return (
    <MapControlWrapper>
      <div className="section">
        <p>{<IntlMessages id="app.sessions.options" />}</p>
        <div className="mapActionsContainer">
          <MapStyleSelector onMapStyleChange={onMapStyleChange} />
        </div>
      </div>
      <div className="section">
        <p>{<IntlMessages id="app.sessions.callerRoute" />}</p>
        <h2>{timeText}</h2>
        <Slider
          tooltipVisible={false}
          marks={time}
          value={timesValue}
          min={0}
          max={times.length - 1}
          step={1}
          onChange={onTimesChange}
          disabled={play ? true : false}
        />
        <Slider
          marks={speed}
          min={0}
          max={100}
          step={1}
          value={speedValue}
          defaultValue={50}
          onChange={onSpeedChange}
          disabled={play ? true : false}
        />
        <div className="routeButtons">
          <div className="buttonContainer">
            <Button
              onClick={() => {
                setPlayStatus();
              }}
              type="primary"
              size={"large"}
              shape="circle"
              icon={play ? <PauseOutlined /> : <CaretRightOutlined />}
            />
          </div>
        </div>
      </div>
    </MapControlWrapper>
  );
};
RouteMapControl.propTypes = {
  direction: PropTypes.string,
  setTimesValue: PropTypes.func,
  setSpeedValue: PropTypes.func,
  setPlayStatus: PropTypes.func,
  times: PropTypes.array,
  timesValue: PropTypes.number,
  speedValue: PropTypes.number,
  play: PropTypes.bool,
  onMapStyleChange: PropTypes.func,
};

export default RouteMapControl;
