import React from "react";
import { Radio, InputNumber } from "antd";
import { PropTypes } from "prop-types";
import IntlMessages from "../../components/utility/intlMessages";
import NewCircleHeader from "../animations/newCircleHeader";
import NewCircleItem from "../animations/newCircleItem";
import NewCircleContent from "../animations/newCircleContent";
import { useIntl } from "react-intl";

const RadioGroup = Radio.Group;

const RespondersPolicy = props => {
  const intl = useIntl();
  const onInvitedRespondersPolicyChange = e => {
    props.onResponderPolicyChange(e.target.value);
  };
  const onNumberOfInvitedRespondersChange = value => {
    props.onResponderNumberChange(value);
  };
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };
  const { respondersAssignPolicy, numberOfAssignResponders } = props;

  return (
    <div className="componentInner">
      <NewCircleHeader>
        <h1>{<IntlMessages id="app.settings.respondersHeadline" />}</h1>
        <p>{<IntlMessages id="app.settings.respondersDescription" />}</p>
      </NewCircleHeader>
      <div style={{ padding: "10px 0 30px 0" }}>
        <RadioGroup
          onChange={onInvitedRespondersPolicyChange}
          value={respondersAssignPolicy}
        >
          <NewCircleContent>
            <NewCircleItem itemKey={1}>
              <Radio style={radioStyle} value={"everyone"}>
                {intl.formatMessage({
                  id: "app.settings.inviteAll"
                })}
              </Radio>
            </NewCircleItem>
            <NewCircleItem itemKey={2}>
              <Radio style={radioStyle} value={"closest"}>
                {intl.formatMessage({
                  id: "app.settings.inviteClosest"
                })}
              </Radio>
            </NewCircleItem>
          </NewCircleContent>
        </RadioGroup>
        {respondersAssignPolicy === "closest" ? (
          <div
            style={{
              display: "flex",
              padding: "0 0 0 2em",
              alignItems: "center"
            }}
          >
            <p style={{ paddingRight: "20px", margin: "0px" }}>
              number of responders to invite:{" "}
            </p>
            <InputNumber
              min={1}
              max={10}
              value={numberOfAssignResponders}
              onChange={onNumberOfInvitedRespondersChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

RespondersPolicy.propTypes = {
  onResponderPolicyChange: PropTypes.func,
  onResponderNumberChange: PropTypes.func,
  respondersAssignPolicy: PropTypes.string,
  numberOfAssignResponders: PropTypes.number
};

export default RespondersPolicy;
