import React from "react";
import { PropTypes } from "prop-types";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  SET_ACTIVE_CIRCLE,
  SET_CIRCLES_DASHBOARD_CATEGORY,
  SET_ANIMATE_MEMBERS,
  SET_ANIMATE_RESPONDERS
} from "../../redux/constants";
import { appActions } from "../../redux/reducers/app/reducer";
import CircleSwitcherWrapper from "./circleSwitcher.style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const CircleSwitcher = props => {
  const dispatch = useDispatch();
  const { direction } = props;
  const activeCircleId = useSelector(state => state.LocalState.activeCircleId);
  const circles = useSelector(state => state.Firebase.circles);
  const localState = useSelector(state => state.LocalState);

  const handleMenuClick = ({ key }) => {
    dispatch({
      type: SET_ACTIVE_CIRCLE,
      circleId: key
    });
    dispatch({
      type: appActions.CHANGE_CURRENT,
      current: "overview"
    });
    dispatch({
      type: SET_CIRCLES_DASHBOARD_CATEGORY,
      category: "overview"
    });
    dispatch({ type: SET_ANIMATE_MEMBERS, value: null });
    dispatch({ type: SET_ANIMATE_RESPONDERS, value: null });
  };

  var activeCircle = circles.filter(circle => circle.id === activeCircleId)[0];
  const menu = (
    <Menu onClick={handleMenuClick}>
      {circles.map(circle => (
        <Menu.Item key={circle.id}>
          <Link
            key={circle.id}
            to={"/" + localState.uid + "/dashboard/" + circle.id + "/overview"}
          >
            {circle.name}{" "}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CircleSwitcherWrapper data-rtl={direction}>
      <Dropdown className="circleSwitcher" overlay={menu} trigger={["click"]}>
        <p className="ant-dropdown-link">
          {activeCircle ? activeCircle.name : ""} <DownOutlined />
        </p>
      </Dropdown>
    </CircleSwitcherWrapper>
  );
};

CircleSwitcher.propTypes = {
  members: PropTypes.object,
  direction: PropTypes.string
};

export default CircleSwitcher;
