import {
  REMOVE_CIRCLE,
  FIRESTORE_LISTEN_REQUESTED,
  FIRESTORE_UNLISTEN_REQUESTED,
  ADD_CIRCLE,
  CIRCLES_FETCHED,
  eventTypes
} from "../../constants";
import { store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";
import { put } from "redux-saga/effects";

// listens when user has been added to a circle or removed; handles also initial load; listens to add/delete/leave circle events
export function* setUserCircles(data, userId, metaType) {
  let circleRef = null;
  yield put({
    type: CIRCLES_FETCHED,
    value: true
  });
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    const change = data.snapshot.docChanges()[j].doc.data();
    const type = data.snapshot.docChanges()[j].type;

    // const circleSessionsRef = firebase
    //   .firestore()
    //   .collection("sessions")
    //   .where("invitedCircles", "array-contains", change.circleId)
    //   .where("sessionStatus", "==", "open");

    const circleSessionsIdsRef = firebase
      .firestore()
      .collection("circlesSessions")
      .doc(change.circleId)
      .collection("circleSessions")
      .where("sessionStatus", "==", "open");

    circleRef =
      change.type === "public"
        ? firebase
            .firestore()
            .collection("publicCircles")
            .doc(change.circleId)
        : firebase
            .firestore()
            .collection("privateCircles")
            .doc(change.circleId);
    if (type === eventTypes.ADDED || type === eventTypes.MODIFIED) {
      const circlesMembersRef =
        change.type === "public"
          ? firebase
              .firestore()
              .collection("circlesMembers")
              .doc(change.circleId)
              .collection("members")
              .orderBy("responder", "desc")
              .limit(100)
          : firebase
              .firestore()
              .collection("circlesMembers")
              .doc(change.circleId)
              .collection("members");
      yield put({
        type: ADD_CIRCLE,
        circle: { id: change.circleId },
        circleMembers: [],
        circleId: change.circleId
      });
      const sessionsPriorityRef = firebase
        .firestore()
        .collection("sessionsPriority")
        .doc(change.circleId)
        .collection("sessionPriority");
      const joinRequestsRef = firebase
        .firestore()
        .collection("circlesJoinRequests")
        .doc(change.circleId)
        .collection("requests");

      const circlesAlertsRef = firebase
        .firestore()
        .collection("circlesAlerts")
        .doc(change.circleId)
        .collection("circleAlerts");

      const instantAlertsRef = firebase
        .firestore()
        .collection("instantLocationAlerts")
        .doc(change.circleId)
        .collection("instantAlerts");

      const scheduledAlertsRef = firebase
        .firestore()
        .collection("scheduledLocationAlerts")
        .doc(change.circleId)
        .collection("scheduledAlerts");

      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: circleRef, id: change.circleId },
        meta: { type: "circles" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: circlesMembersRef, id: change.circleId },
        meta: { type: "circleMembers" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: sessionsPriorityRef, id: change.circleId },
        meta: { type: "circleSesionsPriority" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: joinRequestsRef, id: change.circleId },
        meta: { type: "circleJoinRequests" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: circleSessionsIdsRef, id: change.circleId },
        meta: { type: "circleSessionsIds" }
      });

      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: circlesAlertsRef, id: change.circleId },
        meta: { type: "circleAlerts" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: instantAlertsRef, id: change.circleId },
        meta: { type: "instantAlerts" }
      });
      store.dispatch({
        type: FIRESTORE_LISTEN_REQUESTED,
        payload: { ref: scheduledAlertsRef, id: change.circleId },
        meta: { type: "scheduledAlerts" }
      });

      if (change.type === "public") {
        const geoFencesRef = firebase
          .firestore()
          .collection("circlesGeofences")
          .doc(change.circleId)
          .collection("geofences");
        store.dispatch({
          type: FIRESTORE_LISTEN_REQUESTED,
          payload: { ref: geoFencesRef, id: change.circleId },
          meta: { type: "circleGeoFences" }
        });
      }
    } else if (type === "removed") {
      yield put({ type: REMOVE_CIRCLE, id: change.circleId });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circles" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleMembers" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleSesionsPriority" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleJoinRequests" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleSessionsIds" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleGeoFences" }
      });

      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "circleAlerts" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "instantAlerts" }
      });
      yield put({
        type: FIRESTORE_UNLISTEN_REQUESTED,
        id: change.circleId,
        meta: { type: "scheduledAlerts" }
      });
    }
  }
}
