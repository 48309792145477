import styled from "styled-components";
import {palette} from "styled-theme";

const PlansWrapper = styled.div`
  /* background-color: #ffffff; */
  min-height: calc(100vh - 124px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4em;

  .growing {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16) !important;
  }
  .plansContainer {
    /* box-shadow: 0 5px 10px #ffffff !important; */
    border-radius: 10px;
    padding: 2em;
    background-color: ${palette("surfaceColor", 0)};
    border: 1px solid ${palette("border", 0)};
  }
  .firstCol {
    span {
      width: 100%;
      min-width: 300px;
    }
    .ant-row {
      justify-content: flex-start !important;
    }
  }
  .ant-col {
    padding: 2em 0;
    .ant-row {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
    }
  }
  .ant-typography {
    color: ${palette("title", 0)};
  }
  h1 {
    margin: 0;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: ${palette("text", 0)} !important;
  }
  h4 {
    font-size: 15px;
    font-weight: 600;
    color: ${palette("text", 0)} !important;
    width: 100%;
  }
  .check {
    color: ${palette("primary", 0)} !important;
  }
  .close {
    color: ${palette("color", 4)} !important;
  }
  button {
    height: 36px;
    border-radius: 24px;
    padding: 0 20px;
  }

  .button {
    height: 50px !important;
  }
  .headline {
    height: 50px !important;
  }
  .plans {
    padding: 0 0 1em 0 !important;
  }
`;

export default PlansWrapper;
