import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

const SessionComponentWrapper = props => {
  const { show, children, type } = props;
  const shadeVariants = {
    open: {
      opacity: 1,
      width: type === "mobile" ? "100%" : "25%",
      transition: { duration: 0.3 }
    },
    closed: { opacity: 0, width: 0, transition: { duration: 0.3 } }
  };

  const modalVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.15, duration: 0.5 }
    },
    closed: {
      y: 100,
      opacity: 0,
      transition: { duration: 0.1 }
    }
  };

  return (
    <motion.div
      initial="start"
      variants={shadeVariants}
      animate={show ? "open" : "closed"}
      key="shade"
      className="outerContainer"
      style={{
        flex: "0 0 auto",
        margin: show ? "0px" : "0px",
        maxWidth: type === "mobile" ? "100%" : "400px",
        height: type === "mobile" ? "100%" : "initial"
      }}
    >
      <motion.div
        style={{
          height: type === "mobile" ? "100%" : "initial"
        }}
        key="modal"
        variants={modalVariants}
        className="modal"
        animate={show ? "open" : "closed"}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

SessionComponentWrapper.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default SessionComponentWrapper;
