import styled from "styled-components";
import { palette } from "styled-theme";

const MobileOuter = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  min-height: 0px;
  height: 100%;
  background-color: ${palette("background", 0)};
  scrolling: touch;
`;

export default MobileOuter;
