import React from "react";
import PropTypes from "prop-types";
import PaymentApi from "../../api/PaymentApi";
import { Img } from "react-image";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Form, Input, Row, Col, Button } from "antd";
import { withTheme } from "styled-components";
import { motion } from "framer-motion";
import IntlMessages from "../utility/intlMessages";

const override = css`
  position: relative;
  left: -50%;
`;

const contentWrapperVariants = {
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
  hide: { opacity: 0 },
};

class AddPaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ccn: null,
      cvv: null,
      exp: null,
      cardType: "Default",
      cardUrl: "https://files.readme.io/d1a25b4-generic-card.png",
      cardData: null,
      firstName: "",
      lastName: "",
      renderContent: false,
      loading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  static propTypes = {
    form: PropTypes.object,
    card: PropTypes.object,
    onDismiss: PropTypes.func,
    type: PropTypes.string,
    vaultedShopperId: PropTypes.number,
    theme: PropTypes.object,
    onBack: PropTypes.func,
  };
  formRef = React.createRef();

  componentDidMount() {
    const { type, card } = this.props;
    this.loadHostedFieldsScript();
    if (type === "edit") {
      this.setState({
        firstName: card.billingContactInfo.firstName,
        lastName: card.billingContactInfo.lastName,
      });
    }
  }
  loadHostedFieldsScript() {
    let that = this;
    PaymentApi.loadHostedFieldsScript(this, function (token) {
      if (token !== null) {
        that.setState({
          renderContent: true,
        });
      }
    });
  }

  validateCard = (e) => {
    e.preventDefault();
    const { ccn, cvv, exp } = this.state;
    this.formRef.current
      .validateFields(["firstName", "lastName"])
      .then((values) => {
        if (ccn === null && cvv === null && exp === null) {
          this.submitCredentials();
        }
      })
      .catch((errorInfo) => {});
  };

  submitCredentials = () => {
    let that = this;
    const { card, vaultedShopperId } = this.props;
    this.setState({ loading: true });
    window.bluesnap.submitCredentials(function (result) {
      if (null === result.cardData) {
        var errorArray = result.error;
        for (let i = 0; i < errorArray.length; i++) {
          that.setState({
            [errorArray[i].tagId]: errorArray[i].errorDescription,
            loading: false,
          });
        }
      } else {
        PaymentApi.updateVaultedShopperCard(
          vaultedShopperId,
          that.state,
          card,
          result.cardData,
          function () {
            that.setState({ loading: true });
            that.props.onBack();
          }
        );

        //handel resulte and pftoken
      }
    });
  };

  // onChange: (e) => this.handleChange(e, 'note')

  handleInputChange = (e, name) => {
    const { value } = e.target;
    this.setState({ [name]: value });
    this.formRef.current.setFieldsValue({
      [name]: value,
    });
  };

  render() {
    const { firstName, lastName, loading } = this.state;
    const { type, theme } = this.props;

    const primaryTxt =
      type === "new" ? (
        <IntlMessages id="app.buttons.add" />
      ) : (
        <IntlMessages id="app.buttons.update" />
      );
    const pose = this.state.renderContent === true ? "show" : "hide";

    return (
      <div className="outer">
        <motion.div
          className="addMethodContainer"
          animate={pose}
          variants={contentWrapperVariants}
        >
          <Form
            ref={this.formRef}
            className="pricing-form"
            initialValues={{ firstName: firstName, lastName: lastName }}
          >
            <div className="cardDetails">
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label={<IntlMessages id="app.paymount.firstName" />}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => this.handleInputChange(e, "firstName")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<IntlMessages id="app.paymount.lastName" />}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => this.handleInputChange(e, "lastName")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="cardDetailsContainer">
                <div className="cardDetailsInner">
                  <div className="cardDetailsRow">
                    <div className="formItem">
                      <Form.Item
                        label={<IntlMessages id="app.paymount.cardNumber" />}
                      >
                        <div
                          className="ant-input"
                          id="card-number"
                          data-bluesnap="ccn"
                        />
                      </Form.Item>
                      <div className={"ant-form-explain has-error"}>
                        {this.state.ccn}
                      </div>
                    </div>
                  </div>

                  <div className="cardDetailsRow secret">
                    <div className="formItem">
                      <Form.Item
                        label={<IntlMessages id="app.paymount.expDate" />}
                      >
                        <div
                          className="ant-input"
                          id="exp-date"
                          data-bluesnap="exp"
                        />
                      </Form.Item>
                      <div className={"ant-form-explain has-error"}>
                        {this.state.exp}
                      </div>
                    </div>
                    <div className="formItem">
                      <Form.Item
                        label={<IntlMessages id="app.paymount.securityCode" />}
                      >
                        <div
                          className="ant-input"
                          id="cvv"
                          data-bluesnap="cvv"
                        />
                      </Form.Item>
                      <div className={"ant-form-explain has-error"}>
                        {this.state.cvv}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cardDetailsImage">
                  <Form.Item>
                    <Img src={this.state.cardUrl} />
                  </Form.Item>
                </div>
              </div>

              <Row className="buttonContainer">
                <div>
                  <Button
                    style={{ margin: "0 1em" }}
                    onClick={() => {
                      this.props.onBack();
                    }}
                  >
                    <IntlMessages id="app.buttons.cancel" />
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.validateCard}
                    loading={loading}
                  >
                    {primaryTxt}
                  </Button>
                </div>
              </Row>
            </div>
          </Form>
        </motion.div>
        {this.state.renderContent === true ? null : (
          <div
            style={{ position: "absolute", left: "50%", paddingLeft: "100px" }}
          >
            <ClipLoader
              loading={true}
              color={theme.palette["primary"][0]}
              css={override}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTheme(AddPaymentMethod);
