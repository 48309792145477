import styled from "styled-components";
import { palette } from "styled-theme";

const SessionParticipantsWrapper = styled.div`
  .participantsTopContainer {
    h2 {
      margin: 0;
    }
    .iconWrapper {
      margin: ${props =>
        props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
      width: 40px;
      height: 40px;
      background-color: ${palette("surfaceColor", 0)};
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        font-size: 18px;
        color: ${palette("text", 0)};
      }
    }
  }

  .contactsWrapper {
    height: auto;
    .sectionLabel {
      font-size: 16px;
      font-weight: 500;
      background: ${palette("background", 0)};
      line-height: 1.1;
      padding: 15px;
      margin: 0;
      color: ${palette("text", 0)};
    }
  }
`;

export default SessionParticipantsWrapper;
