import {
  FIRESTORE_LISTEN_REQUESTED,
  FIRESTORE_UNLISTEN_REQUESTED,
  metaTypes
} from "../constants";
import { setSessionMemberUpdate } from "./sessions/sessionMembersSagas";
import { setSessionLocationUpdate } from "./sessions/sessionLocationsSagas";
import { setSessionsUpdate } from "./sessions/sessionChangesSagas";
import { setCircleUpdate } from "./circles/circleChangesSaga";
import { setCircleMemberUpdate } from "./circles/circleMembersSagas";
import { setCircleGeoFenceUpdate } from "./circles/circleGeoFencesSagas";
import { setCircleAlertsUpdate } from "./circles/circleAlertsSaga";
import { setInstantAlertsUpdate } from "./circles/circleInstantAlertsSaga";
import { setsSheduledAlertsUpdate } from "./circles/circleSheduledAlertsSaga";
import { setCircleSessionPriorityUpdate } from "./circles/circleSessionPrioritySaga";
import { setCircleJoinRequestUpdate } from "./circles/circleJoinRequestsSaga";
import { setCircleSessionsIds } from "./circles/circleSessionsIdsSaga";
import { setCircleSessionsUpdate } from "./circles/circleSessionsSaga";
import { setUserCircles } from "./circles/userCirclesSagas";
import { setUserEntities } from "./entities/userEntitiesSagas";
import { setUserDataUpdate } from "./user/userDataSagas";
import { setVaultedDataUpdate } from "./vaultedData/vaultedDataSagas";
import { setActivePlanUpdate } from "./activePlan/activePlanSaga";
import { setUserSubscriptionsUpdate } from "./userSubscriptions/userSubscriptionsSaga";
import { setPlansUpdate } from "./plans/plansSagas";
import { setUserAlertsUpdate } from "./alerts/userAlertsSagas";
import { eventChannel, buffers } from "redux-saga";
import { take, call, fork, cancel } from "redux-saga/effects";

export function createEventChannel(id, ref) {
  const listener = eventChannel(emit => {
    var unsub = ref.onSnapshot(snapshot => {
      emit({
        id: id,
        snapshot: snapshot
      });
    });
    return () => {
      unsub();
    };
  }, buffers.expanding(1));
  return listener;
}

export function* getDataAndListenToChannel(ref, id, metaType) {
  const chan = yield call(createEventChannel, id, ref);
  try {
    while (true) {
      const data = yield take(chan);
      switch (metaType) {
        case metaTypes.sessionMembers:
          yield call(setSessionMemberUpdate, data, id, metaType);
          break;
        case metaTypes.sessionLocations:
          yield call(setSessionLocationUpdate, data, id, metaType);
          break;
        case metaTypes.sessions:
          yield call(setSessionsUpdate, data, id, metaType);
          break;
        case metaTypes.userEntities:
          yield call(setUserEntities, data, id, metaType);
          break;
        case metaTypes.circles:
          yield call(setCircleUpdate, data, id, metaType);
          break;
        case metaTypes.circleMembers:
          yield call(setCircleMemberUpdate, data, id, metaType);
          break;
        case metaTypes.circleGeoFences:
          yield call(setCircleGeoFenceUpdate, data, id, metaType);
          break;
        case metaTypes.circleAlerts:
          yield call(setCircleAlertsUpdate, data, id, metaType);
          break;
        case metaTypes.instantAlerts:
          yield call(setInstantAlertsUpdate, data, id, metaType);
          break;
        case metaTypes.scheduledAlerts:
          yield call(setsSheduledAlertsUpdate, data, id, metaType);
          break;
        case metaTypes.circleSesionsPriority:
          yield call(setCircleSessionPriorityUpdate, data, id, metaType);
          break;
        case metaTypes.circleJoinRequests:
          yield call(setCircleJoinRequestUpdate, data, id, metaType);
          break;
        case metaTypes.circleSessionsIds:
          yield call(setCircleSessionsIds, data, id, metaType);
          break;
        case metaTypes.circleSessions:
          yield call(setCircleSessionsUpdate, data, id, metaType);
          break;
        case metaTypes.userCircles:
          yield call(setUserCircles, data, id, metaType);
          break;
        case metaTypes.userData:
          yield call(setUserDataUpdate, data, id, metaType);
          break;
        case metaTypes.vaultedData:
          yield call(setVaultedDataUpdate, data, id, metaType);
          break;
        case metaTypes.userSubscriptions:
          yield call(setUserSubscriptionsUpdate, data, id, metaType);
          break;
        case metaTypes.activePlan:
          yield call(setActivePlanUpdate, data, id, metaType);
          break;
        case metaTypes.plans:
          yield call(setPlansUpdate, data, id, metaType);
          break;
        case metaTypes.userAlerts:
          yield call(setUserAlertsUpdate, data, id, metaType);
          break;
        default:
          return;
      }
    }
  } finally {
    chan.close();
  }
}

export function* addListener(ref, id, metaType) {
  let task = yield fork(getDataAndListenToChannel, ref, id, metaType);
  while (true) {
    const action = yield take([FIRESTORE_UNLISTEN_REQUESTED]);
    if (
      action.meta.type === metaType &&
      action.id !== undefined &&
      action.id === id
    ) {
      yield cancel(task);
    }
  }
}

export function* firestoreWatchListener(metaType) {
  while (true) {
    const listenRequestAction = yield take(FIRESTORE_LISTEN_REQUESTED);
    if (listenRequestAction.meta.type === metaType) {
      yield fork(
        addListener,
        listenRequestAction.payload.ref,
        listenRequestAction.payload.id,
        metaType
      );
    }
  }
}
