import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/utility/intlMessages";
import { Switch } from "antd";
import NewCircleHeader from "../animations/newCircleHeader";
import NewCircleItem from "../animations/newCircleItem";
import NewCircleContent from "../animations/newCircleContent";

const CircleType = props => {
  const setCircleEmergency = value => {
    props.setCircleEmergency(value);
  };

  return (
    <div className="componentInner">
      <NewCircleHeader>
        <h1>{<IntlMessages id="app.settings.emergencyHeadline" />}</h1>
        <p>{<IntlMessages id="app.settings.emergencyDescription" />}</p>
      </NewCircleHeader>
      <div style={{ padding: "10px 0 30px 0" }}>
        <NewCircleContent>
          <NewCircleItem itemKey={1}>
            <div className="switchContainer">
              <p>Emergency Circle</p>
              <Switch checked={props.emergency} onChange={setCircleEmergency} />
            </div>
          </NewCircleItem>
        </NewCircleContent>
      </div>
    </div>
  );
};

CircleType.propTypes = {
  setCircleEmergency: PropTypes.func,
  emergency: PropTypes.bool
};

export default CircleType;
