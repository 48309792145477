import styled from "styled-components";
import { palette } from "styled-theme";

const SettingsMenuWrapper = styled.div`
  border: solid ${palette("border", 0)} 0px;
  border-left-width: ${props =>
    props["data-rtl"] === "rtl" &&
    props["settings"] === true &&
    props["mobile"] !== true
      ? "1px"
      : "0px"};
  border-right-width: ${props =>
    props["data-rtl"] !== "rtl" &&
    props["settings"] === true &&
    props["mobile"] !== true
      ? "1px"
      : "0px"};
  width: 250px;

  .settingMenu {
    transition: all 0.3s ease;
    padding: 6px 0 0 0;
    background-color: transparent !important;
    .ant-menu-item {
      color: ${palette("text", 0)} !important;
      &:hover {
        color: ${palette("primary", 0)} !important;
      }
    }
  }
  .ant-layout-sider {
    background-color: transparent;
  }
  .settingMenu .ant-menu-item-selected {
    background-color: transparent !important;
    span {
      color: ${palette("primary", 0)} !important;
    }
    &:hover {
      color: ${palette("onPrimaryText", 0)} !important;
    }
  }
  .settingMenu .ant-menu-item:hover {
    color: ${palette("primary", 0)};
  }
  .settingMenu .ant-menu-item:after {
    border-right: 0px solid ${palette("primary", 0)};
  }
`;

export default SettingsMenuWrapper;
