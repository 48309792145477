import {
  ADD_SESSION,
  REMOVE_SESSION,
  SESSION_TYPING_EVENTS_FETCH_SUCCESS,
  ADD_SESSION_CHAT_TYPING_EVENT,
  REMOVE_SESSION_CHAT_TYPING_EVENT
} from "../../constants";

const INITIAL_STATE = {};

function chatTypingEvents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_SESSION:
      return {
        ...state,
        [action.id]: state[action.id] !== undefined ? state[action.id] : []
      };
    case REMOVE_SESSION: {
      const key = action.id;
      const { [key]: _, ...newData } = state.sessions;
      return {
        ...newData
      };
    }

    case SESSION_TYPING_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        [action.sessionId]: action.typingEvents
      };

    case ADD_SESSION_CHAT_TYPING_EVENT:
      return {
        ...state,
        [action.sessionId]: [
          ...state[action.sessionId].filter(
            event => event.userId !== action.userId
          ),
          action.event
        ]
      };

    case REMOVE_SESSION_CHAT_TYPING_EVENT:
      return {
        ...state,
        [action.sessionId]: state[action.sessionId].filter(
          member => member.id !== action.id
        )
      };
    default:
      return state;
  }
}

export default chatTypingEvents;
