// import initialState from '../store/initialState';
import {ADD_VAULTED_DATA} from "../../constants";
const INITIAL_STATE = {
  data: {}
};
const vaultedData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_VAULTED_DATA:
      return {
        ...state,
        data: action.data
      };

    default:
      return state;
  }
};
export default vaultedData;
