import React from "react";
import PropTypes from "prop-types";
import ActionButtonWrapper from "./actionButtonWrapper.style";
import { Button } from "antd";

import { withTheme } from "styled-components";

const ActionButton = React.forwardRef((props, ref) => {
  const { direction, title, icon, primary, flat, onClick, theme } = props;
  const flatBg = primary
    ? theme.palette["onSecondaryBg"][0]
    : theme.palette["background"][0];
  const flatTextBg = primary
    ? theme.palette["secondary"][0]
    : theme.palette["text"][0];
  const onApproveClicked = () => {
    return null;
  };
  return (
    <ActionButtonWrapper
      ref={ref}
      flatBg={flatBg}
      flatTextBg={flatTextBg}
      data-rtl={direction}
      primary={primary}
      className="actionButtonWrapper"
      onClick={onClick}
    >
      {flat ? (
        <Button
          onClick={onApproveClicked}
          type={primary ? "primary" : ""}
          style={{ width: "100%" }}
          className={"light"}
        >
          {title}
        </Button>
      ) : (
        <div className="actionButtonWrapper">
          <p className="title">{title}</p>
          <div className="icon">{icon}</div>
        </div>
      )}
    </ActionButtonWrapper>
  );
});

ActionButton.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.object,
  primary: PropTypes.bool,
  flat: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme(ActionButton);
