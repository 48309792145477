import { ADD_PLANS, PLANS_FETCHED } from "../../constants";
import { put } from "redux-saga/effects";

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setPlansUpdate(data, sessionId, metaType) {
  yield put({
    type: PLANS_FETCHED,
    value: true
  });
  const plans = data.snapshot.data();
  if (plans !== undefined) {
    yield put({ type: ADD_PLANS, data: plans });
  }
}
