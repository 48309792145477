import React from "react";
import PropTypes from "prop-types";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Ico = props => {
  const { name } = props;
  return name === "check" ? (
    <CheckCircleOutlined className={name} />
  ) : (
    <CloseCircleOutlined className={name} />
  );
};

Ico.propTypes = {
  name: PropTypes.string
};

export default Ico;
