import { ADD_SUBSCRIPTION } from "../../constants";

const INITIAL_STATE = [];

function subscriptions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_SUBSCRIPTION:
      return [
        ...state.filter(
          item => item.subscription.subscriptionId !== action.subscriptionId
        ),
        action.subscription
      ];

    default:
      return state;
  }
}

export default subscriptions;
