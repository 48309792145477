import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Select } from "antd";
import countries from "./countries";
import languages from "./languages";
import themes from "./themes";
import plans from "./plans";
import { Form, Typography, Button } from "antd";
import Backend from "../../api/Backend";
import { CHANGE_LANGUAGE } from "../../redux/constants";
import { getCurrentLanguage } from "../../config.js";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";
import IntlMessages from "../utility/intlMessages";
import { history } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getIsEntity } from "../../redux/selectors/user";

const Option = Select.Option;
const { Text } = Typography;

const Account = (props) => {
  const { data } = props;
  const activePlan = useSelector((state) => state.Firebase.activePlan);
  const localState = useSelector((state) => state.LocalState);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(null);
  const [country, setCountry] = useState(null);
  const [plan, setPlan] = useState("personal");
  const [theme, setTheme] = useState("light");
  const isEntity = useSelector((state) => getIsEntity(state));

  let formRef = React.createRef();

  useEffect(() => {
    const updatedCountry = data.general_info.country
      ? data.general_info.country
      : null;
    const updatedPlan =
      data.settings && data.settings.planName ? data.settings.planName : "free";
    const updatedLanguage =
      data.settings && data.settings.language
        ? data.settings.language.text
        : null;
    const updatedTheme =
      data.settings && data.settings.theme ? data.settings.theme : "light";
    setLanguage(updatedLanguage);
    setCountry(updatedCountry);
    setPlan(updatedPlan);
    setTheme(updatedTheme);

    formRef.current.setFieldsValue({
      country: updatedCountry,
      plan: updatedPlan,
      language: updatedLanguage,
      theme: updatedTheme,
    });
  }, [data]);

  const updateFields = (info) => {
    const updatedCountry = info.general_info.country
      ? info.general_info.country
      : null;
    const updatedPlan =
      info.settings && info.settings.planName ? info.settings.planName : "free";
    const updatedLanguage =
      info.settings && info.settings.language
        ? info.settings.language.text
        : null;
    const updatedTheme =
      info.settings && info.settings.theme ? info.settings.theme : "light";
    setLanguage(updatedLanguage);
    setCountry(updatedCountry);
    setPlan(updatedPlan);
    setTheme(updatedTheme);

    formRef.current.setFieldsValue({
      country: updatedCountry,
      plan: updatedPlan,
      language: updatedLanguage,
      theme: updatedTheme,
    });
  };

  const handleSelectChange = (value, field) => {
    if (field === "country") {
      setCountry(value);
    } else if (field === "plan") {
      setPlan(value);
    } else if (field === "theme") {
      setTheme(value);
    } else if (field === "language") {
      setLanguage(value);
    }
    formRef.current.setFieldsValue({
      [field]: value,
    });
  };

  const onDeclineClicked = () => {
    updateFields(data);
  };

  const onFinish = (event) => {
    event.preventDefault();
    const values = formRef.current.getFieldsValue();
    const lan = values.language;
    var updatedLanguage = lan
      ? languages.filter((item) => item.value === lan)[0]["language"]
      : null;
    if (updatedLanguage) {
      document.documentElement.dir = updatedLanguage.direction;
      const selected = getCurrentLanguage(updatedLanguage.languageId);
      dispatch({
        type: CHANGE_LANGUAGE,
        language: selected,
      });
    }
    const country = values.country;
    const theme = values.theme;
    const text = "Account data was updated successfully!";
    Backend.updateAccount(
      null,
      null,
      null,
      null,
      null,
      null,
      updatedLanguage,
      country,
      theme,
      text
    );
  };

  const modifyPlan = () => {
    history.push(`/${localState.uid}/plans`);
  };
  const newCountry =
    data.general_info && data.general_info.country
      ? data.general_info.country
      : null;
  const newPlan =
    data.settings && data.settings.planName ? data.settings.planName : "free";
  const newLanguage =
    data.settings && data.settings.language
      ? data.settings.language.text
      : null;
  const newTheme =
    data.settings && data.settings.theme ? data.settings.theme : "light";
  const isSame =
    newCountry === country &&
    newPlan === plan &&
    newLanguage === language &&
    newTheme === theme;

  const languagesOptions = [];
  for (let i = 0; i < languages.length; i++) {
    languagesOptions.push(
      <Option key={i} value={languages[i].value}>
        {languages[i].value}
      </Option>
    );
  }

  const countriesOptions = [];
  for (let i = 0; i < countries.length; i++) {
    countriesOptions.push(
      <Option key={i} value={countries[i].country}>
        {countries[i].country}
      </Option>
    );
  }

  const themesOptions = [];
  for (let i = 0; i < themes.length; i++) {
    themesOptions.push(
      <Option key={i} value={themes[i].theme}>
        {themes[i].theme}
      </Option>
    );
  }

  const plansOptions = [];
  for (let i = 0; i < plans.length; i++) {
    plansOptions.push(
      <Option key={i} value={plans[i].value}>
        {plans[i].plan}
      </Option>
    );
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter style={{ flexDirection: "column", padding: "20px" }}>
          <div className="title">
            <h1 className="fieldTitle">
              <IntlMessages id="app.settings.account" />
            </h1>
          </div>

          <Form onFinish={onFinish} ref={formRef} className="pricing-form">
            <div className="formFields">
              <Form.Item
                label={<IntlMessages id="app.settings.language" />}
                {...formItemLayout}
                name="language"
                rules={[
                  {
                    required: true,
                    message: "Please select your language!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ padding: "0px" }}
                  placeholder="Select your language"
                  optionFilterProp="children"
                  onChange={(value) => handleSelectChange(value, "language")}
                >
                  {languagesOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label={<IntlMessages id="app.settings.country" />}
                {...formItemLayout}
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select your country"
                  optionFilterProp="children"
                  onChange={(value) => handleSelectChange(value, "country")}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countriesOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label={<IntlMessages id="app.settings.theme" />}
                {...formItemLayout}
                name="theme"
                rules={[
                  {
                    required: true,
                    message: "Please select your theme!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select your theme"
                  optionFilterProp="children"
                  onChange={(value) => handleSelectChange(value, "theme")}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {themesOptions}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </CategoryCenter>
        <div className="buttonsContainer">
          <Button onClick={onDeclineClicked}>
            <IntlMessages id="app.buttons.cancel" />
          </Button>
          <Button
            style={{ margin: "0 1em" }}
            disabled={isSame}
            onClick={onFinish}
            type="primary"
          >
            <IntlMessages id="app.buttons.submit" />
          </Button>
        </div>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

Account.propTypes = {
  data: PropTypes.object,
  activePlan: PropTypes.object,
  localState: PropTypes.object,
};

export default Account;

// <Form.Item
//   label={<IntlMessages id="app.settings.plan" />}
//   {...formItemLayout}
//   style={{ marginBottom: "0px" }}
//   name="plan"
//   rules={[
//     {
//       required: true,
//       message: "Please input your plan!"
//     }
//   ]}
// >
//   <div>
//     <Text>{activePlan ? activePlan.planName : "free"} </Text>
//     <Button
//       style={{ margin: "0 1em" }}
//       onClick={modifyPlan}
//       disabled={isEntity}
//     >
//       <IntlMessages id="app.buttons.change" />
//     </Button>
//   </div>
// </Form.Item>
