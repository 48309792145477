import base64 from "base-64";
import firebase from "firebase/app";
import "firebase/firestore";
import Backend from "./Backend";
import { store } from "../redux/store";
import { store as notificationsStore } from "react-notifications-component";

class PaymentApi {
  pfToken = null;
  cardUrl = {
    AmericanExpress: "https://files.readme.io/97e7acc-Amex.png",
    CarteBleau: "https://files.readme.io/5da1081-cb.png",
    DinersClub: "https://files.readme.io/8c73810-Diners_Club.png",
    Discover: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MaestroUK: "https://files.readme.io/daeabbd-Maestro.png",
    MasterCard: "https://files.readme.io/5b7b3de-Mastercard.png",
    Solo:
      "https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png",
    Visa: "https://files.readme.io/9018c4f-Visa.png",
    Default: "https://files.readme.io/d1a25b4-generic-card.png"
  };

  loadHostedFieldsScript(that, callback) {
    const existingScript = document.getElementById("bluesnapHostedFields");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        process.env.REACT_APP_BLUESNAP_URL + "/source/web-sdk/bluesnap.js"; // URL for the third-party library being loaded.
      script.id = "bluesnapHostedFields"; // e.g., googleMaps or stripe
      document.body.appendChild(script);

      script.onload = scr => {
        this.launchBlueSnap(that, function(token) {
          if (token !== null) {
            callback(token);
          } else {
            callback(null);
          }
        });
      };
    } else {
      this.launchBlueSnap(that, function(token) {
        if (token !== null) {
          callback(token);
        } else {
          callback(null);
        }
      });
    }
  }

  launchBlueSnap(that, callback) {
    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL + "/services/2/payment-fields-tokens"; //
    return fetch(proxyurl + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      }
    })
      .then(response => {
        const url =
          process.env.REACT_APP_BLUESNAP_URL +
          "/services/2/payment-fields-tokens/";
        var token = response.headers.get("Location").replace(url, "");
        this.pfToken = token;
        this.runHostedScript(token, that);
        callback(token);
      })
      .catch(error => {
        callback(null);
        console.error(error);
      });
  }

  runHostedScript(token, that) {
    var cardUrl = this.cardUrl;
    var bsObj = this.getBsObj(that, cardUrl);
    //Run the following command after Document Object Model (DOM) is fully loaded
    window.bluesnap.hostedPaymentFieldsCreation(token, bsObj);
  }

  getBsObj(that, cardUrl) {
    var bsObj = {
      onFieldEventHandler: {
        onFocus: function(tagId) {
          // Handle focus
          // console.log("onFocus" + tagId);
        },
        onBlur: function(tagId) {
          // Handle blur
          // console.log("onBlur" + tagId);
        },
        onError: function(tagId, errorCode, errorDescription) {
          // Handle a change in validation by displaying help text
          that.setState({
            [tagId]: errorDescription,
            cardType: "Default",
            cardUrl: cardUrl["Default"]
          });
        },
        onType: function(tagId, cardType, cardData) {
          // get card type from cardType and display card image
          that.setState({
            cardType: cardType,
            cardUrl: cardUrl[cardType],
            cardData: cardData
          });
        },
        onValid: function(tagId) {
          // Handle a change in validation by removing any help text
          that.setState({ [tagId]: null });
        }
      },
      ccnPlaceHolder: "4111222233334444",
      cvvPlaceHolder: "123",
      expPlaceHolder: "MM/YY",
      expDropDownSelector: false //set to true for
    };
    return bsObj;
  }

  createVaultedShopper(state, callback) {
    var vaultedShopper = {
      firstName: state.firstName,
      lastName: state.lastName,
      companyName: state.companyName,
      country: state.country,
      city: state.city,
      address: state.address,
      zip: state.zip,
      shopperCurrency: "USD",
      email: state.email,
      shippingContactInfo: {
        firstName: state.firstName,
        lastName: state.lastName,
        address1: state.address,
        country: state.country,
        city: state.city,
        zip: state.zip
      }
    };
    if (this.pfToken) {
      vaultedShopper["paymentSources"] = {
        creditCardInfo: [
          {
            pfToken: this.pfToken
          }
        ]
      };
    }

    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL + "/services/2/vaulted-shoppers"; //

    return fetch(proxyurl + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      },
      body: JSON.stringify(vaultedShopper)
    })
      .then(response => response.json())
      .then(data => {
        firebase
          .firestore()
          .collection("vaultedShoppers")
          .doc(Backend.uid)
          .set(data)
          .then(() => {
            notificationsStore.addNotification({
              title: "",
              message: "card was updated successfully!",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false
              }
            });
            callback(data);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(error => {
        notificationsStore.addNotification({
          title: "",
          message: "there was an error updating your card. please try again!",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });

        callback(null);
        console.error(error);
      });
  }

  updateVaultedShopperData(values, vaultedShopperId, callback) {
    this.getVaultedShopperData(vaultedShopperId, function(vaultedShopper) {
      if (vaultedShopper !== null) {
        vaultedShopper["email"] = values.email;
        vaultedShopper["firstName"] = values.firstName;
        vaultedShopper["lastName"] = values.lastName;
        vaultedShopper["companyName"] = values.companyName;
        vaultedShopper["address"] = values.address;
        vaultedShopper["city"] = values.city;
        vaultedShopper["country"] = values.country;
        vaultedShopper["zip"] = values.zip;

        const proxyurl = process.env.REACT_APP_PROXY_URL;
        const url =
          process.env.REACT_APP_BLUESNAP_URL + "/services/2/vaulted-shoppers/"; //

        return fetch(proxyurl + url + vaultedShopperId, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Basic " +
              base64.encode(
                process.env.REACT_APP_BLUESNAP_ID +
                  ":" +
                  process.env.REACT_APP_BLUESNAP_PASSWORD
              )
          },
          body: JSON.stringify(vaultedShopper)
        })
          .then(response => response.json())
          .then(data => {
            firebase
              .firestore()
              .collection("vaultedShoppers")
              .doc(Backend.uid)
              .set(data)
              .then(() => {
                notificationsStore.addNotification({
                  title: "",
                  message: "billing information was updates successfully!",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: false
                  }
                });
                callback(data);
              })
              .catch(err => {
                console.log(err);
                callback(null);
              });
          })
          .catch(error => {
            console.error(error);
            callback(null);
          });
      } else {
        console.log("error");
        callback(null);
      }
    });
  }

  updateVaultedShopperCard(vaultedShopperId, state, card, result, callback) {
    let that = this;
    this.getVaultedShopperData(vaultedShopperId, function(vaultedShopper) {
      if (vaultedShopper !== null) {
        const creditCards =
          card !== null && vaultedShopper["paymentSources"]["creditCardInfo"]
            ? vaultedShopper["paymentSources"]["creditCardInfo"].filter(
                item =>
                  item.creditCard["cardLastFourDigits"] !==
                    result.last4Digits ||
                  (item.creditCard["cardLastFourDigits"] ===
                    result.last4Digits &&
                    item.creditCard["cardType"] !== result.ccType)
              )
            : vaultedShopper["paymentSources"]["creditCardInfo"]
            ? vaultedShopper["paymentSources"]["creditCardInfo"]
            : [];
        creditCards.push({ pfToken: that.pfToken });
        vaultedShopper["paymentSources"]["creditCardInfo"] = creditCards;
        vaultedShopper["firstName"] = state.firstName;
        vaultedShopper["lastName"] = state.lastName;
        const proxyurl = process.env.REACT_APP_PROXY_URL;
        const url =
          process.env.REACT_APP_BLUESNAP_URL + "/services/2/vaulted-shoppers/"; //

        return fetch(proxyurl + url + vaultedShopperId, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Basic " +
              base64.encode(
                process.env.REACT_APP_BLUESNAP_ID +
                  ":" +
                  process.env.REACT_APP_BLUESNAP_PASSWORD
              )
          },
          body: JSON.stringify(vaultedShopper)
        })
          .then(response => response.json())
          .then(data => {
            firebase
              .firestore()
              .collection("vaultedShoppers")
              .doc(Backend.uid)
              .set(data)
              .then(() => {
                notificationsStore.addNotification({
                  title: "",
                  message: "card was updated successfully!",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: false
                  }
                });
                callback(true);
              })
              .catch(err => {
                notificationsStore.addNotification({
                  title: "",
                  message:
                    "there was an error updating your card. please try again!",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });

                callback(null);
                console.log(err);
              });
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        console.log("error");
      }
    });
  }

  getVaultedShopperData(vaultedShopperId, callback) {
    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL + "/services/2/vaulted-shoppers/"; //

    return fetch(proxyurl + url + vaultedShopperId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      }
    })
      .then(response => response.json())
      .then(body => {
        callback(body);
      })

      .catch(error => {
        callback(null);
        console.error(error);
      });
  }

  createGrowingSubscription(vaultedShopperId, callback) {
    const planId = 2516555;
    const planName = "growing";
    var obj = {
      vaultedShopperId: vaultedShopperId,
      planId: planId
    };

    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL +
      "/services/2/recurring/subscriptions"; //

    return fetch(proxyurl + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      },
      body: JSON.stringify(obj)
    })
      .then(response => response.json())
      .then(data => {
        const activePlan = store.getState().Firebase.activePlan;
        if (activePlan) {
          activePlan[
            "canceledAt"
          ] = firebase.firestore.FieldValue.serverTimestamp();
        }
        let batch = firebase.firestore().batch();
        const usersActivePlan = firebase
          .firestore()
          .collection("usersActivePlan")
          .doc(Backend.uid);
        const usersCanceledPlans = firebase
          .firestore()
          .collection("usersCanceledPlans")
          .doc(Backend.uid)
          .collection("plans")
          .doc();
        const usersSubscriptions = firebase
          .firestore()
          .collection("usersSubscriptions")
          .doc(Backend.uid)
          .collection("subscriptions")
          .doc(data.subscriptionId.toString());
        batch.set(usersActivePlan, {
          planName: planName,
          joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
          subscriptionId: data.subscriptionId
        });
        batch.set(usersSubscriptions, {
          subscription: data,
          joinedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
        if (activePlan) {
          batch.set(usersCanceledPlans, activePlan);
        }
        batch
          .commit()
          .then(function() {
            callback(true);
            console.log("subscription was added successfully");
          })
          .catch(err => {
            callback(null);
            console.log(err);
          });
      })
      .catch(error => {
        console.error(error);
      });
  }

  updateSubscriptionStatus(
    subscriptionId,
    status,
    currentPlan,
    newPlan,
    callabck
  ) {
    const obj = {
      status: status
    };
    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL +
      "/services/2/recurring/subscriptions/"; //

    return fetch(proxyurl + url + subscriptionId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      },
      body: JSON.stringify(obj)
    })
      .then(response => response.json())
      .then(data => {
        const activePlan = store.getState().Firebase.activePlan;
        if (activePlan) {
          activePlan[
            "canceledAt"
          ] = firebase.firestore.FieldValue.serverTimestamp();
        }
        let batch = firebase.firestore().batch();
        const usersActivePlan = firebase
          .firestore()
          .collection("usersActivePlan")
          .doc(Backend.uid);
        const usersCanceledPlans = firebase
          .firestore()
          .collection("usersCanceledPlans")
          .doc(Backend.uid)
          .collection("plans")
          .doc();
        const usersSubscriptions = firebase
          .firestore()
          .collection("usersSubscriptions")
          .doc(Backend.uid)
          .collection("subscriptions")
          .doc(data.subscriptionId.toString());
        if (status === "CANCELED") {
          batch.set(usersActivePlan, {
            planName: newPlan,
            joinedAt: firebase.firestore.FieldValue.serverTimestamp()
          });
          batch.set(
            usersSubscriptions,
            {
              subscription: data,
              canceledAt: firebase.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          );
          if (activePlan) {
            batch.set(usersCanceledPlans, activePlan);
          }
        } else {
          batch.set(usersActivePlan, {
            planName: currentPlan,
            joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
            subscriptionId: data.subscriptionId
          });
          batch.set(
            usersSubscriptions,
            {
              subscription: data,
              joinedAt: firebase.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          );
        }

        batch
          .commit()
          .then(function() {
            callabck(true);
            console.log("subscription was updated successfully");
          })
          .catch(err => {
            callabck(null);
            console.log(err);
          });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getSubscription(subscriptionId, callback) {
    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL +
      "/services/2/recurring/subscriptions/"; //

    return fetch(proxyurl + url + subscriptionId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      }
    })
      .then(response => response.json())
      .then(body => {
        callback(body);
      })

      .catch(error => {
        callback(null);
        console.error(error);
      });
  }

  createPlan(chargeFrequency, name, currency, recurringChargeAmount) {
    var obj = {
      chargeFrequency: chargeFrequency,
      name: name,
      currency: currency,
      recurringChargeAmount: recurringChargeAmount
    };

    const proxyurl = process.env.REACT_APP_PROXY_URL;
    const url =
      process.env.REACT_APP_BLUESNAP_URL + "/services/2/recurring/plans"; //

    return fetch(proxyurl + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          base64.encode(
            process.env.REACT_APP_BLUESNAP_ID +
              ":" +
              process.env.REACT_APP_BLUESNAP_PASSWORD
          )
      },
      body: JSON.stringify(obj)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePlan(newPlan, callback) {
    const plan = store.getState().Firebase.activePlan;
    if (plan.subscriptionId) {
      this.updateSubscriptionStatus(
        plan.subscriptionId,
        "CANCELED",
        plan.planName,
        newPlan,
        function(result) {
          if (result !== null) {
            callback(true);
          } else {
            callback(null);
          }
        }
      );
    } else {
      const activePlan = store.getState().Firebase.activePlan;
      if (activePlan) {
        activePlan[
          "canceledAt"
        ] = firebase.firestore.FieldValue.serverTimestamp();
      }
      let batch = firebase.firestore().batch();
      const usersActivePlan = firebase
        .firestore()
        .collection("usersActivePlan")
        .doc(Backend.uid);
      const usersCanceledPlans = firebase
        .firestore()
        .collection("usersCanceledPlans")
        .doc(Backend.uid)
        .collection("plans")
        .doc();

      batch.set(usersActivePlan, {
        planName: newPlan,
        joinedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      if (activePlan) {
        batch.set(usersCanceledPlans, activePlan);
      }
      batch
        .commit()
        .then(function() {
          console.log("changed to ", newPlan);
          callback(true);
        })
        .catch(err => {
          callback(null);
          console.log(err);
        });
    }
  }

  getUsageMonths() {
    var namedMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var from = new Date(firebase.auth().currentUser.metadata.creationTime);
    var to = new Date();
    let monthsArray = [];
    // let months = 0;

    //While year or month mismatch, reduce by one day
    while (
      to.getFullYear() !== from.getFullYear() ||
      to.getMonth() !== from.getMonth()
    ) {
      var oldmonth = from.getMonth();
      from = new Date(from.getTime() + 86400000);
      //if we enter into new month, add to month counter
      if (oldmonth !== from.getMonth()) {
        //Call callback function with month
        monthsArray.push(namedMonths[from.getMonth()]);
        // months++;
      }
    }
    var unique = monthsArray.filter((v, i, a) => a.indexOf(v) === i);
    return unique;
  }

  getUsageYears() {
    var from = new Date(firebase.auth().currentUser.metadata.creationTime);
    var to = new Date();
    const start = from.getFullYear();
    const end = to.getFullYear();
    const arr = [];
    var i = start;
    for (i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  }

  getUsageBetweenDates(start, end) {
    return new Promise(resolve => {
      let usage = [];
      firebase
        .firestore()
        .collection("usersUsage")
        .doc(Backend.uid)
        .collection("dailyUsage")
        .where("createdAt", ">", start)
        .where("createdAt", "<", end)
        .get()
        .then(UsageSnap => {
          if (!UsageSnap.empty) {
            UsageSnap.forEach(doc => {
              const item = doc.data();
              item["key"] = item.createdAt.seconds.toString();
              const date = new Date(
                item.createdAt.seconds * 1000
              ).toLocaleDateString("en-US");
              item["date"] = date;
              usage.push(item);
            });
            resolve(usage);
          } else {
            resolve(null);
          }
        });
    });
  }
}

export default new PaymentApi();
