import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BubbleLeft,
  BubbleRight,
  BubbleImgRight,
  BubbleImgLeft,
  Update
} from "./messagesContainers";
import Backend from "../../api/Backend";
import TypingComponent from "./TypingComponent";
import { withTheme } from "styled-components";
import { motion } from "framer-motion";

const scrollToBottom = () => {
  const messagesDiv = document.getElementById("messages");
  messagesDiv.scrollTop = messagesDiv.scrollHeight;
};

const messagesContainerVariants = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      staggerDirection: -1
    }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const messageVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  hidden: {
    opacity: 0
  }
};

const ChatMessages = props => {
  const [animate, setAnimate] = useState(false);
  const {
    messages,
    showEmojiPicker,
    sessionChatTypingEvents,
    sessionsMembers,
    sessionId
  } = props;
  const onImageClick = message => {
    props.onImageClick(message);
  };

  useEffect(() => {
    let mounted = true;
    scrollToBottom();
    setTimeout(function() {
      if (mounted) {
        setAnimate(true);
      }
    }, 750);
    return () => {
      mounted = false;
    };
  }, [messages]);

  useEffect(() => {
    let mounted = true;
    setTimeout(function() {
      if (mounted) {
        scrollToBottom();
      }
    }, 300);
    return () => {
      mounted = false;
    };
  }, [showEmojiPicker]);

  var uid = Backend.uid;
  var chatMessages = messages ? messages : [];
  return (
    <div id="messages" className="chat-messages-inner">
      <motion.ul
        variants={messagesContainerVariants}
        animate={animate ? "open" : "closed"}
        initial="hidden"
      >
        {chatMessages.map(message => (
          <motion.li key={message._id} variants={messageVariants}>
            {message.type ? (
              <Update
                direction={props.direction}
                message={message}
                listType={props.listType}
                sessionId={props.sessionId}
                ownerId={props.ownerId}
              />
            ) : message.user._id === uid ? (
              message.image ? (
                <BubbleImgRight
                  direction={props.direction}
                  message={message}
                  onImageClick={onImageClick}
                />
              ) : (
                <BubbleRight direction={props.direction} message={message} />
              )
            ) : message.image ? (
              <BubbleImgLeft
                direction={props.direction}
                message={message}
                onImageClick={onImageClick}
                isOwner={message.user._id === props.ownerId ? true : false}
              />
            ) : (
              <BubbleLeft
                direction={props.direction}
                message={message}
                isOwner={message.user._id === props.ownerId ? true : false}
              />
            )}
          </motion.li>
        ))}

        <TypingComponent
          direction={props.direction}
          sessionChatTypingEvents={sessionChatTypingEvents}
          sessionsMembers={sessionsMembers}
          sessionId={sessionId}
        />
      </motion.ul>
    </div>
  );
};

ChatMessages.propTypes = {
  sessionsMembers: PropTypes.array,
  sessionId: PropTypes.string,
  direction: PropTypes.string,
  sessionChatTypingEvents: PropTypes.array,
  messages: PropTypes.array,
  listType: PropTypes.string,
  ownerId: PropTypes.string,
  onImageClick: PropTypes.func,
  showEmojiPicker: PropTypes.bool
};

export default withTheme(ChatMessages);
