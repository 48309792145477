import {store} from "../redux/store";
import {UPDATE_VENDOR_DATA} from "../redux/constants";
import firebase from "firebase/app";
import "firebase/firestore";
import Backend from "./Backend";
class VendorApi {
  updateVendorDetails(details) {
    firebase
      .firestore()
      .collection("vendorsDetails")
      .doc(Backend.uid)
      .set(details);
  }

  vendorDetailsListener(id) {
    firebase
      .firestore()
      .collection("vendorsDetails")
      .doc(id)
      .onSnapshot(snapshot => {
        if (snapshot.exists) {
          const details = snapshot.data();
          store.dispatch({type: UPDATE_VENDOR_DATA, details: details});
        }
      });
  }
}
export default new VendorApi();
