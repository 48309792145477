import {
  CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS,
  ADD_CIRCLE_PENDING_REQUEST,
  REMOVE_CIRCLE_PENDING_REQUEST,
  ADD_CIRCLE
} from "../../constants";

const INITIAL_STATE = {};

function pendingRequests(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined ? state[action.circleId] : []
      };

    case CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS:
      return {
        ...state,
        [action.circleId]: action.requests
      };

    case ADD_CIRCLE_PENDING_REQUEST:
      return {
        ...state,
        [action.circleId]: [
          ...state[action.circleId].filter(
            request => request.userId !== action.value.userId
          ),
          action.value
        ]
      };

    case REMOVE_CIRCLE_PENDING_REQUEST:
      return {
        ...state,
        [action.circleId]: state[action.circleId].filter(
          request => request.userId !== action.id
        )
      };
    default:
      return state;
  }
}

export default pendingRequests;
