import styled from "styled-components";
import {palette} from "styled-theme";

const VendorMenuWrapper = styled.div`
  .settingMenu {
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(15, 28, 68, 0.1) !important;
    transition: all 0.3s ease;
    padding: 6px 0 0 0;
  }
  .settingMenu .ant-menu-item-selected {
    color: ${palette("primary", 0)};
    background-color: #ffded6 !important;
  }
  .settingMenu .ant-menu-item:hover {
    color: ${palette("primary", 0)};
  }
  .settingMenu .ant-menu-item:after {
    border-right: 3px solid ${palette("primary", 0)};
  }
`;

export default VendorMenuWrapper;
