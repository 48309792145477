import styled from "styled-components";
import { palette } from "styled-theme";

const ActionButtonWrapper = styled.div`
padding: 5px 0;

  .actionButtonWrapper{
    /* padding: 5px 0; */
    background-color: ${palette("surfaceColor", 0)};
    flex-direction: row;
    display: flex;
    justify-content:space-between;
    align-items:center;
    cursor: pointer;
    .title {
      color: ${palette("text", 0)};
      margin-right: ${props => (props["data-rtl"] === "rtl" ? "0" : "20px")};
      margin-left: ${props => (props["data-rtl"] === "rtl" ? "20px" : "0")};
      margin-top:0;
      margin-bottom:0;
    }
    .icon {
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 30px;
      background-color: ${palette("background", 0)};
      span {
        font-size: 20px;
        color: ${palette("text", 0)};
        }
      }
      &.flat{
        margin-top: 10px;
        padding:10px 0;
        background-color: ${props => props.flatBg} !important;
        background-color: ${palette("onSecondaryBg", 0)};
        flex-direction: row;
        justify-content:center;
        .title {
           color: ${props => props.flatTextBg} !important;
        }
      }
    }
  }
`;

export default ActionButtonWrapper;
