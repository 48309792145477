import React from "react";
import PropTypes from "prop-types";
import MapStyleSelector from "./mapStyleSelector";
import { Switch } from "antd";
import ControlWrapper from "./index.style";
import { withTheme } from "styled-components";
import ContactListWrapper from "../utility/contactList.style";
import { UserOutlined } from "@ant-design/icons";
import ActionButton from "../utility/actionButton";
import { getDistance, convertDistance } from "geolib";
import IntlMessages from "../utility/intlMessages";

const Control = (props) => {
  const {
    direction,
    selectedId,
    setRespondersVisibility,
    setOwnerVisibility,
    focusOnCaller,
    focusOnParticipant,
    onMapStyleChange,
    activeParticipants,
    ownerMarker,
    locations,
  } = props;

  const setResponders = (value) => {
    setRespondersVisibility(value);
  };

  const setOwner = (value) => {
    setOwnerVisibility(value);
  };

  const getContactDistance = (uid) => {
    const participantMarker = locations.filter(
      (location) => location.userId === uid
    )[0];
    if (
      ownerMarker &&
      ownerMarker.location &&
      participantMarker &&
      participantMarker.location
    ) {
      const meters = getDistance(
        {
          latitude: ownerMarker.location.latitude,
          longitude: ownerMarker.location.longitude,
        },
        {
          latitude: participantMarker.location.latitude,
          longitude: participantMarker.location.longitude,
        }
      );
      const distance = convertDistance(meters, "km"); // 14.2
      return distance;
    }
  };

  const singleContact = (contact) => {
    const activeClass = selectedId && contact.id === selectedId ? "active" : "";
    const setProfileId = () => {
      focusOnParticipant(contact.id);
    };
    const distance = getContactDistance(contact.id);
    return (
      <div
        key={contact.id}
        className={`${activeClass} contactItem`}
        onClick={setProfileId}
      >
        <div className="contactAvatarContainer">
          {contact.photoUrl ? (
            <img alt="#" src={contact.photoUrl.uri} />
          ) : (
            <UserOutlined />
          )}
        </div>
        <div className="contactDetailsContainer" style={{ width: "100%" }}>
          <div className="primaryDetail">
            <h3>{contact.fullName ? contact.fullName : "No Name"}</h3>
          </div>
        </div>
        <div className="contactRightContainer">
          {distance !== undefined ? (
            <p>
              {distance} {<IntlMessages id="app.sessions.km" />}
            </p>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <ControlWrapper data-rtl={direction}>
      <div>
        <div className="mapActionsContainer">
          <p>{<IntlMessages id="app.sessions.options" />}</p>
          <ActionButton
            direction={direction}
            title={<IntlMessages id="app.sessions.focusOnCaller" />}
            primary={true}
            onClick={focusOnCaller}
            flat={true}
          />
          <MapStyleSelector
            direction={direction}
            onMapStyleChange={onMapStyleChange}
          />
        </div>
        <div className="mapFiltersContainer">
          <p>{<IntlMessages id="app.sessions.filters" />}</p>
          <div className="filterContainer">
            <p className="label">
              {<IntlMessages id="app.sessions.showCaller" />}
            </p>
            <Switch defaultChecked onChange={setOwner} />
          </div>
          <div className="filterContainer">
            <p className="label">
              {<IntlMessages id="app.sessions.showResponders" />}
            </p>
            <Switch defaultChecked onChange={setResponders} />
          </div>
        </div>

        {activeParticipants && activeParticipants.length > 0 ? (
          <div className="mapParticipantsContainer">
            <p>{<IntlMessages id="app.sessions.participants" />}</p>
            <ContactListWrapper data-rtl={direction}>
              {activeParticipants.map((contact) => singleContact(contact))}
            </ContactListWrapper>
          </div>
        ) : null}
      </div>
    </ControlWrapper>
  );
};
Control.propTypes = {
  direction: PropTypes.string,
  selectedId: PropTypes.string,
  setRespondersVisibility: PropTypes.func,
  setOwnerVisibility: PropTypes.func,
  focusOnCaller: PropTypes.func,
  focusOnParticipant: PropTypes.func,
  onMapStyleChange: PropTypes.func,
  activeParticipants: PropTypes.array,
  ownerMarker: PropTypes.object,
  locations: PropTypes.array,
};

export default withTheme(Control);
