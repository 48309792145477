import {
  ADD_SESSION_PRIORITY,
  CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS,
  REMOVE_SESSION_PRIORITY,
  ADD_CIRCLE
} from "../../constants";

const INITIAL_STATE = {};

function priority(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined ? state[action.circleId] : []
      };
    case ADD_SESSION_PRIORITY:
      return {
        ...state,
        [action.circleId]: [
          ...state[action.circleId].filter(
            priority => priority.sessionId !== action.value.sessionId
          ),
          action.value
        ]
      };

    case CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS:
      return {
        ...state,
        [action.circleId]: action.priorities
      };

    case REMOVE_SESSION_PRIORITY:
      return {
        ...state,
        [action.circleId]: state[action.circleId].filter(
          priority => priority.sessionId !== action.sessionId
        )
      };

    default:
      return state;
  }
}

export default priority;
