import styled from "styled-components";
import { palette } from "styled-theme";

const PopUpInfoWrapper = styled.div`
  display: flex;
  /* right: -138px; */
  /* bottom: 100px; */
  /* border-radius: 10px; */
  flex-direction: row;
  /* position: absolute; */
  /* justify-content: center; */
  align-items: center;
  width: 300px;
  padding: 10px;
  /* border: 1px solid ${palette("border", 0)} !important; */
  .imgWrapper {
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
      width: 90px;
      height: 90px;
      border-radius: 5px;
      border: 1px solid white;
    }
  }
  .popUpDetails {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 10px;
    flex:1;
    .popUpName {
      margin:0 !important;
    }
    .popUpDistance {
      margin:0 !important;

    }
  }
`;

export default PopUpInfoWrapper;
