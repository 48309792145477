import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

const { Title } = Typography;

const Headline = props => {
  const { content } = props;
  return <Title level={3}>{content}</Title>;
};

Headline.propTypes = {
  content: PropTypes.string
};

export default Headline;
