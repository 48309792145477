import styled from "styled-components";
import { palette } from "styled-theme";

const SidebarWrapper = styled.div`
   .sidebar {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
    z-index: 998;
    background: ${palette("surfaceColor", 0)};
    width: 25%;
    flex: 0 0 25%;
    border-right: ${props =>
      props["data-rtl"] === "rtl" ? "none" : "1px solid"};
    border-left: ${props =>
      props["data-rtl"] === "rtl" ? "1px solid" : "none"};
    border-color: ${palette("border", 0)};
    .scrollarea {
      height: calc(100vh - 64px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }
    .scrollbars > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .plans {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-top:1px solid  ${palette("border", 0)};
      margin-bottom: 5px;
      height: 62px;
      p {
        margin: 0;
        color: ${palette("lightText", 0)};
      }
      .planName {
        color: ${palette("text", 0)};
      }
    }
    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .dashboardMenu {
      padding: ${props =>
        props["data-rtl"] === "rtl"
          ? "20px 10px 35px 10px"
          : "20px 10px 35px 10px"};
      background: transparent;
      a {
        text-decoration: none;
        font-weight: 400;
        color: ${palette("text", 0)};
        &:hover {
          color: ${palette("text", 0)} !important;
        }
      }

      .ant-menu-item {
        border-radius: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0 !important;
        margin: 0 0 5px 0;
        line-height: initial;
        height: auto;

        &:hover {
          background-color: ${palette("lightBg", 1)};
        }
      }

      .menuItem {
        display: flex;
        align-items: center;
      }
      .menuIcon {
        height: 36px;
        width: 36px;
        margin: ${props =>
          props["data-rtl"] === "rtl" ? "0 10px 0 0" : "0 0 0 10px"};
        border-radius: 20px;
        background: ${palette("iconBg", 0)};
        align-items: center;
        justify-content: center;
        display: flex;
      }

      .anticon {
        font-size: 18px;
        margin: 0px;
        color: inherit;
        display: flex;
      }

      .nav-text {
        font-size: 14px;
        color: inherit;
        font-weight: 400;
        color: ${palette("text", 0)};
        padding: 5px 10px;

        /* padding: ${props =>
          props["data-rtl"] === "rtl" ? "0 10px 0 0" : "0 0 0 10px"}; */
      }

      .ant-menu-item-selected {
        background-color: ${palette("selected", 0)} !important;
        border-radius: 10px;
        &:hover {
          background-color: ${palette("selected", 0)} !important;
        }
        .anticon {
          color: #ffffff !important;
         }
        .menuIcon {
          background: ${props => props.menuIconSelectedBg} !important;
        }

        &.ant-menu-item::after {
          border: none;
        }
        .nav-text {
          color: ${palette("primary", 0)};
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }
      .plans {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }
    }
  }
`;

export default SidebarWrapper;
