import { ADD_CIRCLE_ALERT, eventTypes } from "../../constants";
import { store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";
import { eventChannel, buffers } from "redux-saga";
import { put, call, take } from "redux-saga/effects";

export function getAlertSnap(ref) {
  const listener = eventChannel(emit => {
    ref.get().then(AlertSnap => {
      emit({
        snapshot: AlertSnap
      });
    });
    return () => {};
  }, buffers.expanding(1));
  return listener;
}

// listens when user has been added to a session or removed; handles also initial load; listens to add/delete/leave circle events
export function* setUserAlertsUpdate(data, userId, metaType) {
  for (let j = 0; j < data.snapshot._changes.length; j++) {
    const change = data.snapshot._changes[j];
    if (
      change.type === eventTypes.ADDED ||
      change.type === eventTypes.MODIFIED
    ) {
      let item = change.doc.data();
      let status = item.status;

      if (status === "sent") {
        firebase
          .firestore()
          .collection("usersAlerts")
          .doc(userId)
          .collection("userAlerts")
          .doc(change.doc.id)
          .set(
            {
              status: "received"
            },
            { merge: true }
          );
      }

      const alerts = store.getState().userAlerts.alerts;
      const previous = alerts.filter(
        alert => alert.alertId === item.alertId
      )[0];
      if (previous === undefined || previous.status !== "read") {
        const ref = firebase
          .firestore()
          .collection("alerts")
          .doc(item.alertId);
        const chan = yield call(getAlertSnap, ref);
        try {
          const snap = yield take(chan);
          const alert = snap.snapshot.data();
          alert["alertId"] = item.alertId;
          alert["status"] = item.status;
          yield put({ type: ADD_CIRCLE_ALERT, alert: alert });
        } finally {
          chan.close();
        }
      }
    }
  }
}
