import React from "react";
import { PropTypes } from "prop-types";
import RespondersInviteListWrapper from "./respondersInviteListWrapper.style";
import InviteList from "./inviteList";
import { UserAddOutlined } from "@ant-design/icons";
import SessionComponentWrapper from "../animations/sessionComponentWrapper";
import SessionComponentCloseButton from "../animations/sessionComponentCloseButton";
import IntlMessages from "../utility/intlMessages";
import { useSelector } from "react-redux";

const RespondersInviteList = props => {
  const {
    direction,
    showInviteList,
    sessionMembers,
    sessionId,
    show,
    type
  } = props;
  const circles = useSelector(state => state.Firebase.circles);
  const circlesMembers = useSelector(state => state.Firebase.members);
  const hideList = () => {
    showInviteList(false);
  };

  let membersList = [];
  circles.forEach(circle => {
    if (circlesMembers[circle.id].length > 0) {
      circlesMembers[circle.id].forEach(member => {
        if (member.responder === true) {
          member["fullName"] = member.name;
          membersList.push(member);
        }
      });
    }
  });
  let uniqueResponders = [
    ...new Map(membersList.map(item => [item["id"], item])).values()
  ];
  uniqueResponders.forEach(responder => {
    const temp = sessionMembers
      ? sessionMembers.filter(
          sessionMember => sessionMember.id === responder.id
        )
      : [];
    if (temp[0] !== undefined) {
      uniqueResponders = uniqueResponders.filter(
        member => member.id !== temp[0].id
      );
      uniqueResponders.push(temp[0]);
    }
  });
  return (
    <SessionComponentWrapper show={show} type={type}>
      <div className="sessionLeftContainer">
        <RespondersInviteListWrapper data-rtl={direction}>
          <div className="respondersTopContainer">
            <div className="iconWrapper">
              <UserAddOutlined />
            </div>

            <h2>{<IntlMessages id="app.sessions.addResponders" />}</h2>
          </div>
          <div
            className="sessionInnerContainer"
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column"
            }}
          ></div>
          <InviteList sessionId={sessionId} contacts={uniqueResponders} />
          {type !== "mobile" ? (
            <SessionComponentCloseButton show={show} onClick={hideList} />
          ) : null}
        </RespondersInviteListWrapper>
      </div>
    </SessionComponentWrapper>
  );
};

RespondersInviteList.propTypes = {
  direction: PropTypes.string,
  showInviteList: PropTypes.func,
  circles: PropTypes.array,
  circlesMembers: PropTypes.object,
  sessionMembers: PropTypes.array,
  sessionId: PropTypes.string,
  show: PropTypes.bool,
  type: PropTypes.string
};

export default RespondersInviteList;
