const darkTheme = {};

darkTheme.palette = {
  primary: [
    "#3b60ff" // 0: primary color
  ],
  secondary: [
    "#ff3a3a" // 0: secondary color
  ],
  surfaceColor: [
    "#172021" ///base backgroun color
  ],
  primaryDarkBg: [
    "#172021", // 0: primary dark background
    "#111111" // 0: primary darkest background
  ],
  lightBg: [
    "#25383a", // 0:light background
    "#253233" //1: darker
  ],
  lightText: [
    "rgba(255,255,255,0.6)" // 1: secondary text color
  ],
  mediumEmphasisWhiteText: [
    "rgba(255,255,255,0.6)" // 0: light white text
  ],
  onPrimaryText: [
    "#000000" // 0: text color on primary background
  ],
  onPrimaryLightText: [
    "rgba(60,60,67,0.45)" // 0: light text color on primary background
  ],
  onDarkBgText: [
    "rgb(255,255,255)" // 0: text color on primary background
  ],
  onDarkBgLightText: [
    "rgba(255,255,255,0.6)" // 0: light text color on primary background
  ],
  onSecondaryBg: [
    "#000000", // 0: light background for primary text - primary buttons
    "#ff5e5e"
  ],
  iconBg: [
    "#000000" //0: icons background
  ],
  selected: [
    "#25383a", //selected background
    "rgba(61, 97, 255, 0.7)" // selected secondary text
  ],
  disabledBg: [
    "#24383a" //disabled background
  ],
  disabledColor: [
    "rgba(255, 255, 255, 0.25)" //disabled color
  ],
  title: [
    "#ffffff" // 0: text color
  ],

  color: [
    "#0085FF", // 0: Blue
    "#876CFE", // 1: Purple
    "#F4C744", // 2: Yellow
    "#3ac13c", // 3: Green
    "#e75454" //  4: Red
  ],
  chat: [
    "#14202B", // 0: background
    "#192734", // 1: owner bubble bg
    "#e5e9ed", // 2: guest bubble bg
    "#8899a6", // 3: system messages color
    "#144c80", // 4: lightr bg
    "#C9CEE4" // 5: recieved message sender name
  ],
  warning: [
    "#ffbf00" // 0: Warning
  ],
  success: [
    "#00b16a" // 0: Success
  ],
  error: [
    "#f64744", // 0: Error
    "#EC3D3A", // 1: Darken 4%
    "#FF5B58" // 2: Lighten 8%
  ],
  background: [
    "#000000", // 0:light blue
    "#F1F3F6", // 1:light gray
    "#283fb0", // 2:dark primary color
    "#F6F8FB", // 3: light gray bg
    "#476282" // 4:active console menu bg
  ],
  icons: [
    "#8ca0ea", //light color
    "#003138" //icon buttons color
  ],
  grayscale: [
    "#bababa", // 0: GreyShade
    "#c1c1c1", // 1: GreyDark
    "#f5f5f5", // 2: Grey
    "#f1f1f1", // 3: GreyAlt
    "#fbfbfb", // 4: GreyLight
    "#E7E9ED", // 5: chat messages
    "#F9F9F9" // 6: DarkerWhit
  ],
  text: [
    "#ffffff", // 0: text color
    "rgba(255,255,255,0.6)", // 1: secondary text color
    "#1b3a57", // 2: titles color
    "#7e809a" // 3: page title
  ],
  border: [
    "rgba(64,64,67,1.0)", // 0: Border
    "rgba(228, 228, 228, 0.65)" // 1:
  ]
};

darkTheme.fonts = {
  primary: "Open Sans, sans-serif, Roboto",
  pre: "Consolas, Liberation Mono, Menlo, Courier, monospace"
};
darkTheme.name = "darkTheme";

export default darkTheme;
