import styled from "styled-components";
import { palette } from "styled-theme";
import { transition } from "../../settings/style-util";

const TopbarWrapper = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;

  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: ${palette("surfaceColor", 0)} !important;
    border-bottom: 1px solid ${palette("border", 0)};
    ${transition()};
    @media only screen and (max-width: 767px) {
    }

    .isoLeft {
      display: flex;
      align-items: center;
      margin: 0 20px;
      }
    }
    .isoRight {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      z-index:2;
      li {
        margin-left: ${props => (props["data-rtl"] === "rtl" ? "10px" : "0")};
        margin-right: ${props => (props["data-rtl"] === "rtl" ? "0" : "10px")};
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: ${props => (props["data-rtl"] === "rtl" ? "25px" : "0")};
          margin-right: ${props =>
            props["data-rtl"] === "rtl" ? "0" : "25px"};
        }

        .badgeWrapper {
          left: 40px !important;
        }
        &.sessionSwitcher{
          padding:10px;
        }
      }
    }
  }
  .motionNav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index:20000;
    .background {
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 300px;
      background: ${palette("secondary", 0)};
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;

    }

    button {
      outline: none;
      border: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      position: absolute;
      top: 21px;
      left: 21px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      path {
        stroke: ${palette("onDarkBgText", 0)};
      }
    }
    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 25px;
      position: absolute;
      top: 60px;
    }

    li {
      list-style: none;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .nav-item {
        display: flex;
        .nav-icon {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background: ${palette("onSecondaryBg", 1)};
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${palette("onSecondaryBg", 0)};
        }
        .nav-text {
          line-height:40px;
          padding:0 20px;
          color: ${palette("onDarkBgLightText", 0)};
        }
      }
      &.active {
          .nav-item {
            .nav-icon {
              background: ${palette("onDarkBgText", 0)};
              span {
                color: ${palette("secondary", 0)};
              }
            }
            .nav-text{
              span {
                color: ${palette("onDarkBgText", 0)};
              }
            }
          }
      }
    }

    .icon-placeholder {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex: 40px 0;
      margin-right: 20px;
    }

    .text-placeholder {
      border-radius: 5px;
      width: 200px;
      height: 20px;
      flex: 1;
    }

    .refresh {
      padding: 10px;
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }


`;

export default TopbarWrapper;
