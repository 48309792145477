import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "antd";
import MobilePageHeaderWrapper from "./mobilePageHeader.style";
import { withTheme } from "styled-components";

const MobilePageHeader = props => {
  const {
    rightComponent,
    title,
    onBack,
    direction,
    subTitle,
    settings,
    theme
  } = props;
  return (
    <MobilePageHeaderWrapper
      className="outer"
      data-rtl={direction}
      settings={settings}
      bg={settings ? "transparent" : theme.palette["surfaceColor"][0]}
    >
      <div className="header">
        <PageHeader
          className="page-header"
          onBack={onBack}
          title={title}
          subTitle={subTitle}
        />
      </div>
      <div className="rightComponent">{rightComponent}</div>
    </MobilePageHeaderWrapper>
  );
};

MobilePageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightComponent: PropTypes.object,
  onBack: PropTypes.func,
  direction: PropTypes.string,
  settings: PropTypes.bool,
  theme: PropTypes.object,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withTheme(MobilePageHeader);
