import { ADD_VAULTED_DATA } from "../../constants";
import { put } from "redux-saga/effects";
// import PlansApi from '../../api/PlansApi';

// listens when there is a change in one of the user session details (status, timestamp...)
export function* setVaultedDataUpdate(data, sessionId, metaType) {
  const vaultedData = data.snapshot.data();
  if (vaultedData !== undefined) {
    yield put({ type: ADD_VAULTED_DATA, data: vaultedData });
    // PlansApi.setUserPlan(userData);
  }
}
