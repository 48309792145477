import actions from "./actions";

const initState = {
  idToken: null,
  uid: null,
  consent: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        uid: action.uid,
        name: action.name,
        consent: true
      };

    case actions.LOGOUT:
      localStorage.removeItem("id_token");
      localStorage.removeItem("uid");

      return initState;
    default:
      return state;
  }
}
