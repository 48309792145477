import React, { useState } from "react";
import { PropTypes } from "prop-types";
import SessionsApi from "../../api/SessionsApi";
import ImageButton from "./ImageButton";
import { Input } from "antd";
import ChatInputWrapper from "./input.style";
import { withTheme } from "styled-components";
import { useIntl } from "react-intl";

const { TextArea } = Input;

const ChatInput = props => {
  const [newMessage, setNewMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const intl = useIntl();

  const onInputTextChanged = text => {
    const previous = newMessage;
    setNewMessage(text);
    props.handleTextChange(text);
    if (text !== "" || previous !== "") {
      SessionsApi.setChatTypingEvent(props.sessionId, false);
    }
  };

  const toggleEmojiPicker = value => {
    setShowEmojiPicker(value);
    props.showEmojiPicker(value);
  };
  const theme = props.theme;
  return (
    <ChatInputWrapper data-rtl={props.direction}>
      <div className="chat-input-container">
        <div className="chat-input-textfield">
          <TextArea
            placeholder={intl.formatMessage({
              id: "app.sessions.typeMessage"
            })}
            autoSize={{ minRows: 1, maxRows: 6 }}
            style={{
              marginBottom: "5px",
              marginTop: "5px",
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
            value={props.message}
            onChange={e => onInputTextChanged(e.target.value)}
          />
        </div>

        <div>
          <i
            style={{
              color: theme.palette["lightText"][0],
              fontSize: "24px",
              padding: props.direction === "rtl" ? "0 0 0 1.2em" : "0 1.2em 0 0"
            }}
            className={
              showEmojiPicker ? "ion-md-close-circle-outline" : "ion-md-happy"
            }
            onClick={() => {
              toggleEmojiPicker(!showEmojiPicker);
            }}
          />
        </div>

        {props.message !== "" ? (
          <div>
            <i
              style={{
                color: theme.palette["secondary"][0],
                fontSize: "24px",
                paddingRight: "1.2em"
              }}
              className="ion-md-send"
              onClick={() => {
                SessionsApi.sendMessage(props.sessionId, props.message);
                onInputTextChanged("");
                toggleEmojiPicker(false);
              }}
            />
          </div>
        ) : (
          <ImageButton
            sessionId={props.sessionId}
            direction={props.direction}
          />
        )}
      </div>
    </ChatInputWrapper>
  );
};

ChatInput.propTypes = {
  sessionId: PropTypes.string,
  direction: PropTypes.string,
  showEmojiPicker: PropTypes.func,
  handleTextChange: PropTypes.func,
  message: PropTypes.string
};

export default withTheme(ChatInput);
