/* Action Types */
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CLEAR_STATE = "CLEAR_STATE";
export const UPDATE_VENDOR_DATA = "UPDATE_VENDOR_DATA";
export const SET_UID = "SET_UID";
export const ADD_SESSION_MEMBER_PRESENCE = "ADD_SESSION_MEMBER_PRESENCE";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_VAULTED_DATA = "ADD_VAULTED_DATA";
export const ADD_PLAN = "ADD_PLAN";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_PLANS = "ADD_PLANS";
export const ADD_MESSAGE = "Session/ADD_MESSAGE";
export const ADD_SESSION_CHAT_TYPING_EVENT = "ADD_SESSION_CHAT_TYPING_EVENT";
export const REMOVE_SESSION_CHAT_TYPING_EVENT =
  "REMOVE_SESSION_CHAT_TYPING_EVENT";
export const SESSION_TYPING_EVENTS_FETCH_SUCCESS =
  "SESSION_TYPING_EVENTS_FETCH_SUCCESS";
export const SET_CURRENT_USERID = "Session/SET_CURRENT_USERID";
export const ADD_HISTORY = "App/ADD_HISTORY";
export const ADD_USER = "App/ADD_USER";
export const REMOVE_USER = "App/REMOVE_USER";
export const UPDATE_ACTIVE_PROFILE_PRIVATE_INFO =
  "UPDATE_ACTIVE_PROFILE_PRIVATE_INFO";
export const UPDATE_ACTIVE_PROFILE_MEDICAL_INFO =
  "UPDATE_ACTIVE_PROFILE_MEDICAL_INFO";
export const RESET_ACTIVE_PROFILE = "RESET_ACTIVE_PROFILE";
export const SET_MODAL_STATUS = "SET_MODAL_STATUS";
export const SET_NEW_ALERT_VISIBILTY = "SET_NEW_ALERT_VISIBILTY";
export const SET_SESSION_DETAILS_CLASS = "SET_SESSION_DETAILS_CLASS";
export const SET_NEW_GROUP_TYPE = "SET_NEW_GROUP_TYPE";
export const SET_NEW_GROUP_NAME = "SET_NEW_GROUP_NAME";
export const SET_NEW_GROUP_LIMIT = "SET_NEW_GROUP_LIMIT";
export const SET_NEW_GROUP_PHOTOURL = "SET_NEW_GROUP_PHOTOURL";
export const RESET_NEW_GROUP_DETAILS = "RESET_NEW_GROUP_DETAILS";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";
export const ADD_CIRCLE = "ADD_CIRCLE";
export const REMOVE_CIRCLE = "REMOVE_CIRCLE";
export const ADD_CIRCLE_MEMBER = "ADD_CIRCLE_MEMBER";
export const REMOVE_CIRCLE_MEMBER = "REMOVE_CIRCLE_MEMBER";
export const CIRCLE_MEMBERS_FETCH_SUCCESS = "CIRCLE_MEMBERS_FETCH_SUCCESS";
export const ADD_ENTITY = "ADD_ENTITY";
export const REMOVE_ENTITY = "REMOVE_ENTITY";
export const ADD_ONLINE_SESSION = "ADD_ONLINE_SESSION";
export const REMOVE_ONLINE_SESSION = "REMOVE_ONLINE_SESSION";
export const CIRCLE_SESSIONS_FETCH_SUCCESS = "CIRCLE_SESSIONS_FETCH_SUCCESS";
export const LOAD_CIRCLE_PENDING_REQUESTS = "LOAD_CIRCLE_PENDING_REQUESTS";
export const CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS =
  "CIRCLE_PENDING_REQUESTS_FETCH_SUCCESS";
export const ADD_CIRCLE_PENDING_REQUEST = "ADD_CIRCLE_PENDING_REQUEST";
export const REMOVE_CIRCLE_PENDING_REQUEST = "REMOVE_CIRCLE_PENDING_REQUEST";
export const LOAD_CIRCLE_GEO_FENCES = "LOAD_CIRCLE_GEO_FENCES";
export const ADD_CIRCLE_GEO_FENCES = "ADD_CIRCLE_GEO_FENCES";
export const CIRCLE_GEO_FENCES_FETCH_SUCCESS =
  "CIRCLE_GEO_FENCES_FETCH_SUCCESS";
export const REMOVE_CIRCLE_GEO_FENCES = "REMOVE_CIRCLE_GEO_FENCES";
export const LOAD_CIRCLE_ALERTS = "LOAD_CIRCLE_ALERTS";
export const ADD_CIRCLE_ALERT = "ADD_CIRCLE_ALERT";
export const REMOVE_CIRCLE_ALERT = "REMOVE_CIRCLE_ALERT";
export const SET_CIRCLE_LOCATION = "SET_CIRCLE_LOCATION";
export const SET_CIRCLE_NAME = "SET_CIRCLE_NAME";
export const SET_CIRCLE_DESCRIPTION = "SET_CIRCLE_DESCRIPTION";
export const SET_CIRCLE_EMERGENCY = "SET_CIRCLE_EMERGENCY";
export const SET_CIRCLE_CATEGORIES = "SET_CIRCLE_CATEGORIES";
export const SET_CIRCLE_JOIN_POLICY = "SET_CIRCLE_JOIN_POLICY";
export const SET_CIRCLE_RESPONDERS_POLICY = "SET_CIRCLE_RESPONDERS_POLICY";
export const SET_CIRCLE_NUMBER_OF_ASSIGNED_RESPONDERS =
  "SET_CIRCLE_NUMBER_OF_ASSIGNED_RESPONDERS";
export const SET_CIRCLE_PHOTO = "SET_CIRCLE_PHOTO";
export const SET_MEMBER_ROLE = "SET_MEMBER_ROLE";
export const SET_RESPONDER = "SET_RESPONDER";
export const SET_MEMBER_MUTE_STATUS = "SET_MEMBER_MUTE_STATUS";
export const SET_MEMBER_REVEAL_LOCATION_STATUS =
  "SET_MEMBER_REVEAL_LOCATION_STATUS";
export const SET_MEMBER_LAST_LOCATION = "SET_MEMBER_LAST_LOCATION";
export const ADD_CIRCLE_RESPONDER_SELECTION = "ADD_CIRCLE_RESPONDER_SELECTION";
export const REMOVE_CIRCLE_RESPONDER_SELECTION =
  "REMOVE_CIRCLE_RESPONDER_SELECTION";
export const RESET_CIRCLE_RESPONDER_SELECTION =
  "RESET_CIRCLE_RESPONDER_SELECTION";
export const UPDATE_SESSION_MEMBER = "UPDATE_SESSION_MEMBER";
export const SESSION_MEMBERS_FETCH_SUCCESS = "SESSION_MEMBERS_FETCH_SUCCESS";
export const ADD_SESSION = "ADD_SESSION";
export const REMOVE_SESSION = "REMOVE_SESSION";
export const ADD_SESSION_MEMBER = "ADD_SESSION_MEMBER";
export const REMOVE_SESSION_MEMBER = "REMOVE_SESSION_MEMBER";
export const ADD_RESOLVED_SESSION = "ADD_RESOLVED_SESSION";
export const ADD_LAST_VISIBLE_RESOLVED_SESSION =
  "ADD_LAST_VISIBLE_RESOLVED_SESSION";
export const SET_ACTIVE_SESSION = "SET_ACTIVE_SESSION";
export const SET_ACTIVE_RESOLVED_SESSION = "SET_ACTIVE_RESOLVED_SESSION";
export const UPDATE_SESSION_MEMBER_LOCATION = "UPDATE_SESSION_MEMBER_LOCATION";
export const SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS =
  "SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS";
export const UPDATE_HIDE_LOCATION_STATUS = "UPDATE_HIDE_LOCATION_STATUS";
export const UPDATE_SESSION_OWNER_LOCATION = "UPDATE_SESSION_OWNER_LOCATION";
export const LOAD_SESSION_OWNER_LOCATIONS = "LOAD_SESSION_OWNER_LOCATIONS";
export const CREATE_SESSION_MEMBER_GROUP = "CREATE_SESSION_MEMBER_GROUP";
export const SESSIONS_MESSAGES_FETCH_SUCCESS =
  "SESSIONS_MESSAGES_FETCH_SUCCESS";
export const SESSION_MESSAGES_FETCH_SUCCESS = "SESSION_MESSAGES_FETCH_SUCCESS";
export const UPDATE_SESSION_MESSAGE_PROGRESS =
  "UPDATE_SESSION_MESSAGE_PROGRESS";
export const CREATE_SESSION_MESSAGES_GROUP = "CREATE_SESSION_MESSAGES_GROUP";
export const RESET_SESSIONS_MESSAGES = "RESET_SESSIONS_MESSAGES";
export const ADD_SESSION_MESSAGE = "ADD_SESSION_MESSAGE";
export const REMOVE_SESSION_MESSAGE = "REMOVE_SESSION_MESSAGE";
export const UPDATE_SESSION_MESSAGE_SENT = "UPDATE_SESSION_MESSAGE_SENT";
export const UPDATE_SESSION_MESSAGE_UPLOADED =
  "UPDATE_SESSION_MESSAGE_UPLOADED";
export const SET_ACTIVE_CIRCLE = "SET_ACTIVE_CIRCLE";
export const SET_ACTIVE_MEMBER_ID = "SET_ACTIVE_MEMBER_ID";
export const SET_ACTIVE_RESPONDER_ID = "SET_ACTIVE_RESPONDER_ID";
export const SET_ACTIVE_PENDING_MEMBER_ID = "SET_ACTIVE_PENDING_MEMBER_ID";
export const SET_ACTIVE_GEO_FENCE_ID = "SET_ACTIVE_GEO_FENCE_ID";
export const SET_ACTIVE_SESSION_OWNER_ID = "SET_ACTIVE_SESSION_OWNER_ID";
export const IS_SIGNED = "IS_SIGNED";
export const SET_CIRCLES_DASHBOARD_CATEGORY = "SET_CIRCLES_DASHBOARD_CATEGORY";
export const SET_SNACK_BAR = "SET_SNACK_BAR";
export const SET_CIRCLE_SNACK_BAR = "SET_CIRCLE_SNACK_BAR";
export const SHOW_CALLER_ROUTE = "SHOW_CALLER_ROUTE";
export const SHOW_RESPONDERS_LOCATIONS = "SHOW_RESPONDERS_LOCATIONS";
export const FOCUS_ON_CALLER = "FOCUS_ON_CALLER";
export const SHOW_CHAT = "SHOW_CHAT";
export const SET_CHAT_CLASS = "SET_CHAT_CLASS";
export const SET_SESSION_INFO_MODAL = "SET_SESSION_INFO_MODAL";
export const SET_PROFILE_MODAL = "SET_PROFILE_MODAL";
export const SET_GEO_FENCE_MODAL = "SET_GEO_FENCE_MODAL";
export const SET_ANIMATE_MEMBERS = "SET_ANIMATE_MEMBERS";
export const SET_ANIMATE_RESPONDERS = "SET_ANIMATE_RESPONDERS";
export const SET_SHOW_NEW_MEMBER = "SET_SHOW_NEW_MEMBER";
export const SET_SHOW_SEARCH_MEMBER = "SET_SHOW_SEARCH_MEMBER";
export const SET_SHOW_NEW_RESPONDER = "SET_SHOW_NEW_RESPONDER";
export const SET_SHOW_SEARCH_RESPONDER = "SET_SHOW_SEARCH_RESPONDER";
export const ADD_SESSION_PRIORITY = "ADD_SESSION_PRIORITY";
export const CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS =
  "CIRCLE_SESSION_PRIORITIES_FETCH_SUCCESS";
export const REMOVE_SESSION_PRIORITY = "REMOVE_SESSION_PRIORITY";
export const CIRCLES_FETCHED = "CIRCLES_FETCHED";
export const PLANS_FETCHED = "PLANS_FETCHED";
export const USER_FETCHED = "USER_FETCHED";
export const ACTIVE_PLAN_FETCHED = "ACTIVE_PLAN_FETCHED";
export const CHANGE_MAP_VIEWPORT = "CHANGE_MAP_VIEWPORT";
export const CHANGE_MAP_FIELD = "CHANGE_MAP_FIELD";
export const CHANGE_MAP_STYLE = "CHANGE_MAP_STYLE";

export const FIRESTORE_LISTEN_REQUESTED = "FIRESTORE_LISTEN_REQUESTED";
export const FIRESTORE_REMOVE_LISTENER_REQUESTED =
  "FIRESTORE_REMOVE_LISTENER_REQUESTED";
export const FIRESTORE_REMOVE_ALL_LISTENERS_REQUESTED =
  "FIRESTORE_REMOVE_ALL_LISTENERS_REQUESTED";

export const FIREBASE_LISTEN_REQUESTED = "FIREBASE_LISTEN_REQUESTED";
export const FIREBASE_OBJECT_LISTEN_REQUESTED =
  "FIREBASE_OBJECT_LISTEN_REQUESTED";
export const FIREBASE_REMOVE_LISTENER_REQUESTED =
  "FIREBASE_REMOVE_LISTENER_REQUESTED";
export const FIREBASE_REMOVE_ALL_LISTENERS_REQUESTED =
  "FIREBASE_REMOVE_ALL_LISTENERS_REQUESTED";

export const FIREBASE_UNLISTEN_REQUESTED = "FIREBASE_UNLISTEN_REQUESTED";
export const FIRESTORE_UNLISTEN_REQUESTED = "FIRESTORE_UNLISTEN_REQUESTED";

export const FILTER_TAG_ATTRIBUTE = "FILTER_TAG_ATTRIBUTE";
export const FILTER_BUCKET_ATTRIBUTE = "FILTER_BUCKET_ATTRIBUTE";
export const SET_SELECTED_ALERT_ID = "SET_SELECTED_ALERT_ID";
export const COMPOSE_ALERT = "COMPOSE_ALERT";

export const metaTypes = {
  sessionMembers: "sessionMembers",
  sessionMessages: "sessionMessages",
  sessionLocations: "sessionLocations",
  sessionTypingEvents: "sessionTypingEvents",
  sessions: "sessions",
  userSessions: "userSessions",
  circles: "circles",
  circleMembers: "circleMembers",
  circleGeoFences: "circleGeoFences",
  circleAlerts: "circleAlerts",
  instantAlerts: "instantAlerts",
  scheduledAlerts: "scheduledAlerts",
  circleSesionsPriority: "circleSesionsPriority",
  circleJoinRequests: "circleJoinRequests",
  circleSessionsIds: "circleSessionsIds",
  circleSessions: "circleSessions",
  userEntities: "userEntities",
  userCircles: "userCircles",
  userData: "userData",
  vaultedData: "vaultedData",
  activePlan: "activePlan",
  userSubscriptions: "userSubscriptions",
  plans: "plans",
  userSafetyContacts: "userSafetyContacts",
  userAlerts: "userAlerts",
  userFriends: "userFriends",
  sessionMembersPresence: "sessionMembersPresence"
};

export const eventTypes = {
  CHILD_ADDED: "CHILD_ADDED",
  CHILD_REMOVED: "CHILD_REMOVED",
  CHILD_CHANGED: "CHILD_CHANGED",
  ADDED: "added",
  MODIFIED: "modified",
  REMOVED: "removed",
  VALUE: "value"
};
