import antdEn from "antd/lib/locale-provider/he_IL";
import heMessages from "../locales/he_IL.json";
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const HeLang = {
  messages: {
    ...heMessages
  },
  antd: antdEn,
  locale: "he-IL"
};
export default HeLang;
