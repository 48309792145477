import React, {Component} from "react";
import {Layout, Menu} from "antd";
import VendorMenuWrapper from "./menu.style";
import {withTheme} from "styled-components";
const {Sider} = Layout;

class VendorMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: ["0"]
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    this.props.changeCategory(e.key);
    this.setState({
      selectedKeys: [e.key]
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedKeys !== prevState.selectedKeys) {
      return {selectedKeys: nextProps.selectedKeys};
    } else return null;
  }

  render() {
    const theme = this.props.theme;
    return (
      <VendorMenuWrapper data-rtl={this.props.direction}>
        <Sider width={200} style={{background: theme.palette["background"][0]}}>
          <Menu
            selectedKeys={this.state.selectedKeys}
            className="settingMenu"
            onClick={this.handleClick}
            mode="inline"
            defaultSelectedKeys={["0"]}
          >
            <Menu.Item key="0">
              <span>Introduction</span>
            </Menu.Item>
            <Menu.Item key="1">
              <span>Company Information</span>
            </Menu.Item>
            <Menu.Item key="2">
              <span>Principal Information</span>
            </Menu.Item>
            <Menu.Item key="3">
              <span>Account</span>
            </Menu.Item>
          </Menu>
        </Sider>
      </VendorMenuWrapper>
    );
  }
}
export default withTheme(VendorMenu);
