import {
  ADD_CIRCLE_ALERT,
  REMOVE_CIRCLE_ALERT,
  ADD_CIRCLE
} from "../../constants";

const INITIAL_STATE = {};

function alerts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined ? state[action.circleId] : []
      };

    case ADD_CIRCLE_ALERT:
      return {
        ...state,
        [action.circleId]: [
          ...state[action.circleId].filter(
            alert => alert.alertId !== action.value.alertId
          ),
          action.value
        ]
      };

    case REMOVE_CIRCLE_ALERT:
      return {
        ...state,
        [action.circleId]: state[action.circleId].filter(
          circleAlert => circleAlert.alertId !== action.alertId
        )
      };
    default:
      return state;
  }
}

export default alerts;
