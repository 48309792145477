import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { Layout } from "antd";
import ContentHolder from "../../components/utility/contentHolder";
import VendorInfo from "../../components/vendor/vendorInfo";
import VendorPrincipal from "../../components/vendor/vendorPrincipal";
import PayoutInfo from "../../components/vendor/payoutInfo";
import VendorMenu from "../../components/vendor/vendorMenu";
import { Button } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import authAction from "../../redux/reducers/auth/actions";
import { toggleAll } from "../../redux/reducers/app/reducer";
import Topbar from "../Topbar/Topbar";
import VendorApi from "../../api/VendorApi";
import { AppLocale } from "../../dashApp";
import VendorDetailsWrapper from "./style";
const { Content } = Layout;
const { logout } = authAction;

export class VendorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "0",
      selectedKey: "0",
      validate: false,
      validationCount: 0,
      vendor_vendorType: "Business",
      vendor_name: "",
      vendor_firstName: "",
      vendor_lastName: "",
      vendor_email: "",
      vendor_phone: "",
      vendor_address: "",
      vendor_city: "",
      vendor_country: "",
      vendor_state: "",
      vendor_zip: "",
      vendor_taxId: "",
      vendor_vatId: "",
      vendor_currency: "",
      principal_firstName: "",
      principal_lastName: "",
      principal_address: "",
      principal_city: "",
      principal_country: "",
      principal_zip: "",
      principal_dob: "",
      principal_personalIdentificationNumber: "",
      principal_driverLicenseNumber: "",
      principal_passportNumber: "",
      principal_email: "",

      payout_payoutType: "",
      payout_baseCurrency: "",
      payout_bankName: "",
      payout_address: "",
      payout_country: "",
      payout_state: "",
      payout_city: "",
      payout_zip: "",
      payout_nameOnAccount: "",
      payout_bankAccountClass: "",
      payout_bankAccountType: "",
      payout_bankId: "",
      payout_bankAccountId: "",
      payout_iban: "",
      payout_swiftBic: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidated = this.isValidated.bind(this);

    this.handleVendor_vendorTypeChange = this.handleVendor_vendorTypeChange.bind(
      this
    );
    this.handleVendor_nameChange = this.handleVendor_nameChange.bind(this);
    this.handleVendor_firstNameChange = this.handleVendor_firstNameChange.bind(
      this
    );
    this.handleVendor_lastNameChange = this.handleVendor_lastNameChange.bind(
      this
    );
    this.handleVendor_emailChange = this.handleVendor_emailChange.bind(this);
    this.handleVendor_phoneChange = this.handleVendor_phoneChange.bind(this);
    this.handleVendor_addressChange = this.handleVendor_addressChange.bind(
      this
    );
    this.handleVendor_cityChange = this.handleVendor_cityChange.bind(this);
    this.handleVendor_countryChange = this.handleVendor_countryChange.bind(
      this
    );
    this.handleVendor_stateChange = this.handleVendor_stateChange.bind(this);
    this.handleVendor_zipChange = this.handleVendor_zipChange.bind(this);
    this.handleVendor_taxIdChange = this.handleVendor_taxIdChange.bind(this);
    this.handleVendor_vatIdChange = this.handleVendor_vatIdChange.bind(this);
    this.handleVendor_currencyChange = this.handleVendor_currencyChange.bind(
      this
    );

    this.handlePrincipal_firstNameChange = this.handlePrincipal_firstNameChange.bind(
      this
    );
    this.handlePrincipal_lastNameChange = this.handlePrincipal_lastNameChange.bind(
      this
    );
    this.handlePrincipal_addressChange = this.handlePrincipal_addressChange.bind(
      this
    );
    this.handlePrincipal_cityChange = this.handlePrincipal_cityChange.bind(
      this
    );
    this.handlePrincipal_countryChange = this.handlePrincipal_countryChange.bind(
      this
    );
    this.handlePrincipal_zipChange = this.handlePrincipal_zipChange.bind(this);
    this.handlePrincipal_dobChange = this.handlePrincipal_dobChange.bind(this);
    this.handlePrincipal_personalIdentificationNumberChange = this.handlePrincipal_personalIdentificationNumberChange.bind(
      this
    );
    this.handlePrincipal_driverLicenseNumberChange = this.handlePrincipal_driverLicenseNumberChange.bind(
      this
    );
    this.handlePrincipal_passportNumberChange = this.handlePrincipal_passportNumberChange.bind(
      this
    );
    this.handlePrincipal_emailChange = this.handlePrincipal_emailChange.bind(
      this
    );

    this.handlePayout_payoutTypeChange = this.handlePayout_payoutTypeChange.bind(
      this
    );
    this.handlePayout_baseCurrencyChange = this.handlePayout_baseCurrencyChange.bind(
      this
    );
    this.handlePayout_bankNameChange = this.handlePayout_bankNameChange.bind(
      this
    );
    this.handlePayout_addressChange = this.handlePayout_addressChange.bind(
      this
    );
    this.handlePayout_countryChange = this.handlePayout_countryChange.bind(
      this
    );
    this.handlePayout_stateChange = this.handlePayout_stateChange.bind(this);
    this.handlePayout_cityChange = this.handlePayout_cityChange.bind(this);
    this.handlePayout_zipChange = this.handlePayout_zipChange.bind(this);
    this.handlePayout_nameOnAccountChange = this.handlePayout_nameOnAccountChange.bind(
      this
    );
    this.handlePayout_bankAccountClassChange = this.handlePayout_bankAccountClassChange.bind(
      this
    );
    this.handlePayout_bankAccountTypeChange = this.handlePayout_bankAccountTypeChange.bind(
      this
    );
    this.handlePayout_bankIdChange = this.handlePayout_bankIdChange.bind(this);
    this.handlePayout_bankAccountIdChange = this.handlePayout_bankAccountIdChange.bind(
      this
    );
    this.handlePayout_ibanChange = this.handlePayout_ibanChange.bind(this);
    this.handlePayout_swiftBicChange = this.handlePayout_swiftBicChange.bind(
      this
    );
  }

  static propTypes = {
    toggleAll: PropTypes.func,
    locale: PropTypes.string,
    match: PropTypes.object,
    vendorDetails: PropTypes.object,
    userSettings: PropTypes.object
  };

  changeCategory = category => {
    this.setState({
      category: category,
      selectedKey: category
    });
  };

  goToIntroduction = () => {
    this.setState({
      selectedKey: "0",
      category: "0"
    });
  };

  goToCompany = () => {
    this.setState({
      selectedKey: "1",
      category: "1"
    });
  };

  goToPrincipal = () => {
    this.setState({
      selectedKey: "2",
      category: "2"
    });
  };

  goToBank = () => {
    this.setState({
      selectedKey: "3",
      category: "3"
    });
  };

  handleVendor_vendorTypeChange(value) {
    this.setState({ vendor_vendorType: value });
  }

  handleVendor_nameChange(value) {
    this.setState({ vendor_name: value });
  }

  handleVendor_firstNameChange(value) {
    this.setState({ vendor_firstName: value });
  }

  handleVendor_lastNameChange(value) {
    this.setState({ vendor_lastName: value });
  }

  handleVendor_emailChange(value) {
    this.setState({ vendor_email: value });
  }

  handleVendor_phoneChange(value) {
    this.setState({ vendor_phone: value });
  }

  handleVendor_addressChange(value) {
    this.setState({ vendor_address: value });
  }

  handleVendor_cityChange(value) {
    this.setState({ vendor_city: value });
  }

  handleVendor_countryChange(value) {
    this.setState({ vendor_country: value });
  }

  handleVendor_stateChange(value) {
    this.setState({ vendor_state: value });
  }

  handleVendor_zipChange(value) {
    this.setState({ vendor_zip: value });
  }

  handleVendor_taxIdChange(value) {
    this.setState({ vendor_taxId: value });
  }

  handleVendor_vatIdChange(value) {
    this.setState({ vendor_vatId: value });
  }

  handleVendor_currencyChange(value) {
    this.setState({ vendor_currency: value });
  }

  handlePrincipal_firstNameChange(value) {
    this.setState({ principal_firstName: value });
  }

  handlePrincipal_lastNameChange(value) {
    this.setState({ principal_lastName: value });
  }

  handlePrincipal_addressChange(value) {
    this.setState({ principal_address: value });
  }

  handlePrincipal_cityChange(value) {
    this.setState({ principal_city: value });
  }

  handlePrincipal_countryChange(value) {
    this.setState({ principal_country: value });
  }

  handlePrincipal_zipChange(value) {
    this.setState({ principal_zip: value });
  }

  handlePrincipal_dobChange(value) {
    this.setState({ principal_dob: value });
  }

  handlePrincipal_personalIdentificationNumberChange(value) {
    this.setState({ principal_personalIdentificationNumber: value });
  }

  handlePrincipal_driverLicenseNumberChange(value) {
    this.setState({ principal_driverLicenseNumber: value });
  }

  handlePrincipal_passportNumberChange(value) {
    this.setState({ principal_passportNumber: value });
  }

  handlePrincipal_emailChange(value) {
    this.setState({ principal_email: value });
  }

  handlePayout_payoutTypeChange(value) {
    this.setState({ payout_payoutType: value });
  }

  handlePayout_baseCurrencyChange(value) {
    this.setState({ payout_baseCurrency: value });
  }

  handlePayout_bankNameChange(value) {
    this.setState({ payout_bankName: value });
  }

  handlePayout_addressChange(value) {
    this.setState({ payout_address: value });
  }

  handlePayout_countryChange(value) {
    this.setState({ payout_country: value });
  }

  handlePayout_stateChange(value) {
    this.setState({ payout_state: value });
  }

  handlePayout_cityChange(value) {
    this.setState({ payout_city: value });
  }

  handlePayout_zipChange(value) {
    this.setState({ payout_zip: value });
  }

  handlePayout_nameOnAccountChange(value) {
    this.setState({ payout_nameOnAccount: value });
  }

  handlePayout_bankAccountClassChange(value) {
    this.setState({ payout_bankAccountClass: value });
  }

  handlePayout_bankAccountTypeChange(value) {
    this.setState({ payout_bankAccountType: value });
  }

  handlePayout_bankIdChange(value) {
    this.setState({ payout_bankId: value });
  }

  handlePayout_bankAccountIdChange(value) {
    this.setState({ payout_bankAccountId: value });
  }

  handlePayout_ibanChange(value) {
    this.setState({ payout_iban: value });
  }

  handlePayout_swiftBicChange(value) {
    this.setState({ payout_swiftBic: value });
  }

  isValidated = item => {
    this.setState(item);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      validationCount: this.state.validationCount + 1,
      vendorPrincipalValidation: false,
      vendorInfoValidation: false,
      payoutInfoValidation: false
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    // return a boolean value
    if (
      nextState.payoutInfoValidation === true &&
      nextState.vendorInfoValidation === true &&
      nextState.vendorPrincipalValidation === true
    ) {
      this.sendVendorRequest();
    }
    return true;
  }

  sendVendorRequest = () => {
    const vendor = {
      email: this.state.vendor_email,
      phone: this.state.vendor_phone,
      address: this.state.vendor_address,
      city: this.state.vendor_city,
      country: this.state.vendor_country,
      state: this.state.vendor_state,
      zip: this.state.vendor_zip,
      taxId: this.state.vendor_taxId,
      vatId: this.state.vendor_vatId,
      defaultPayoutCurrency: this.state.vendor_currency,
      frequency: "DAILY",
      delay: 3,
      vendorPrincipal: {
        firstName: this.state.principal_firstName,
        lastName: this.state.principal_lastName,
        address: this.state.principal_address,
        city: this.state.principal_city,
        country: this.state.principal_country,
        zip: this.state.principal_zip,
        dob: this.state.principal_dob,
        personalIdentificationNumber: this.state
          .principal_personalIdentificationNumber,
        driverLicenseNumber: this.state.principal_driverLicenseNumber,
        passportNumber: this.state.principal_passportNumber,
        email: this.state.principal_email
      },
      vendorAgreement: {
        commissionPercent: "30"
      },
      payoutInfo: {
        payoutType: this.state.payout_payoutType,
        baseCurrency: this.state.payout_baseCurrency,
        nameOnAccount: this.state.payout_nameOnAccount,
        bankAccountType: this.state.payout_bankAccountType,
        bankAccountClass: this.state.payout_bankAccountClass,
        bankName: this.state.payout_bankName,
        bankId: this.state.payout_bankId,
        country: this.state.payout_country,
        state: this.state.payout_state,
        city: this.state.payout_city,
        address: this.state.payout_address,
        zip: this.state.payout_zip,
        bankAccountId: this.state.payout_bankAccountId,
        iban: this.state.payout_iban,
        swiftBic: this.state.payout_swiftBic
      }
    };
    if (this.state.vendor_vendorType === "Business") {
      vendor["name"] = this.state.vendor_name;
    } else {
      vendor["firstName"] = this.state.vendor_firstName;
      vendor["lastName"] = this.state.vendor_lastName;
    }
    if (this.state.vendor_currency) {
      vendor["defaultPayoutCurrency"] = this.state.vendor_currency;
    }
    vendor["vendorType"] = this.state.vendor_vendorType;
    VendorApi.updateVendorDetails(vendor);
  };

  componentDidMount() {
    const { vendorDetails } = this.props;
    const principal = vendorDetails.vendorPrincipal;
    const payoutInfo = vendorDetails.payoutInfo;
    if (principal) {
      this.setState({
        vendor_vendorType: vendorDetails.vendorType,
        vendor_name: vendorDetails.name,
        vendor_firstName: vendorDetails.firstName,
        vendor_lastName: vendorDetails.lastName,
        vendor_email: vendorDetails.email,
        vendor_phone: vendorDetails.phone,
        vendor_address: vendorDetails.address,
        vendor_city: vendorDetails.city,
        vendor_country: vendorDetails.country,
        vendor_state: vendorDetails.state,
        vendor_zip: vendorDetails.zip,
        vendor_taxId: vendorDetails.taxId,
        vendor_vatId: vendorDetails.vatId,
        vendor_currency: vendorDetails.defaultPayoutCurrency,
        principal_firstName: principal.firstName,
        principal_lastName: principal.lastName,
        principal_address: principal.address,
        principal_city: principal.city,
        principal_country: principal.country,
        principal_zip: principal.zip,
        principal_dob: principal.dob,
        principal_personalIdentificationNumber:
          principal.personalIdentificationNumber,
        principal_driverLicenseNumber: principal.driverLicenseNumber,
        principal_passportNumber: principal.passportNumber,
        principal_email: principal.email,
        payout_payoutType: payoutInfo.payoutType,
        payout_baseCurrency: payoutInfo.baseCurrency,
        payout_bankName: payoutInfo.bankName,
        payout_address: payoutInfo.address,
        payout_country: payoutInfo.country,
        payout_state: payoutInfo.state,
        payout_city: payoutInfo.city,
        payout_zip: payoutInfo.zip,
        payout_nameOnAccount: payoutInfo.nameOnAccount,
        payout_bankAccountClass: payoutInfo.bankAccountClass,
        payout_bankAccountType: payoutInfo.bankAccountType,
        payout_bankId: payoutInfo.bankId,
        payout_bankAccountId: payoutInfo.bankAccountId,
        payout_iban: payoutInfo.iban,
        payout_swiftBic: payoutInfo.swiftBic
      });
    }
  }

  render() {
    const { vendorDetails, userSettings, theme } = this.props;
    const { url } = this.props.match;
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];
    const margin = {
      margin: "0 8px 8px 0"
    };
    const bg = { backgroundColor: theme.palette["primary"][0] };
    const direction =
      userSettings && userSettings.language && userSettings.language.direction
        ? userSettings.language.direction
        : "ltr";
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Layout style={{ height: "100vh" }}>
          <Debounce time="1000" handler="onResize">
            <WindowSizeListener
              onResize={windowSize =>
                this.props.toggleAll(
                  windowSize.windowWidth,
                  windowSize.windowHeight
                )
              }
            />
          </Debounce>
          <Topbar data-rtl={direction} url={url} showMenuBtn={false} />
          <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
            <Layout
              style={{
                height: "100vh"
              }}
            >
              <Content
                className="isomorphicContent"
                style={{
                  padding: "70px 0 0",
                  flexShrink: "0"
                }}
              >
                <ContentHolder>
                  <Layout style={{ margin: "0 17px" }}>
                    <VendorMenu
                      direction={direction}
                      selectedKey={this.state.selectedKey}
                      changeCategory={this.changeCategory}
                    />
                    <VendorDetailsWrapper data-rtl={direction}>
                      <Content className="settingsContent">
                        <Content
                          style={{
                            background: "#fff",
                            padding: "24px",
                            minWidth: "70%"
                          }}
                        >
                          {this.state.category === "0" ? (
                            <ContentHolder direction={direction}>
                              <p className="categoryTitle">Introduction</p>
                              <p>
                                Before you can get payment, we first need to
                                authorize you, and get your details.
                              </p>
                              <p>
                                After you feel all necessary details and sumit
                                the authorization request you can start
                                providing paid services instantely, but money
                                will be delivered to you only after your request
                                will be approved.
                              </p>

                              {/* <Alert
                                  message="Vendor information"
                                  description="before you can get payment, we first need to authorize you, and get your details. after you feel all necessary details and sumit the authorization request you can start providing paid services instantely, but  money will be delivered to you only after you rour request will be approved"
                                  type="info"
                                  showIcon
                                  style={{marginBottom: '0px', backgroundColor:'#ffffff', border:'none'}}
                                /> */}
                              <div
                                style={{
                                  paddingTop: "2em",
                                  paddingBottom: "2em",
                                  paddingRight: "40px",
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={this.goToCompany}
                                  type="primary"
                                  style={(margin, bg)}
                                >
                                  Lets start
                                </Button>
                              </div>
                            </ContentHolder>
                          ) : null}
                          {this.state.category === "1" ? (
                            <ContentHolder direction={direction}>
                              <p className="categoryTitle">
                                Companys Information
                              </p>
                              <VendorInfo
                                handleVendor_vendorTypeChange={
                                  this.handleVendor_vendorTypeChange
                                }
                                handleVendor_nameChange={
                                  this.handleVendor_nameChange
                                }
                                handleVendor_firstNameChange={
                                  this.handleVendor_firstNameChange
                                }
                                handleVendor_lastNameChange={
                                  this.handleVendor_lastNameChange
                                }
                                handleVendor_emailChange={
                                  this.handleVendor_emailChange
                                }
                                handleVendor_phoneChange={
                                  this.handleVendor_phoneChange
                                }
                                handleVendor_addressChange={
                                  this.handleVendor_addressChange
                                }
                                handleVendor_cityChange={
                                  this.handleVendor_cityChange
                                }
                                handleVendor_countryChange={
                                  this.handleVendor_countryChange
                                }
                                handleVendor_stateChange={
                                  this.handleVendor_stateChange
                                }
                                handleVendor_zipChange={
                                  this.handleVendor_zipChange
                                }
                                handleVendor_taxIdChange={
                                  this.handleVendor_taxIdChange
                                }
                                handleVendor_vatIdChange={
                                  this.handleVendor_vatIdChange
                                }
                                handleVendor_currencyChange={
                                  this.handleVendor_currencyChange
                                }
                                validationCount={this.state.validationCount}
                                validate={this.state.validate}
                                isValidated={this.isValidated}
                                vendorDetails={vendorDetails}
                              />
                              <div
                                style={{
                                  paddingTop: "2em",
                                  paddingBottom: "2em",
                                  paddingRight: "40px",
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={this.goToIntroduction}
                                  style={margin}
                                >
                                  back
                                </Button>
                                <Button
                                  onClick={this.goToPrincipal}
                                  type="primary"
                                  style={(margin, bg)}
                                >
                                  next
                                </Button>
                              </div>
                            </ContentHolder>
                          ) : null}

                          {this.state.category === "2" ? (
                            <ContentHolder direction={direction}>
                              <p className="categoryTitle">
                                Principals Information
                              </p>
                              <VendorPrincipal
                                direction={direction}
                                handlePrincipal_firstNameChange={
                                  this.handlePrincipal_firstNameChange
                                }
                                handlePrincipal_lastNameChange={
                                  this.handlePrincipal_lastNameChange
                                }
                                handlePrincipal_addressChange={
                                  this.handlePrincipal_addressChange
                                }
                                handlePrincipal_cityChange={
                                  this.handlePrincipal_cityChange
                                }
                                handlePrincipal_countryChange={
                                  this.handlePrincipal_countryChange
                                }
                                handlePrincipal_zipChange={
                                  this.handlePrincipal_zipChange
                                }
                                handlePrincipal_dobChange={
                                  this.handlePrincipal_dobChange
                                }
                                handlePrincipal_personalIdentificationNumberChange={
                                  this
                                    .handlePrincipal_personalIdentificationNumberChange
                                }
                                handlePrincipal_driverLicenseNumberChange={
                                  this.handlePrincipal_driverLicenseNumberChange
                                }
                                handlePrincipal_passportNumberChange={
                                  this.handlePrincipal_passportNumberChange
                                }
                                handlePrincipal_emailChange={
                                  this.handlePrincipal_emailChange
                                }
                                validationCount={this.state.validationCount}
                                validate={this.state.validate}
                                isValidated={this.isValidated}
                                vendorDetails={vendorDetails}
                              />
                              <div
                                style={{
                                  paddingTop: "2em",
                                  paddingBottom: "2em",
                                  paddingRight: "40px",
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={this.goToCompany}
                                  style={margin}
                                >
                                  back
                                </Button>
                                <Button
                                  onClick={this.goToBank}
                                  type="primary"
                                  style={(margin, bg)}
                                >
                                  next
                                </Button>
                              </div>
                            </ContentHolder>
                          ) : null}

                          {this.state.category === "3" ? (
                            <ContentHolder direction={direction}>
                              <p className="categoryTitle">
                                Bank accounts Information
                              </p>
                              <PayoutInfo
                                direction={direction}
                                handlePayout_payoutTypeChange={
                                  this.handlePayout_payoutTypeChange
                                }
                                handlePayout_baseCurrencyChange={
                                  this.handlePayout_baseCurrencyChange
                                }
                                handlePayout_bankNameChange={
                                  this.handlePayout_bankNameChange
                                }
                                handlePayout_addressChange={
                                  this.handlePayout_addressChange
                                }
                                handlePayout_countryChange={
                                  this.handlePayout_countryChange
                                }
                                handlePayout_stateChange={
                                  this.handlePayout_stateChange
                                }
                                handlePayout_cityChange={
                                  this.handlePayout_cityChange
                                }
                                handlePayout_zipChange={
                                  this.handlePayout_zipChange
                                }
                                handlePayout_nameOnAccountChange={
                                  this.handlePayout_nameOnAccountChange
                                }
                                handlePayout_bankAccountClassChange={
                                  this.handlePayout_bankAccountClassChange
                                }
                                handlePayout_bankAccountTypeChange={
                                  this.handlePayout_bankAccountTypeChange
                                }
                                handlePayout_bankIdChange={
                                  this.handlePayout_bankIdChange
                                }
                                handlePayout_bankAccountIdChange={
                                  this.handlePayout_bankAccountIdChange
                                }
                                handlePayout_ibanChange={
                                  this.handlePayout_ibanChange
                                }
                                handlePayout_swiftBicChange={
                                  this.handlePayout_swiftBicChange
                                }
                                validationCount={this.state.validationCount}
                                validate={this.state.validate}
                                isValidated={this.isValidated}
                                vendorDetails={vendorDetails}
                              />
                              <div
                                style={{
                                  paddingTop: "2em",
                                  paddingBottom: "2em",
                                  paddingRight: "40px",
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={this.onDeclineClicked}
                                  style={margin}
                                >
                                  cancel
                                </Button>
                                <Button
                                  disabled={false}
                                  onClick={this.handleSubmit}
                                  type="primary"
                                  style={(margin, bg)}
                                >
                                  submit
                                </Button>
                              </div>
                            </ContentHolder>
                          ) : null}
                        </Content>
                      </Content>
                    </VendorDetailsWrapper>
                  </Layout>
                </ContentHolder>
              </Content>
              {/* {this.state.category === '0'
                  ? <div style={{paddingTop:'2em', paddingBottom:'2em', paddingRight: '40px', display: 'flex',justifyContent: 'flex-end'}}>
                    <Button onClick={this.goToCompany} type="primary" style={margin}>Let's start</Button>
                  </div>
                  : null
                  }
                  {this.state.category === '1'
                  ? <div style={{paddingTop:'2em', paddingBottom:'2em', paddingRight: '40px', display: 'flex',justifyContent: 'flex-end'}}>
                    <Button onClick={this.goToIntroduction} style={margin}>back</Button>
                    <Button onClick={this.goToPrincipal} type="primary" style={margin}>next</Button>
                  </div>
                  : null
                  }

                  {this.state.category === '2'
                  ? <div style={{paddingTop:'2em', paddingBottom:'2em', paddingRight: '40px', display: 'flex',justifyContent: 'flex-end'}}>
                    <Button onClick={this.goToCompany} style={margin}>back</Button>
                    <Button onClick={this.goToBank} type="primary" style={margin}>next</Button>
                  </div>
                  : null
                  }


                  {this.state.category === '3'
                  ? <div style={{paddingTop:'2em', paddingBottom:'2em', paddingRight: '40px', display: 'flex',justifyContent: 'flex-end'}}>
                    <Button onClick={this.onDeclineClicked} style={margin}>cancel</Button>
                    <Button disabled={false} onClick={this.handleSubmit} type="primary" style={margin}>submit</Button>
                  </div>
                  : null
                } */}
            </Layout>
          </Layout>
        </Layout>
      </IntlProvider>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    auth: state.Auth,
    localState: state.LocalState,
    vendorDetails: state.Firebase.vendorDetails.details,
    data: state.Firebase.userData,
    userSettings: state.Firebase.userData.settings
  }),
  { logout, toggleAll }
)(withTheme(VendorDetails));
