import { ADD_ENTITY, REMOVE_ENTITY } from "../../constants";

const INITIAL_STATE = [];

function entities(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ENTITY:
      return [
        ...state.filter(entity => entity.entityId !== action.entity.entityId),
        action.entity
      ];

    case REMOVE_ENTITY:
      return state.filter(entity => entity.entityId !== action.id);

    default:
      return state;
  }
}

export default entities;
