import styled from "styled-components";
import {palette} from "styled-theme";
import WithDirection from "../../settings/withDirection";

const CircleSwitcherWrapper = styled.div`
  p {
    color: ${palette("text", 1)} !important;
    margin: 0;
  }
`;

export default WithDirection(CircleSwitcherWrapper);
