import { ADD_SESSION_MEMBER_PRESENCE } from "../../constants";
import { put } from "redux-saga/effects";

export function* setSessionMembersPresenceUpdate(
  value,
  sessionId,
  key,
  metaType
) {
  yield put({
    type: ADD_SESSION_MEMBER_PRESENCE,
    sessionId: sessionId,
    userId: key,
    item: value
  });
}
