import styled from "styled-components";

const PrivacyWrapper = styled.div`
  background-color: transparent;
  .iubenda_embed_policy .iub_base_container {
    margin: 0 !important;
  }

  h1,
  h2 {
    color: ${props =>
      props["theme"] === "darkTheme"
        ? "#ffffff !important"
        : "#000000 !important"};
  }
  p {
    color: ${props =>
      props["theme"] === "darkTheme"
        ? "rgba(255,255,255,0.6) !important"
        : "rgba(0,0,0,0.6) !important"};
  }
  a {
    color: ${props =>
      props["theme"] === "darkTheme"
        ? "#82DBE2 !important"
        : "#82DBE2 !important"};
    border-bottom: 1px solid #82dbe2 !important;
  }

  h3,
  h4 {
    color: ${props =>
      props["theme"] === "darkTheme"
        ? "rgba(255,255,255,0.7) !important"
        : "rgba(0,0,0,0.7) !important"};
  }
  .box_primary {
    background-color: ${props =>
      props["theme"] === "darkTheme"
        ? "#172021 !important"
        : "#ffffff !important"};
  }
`;

export default PrivacyWrapper;
