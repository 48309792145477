import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Popover, Switch } from "antd";
import { Link } from "react-router-dom";
import TopbarUserWrapper from "./topbarUser.style";
import Backend from "../../api/Backend";
import { withTheme } from "styled-components";
import {
  SettingOutlined,
  LogoutOutlined,
  BgColorsOutlined,
  RightOutlined,
  UserOutlined
} from "@ant-design/icons";
import { getDirection } from "../../redux/selectors/user";
import { useSelector } from "react-redux";

const TopbarUser = props => {
  const { theme, session } = props;
  const direction = useSelector(state => getDirection(state));
  const userInfo = useSelector(state => state.Firebase.userData);
  const localState = useSelector(state => state.LocalState);
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  const logOut = () => {
    Backend.logout();
  };

  const onChangeDarkMode = () => {
    const newTheme =
      userInfo.settings && userInfo.settings.theme === "dark"
        ? "light"
        : "dark";
    const text = "Account data was updated successfully!";

    Backend.updateAccount(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      newTheme,
      text
    );
  };

  const content = (
    <TopbarUserWrapper data-rtl={direction}>
      <div className="dropdownContainer">
        <Link
          to={{
            pathname: `/${localState.uid}/settings`,
            category: "profile"
          }}
        >
          <div className="itemContainer">
            <div className="itemLeft">
              <img
                className="userImage"
                alt="user"
                src={
                  userInfo.general_info && userInfo.general_info.photoUrl
                    ? userInfo.general_info.photoUrl.uri
                    : ""
                }
              />
            </div>

            <div className="itemMiddle">
              <p className="primaryText">
                {userInfo.general_info && userInfo.general_info.fullName
                  ? userInfo.general_info.fullName
                  : ""}
              </p>
              <p>see your profile</p>
            </div>
          </div>
        </Link>

        <div className="devider" />

        <Link
          to={{
            pathname: `/${localState.uid}/settings`,
            category: "account"
          }}
        >
          <div className="itemContainer">
            <div className="itemLeft">
              <div className="iconContainer">
                <SettingOutlined />
              </div>
            </div>
            <div className="itemMiddle">
              <p className="primaryText">Settings & privacy</p>
            </div>

            <div className="itemRight">
              <RightOutlined />
            </div>
          </div>
        </Link>

        <div className="itemContainer" onClick={onChangeDarkMode}>
          <div className="itemLeft">
            <div className="iconContainer">
              <BgColorsOutlined />
            </div>
          </div>
          <div className="itemMiddle">
            <p className="primaryText">Dark Mode</p>
          </div>

          <div className="itemRight">
            <Switch
              checked={
                userInfo.settings ? userInfo.settings.theme === "dark" : false
              }
            />
          </div>
        </div>

        <Link to={`/signin`}>
          <div className="itemContainer" onClick={logOut}>
            <div className="itemLeft">
              <div className="iconContainer">
                <LogoutOutlined />
              </div>
            </div>
            <div className="itemMiddle">
              <p className="primaryText">Logout</p>
            </div>

            <div className="itemRight">
              <RightOutlined />
            </div>
          </div>
        </Link>
      </div>
    </TopbarUserWrapper>
  );

  return (
    <TopbarUserWrapper data-rtl={direction}>
      <Popover
        content={content}
        placement={session ? "right" : "bottom"}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        arrowPointAtCenter={true}
      >
        <div className="topbarUserContainer">
          {userInfo.general_info && userInfo.general_info.photoUrl ? (
            <img
              className="topbarUserImage"
              alt="user"
              src={
                userInfo.general_info && userInfo.general_info.photoUrl
                  ? userInfo.general_info.photoUrl.uri
                  : ""
              }
            />
          ) : (
            <UserOutlined
              style={{
                padding: "5px",
                background: theme.palette["surfaceColor"][0],
                borderRadius: "20px",
                fontSize: "16px"
              }}
            />
          )}
          <p className="topbarUserName">
            {userInfo.general_info && userInfo.general_info.fullName
              ? userInfo.general_info.fullName
              : ""}
          </p>
          <span className="userActivity online" />
        </div>
      </Popover>
    </TopbarUserWrapper>
  );
};

TopbarUser.propTypes = {
  theme: PropTypes.object,
  session: PropTypes.object
};

export default withTheme(TopbarUser);
