import {
  ADD_RESOLVED_SESSION,
  ADD_LAST_VISIBLE_RESOLVED_SESSION
} from "../../constants";

const INITIAL_STATE = {
  lastVisible: null
};
const resolvedSessions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_RESOLVED_SESSION:
      return {
        ...state,
        [action.circleId]: state[action.circleId]
          ? [
              ...state[action.circleId].filter(
                session => session.sessionId !== action.session.sessionId
              ),
              action.session
            ]
          : [action.session]
      };

    case ADD_LAST_VISIBLE_RESOLVED_SESSION:
      return {
        ...state,
        lastVisible: {
          [action.circleId]: action.lastVisible
        }
      };
    default:
      return state;
  }
};

export default resolvedSessions;
