import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { Button } from "antd";
import IntlMessages from "../../utility/intlMessages";
import { Form } from "antd";
import AuthApi from "../../../api/AuthApi";

const CreateEntity = props => {
  const { hideCreate, data } = props;
  const [form] = Form.useForm();

  // const userUid = useSelector(state => state.Auth.uid);
  // useEffect(() => {
  //   if (isLoggedIn === true) {
  //     history.push(`/${userUid}/console`);
  //   }
  // }, [isLoggedIn, history, userUid]);

  const handleSubmit = values => {
    hideCreate();
    AuthApi.createEntity(values.email, values.password, values.name, data);
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 8
      }
    },
    wrapperCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 16
      }
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={handleSubmit}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please input your name!",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!"
          },
          {
            required: true,
            message: "Please input a valid E-mail!"
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input a password!"
          }
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm the password!"
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button onClick={hideCreate}>
          <IntlMessages id="app.buttons.cancel" />
        </Button>
        <Button style={{ margin: "0 10px" }} type="primary" htmlType="submit">
          <IntlMessages id="app.buttons.submit" />
        </Button>
      </Form.Item>
    </Form>
  );
};

CreateEntity.propTypes = {
  data: PropTypes.object,
  hideCreate: PropTypes.func
};

export default CreateEntity;
