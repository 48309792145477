import styled from "styled-components";
import { palette } from "styled-theme";

const CallerInfoWrapper = styled.div`
    display: flex;
    height: 100%;
    width:100%;
    flex-direction: column;
   .sessionCallerTopContiner { 
    p {
      margin: 0;
     }
    .timerContainer {
      display: flex;
      align-items: center;
      .iconWrapper{
        margin: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
        width:40px;
        height:40px;
        background-color:${palette("surfaceColor", 0)};
        border-radius:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        .anticon {
          font-size:18px;
          color: ${palette("text", 0)};

        }

      }

    }
  }
  .sessionInnerContainer {
    padding-top:10px;
    .sessionCallerImageContiner {
      flex-direction: column;
      align-items: center;
      display: flex;
      overflow: auto;
      padding-top:10px;
      .sessionCallerImage {
        margin: 0 0 1em 0;
        width: 120px;
        height: 120px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 60px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .sessionCallerGeneralDetails {
        h3,
        p {
          text-align: center;
        }
      }
      .sessionCallerMedicalContainer {
        display: flex;
        flex-direction: column;
        padding: 0 1em;
        height:65px;
        p{
          text-align:center
        }
        .callerDetail {
          display: flex;
          align-items: center;
          padding: ${props =>
            props["data-rtl"] === "rtl" ? "0 1em 0 1em" : "0 1em 0 1em"};
          p {
            margin: 0;
          }
          i {
            font-size: 20px;
            padding: ${props =>
              props["data-rtl"] === "rtl" ? "0 0 0 0.5em" : "0 0.5em 0 0"};
            color: ${palette("title", 0)};
          }
        }
      }
      .detailsContainer {
        /* height: ${props => props["height"] - 334}px; */
        flex-grow: 1;
        overflow-y: scroll;
        /* padding-left: 1em; */
        width: 100%;
        /* background-color: ${palette("lightBg", 1)}; */

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
        .fieldTitle {
          display: flex;
          margin-top: 1em;
          width: 100%;

          p {
            margin: 0;
            background-color: ${palette("background", 0)};
            color:${palette("text", 0)};
          }
        }
        p {
          width: 100%;
          /* text-align: left; */
          padding: 0.2em 1em;
          color:${palette("lightText", 0)};
          /* background-color: ${palette("surfaceColor", 0)}; */
        }

      }
      .loaderContainer {
        position:absolute;
        top:25%;
        height: ${props => props["height"] - 378}px;
        display: flex;
        width:100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default CallerInfoWrapper;
