import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Loading from "./containers/App/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { withTheme } from "styled-components";
import { useSelector } from "react-redux";

const RestrictedRouteWrapper = props => {
  const [animate, setAnimate] = useState(false);
  const { component, theme } = props;
  const fetched = useSelector(
    state =>
      state.Firebase.fetch.circlesFetched === true &&
      state.Firebase.fetch.plansFetched === true &&
      state.Firebase.fetch.userFetched === true &&
      state.Firebase.fetch.activePlanFetched === true
  );

  useEffect(() => {
    if (fetched) {
      setTimeout(function() {
        setAnimate(true);
      }, 500);
    }
  }, [fetched]);
  const Component = component;
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.palette["surfaceColor"][0]
      }}
    >
      <AnimatePresence>{!animate && <Loading />}</AnimatePresence>
      <motion.div
        transition={{ duration: 1 }}
        key={"2"}
        initial="hidden"
        animate={animate ? "visible" : "hidden"}
        variants={variants}
      >
        <Component {...props} />
      </motion.div>
    </div>
  );
};

RestrictedRouteWrapper.propTypes = {
  fetched: PropTypes.bool,
  theme: PropTypes.object
  // component: PropTypes.func
};

export default withTheme(RestrictedRouteWrapper);
