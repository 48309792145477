import styled from "styled-components";
import { palette } from "styled-theme";

const ItemWrapper = styled.li`
  background-color: ${palette("surfaceColor", 0)};
  padding: 20px;
  border-radius: 20px;
  border: 1px solid ${palette("border", 0)};
  min-height: 350px;
  &:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16) !important;
  }
  &.add {
    background-color: ${palette("primary", 0)};
  }
  h1 {
    font-size: 24px !important;
  }
  .circleContent {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .circleCategories {
      flex: 1;
      padding: 20px 0;
      margin: 0;
      list-style: none;
      .circleCategory {
        display: flex;
        align-items: center;
        padding: 0 0 10px 0;
        .categoryIcon {
          height: 36px;
          width: 36px;
          border-radius: 20px;
          background: ${palette("iconBg", 0)};
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .categoryNumber {
          color: ${palette("text", 0)};
          font-size: 20px;
        }
        p {
          margin: 0;
          padding: ${props =>
            props["data-rtl"] === "rtl"
              ? "0px  10px 0px 0px"
              : "0px 0px 0px 10px"};
          font-size: 16px;
        }
      }
    }
    .circleImg {
      width: 80px;
      height: 80px;
      border-radius: 100px;
    }
    .iconWrapper {
      width: 80px;
      height: 80px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${palette("selected", 0)};
      font-size: 30px;
    }
  }
  .membersAvatarsContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    .memberAvatar {
      display: flex;
      border-width: 1px;
      height: 36px;
      margin-right: 3px;
      width: 36px;
      border-color: ${palette("border", 1)};
      border-radius: 50%;
      border-style: solid;
    }
    .iconWrapper {
      border-width: 1px;
      height: 36px;
      margin-right: 3px;
      width: 36px;
      border-color: ${palette("border", 1)};
      border-radius: 50%;
      border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .newCircleTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    h1 {
      color: ${palette("onDarkBgText", 0)};
    }
    .circleIcon {
      margin-top: 3em;
      height: 160px;
      width: 160px;
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px ${palette("onDarkBgText", 0)};
      i {
        font-size: 80px;
        color: ${palette("onDarkBgText", 0)};
      }
    }
    .addIcon {
      background-color: ${palette("secondary", 0)};
      width: 26px;
      height: 26px;
      display: flex;
      border-radius: 13px;
      align-items: center;
      justify-content: center;
      margin-top: -20px;
      margin-right: -40px;
      border: 2px solid #ffffff;
      i {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
`;

const CirclesListWrapper = styled.div`
  padding: 50px 35px;
  max-width: 1200px;
  list-style: none;
  width: 100%;
`;

export { ItemWrapper, CirclesListWrapper };
