import React from "react";
import { PropTypes } from "prop-types";
import { CheckOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";

const SiderStep = props => {
  const { text, number, status, theme } = props;
  const icon = {
    initial: {
      backgroundColor: theme.palette["iconBg"][0],
      borderColor: theme.palette["iconBg"][0],
      borderWidth: "1px",
      borderStyle: "solid",

      color: theme.palette["lightText"][0]
    },
    completed: {
      backgroundColor: theme.palette["primary"][0],
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette["primary"][0],

      color: theme.palette["surfaceColor"][0]
    },
    inProcess: {
      backgroundColor: theme.palette["surfaceColor"][0],
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette["secondary"][0],
      color: theme.palette["text"][0]
    }
  };
  const label = {
    initial: {
      color: theme.palette["text"][0],
      fontSize: "16px"
    },
    completed: {
      color: theme.palette["lightText"][0],
      fontSize: "16px"
    },
    inProcess: {
      color: theme.palette["secondary"][0],
      fontSize: "19px"
    }
  };
  return (
    <div className="siderStep">
      <motion.div
        variants={icon}
        initial="initial"
        animate={status}
        className="siderStepIcon"
      >
        {status === "completed" ? <CheckOutlined /> : <span>{number}</span>}
      </motion.div>
      <motion.p
        variants={label}
        initial="initial"
        animate={status}
        className="siderStepText"
      >
        {text}
      </motion.p>
    </div>
  );
};

SiderStep.propTypes = {
  direction: PropTypes.string,
  number: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(SiderStep);
