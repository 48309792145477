import { ADD_CIRCLE } from "../../constants";
import { put } from "redux-saga/effects";

// listens when there is a change in one of the user circles details (location, name, photoUrl)
export function* setCircleUpdate(data, circleId, metaType) {
  const circle = data.snapshot.data();
  if (circle !== undefined) {
    circle["id"] = circleId;
    yield put({
      type: ADD_CIRCLE,
      circle: circle,
      circleMembers: [],
      circleId: circleId
    });
  }
}
