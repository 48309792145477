import React from "react";
import ContentHolder from "../utility/contentHolder";
import { Input } from "antd";
import { Form, Tooltip, Select, Radio } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import countryCodes from "./countryCodes";
import usStates from "./usStates";
import caStates from "./caStates";
import payoutBaseCurrency from "./payoutBaseCurrency";
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

class VendorInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorType: "Business",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      taxId: "",
      vatId: "",
      defaultPayoutCurrency: ""
    };
    this.handleVendorTypeChange = this.handleVendorTypeChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleTaxIdChange = this.handleTaxIdChange.bind(this);
    this.handleVatIdChange = this.handleVatIdChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }
  formRef = React.createRef();

  handleVendorTypeChange = e => {
    this.setState({
      vendorType: e.target.value
    });
    this.props.handleVendor_vendorTypeChange(e.target.value);
  };

  handleNameChange(event) {
    this.setState({ name: event.target.value });
    this.props.handleVendor_nameChange(event.target.value);
  }

  handleFirstNameChange(event) {
    this.setState({ firstName: event.target.value });
    this.props.handleVendor_firstNameChange(event.target.value);
  }

  handleLastNameChange(event) {
    this.setState({ lastName: event.target.value });
    this.props.handleVendor_lastNameChange(event.target.value);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
    this.props.handleVendor_emailChange(event.target.value);
  }

  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
    this.props.handleVendor_phoneChange(event.target.value);
  }

  handleAddressChange(event) {
    this.setState({ address: event.target.value });
    this.props.handleVendor_addressChange(event.target.value);
  }

  handleCityChange(event) {
    this.setState({ city: event.target.value });
    this.props.handleVendor_cityChange(event.target.value);
  }

  handleCountryChange(value) {
    this.setState({ country: value });
    this.props.handleVendor_countryChange(value);
  }

  handleStateChange(value) {
    this.setState({ state: value });
    this.props.handleVendor_stateChange(value);
  }

  handleZipChange(event) {
    this.setState({ zip: event.target.value });
    this.props.handleVendor_zipChange(event.target.value);
  }

  handleTaxIdChange(event) {
    this.setState({ taxId: event.target.value });
    this.props.handleVendor_taxIdChange(event.target.value);
  }

  handleVatIdChange(event) {
    this.setState({ vatId: event.target.value });
    this.props.handleVendor_vatIdChange(event.target.value);
  }

  handleCurrencyChange(value) {
    this.setState({ defaultPayoutCurrency: value });
    this.props.handleVendor_currencyChange(value);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state && nextProps.validationCount > 0) {
      const value = { vendorInfoValidation: false };
      this.props.isValidated(value);
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validationCount !== this.state.validationCount) {
      this.validateFields();
    }
    if (prevState.vendorDetails !== this.state.vendorDetails) {
      const details = this.props.vendorDetails;
      if (details) {
        this.setState({
          vendorType: details.vendorType,
          name: details.name,
          firstName: details.firstName,
          lastName: details.lastName,
          email: details.email,
          phone: details.phone,
          address: details.address,
          city: details.city,
          country: details.country,
          state: details.state,
          zip: details.zip,
          taxId: details.taxId,
          vatId: details.vatId,
          defaultPayoutCurrency: details.defaultPayoutCurrency
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.validationCount !== prevState.validationCount) {
      return { validationCount: nextProps.validationCount };
    } else if (nextProps.vendorDetails !== prevState.vendorDetails) {
      return { vendorDetails: nextProps.vendorDetails };
    } else return null;
  }

  validateFields() {
    this.formRef.current.validateFields((err, values) => {
      if (!err) {
        const value = { vendorInfoValidation: true };
        this.props.isValidated(value);
      } else {
        const value = { vendorInfoValidation: false };
        this.props.isValidated(value);
      }
    });
  }

  componentDidMount() {
    const { vendorDetails } = this.props;
    const details = vendorDetails;
    if (details) {
      this.setState({
        vendorType: details.vendorType,
        name: details.name,
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        phone: details.phone,
        address: details.address,
        city: details.city,
        country: details.country,
        state: details.state,
        zip: details.zip,
        taxId: details.taxId,
        vatId: details.vatId,
        defaultPayoutCurrency: details.defaultPayoutCurrency
      });
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      }
    };
    const countriesOptions = [];
    for (let i = 0; i < countryCodes.length; i++) {
      countriesOptions.push(
        <Option key={i} value={countryCodes[i].code}>
          {countryCodes[i].name}
        </Option>
      );
    }

    const usStatesOptions = [];
    for (let i = 0; i < usStates.length; i++) {
      usStatesOptions.push(
        <Option key={i} value={usStates[i].abbreviation}>
          {usStates[i].name}
        </Option>
      );
    }

    const caStatesOptions = [];
    for (let i = 0; i < caStates.length; i++) {
      caStatesOptions.push(
        <Option key={i} value={caStates[i].abbreviation}>
          {caStates[i].name}
        </Option>
      );
    }

    const stateOptions =
      this.state.country === "US"
        ? usStatesOptions
        : this.state.country === "CA"
        ? caStatesOptions
        : null;

    const currenciesOptions = [];
    for (let i = 0; i < payoutBaseCurrency.length; i++) {
      currenciesOptions.push(
        <Option key={i} value={payoutBaseCurrency[i].code}>
          {payoutBaseCurrency[i].name}
        </Option>
      );
    }
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    return (
      <ContentHolder direction={this.props.direction}>
        <Form
          ref={this.formRef}
          initialValues={{
            vendorType: this.state.vendorType,
            name: this.state.name,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            country: this.state.country,
            state: this.state.state,
            zip: this.state.zip,
            taxId: this.state.taxId,
            vatId: this.state.vatId,
            defaultPayoutCurrency: this.state.defaultPayoutCurrency
          }}
        >
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Vendor type&nbsp;
                <Tooltip title="Are you Business or Individual?">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="vendorType"
            rules={[{ required: true }]}
          >
            <RadioGroup onChange={this.handleVendorTypeChange}>
              <Radio style={radioStyle} value={"Business"}>
                Business
              </Radio>
              <Radio style={radioStyle} value={"Individual"}>
                Individual
              </Radio>
            </RadioGroup>
          </FormItem>
          {this.state.vendorType === "Business" ? (
            <FormItem
              style={{ paddingTop: "0em" }}
              {...formItemLayout}
              label={
                <span>
                  Business name&nbsp;
                  <Tooltip title="Business name">
                    <QuestionOutlined />
                  </Tooltip>
                </span>
              }
              hasFeedback
              name="name"
              rules={[
                {
                  required: true,
                  message: "Business name is required",
                  whitespace: true
                }
              ]}
            >
              <Input onChange={this.handleNameChange} />
            </FormItem>
          ) : (
            <FormItem
              style={{ paddingTop: "0em" }}
              {...formItemLayout}
              label={
                <span>
                  First Name&nbsp;
                  <Tooltip title="Vendor first name">
                    <QuestionOutlined />
                  </Tooltip>
                </span>
              }
              hasFeedback
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                  whitespace: true
                }
              ]}
            >
              <Input onChange={this.handleFirstNameChange} />
            </FormItem>
          )}

          {this.state.vendorType === "Individual" ? (
            <FormItem
              style={{ paddingTop: "0em" }}
              {...formItemLayout}
              label={
                <span>
                  Last Name&nbsp;
                  <Tooltip title="Vendor last name">
                    <QuestionOutlined />
                  </Tooltip>
                </span>
              }
              hasFeedback
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                  whitespace: true
                }
              ]}
            >
              <Input onChange={this.handleLastNameChange} />
            </FormItem>
          ) : null}

          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Email&nbsp;
                <Tooltip title="Vendor email">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: "Email is required"
              }
            ]}
          >
            <Input onChange={this.handleEmailChange} />
          </FormItem>

          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Phone&nbsp;
                <Tooltip title="Vendor phone number">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="phone"
            rules={[
              {
                message: "The input is not valid phone number!"
              },
              {
                required: true,
                message: "Phone is required"
              }
            ]}
          >
            <Input onChange={this.handlePhoneChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Address&nbsp;
                <Tooltip title="Vendor address">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="address"
            rules={[{ required: false, message: "Address is required" }]}
          >
            <Input onChange={this.handleAddressChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                City&nbsp;
                <Tooltip title="Vendor City">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="city"
            rules={[
              {
                required: true,
                message: "City is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleCityChange} />
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Country&nbsp;
                <Tooltip title="Vendor Country">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="country"
            rules={[{ required: true, message: "Country is required" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleCountryChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {countriesOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                State&nbsp;
                <Tooltip title="Vendor State/Province">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="state"
            rules={[
              {
                required:
                  this.state.country === "US" || this.state.country === "CA",
                message: "State is required",
                whitespace: true
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your State"
              optionFilterProp="children"
              onChange={this.handleStateChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {stateOptions}
            </Select>
          </FormItem>
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Zip&nbsp;
                <Tooltip title="Vendor zip/postal code">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="zip"
            rules={[
              {
                required: true,
                message: "zip/postal code is required",
                whitespace: true
              }
            ]}
          >
            <Input onChange={this.handleZipChange} />
          </FormItem>
          {this.state.vendorType === "Business" ? (
            <FormItem
              style={{ paddingTop: "0em" }}
              {...formItemLayout}
              label={
                <span>
                  business ID number&nbsp;
                  <Tooltip title="The company’s business ID number. For example, EIN in the US, Business Number in Canada, Company Number in the UK, etc.">
                    <QuestionOutlined />
                  </Tooltip>
                </span>
              }
              hasFeedback
              name="taxId"
              rules={[
                {
                  required: this.state.vatId === "",
                  message: "business ID number is required",
                  whitespace: true
                }
              ]}
            >
              <Input onChange={this.handleTaxIdChange} />
            </FormItem>
          ) : null}
          {this.state.vendorType === "Business" ? (
            <FormItem
              style={{ paddingTop: "0em" }}
              {...formItemLayout}
              label={
                <span>
                  Vat Id&nbsp;
                  <Tooltip title="The company’s VAT number">
                    <QuestionOutlined />
                  </Tooltip>
                </span>
              }
              hasFeedback
              name="vatId"
              rules={[
                {
                  required: this.state.taxId === "",
                  message: "VAT number is required",
                  whitespace: true
                }
              ]}
            >
              <Input onChange={this.handleVatIdChange} />
            </FormItem>
          ) : null}
          <FormItem
            style={{ paddingTop: "0em" }}
            {...formItemLayout}
            label={
              <span>
                Currency&nbsp;
                <Tooltip title="Vendor's default payout currency. If a sale occurs in a currency for which there is not an associated bank account, sale funds will be converted to this currency">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
            name="defaultPayoutCurrency"
            rules={[{ required: false, message: "Currency is required" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select your country"
              optionFilterProp="children"
              onChange={this.handleCurrencyChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {currenciesOptions}
            </Select>
          </FormItem>
        </Form>
      </ContentHolder>
    );
  }
}

export default VendorInfo;
