import {
  eventTypes,
  UPDATE_SESSION_MEMBER_LOCATION,
  SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS
} from "../../constants";
import { put } from "redux-saga/effects";

export function* setSessionLocationUpdate(data, sessionId, metaType) {
  let allLocations = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j];
    // listens when session members locations are added, or modified
    if (
      change.type === eventTypes.ADDED ||
      change.type === eventTypes.MODIFIED
    ) {
      let location = change.doc.data() || {};
      if (data.snapshot.docChanges().length > 1) {
        allLocations.push(location);
        if (counter === data.snapshot.docChanges().length) {
          yield put({
            type: SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS,
            sessionId: sessionId,
            locations: allLocations
          });
        }
      } else {
        yield put({
          type: UPDATE_SESSION_MEMBER_LOCATION,
          sessionId: sessionId,
          location: location
        });
      }
    }
  }
}
