import styled from "styled-components";
import {palette} from "styled-theme";

const PaymentWrapper = styled.div`
  .topContainer {
    background: ${palette("primary", 0)};
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 0;
      color: #ffffff;
      font-family: Myfont;
      text-align: center;
    }
    p {
      margin: 0;
      color: #ffffff;
      text-align: center;
    }
    .topLeftButton {
      position: absolute;
      left: 1em;
    }
    .topRightButton {
      position: absolute;
      right: 1em;
    }
  }
  .pricing-form {
    /* background: #ffffff; */
    padding: 2em;
    .cardNumberContainer {
      display: flex;
      width: 100%;
      .cardNumber {
        padding-right: 8px;
        width: 100%;
      }
      img {
        margin-top: 39px;
        width: 42px;
      }
    }
  }
  .ant-form-explain.has-error {
    color: #f5222d;
  }
  .cardDetails {
    .ant-form-item {
      margin: 0;
      min-height: 90px;
    }
  }

  .form-control {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 32px;
    line-height: 1.5 \9;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &::placeholder {
      color: red;
    }
    input {
      color: red;
      &::placeholder {
        color: red;
      }
    }
  }
  .ant-form-item-control {
    line-height: initial;
    width: 100%;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin: 1em 0 0 !important;
    min-height: 0 !important;
  }
`;

export default PaymentWrapper;
