import styled from "styled-components";
import { palette } from "styled-theme";

const CardWidgetWrapper = styled.div`
  display: flex;
  padding: 20px 20px 10px 20px;
  min-width: 300px;
  background-color: ${palette("surfaceColor", 0)};
  border-radius: 10px;
  border: 1px solid ${palette("border", 0)};
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .widgetContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    align-items: center;
    .iconWrapper {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: ${palette("iconBg", 0)};
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 24px;
        color: ${palette("text", 0)};
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      justify-content: center;

      .contentNumber {
        font-size: 24px;
        margin: 0;
        color: ${palette("text", 0)};
      }
      .contentLabel {
        font-size: 20px;
        margin: 0;
        color: ${palette("lightText", 0)};
      }
    }
  }
`;

export default CardWidgetWrapper;
