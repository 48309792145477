import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Menu } from "antd";
import SettingsMenuWrapper from "../circleSettings/menu.style";
import { withTheme } from "styled-components";
import IntlMessages from "../utility/intlMessages";
import { getIsEntity } from "../../redux/selectors/user";
import { useSelector } from "react-redux";

const SettingsMenu = (props) => {
  const { type, changeCategory, theme, direction } = props;
  const [selectedKeys, setSelectedKeys] = useState("profile");
  const isEntity = useSelector((state) => getIsEntity(state));
  const router = useSelector((state) => state.router);

  useEffect(() => {
    if (router.location && router.location.category && type !== "mobile") {
      changeCategory(router.location.category);
      setSelectedKeys([router.location.category]);
    }
  }, [router, type]);

  const handleClick = (e) => {
    changeCategory(e.key);
    setSelectedKeys([e.key]);
  };
  // console.log(selectedKeys);

  const themeName = theme.name;
  const bg =
    themeName === "lightTheme"
      ? theme.palette["background"][0]
      : theme.palette["lightBg"][0];
  return (
    <SettingsMenuWrapper
      data-rtl={direction}
      bg={bg}
      settings={true}
      mobile={type === "mobile"}
    >
      <Menu
        direction="rtl"
        className="settingMenu"
        onClick={handleClick}
        mode="inline"
        defaultSelectedKeys={type === "mobile" ? [] : ["profile"]}
        selectedKeys={selectedKeys}
      >
        <Menu.Item key="profile">
          <span className="menuItem">
            <span className="nav-text">
              <IntlMessages id="app.settings.profile" />
            </span>
          </span>
        </Menu.Item>
        <Menu.Item key="account">
          <span className="menuItem">
            <span className="nav-text">
              <IntlMessages id="app.settings.account" />
            </span>
          </span>
        </Menu.Item>

        <Menu.Item key="usage">
          <span className="menuItem">
            <span className="nav-text">
              <IntlMessages id="app.settings.usage" />
            </span>
          </span>
        </Menu.Item>
        <Menu.Item key="privacy">
          <span className="menuItem">
            <span className="nav-text">
              <IntlMessages id="app.consent.privacy" />
            </span>
          </span>
        </Menu.Item>
        <Menu.Item key="terms">
          <span className="menuItem">
            <span className="nav-text">
              <IntlMessages id="app.consent.terms" />
            </span>
          </span>
        </Menu.Item>
      </Menu>
    </SettingsMenuWrapper>
  );
};

SettingsMenu.propTypes = {
  changeCategory: PropTypes.func,
  direction: PropTypes.string,
  type: PropTypes.string,
  router: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(SettingsMenu);

// {isEntity ? null : (
//   <Menu.Item key="entities">
//     <span className="menuItem">
//       <span className="nav-text">
//         <IntlMessages id="app.settings.entities" />
//       </span>
//     </span>
//   </Menu.Item>
// )}
//
// {isEntity ? null : (
//   <Menu.Item key="paymentSettings">
//     <span className="menuItem">
//       <span className="nav-text">
//         <IntlMessages id="app.settings.paymentSettings" />
//       </span>
//     </span>
//   </Menu.Item>
// )}
//
// {isEntity ? null : (
//   <Menu.Item key="paymentMethod">
//     <span className="menuItem">
//       <span className="nav-text">
//         <IntlMessages id="app.settings.paymentMethod" />
//       </span>
//     </span>
//   </Menu.Item>
// )}
