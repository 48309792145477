import { createSelector } from "reselect";

const pendingRequests = state =>
  state.Firebase.pendingRequests[state.LocalState.activeCircleId]
    ? state.Firebase.pendingRequests[state.LocalState.activeCircleId]
    : [];

const activePendingMemberId = state => state.LocalState.activePendingMemberId;

export const getPendingRequests = createSelector(
  [pendingRequests],
  pendingRequests => {
    return pendingRequests;
  }
);

export const getPendingRequestsCount = createSelector(
  [pendingRequests],
  pendingRequests => {
    return pendingRequests.length.toString();
  }
);

export const getSelectedRequest = createSelector(
  [pendingRequests, activePendingMemberId],
  (pendingRequests, activePendingMemberId) => {
    return activePendingMemberId !== ""
      ? pendingRequests.filter(
          contact => contact.userId === activePendingMemberId
        )[0]
      : null;
  }
);
