import styled from "styled-components";
import { palette } from "styled-theme";

const TopbarWrapper = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;

  .topbarInner {
    display: flex;
    justify-content: space-between;
    background-color: ${palette("surfaceColor", 0)} !important;
    border-bottom: 1px solid ${palette("border", 0)};

    .leftContainer {
      display: flex;
      align-items: center;
      margin: 0 20px;
    }

    .rightContainer {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      z-index: 2;
    }
  }
  .motionNav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 20000;

    .background {
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 300px;
      background: ${palette("secondary", 0)};
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
    }
    button {
      outline: none;
      border: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      position: absolute;
      top: 21px;
      left: 21px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      path {
        stroke: ${palette("onDarkBgText", 0)};
      }
    }
    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 25px;
      position: absolute;
      top: 60px;
    }

    li {
      list-style: none;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .nav-item {
        display: flex;
        .nav-icon {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background: ${palette("onSecondaryBg", 1)};
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${palette("onSecondaryBg", 0)};
        }
        .nav-text {
          line-height: 40px;
          padding: 0 20px;
          color: ${palette("onDarkBgLightText", 0)};
        }
      }
      &.active {
        .nav-item {
          .nav-icon {
            background: ${palette("onDarkBgText", 0)};
            span {
              color: ${palette("secondary", 0)};
            }
          }
          .nav-text {
            span {
              color: ${palette("onDarkBgText", 0)};
            }
          }
        }
      }
    }

    .icon-placeholder {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex: 40px 0;
      margin-right: 20px;
    }

    .text-placeholder {
      border-radius: 5px;
      width: 200px;
      height: 20px;
      flex: 1;
    }
  }
`;

export default TopbarWrapper;
