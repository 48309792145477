import styled from "styled-components";
import { palette } from "styled-theme";

const NewCircleWrapper = styled.div`
  flex-direction: row;
  overflow-x: hidden;
  margin-top: 64px;
  .sider {
    padding: 10px;
    .siderHeader {
      border-bottom: 1px solid ${palette("border", 0)};
      margin-bottom: 10px;
      padding: 10px 0;
      h1 {
        font-size: 24px;
        padding: 0 5px;
      }
    }
    .siderStep {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .siderStepIcon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: ${palette("iconBg", 0)};
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .siderStepText {
        font-size: 16px;
        margin: 0;
        padding: 0 20px;
      }
    }
  }
  .componentWrapper {
    padding: 20px;
    flex-direction: column;
    min-height: 400px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .componentInner {
      flex: 1;
    }
    .detailsForm {
      min-width: 300px;
      width: fit-content;
    }
    .header {
      padding-bottom: 20px;
    }
    .switchContainer {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        color: ${palette("text", 0)};
        padding: ${props =>
          props["data-rtl"] === "rtl" ? "0 0 0 20px" : "0 20px 0 0"};
      }
    }
  }
`;

export default NewCircleWrapper;
