import styled from "styled-components";

const UsageWrapper = styled.div`
  .ant-select-selection {
    margin-right: ${props => (props["data-rtl"] === "rtl" ? "0" : "1em")};
  }
  .selectContainer {
    padding-bottom: 2em;
  }
`;

export default UsageWrapper;
