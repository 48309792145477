import {
  CHANGE_MAP_VIEWPORT,
  CHANGE_MAP_FIELD,
  CHANGE_MAP_STYLE
} from "../../constants";

function defaultMapState() {
  const defaults = {};
  const initState = {
    viewport: {
      latitude: 31.87165385358801,
      longitude: 34.80026644886494,
      bearing: 0,
      zoom: 11,
      isDragging: false,
      startDragLngLat: undefined,
      ...defaults
    },
    mapStyle: "mapbox://styles/mapbox/light-v9"
  };
  return initState;
}

export default function MapBox(state = defaultMapState(), action = {}) {
  switch (action.type) {
    case CHANGE_MAP_VIEWPORT: {
      return {
        ...state,
        viewport: action.payload
      };
    }
    case CHANGE_MAP_FIELD: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          [action.field]: action.payload
        }
      };
    }
    case CHANGE_MAP_STYLE: {
      return {
        ...state,
        mapStyle: action.payload
      };
    }
    default:
      return state;
  }
}
