import {
  eventTypes,
  ADD_SESSION_MEMBER,
  SESSION_MEMBERS_FETCH_SUCCESS,
  FIREBASE_UNLISTEN_REQUESTED,
  FIRESTORE_UNLISTEN_REQUESTED,
  FIREBASE_OBJECT_LISTEN_REQUESTED
} from "../../constants";
import Backend from "../../../api/Backend";
import { store } from "../../store";
import firebase from "firebase/app";
import { eventChannel, buffers } from "redux-saga";
import { put, call, take } from "redux-saga/effects";

export function getMemberSnap(ref) {
  const listener = eventChannel(emit => {
    ref.get().then(MemberSnap => {
      emit({
        snapshot: MemberSnap
      });
    });
    return () => {};
  }, buffers.expanding(1));
  return listener;
}

export function* setSessionMemberUpdate(data, sessionId, metaType) {
  let allMembers = [];
  let counter = 0;
  for (let j = 0; j < data.snapshot.docChanges().length; j++) {
    counter++;
    const change = data.snapshot.docChanges()[j];
    // listens when session members are added
    if (change.type === eventTypes.ADDED) {
      let member = change.doc.data() || {};
      let members = store.getState().Firebase.participants[
        sessionId
      ]
        ? store.getState().Firebase.participants[sessionId]
        : [];
      yield put({
        type: FIREBASE_OBJECT_LISTEN_REQUESTED,
        payload: {
          ref: firebase.database().ref("status/" + change.doc.data().id),
          id: sessionId
        },
        meta: { type: "sessionMembersPresence" }
      });
      const previous =
        members.length > 0
          ? members.filter(member => member.id === change.doc.data().id)[0]
          : null;
      if (
        previous !== null &&
        previous !== undefined &&
        previous.fullName !== undefined
      ) {
        member["fullName"] = previous.fullName;
        member["photoUrl"] = previous.photoUrl;
        //if there is more then one change, it is initial load
        if (data.snapshot.docChanges().length > 1) {
          allMembers.push(member);
          if (counter === data.snapshot.docChanges().length) {
            yield put({
              type: SESSION_MEMBERS_FETCH_SUCCESS,
              sessionId: sessionId,
              sessionMembers: allMembers
            });
          }
        } else {
          yield put({
            type: ADD_SESSION_MEMBER,
            sessionId: sessionId,
            member: member
          });
        }
      } else {
        const ref = firebase
          .firestore()
          .collection("users")
          .doc(member.id);
        const chan = yield call(getMemberSnap, ref);
        try {
          const userData = yield take(chan);
          if (
            userData.snapshot.data() &&
            userData.snapshot.data().general_info
          ) {
            member["fullName"] = userData.snapshot.data().general_info.fullName;
            member["photoUrl"] = userData.snapshot.data().general_info.photoUrl;
          }
          //if there is more then one change, it is initial load
          if (data.snapshot.docChanges().length > 1) {
            allMembers.push(member);
            if (counter === data.snapshot.docChanges().length) {
              yield put({
                type: SESSION_MEMBERS_FETCH_SUCCESS,
                sessionId: sessionId,
                sessionMembers: allMembers
              });
            }
          } else {
            yield put({
              type: ADD_SESSION_MEMBER,
              sessionId: sessionId,
              member: member
            });
          }
        } finally {
          chan.close();
        }
      }
    }
    // listens when session members are modified
    if (change.type === eventTypes.MODIFIED) {
      const members = store.getState().Firebase.participants[
        sessionId
      ];
      const previous = members.filter(
        member => member.id === change.doc.data().id
      )[0];
      const member = change.doc.data();
      member["fullName"] = previous.fullName;
      member["photoUrl"] = previous.photoUrl;
      yield put({
        type: ADD_SESSION_MEMBER,
        sessionId: sessionId,
        member: member
      });
      if (member.id === Backend.uid && member.status === "active") {
        // const isOwner = member.owner ? member.owner : false;
        // SessionsApi.handleActiveSessionMember(sessionId, isOwner);
      } else if (
        member.id === Backend.uid &&
        (member.status === "rejected" ||
          member.status === "left" ||
          member.status === "removed")
      ) {
        //user left or rejected or removed
        yield put({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessions" }
        });
        yield put({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionMembers" }
        });
        yield put({
          type: FIRESTORE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionLocations" }
        });
        yield put({
          type: FIREBASE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionMessages" }
        });
        yield put({
          type: FIREBASE_UNLISTEN_REQUESTED,
          id: sessionId,
          meta: { type: "sessionTypingEvents" }
        });
      }
    }
  }
}
