import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../utility/intlMessages";
import { motion } from "framer-motion";
import CardWidgetWrapper from "./style";
import {
  TeamOutlined,
  HourglassOutlined,
  BellOutlined,
  MedicineBoxOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";
import { getAlertsCount } from "../../redux/selectors/alerts";
import { getGeofencesCount } from "../../redux/selectors/geofences";
import { getPendingRequestsCount } from "../../redux/selectors/pendingRequests";
import {
  getCircleMembersCount,
  getCircleRespondersCount
} from "../../redux/selectors/circles";
import { useSelector } from "react-redux";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const ListItem = props => {
  const { icon, count, desc } = props;
  return (
    <div className="widgetContainer">
      <div className="iconWrapper"> {icon}</div>
      <div className="contentWrapper">
        <h3 className="contentNumber">{count}</h3>
        <p className="contentLabel">{desc}</p>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  icon: PropTypes.object,
  count: PropTypes.string,
  desc: PropTypes.object
};

const Stat = props => {
  const { direction } = props;
  const alertsCount = useSelector(state => getAlertsCount(state));
  const geofencesCount = useSelector(state => getGeofencesCount(state));
  const pendingRequestsCount = useSelector(state =>
    getPendingRequestsCount(state)
  );
  const circleMembersCount = useSelector(state => getCircleMembersCount(state));
  const circleRespondersCount = useSelector(state =>
    getCircleRespondersCount(state)
  );

  const renderStat = (
    alertsCount,
    geofencesCount,
    pendingRequestsCount,
    circleMembersCount,
    circleRespondersCount
  ) => {
    const stat = [
      {
        count: circleMembersCount,
        desc: <IntlMessages id="app.sidebar.members" />,
        icon: <TeamOutlined />
      },
      {
        count: circleRespondersCount,
        desc: <IntlMessages id="app.sidebar.responders" />,
        icon: <MedicineBoxOutlined />
      },
      {
        count: alertsCount,
        desc: <IntlMessages id="app.sidebar.alerts" />,
        icon: <BellOutlined />
      },
      {
        count: geofencesCount,
        desc: <IntlMessages id="app.sidebar.geoFences" />,
        icon: <EnvironmentOutlined />
      },
      {
        count: pendingRequestsCount,
        desc: <IntlMessages id="app.sidebar.pending" />,
        icon: <HourglassOutlined />
      }
    ];
    return (
      <CardWidgetWrapper data-rtl={direction}>
        <motion.ul
          className="widgetsContainer"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {stat.map((card, i) => (
            <motion.li key={i} className="item" variants={item}>
              <ListItem key={i} {...stat[i]} />
            </motion.li>
          ))}
        </motion.ul>
      </CardWidgetWrapper>
    );
  };
  return renderStat(
    alertsCount,
    geofencesCount,
    pendingRequestsCount,
    circleMembersCount,
    circleRespondersCount
  );
};

Stat.propTypes = {
  direction: PropTypes.string,
  alertsCount: PropTypes.string,
  geofencesCount: PropTypes.string,
  pendingRequestsCount: PropTypes.string,
  circleMembersCount: PropTypes.string,
  circleRespondersCount: PropTypes.string
};

export default Stat;
