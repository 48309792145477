import React from "react";
import PropTypes from "prop-types";
import SessionsApi from "../../api/SessionsApi";
import { withTheme } from "styled-components";
import { Upload } from "antd";
import Resizer from "react-image-file-resizer";

const ImageButton = props => {
  const handleChange = info => {
    resizeImage(info.file.originFileObj);
  };
  const customRequest = () => {
    return null;
  };

  const resizeImage = file => {
    Resizer.imageFileResizer(
      file,
      612,
      612,
      "JPEG",
      100,
      0,
      uri => {
        SessionsApi.sendPhotoMessage(props.sessionId, file.name, uri);
      },
      "base64"
    );
  };

  const theme = props.theme;
  return (
    <Upload
      accept="image/*"
      name="chatImagePicker"
      showUploadList={false}
      onChange={handleChange}
      customRequest={customRequest}
    >
      <i
        className="ion-md-images"
        style={{
          color: theme.palette["lightText"][0],
          fontSize: "24px",
          padding: props.direction === "rtl" ? "0 0 0 1.2em" : "0 1.2em 0 0"
        }}
      />
    </Upload>
  );
};

ImageButton.propTypes = {
  uri: PropTypes.string,
  sessionId: PropTypes.string,
  direction: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(ImageButton);
