import { store } from "../redux/store";
import { message as antMessage } from "antd";

import {
  ADD_SESSION_MESSAGE,
  REMOVE_SESSION_MESSAGE,
  ADD_RESOLVED_SESSION,
  ADD_LAST_VISIBLE_RESOLVED_SESSION,
  SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS,
  SESSION_MEMBERS_FETCH_SUCCESS,
  SESSION_MESSAGES_FETCH_SUCCESS,
} from "../redux/constants";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

import Backend from "./Backend";
class SessionsApi {
  //accept session invite
  acceptSessionInvitation = (session, sessionId) => {
    let that = this;
    let userDetails = store.getState().Firebase.userData.general_info;
    let chatMessagesRef = "sessionMessages/" + sessionId;
    let chatMessage = {
      name: userDetails.fullName,
      user: {
        _id: 1,
        avatar: null,
      },
      type: "memberJoinUpdate",
      system: true,
    };
    const ref = firebase
      .firestore()
      .collection("sessionsMembers")
      .doc(sessionId)
      .collection("sessionMembers")
      .doc(Backend.uid);
    ref
      .set(
        {
          status: "active",
        },
        { merge: true }
      )
      .then(() => {
        firebase
          .database()
          .ref(`/sessionsMembers/${sessionId}/${Backend.uid}`)
          .update({
            status: "active",
          });
        that.sendSessionUpdateMessage(sessionId, chatMessage, chatMessagesRef);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  rejectSessionInvitation = (activeCircleId, session, sessionId) => {
    let batch = firebase.firestore().batch();
    const sessionMemberRef = firebase
      .firestore()
      .collection("sessionsMembers")
      .doc(sessionId)
      .collection("sessionMembers")
      .doc(Backend.uid);
    const circleSessionRef = firebase
      .firestore()
      .collection("circlesSessions")
      .doc(activeCircleId)
      .collection("circleSessions")
      .doc(sessionId);
    batch.set(
      sessionMemberRef,
      {
        status: "rejected",
      },
      { merge: true }
    );
    batch.set(
      circleSessionRef,
      {
        sessionStatus: "rejected",
      },
      { merge: true }
    );
    batch
      .commit()
      .then(function () {
        firebase
          .database()
          .ref(`/sessionsMembers/${sessionId}/${Backend.uid}`)
          .update({
            status: "rejected",
          });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  //add new session members (during session)
  addSessionMember = (userId, sessionId) => {
    let batch = firebase.firestore().batch();
    const userCirclesRef = firebase
      .firestore()
      .collection("usersSessions")
      .doc(userId)
      .collection("userSessions")
      .doc(sessionId);
    const circleMembersRef = firebase
      .firestore()
      .collection("sessionsMembers")
      .doc(sessionId)
      .collection("sessionMembers")
      .doc(userId);
    batch.set(userCirclesRef, {
      sessionId: sessionId,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
    batch.set(circleMembersRef, {
      id: userId,
      status: "invited",
    });
    batch
      .commit()
      .then(function () {
        firebase.database().ref(`/sessionsMembers/${sessionId}/${userId}`).set({
          invitedAt: Date.now(),
          userId: userId,
          status: "invited",
        });
        console.log("session member was added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //leave session
  leaveSession = (sessionId) => {
    let that = this;
    return new Promise((resolve) => {
      let userDetails = store.getState().Firebase.userData.general_info;
      let messagesRef = "sessionMessages/" + sessionId;
      let message = {};
      const ref = firebase
        .firestore()
        .collection("sessionsMembers")
        .doc(sessionId)
        .collection("sessionMembers")
        .doc(Backend.uid);
      ref
        .set(
          {
            status: "left",
            leftAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          firebase
            .database()
            .ref(`/sessionsMembers/${sessionId}/${Backend.uid}`)
            .update({
              status: "left",
              leftAt: Date.now(),
            });
          message = {
            name: userDetails.fullName,
            user: {
              _id: 1,
            },
            type: "memberLeaveUpdate",
            system: true,
          };
          that.sendSessionUpdateMessage(sessionId, message, messagesRef);
          resolve(true);
        })
        .catch(function (error) {});
    });
  };

  //send session update (like "yogev erez joined" etc)
  sendSessionUpdateMessage = (sessionId, message, messagesRef) => {
    firebase
      .database()
      .ref(messagesRef)
      .push({
        name: message.name,
        text: "",
        user: message.user,
        type: message.type,
        system: message.system ? message.system : false,
        createdAt: Date.now(),
      })
      .then(() => {
        firebase
          .firestore()
          .collection("sessionsLastActivity")
          .doc(sessionId)
          .set({
            timestamp: Date.now(),
          });
      });
  };

  sendMessage = (sessionId, message) => {
    const membersStatuses = this.getMessageMembersStatuses(sessionId);
    var messagesRef = "sessionMessages/" + sessionId;
    var user = {
      _id: Backend.uid,
      name: Backend.userName,
    };
    firebase
      .database()
      .ref(messagesRef)
      .push({
        status: membersStatuses,
        text: message,
        user: user,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      })
      .then(() => {
        firebase
          .firestore()
          .collection("sessionsLastActivity")
          .doc(sessionId)
          .set({
            timestamp: Date.now(),
          });
      });
  };

  //send photo message
  sendPhotoMessage = async (sessionId, name, normalPic) => {
    var user = {
      _id: Backend.uid,
      name: Backend.userName,
    };
    const messagesRef = "sessionMessages/" + sessionId;
    const membersStatuses = this.getMessageMembersStatuses(sessionId);

    var newMessageKey = firebase.database().ref(messagesRef).push().key;
    let photoUrlUploadPath =
      "/sessionMessages/" + sessionId + "/" + newMessageKey + "/" + name;
    const photoUrl = Backend.uploadChatPhoto(
      photoUrlUploadPath,
      normalPic,
      sessionId,
      newMessageKey,
      true
    );
    const message = {
      status: membersStatuses,
      user: user,
      createdAt: Date.now(),
      image: normalPic,
      _id: newMessageKey,
      uploading: true,
      uploadProgress: 0,
    };

    store.dispatch({
      type: ADD_SESSION_MESSAGE,
      sessionId: sessionId,
      message: message,
    });
    Promise.all([photoUrl]).then(function (values) {
      firebase
        .database()
        .ref(messagesRef)
        .child(newMessageKey)
        .set({
          status: membersStatuses,
          user: user,
          createdAt: Date.now(),
          image: values[0],
          bubbleImage: values[0],
        })
        .then(() => {
          firebase
            .firestore()
            .collection("sessionsLastActivity")
            .doc(sessionId)
            .set({
              timestamp: Date.now(),
            });
        })
        .catch(function (error) {
          store.dispatch({
            type: REMOVE_SESSION_MESSAGE,
            sessionId: sessionId,
            messageId: newMessageKey,
          });
          antMessage.error(
            "There was an error during sending the message. please try again",
            error
          );
        });
    });
  };

  getMessageMembersStatuses(sessionId) {
    let members = store.getState().Firebase.participants[sessionId];
    const membersStatuses = {};
    for (let j = 0; j < members.length; j++) {
      const userId = members[j].id;
      if (userId === Backend.uid) {
        membersStatuses[userId] = {
          status: "read",
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        };
      } else {
        membersStatuses[userId] = {
          status: "sent",
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        };
      }
    }
    return membersStatuses;
  }

  setMessageStatus = (message, sessionId) => {
    firebase
      .database()
      .ref(
        "sessionMessages/" +
          sessionId +
          "/" +
          message._id +
          "/status/" +
          Backend.uid
      )
      .set({
        status: "read",
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      });
  };

  setChatTypingEvent(sessionId, isSend) {
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    firebase
      .database()
      .ref("sessionChatTypingEvent/" + sessionId + "/" + Backend.uid)
      .set({
        timestamp: timestamp,
        name: store.getState().Firebase.userData.general_info.fullName,
        send: isSend,
      });
  }

  getSessionByType(sessions, members, type) {
    let filtered = [];
    if (sessions !== undefined) {
      sessions.forEach((session) => {
        const sessionsMembers = members[session.sessionId];
        const member = sessionsMembers
          ? sessionsMembers.filter((member) => member.id === Backend.uid)[0]
          : undefined;
        const memberStatus = member !== undefined ? member.status : "";
        const sessionStatus = session.sessionStatus;
        // const owner = member[0].owner;
        if (
          memberStatus === "invited" &&
          sessionStatus === "open" &&
          type === "pending"
        ) {
          filtered.push(session);
        } else if (
          (memberStatus === "active" || memberStatus === "invited") &&
          sessionStatus === "open" &&
          type === "active"
        ) {
          filtered.push(session);
        } else if (
          memberStatus === "left" &&
          sessionStatus === "open" &&
          type === "notactive"
        ) {
          //user left session but session is still active
          filtered.push(session);
        } else if (
          sessionStatus === "closed" &&
          memberStatus !== "removed" &&
          memberStatus !== "rejected" &&
          type === "notactive"
        ) {
          //user was not removed from a session or rejected invitation or didnt respond to invitation
          filtered.push(session);
        }
      });
    }
    return filtered;
  }

  loadMoreResolvedSessions(circleId, limit) {
    const resolved = store.getState().Firebase.resolvedSessions;
    let ref = null;
    let that = this;
    if (resolved.lastVisible && resolved.lastVisible[circleId]) {
      ref = firebase
        .firestore()
        .collection("circlesSessions")
        .doc(circleId)
        .collection("circleSessions")
        .where("sessionStatus", "==", "closed")
        .orderBy("startedAt", "desc")
        .startAfter(resolved.lastVisible[circleId])
        .limit(limit);
    } else {
      ref = firebase
        .firestore()
        .collection("circlesSessions")
        .doc(circleId)
        .collection("circleSessions")
        .where("sessionStatus", "==", "closed")
        .orderBy("startedAt", "desc")
        .limit(limit);
    }
    ref.get().then(function (documentSnapshots) {
      for (let j = 0; j < documentSnapshots.docChanges().length; j++) {
        const sessionId = documentSnapshots.docChanges()[j].doc.data()
          .sessionId;
        firebase
          .firestore()
          .collection("sessions")
          .doc(sessionId)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              const session = doc.data();
              session["sessionId"] = sessionId;
              store.dispatch({
                type: ADD_RESOLVED_SESSION,
                circleId: circleId,
                session: session,
              });
              that.loadResolvedSessionLocations(sessionId);
              that.loadResolvedSessionMembers(sessionId);
              that.loadResolvedSessionMessages(sessionId);
            } else {
              console.log("No such document!");
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }
      // Get the last visible document
      if (documentSnapshots.docChanges().length > 0) {
        const length = documentSnapshots.docChanges().length - 1;
        var lastVisible = documentSnapshots.docChanges()[length].doc.data()
          .startedAt;
        store.dispatch({
          type: ADD_LAST_VISIBLE_RESOLVED_SESSION,
          circleId: circleId,
          lastVisible: lastVisible,
        });
      }
    });
  }

  loadResolvedSessionLocations(sessionId) {
    let allLocations = [];
    firebase
      .firestore()
      .collection("sessionsLocations")
      .doc(sessionId)
      .collection("sessionLocations")
      .get()
      .then(function (documentSnapshots) {
        for (let j = 0; j < documentSnapshots.docChanges().length; j++) {
          let location = documentSnapshots.docChanges()[j].doc.data() || {};
          allLocations.push(location);
          if (j === documentSnapshots.docChanges().length - 1) {
            store.dispatch({
              type: SESSION_MEMBERS_LOCATIONS_FETCH_SUCCESS,
              sessionId: sessionId,
              locations: allLocations,
            });
          }
        }
      });
  }

  loadResolvedSessionMembers(sessionId) {
    let allMembers = [];

    firebase
      .firestore()
      .collection("sessionsMembers")
      .doc(sessionId)
      .collection("sessionMembers")
      .get()
      .then(function (documentSnapshots) {
        for (let j = 0; j < documentSnapshots.docChanges().length; j++) {
          let member = documentSnapshots.docChanges()[j].doc.data() || {};

          firebase
            .firestore()
            .collection("users")
            .doc(member.id)
            .get()
            .then(function (snapshot) {
              const user = snapshot.data();
              member["fullName"] = user.general_info.fullName;
              member["photoUrl"] = user.general_info.photoUrl;
              allMembers.push(member);
            });
          if (j === documentSnapshots.docChanges().length - 1) {
            store.dispatch({
              type: SESSION_MEMBERS_FETCH_SUCCESS,
              sessionId: sessionId,
              sessionMembers: allMembers,
            });
          }
        }
      });
  }
  loadResolvedSessionMessages(sessionId) {
    let allMessages = [];
    let counter = 0;
    firebase
      .database()
      .ref("sessionMessages/" + sessionId)
      .orderByChild("createdAt")
      .once("value", function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          counter++;
          var messageKey = childSnapshot.key;
          var item = childSnapshot.val();
          const newMessage = {
            _id: messageKey,
            text: item.text,
            name: item.name,
            user: {
              _id: item.user._id,
              name: item.user.name,
              avatar: item.user.avatar,
            },
            system: item.system,
            createdAt: new Date(item.createdAt),
            image: item.image,
            type: item.type,
            status: item.status,
          };
          allMessages.push(newMessage);
          if (counter === snapshot.numChildren()) {
            store.dispatch({
              type: SESSION_MESSAGES_FETCH_SUCCESS,
              sessionId: sessionId,
              messages: allMessages,
            });
          }
        });
      });
  }

  getSessionOwnerRoute(sessionId, ownerId, callback) {
    let routes = [];
    let times = [];
    let count = 0;
    firebase
      .firestore()
      .collection("sessionsOwnersLocations")
      .doc(sessionId)
      .collection("sessionOwnersLocations")
      .doc(ownerId)
      .get()
      .then((LocationsSnap) => {
        if (LocationsSnap.exists) {
          const locations = LocationsSnap.data().locations;
          locations.forEach((location) => {
            const coordinate = [
              location.location.longitude,
              location.location.latitude,
            ];
            routes.push(coordinate);
            times.push(location.timestamp);
            count++;
            if (count === locations.length) {
              callback(routes, times);
            }
          });
        }
      });
  }
}
export default new SessionsApi();
