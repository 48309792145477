import styled from "styled-components";

const GeofencesCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  padding: 10px;
  .mapboxgl-map {
    border-radius: 0.5em;
  }
`;

export default GeofencesCardWrapper;
