import styled from "styled-components";
import { palette } from "styled-theme";

const SettingsWrapper = styled.div`
  .settingsContainer {
    width: 100%;
    .title {
      border-bottom: 1px solid ${palette("border", 0)};
      margin-bottom: 20px;
    }

    .settingsContent {
      .ant-form {
        max-width: 650px;
        width: 100%;
        padding: ${props =>
          props["data-rtl"] === "ltr" ? "0 1em 0 0" : "0 0 0 1em"};
      }
      .ant-layout-content {
        transition: all 0.3s ease;
        padding: 6px 0 0 0;
      }

      .ant-form-item-label label .anticon {
        color: #cdcdcd;
      }
      .ant-form-item-required:before {
        color: ${palette("secondary", 0)} !important;
      }
      .ant-form-item {
        margin-bottom: 1.5em;
        display: flex;
      }
      .ant-form-item-label {
        text-align: ${props =>
          props["data-rtl"] === "ltr" ? "left" : "right"};
        align-items: center;
        display: flex;
      }
      .has-success.has-feedback .ant-form-item-children-icon {
        left: ${props => (props["data-rtl"] === "ltr" ? "auto" : "0")};
        right: ${props => (props["data-rtl"] === "ltr" ? "0" : "auto")};
      }
      form {
        .has-feedback {
          .ant-input {
            padding: ${props =>
              props["data-rtl"] === "rtl" ? "0 11px 0 24px" : "0 24px 0 11px"};
          }
        }
      }
    }
    .innerContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
  .buttonsContainer {
    margin: 3em 0 0 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export default SettingsWrapper;
