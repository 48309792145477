import { combineReducers } from "redux";
import appReducer from "./app";
import newCircleDetails from "./newCircleDetails";
// import localState from "./localState";
import userData from "./userData";
import vaultedData from "./vaultedData";
import subscriptions from "./subscriptions";
import activePlan from "./activePlan";
import plans from "./plans";
import vendorDetails from "./vendorDetails";
import activeMemberProfile from "./activeMemberProfile";
import circles from "./circles";
import members from "./members";
import pendingRequests from "./pendingRequests";
// import sessions from "./sessions";
import resolvedSessions from "./resolvedSessions";
import participants from "./participants";
import priority from "./priority";
import openSessions from "./openSessions";
import geoFences from "./geoFences";
import alerts from "./alerts";
import membersLocations from "./membersLocations";
import ownersLocations from "./ownersLocations";
import messages from "./messages";
import entities from "./entities";
import chatTypingEvents from "./chatTypingEvents";
import fetch from "./fetch";

const Firebase = combineReducers({
  // routing: routerReducer,
  app: appReducer,
  userData,
  vaultedData,
  subscriptions,
  activePlan,
  plans,
  vendorDetails,
  entities,
  circles,
  members,
  pendingRequests,
  geoFences,
  alerts,
  newCircleDetails,
  // localState,
  activeMemberProfile,
  // sessions,
  openSessions,
  resolvedSessions,
  participants,
  priority,
  membersLocations,
  ownersLocations,
  messages,
  chatTypingEvents,
  fetch
});

export default Firebase;
