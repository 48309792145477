import React from "react";
import PropTypes from "prop-types";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Button } from "antd";
import {
  GlobalOutlined,
  TeamOutlined,
  SettingOutlined,
  SafetyOutlined,
  EnvironmentOutlined,
  BellOutlined,
  AlertOutlined,
  MedicineBoxOutlined,
  HourglassOutlined,
} from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { Menu } from "antd";
import { history } from "../../redux/store";
import { withTheme } from "styled-components";
import IntlMessages from "../../components/utility/intlMessages";
import { appActions } from "../../redux/reducers/app/reducer";
import SidebarWrapper from "./sidebar.style";
import {
  SET_ANIMATE_MEMBERS,
  SET_ANIMATE_RESPONDERS,
} from "../../redux/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const { Sider } = Layout;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.App);
  const localState = useSelector((state) => state.LocalState);
  const userSettings = useSelector((state) => state.Firebase.userData.settings);
  const activePlan = useSelector((state) => state.Firebase.activePlan);
  const { url, theme } = props;

  const handleClick = (e) => {
    dispatch({
      type: appActions.CHANGE_CURRENT,
      current: e.key,
    });

    if (app.view === "MobileView") {
      dispatch({
        type: appActions.COLLPSE_CHANGE,
        collapsed: !app.collapsed,
      });
      dispatch({
        type: appActions.COLLPSE_OPEN_DRAWER,
        openDrawer: !app.openDrawer,
      });
    }
    if (e.key === "members") {
      dispatch({ type: SET_ANIMATE_MEMBERS, value: true });
    }

    if (e.key === "responders") {
      dispatch({ type: SET_ANIMATE_RESPONDERS, value: true });
    }
  };
  const onOpenChange = (newOpenKeys) => {
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch({
      type: appActions.CHANGE_OPEN_KEYS,
      openKeys: nextOpenKeys,
    });
  };

  const modifyPlan = () => {
    history.push(`/${localState.uid}/plans`);
  };
  const getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  const current = app.current;
  const collapsed =
    (clone(app.collapsed) && !clone(app.openDrawer)) ||
    (current !== "" && app.width < 1384);
  const { openDrawer } = app;
  const mode = collapsed === true ? "vertical" : "inline";
  const onMouseEnter = () => {
    if (openDrawer === false) {
      dispatch({
        type: appActions.COLLPSE_OPEN_DRAWER,
        openDrawer: !app.openDrawer,
      });
    }
    return;
  };
  const onMouseLeave = () => {
    if (openDrawer === true) {
      dispatch({
        type: appActions.COLLPSE_OPEN_DRAWER,
        openDrawer: !app.openDrawer,
      });
    }
    return;
  };
  const scrollheight = app.height;
  const direction =
    userSettings && userSettings.language && userSettings.language.direction
      ? userSettings.language.direction
      : "ltr";

  let planDescription = "";
  if (activePlan.planName === "personal") {
    planDescription = "free";
  } else if (activePlan.planName === "growing") {
    planDescription = "45$/month";
  } else if (activePlan.planName === "premium") {
    planDescription = "pay as you go";
  }
  const themeName = theme.name;
  const bg =
    themeName === "lightTheme"
      ? theme.palette["background"][0]
      : theme.palette["lightBg"][0];

  const menuIconSelectedBg =
    themeName === "lightTheme"
      ? theme.palette["primary"][0]
      : theme.palette["surfaceColor"][0];

  return (
    <SidebarWrapper
      data-rtl={direction}
      bg={bg}
      menuIconSelectedBg={menuIconSelectedBg}
    >
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
        width={window.innerWidth / 5}
        className="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Scrollbars
          className="scrollbars"
          style={{ height: scrollheight - 64 }}
        >
          <Menu
            onClick={handleClick}
            theme="dark"
            mode={mode}
            openKeys={app.openKeys}
            selectedKeys={[app.current]}
            onOpenChange={onOpenChange}
            className="dashboardMenu"
          >
            <Menu.Item key="overview">
              <Link to={`${url}/overview`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <GlobalOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.overview" />
                  </span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="members">
              <Link to={`${url}/members`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <TeamOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.members" />
                  </span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="responders">
              <Link to={`${url}/responders`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <MedicineBoxOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.responders" />
                  </span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="pendingRequests">
              <Link to={`${url}/pendingRequests`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <HourglassOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.pending" />
                  </span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="activeSessions">
              <Link to={`${url}/activeSessions`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <AlertOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.active" />
                  </span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="resolvedSessions">
              <Link to={`${url}/resolvedSessions`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <SafetyOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.resolved" />
                  </span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="settings">
              <Link to={`${url}/settings`}>
                <span className="menuItem">
                  <span className="menuIcon">
                    <SettingOutlined />
                  </span>

                  <span className="nav-text">
                    <IntlMessages id="app.sidebar.settings" />
                  </span>
                </span>
              </Link>
            </Menu.Item>
          </Menu>
          <div className="plans">
            <div>
              <p className="planName">{activePlan.planName}</p>
              <p>{planDescription}</p>
            </div>
            <Button
              size={"small"}
              onClick={() => {
                modifyPlan();
              }}
            >
              modify
            </Button>
          </div>
        </Scrollbars>
      </Sider>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  url: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(Sidebar);

// <Menu.Item key="email">
//   <Link to={`${url}/alerts`}>
//     <span className="menuItem">
//       <span className="menuIcon">
//         <BellOutlined />
//       </span>
//       <span className="nav-text">
//         <IntlMessages id="app.sidebar.alerts" />
//       </span>
//     </span>
//   </Link>
// </Menu.Item>
//
// <Menu.Item key="geofences">
//   <Link to={`${url}/geofences`}>
//     <span className="menuItem">
//       <span className="menuIcon">
//         <EnvironmentOutlined />
//       </span>
//
//       <span className="nav-text">
//         <IntlMessages id="app.sidebar.geoFences" />
//       </span>
//     </span>
//   </Link>
// </Menu.Item>
