import { ADD_CIRCLE, REMOVE_CIRCLE } from "../../constants";

const INITIAL_STATE = [];

function circles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return [
        ...state.filter(circle => circle.id !== action.circleId),
        action.circle
      ];

    case REMOVE_CIRCLE:
      return state.filter(circle => circle.id !== action.id);

    default:
      return state;
  }
}

export default circles;
