import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from "./list";
import EntityDetails from "./entityDetails";
import Backend from "../../../api/Backend";
import { Button } from "antd";
import EmptyState from "../../emptyState/emptyState";
import MobileView from "./mobileView";
import IntlMessages from "../../utility/intlMessages";
import CategorySider from "../../layout/categorySider.style";
import CategoryCenter from "../../layout/categoryCenter.style";
import CategoryInner from "../../layout/categoryInner.style";
import CategoryOuter from "../../layout/categoryOuter.style";
import { withTheme } from "styled-components";
import { getDirection } from "../../../redux/selectors/user";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeGetActiveEntity } from "../../../redux/selectors/entities";
import { ADD_ENTITY } from "../../../redux/constants";

const EntitiesView = props => {
  const dispatch = useDispatch();
  const [activeEntity, setActiveEntity] = useState(null);
  const { onCreateClicked, theme } = props;
  const direction = useSelector(state => getDirection(state));
  const userData = useSelector(state => state.Firebase.userData);
  const entities = useSelector(state => state.Firebase.entities);
  const getActiveEntity = makeGetActiveEntity();
  const activeItem = useSelector(state => getActiveEntity(state, props));
  const view = useSelector(state => state.App.view);
  //
  useEffect(() => {
    let isMounted = true;
    if (
      entities &&
      entities.length > 0 &&
      activeEntity === null &&
      view !== "MobileView" &&
      isMounted
    ) {
      let activeEntity = entities[0];
      setActiveEntity(activeEntity.entityId);
      Backend.getEntityInfo(entities[0].entityId, function(info) {
        if (info) {
          activeEntity["closedSessions"] = info.closedSessions.toString();
          activeEntity["openSessions"] = info.openSessions.toString();
          activeEntity["circles"] = info.circles.toString();
        }
        if (isMounted) {
          dispatch({
            type: ADD_ENTITY,
            entity: activeEntity
          });
        }
      });
    }
    return () => (isMounted = false);
  }, [entities]);

  const onEntitySelect = item => {
    setActiveEntity(item.entityId);
    Backend.getEntityInfo(item.entityId, function(info) {
      if (info) {
        item["closedSessions"] = info.closedSessions.toString();
        item["openSessions"] = info.openSessions.toString();
        item["circles"] = info.circles.toString();
      }
      dispatch({
        type: ADD_ENTITY,
        entity: item
      });
    });
  };

  const onBack = () => {
    setActiveEntity(null);
  };
  return view === "MobileView" ? (
    <MobileView
      direction={direction}
      entities={entities}
      userData={userData}
      activeEntity={activeEntity}
      view={view}
      onEntitySelect={onEntitySelect}
      onBack={onBack}
      onCreateClicked={onCreateClicked}
      // direction={direction}
      // sessions={openSessions}
      // activeSessionId={activeSessionId}
      // activeCircleId={activeCircleId}
      // userData={userData}
      // category="open"
      // otherAttributes={otherAttributes}
      // activeSession={activeSession}
      // userDetails={userDetails}
      // startOfSession={startOfSession}
      // variants={variants}
      // onApproveClicked={onApproveClicked}
      // onDeclineClicked={onDeclineClicked}
      // localState={localState}
      // height={height}
      // messages={messages}
      // participants={participants}
      // view={view}
    />
  ) : entities && entities.length > 0 ? (
    <CategoryOuter>
      <CategorySider
        direction={direction}
        settings={true}
        style={{ border: "none" }}
      >
        <Button
          onClick={onCreateClicked}
          type="primary"
          className={"addButton"}
        >
          <IntlMessages id="app.buttons.createEntity" />
        </Button>
        {entities ? (
          <List
            direction={direction}
            entities={entities}
            userData={userData}
            activeEntity={activeEntity}
            view={view}
            onEntitySelect={onEntitySelect}
          />
        ) : null}
      </CategorySider>
      <CategoryInner
        style={{ backgroundColor: theme.palette["surfaceColor"][0] }}
      >
        <CategoryCenter>
          {entities && entities.length > 0 ? (
            <div key="page" style={{ width: "100%" }}>
              <EntityDetails
                theme={theme}
                direction={direction}
                activeEntity={activeEntity}
                entities={entities}
              />
            </div>
          ) : null}
        </CategoryCenter>
      </CategoryInner>
    </CategoryOuter>
  ) : (
    <div>
      <EmptyState
        title={<IntlMessages id="app.settings.noEntities" />}
        description={<IntlMessages id="app.settings.noEntitiesDescription" />}
        direction={direction}
      />
      <Button onClick={onCreateClicked} type="primary" className={"addButton"}>
        <IntlMessages id="app.buttons.createEntity" />
      </Button>
    </div>
  );
};

EntitiesView.propTypes = {
  theme: PropTypes.object,
  onCreateClicked: PropTypes.func
};

export default withTheme(EntitiesView);
