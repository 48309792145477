import React from "react";
import PropTypes from "prop-types";
import PaymentMethodWrapper from "./paymentMethod.style";
import AddPaymentMethod from "./addPaymentMethod";
import { Img } from "react-image";
import { connect } from "react-redux";
import IntlMessages from "../utility/intlMessages";
import { Typography, Button, Col, Row } from "antd";
import { store as notificationsStore } from "react-notifications-component";
import ActionNotification from "../notifications/actionNotification";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";
import { PlusOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Title } = Typography;
class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardUrl: {
        AMERICANEXPRESS: "https://files.readme.io/97e7acc-Amex.png",
        CARTEBLEAU: "https://files.readme.io/5da1081-cb.png",
        DINERSCLUB: "https://files.readme.io/8c73810-Diners_Club.png",
        DISKOVER: "https://files.readme.io/caea86d-Discover.png",
        JCB: "https://files.readme.io/e076aed-JCB.png",
        MAESTROUK: "https://files.readme.io/daeabbd-Maestro.png",
        MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
        SOLO:
          "https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png",
        VISA: "https://files.readme.io/9018c4f-Visa.png",
        DEFAULT: "https://files.readme.io/d1a25b4-generic-card.png",
      },
      showAddMethod: false,
      type: "edit",
      selectedCard: null,
    };
  }

  static propTypes = {
    form: PropTypes.object,
    onDismiss: PropTypes.func,
    vaultedData: PropTypes.object,
    direction: PropTypes.string,
  };

  componentDidMount() {
    this.resetFields();
  }

  resetFields() {
    const data = this.props.vaultedData;
    // const data =
    //   this.props.vaultedData && this.props.vaultedData.paymentSources
    //     ? this.props.vaultedData.paymentSources.creditCardInfo
    //     : {};
    this.setState({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      address: data.address,
      city: data.city,
      country: data.country,
      zip: data.zip,
    });
  }

  showAddPayment = () => {
    this.setState({
      showAddMethod: true,
      type: "new",
    });
  };

  showEditPayment = (card) => {
    this.setState({
      showAddMethod: true,
      type: "edit",
      selectedCard: card,
    });
  };

  onBack = () => {
    this.setState({
      showAddMethod: false,
      type: "",
      selectedCard: null,
    });
  };

  removeCard = () => {
    const confirmText = "Are you sure you want to remove this card?";

    notificationsStore.addNotification({
      content: ({ id }) => (
        <ActionNotification
          type={"danger"}
          id={id}
          approve={(id) => {
            notificationsStore.removeNotification(id);
          }}
          decline={(id) => {
            notificationsStore.removeNotification(id);
          }}
          title={confirmText}
          message={""}
        />
      ),
      dismiss: {
        click: false,
        touch: false,
        duration: 0,
        waitForAnimation: true,
      },
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "flipInX"],
      animationOut: ["animated", "flipOutX"],
    });
  };

  renderCards() {
    const { vaultedData } = this.props;
    const cards =
      vaultedData &&
      vaultedData.paymentSources &&
      vaultedData.paymentSources.creditCardInfo
        ? vaultedData.paymentSources.creditCardInfo
        : [];
    return cards.map((card, i) => {
      const url = this.state.cardUrl[card.creditCard["cardType"]]
        ? this.state.cardUrl[card.creditCard["cardType"]]
        : this.state.cardUrl["DEFAULT"];
      const details =
        card.creditCard["cardType"] +
        " •••• " +
        card.creditCard["cardLastFourDigits"];
      const expires =
        " " +
        card.creditCard["expirationMonth"] +
        "/" +
        card.creditCard["expirationYear"];
      return (
        <Col key={i} xs={24} sm={24} md={24} lg={24} xl={12}>
          <div className="cardContainer">
            <div className="cardDetails">
              <Img src={url} />
              <div>
                <Title level={3}>{details}</Title>
                <Text>
                  <IntlMessages id="app.settings.expires" />
                  {expires}
                </Text>
              </div>
            </div>
            <div className="cardActions">
              <Button
                style={{ margin: "0 1em" }}
                type="danger"
                onClick={this.removeCard}
              >
                <IntlMessages id="app.buttons.remove" />
              </Button>
              <Button
                onClick={() => {
                  this.showEditPayment(card);
                }}
              >
                <IntlMessages id="app.settings.edit" />
              </Button>
            </div>
          </div>
        </Col>
      );
    });
  }

  render() {
    const { vaultedData, direction } = this.props;
    const { showAddMethod, type, selectedCard } = this.state;
    const vaultedShopperId = vaultedData.vaultedShopperId;

    return (
      <CategoryOuter>
        <CategoryInnerVertical>
          <CategoryCenter style={{ flexDirection: "column", padding: "20px" }}>
            <div className="title">
              <h1 className="fieldTitle">
                <IntlMessages id="app.settings.paymentMethod" />
              </h1>
            </div>

            <PaymentMethodWrapper data-rtl={direction}>
              {!showAddMethod ? (
                <Row
                  gutter={24}
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="innerContainer"
                  style={{ paddingBottom: "24px" }}
                >
                  {this.renderCards()}{" "}
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Button
                      className={"addMethodBtn"}
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={this.showAddPayment}
                    >
                      <IntlMessages id="app.settings.addPaymountMethod" />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <AddPaymentMethod
                  onBack={this.onBack}
                  type={type}
                  card={selectedCard}
                  vaultedShopperId={vaultedShopperId}
                />
              )}
            </PaymentMethodWrapper>
          </CategoryCenter>
        </CategoryInnerVertical>
      </CategoryOuter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vaultedData: state.Firebase.vaultedData.data,
  };
};

export default connect(mapStateToProps)(PaymentMethod);
