import { all } from "redux-saga/effects";
import { metaTypes } from "../constants";
import authSagas from "../reducers/auth/saga";
import { firebaseWatchListener } from "./sagasFirebaseListeners";
import { firebaseObjectWatchListener } from "./sagasFirebaseObjectListeners";
import { firestoreWatchListener } from "./sagasFirestoreListeners";
import { call } from "redux-saga/effects";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    call(firebaseWatchListener, metaTypes.sessionMessages),
    call(firebaseWatchListener, metaTypes.sessionTypingEvents),
    call(firebaseObjectWatchListener, metaTypes.sessionMembersPresence),
    call(firestoreWatchListener, metaTypes.sessionMembers),
    call(firestoreWatchListener, metaTypes.sessionLocations),
    call(firestoreWatchListener, metaTypes.sessions),
    call(firestoreWatchListener, metaTypes.userEntities),
    call(firestoreWatchListener, metaTypes.circles),
    call(firestoreWatchListener, metaTypes.circleMembers),
    call(firestoreWatchListener, metaTypes.circleGeoFences),
    call(firestoreWatchListener, metaTypes.circleAlerts),
    call(firestoreWatchListener, metaTypes.instantAlerts),
    call(firestoreWatchListener, metaTypes.scheduledAlerts),
    call(firestoreWatchListener, metaTypes.circleSesionsPriority),
    call(firestoreWatchListener, metaTypes.circleJoinRequests),
    call(firestoreWatchListener, metaTypes.circleSessions),
    call(firestoreWatchListener, metaTypes.circleSessionsIds),
    call(firestoreWatchListener, metaTypes.userCircles),
    call(firestoreWatchListener, metaTypes.userData),
    call(firestoreWatchListener, metaTypes.vaultedData),
    call(firestoreWatchListener, metaTypes.userSubscriptions),
    call(firestoreWatchListener, metaTypes.activePlan),

    call(firestoreWatchListener, metaTypes.plans)
    // call(firestoreWatchListener,metaTypes.userAlerts),
  ]);
}
