import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Input } from "antd";
import { Form } from "antd";
import Dropzone from "../utility/dropzone";
import Resizer from "react-image-file-resizer";
import { Button } from "antd";
import Backend from "../../api/Backend";
import CategoryCenter from "../layout/categoryCenter.style";
import CategoryInnerVertical from "../layout/categoryInnerVertical.style";
import CategoryOuter from "../layout/categoryOuter.style";
import IntlMessages from "../utility/intlMessages";
import { getIsEntity } from "../../redux/selectors/user";
import { useSelector } from "react-redux";

const FormItem = Form.Item;

const Profile = props => {
  const { data } = props;
  const [imageName, setImageName] = useState(null);
  const [uri, setUri] = useState(null);
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [about, setAbout] = useState("");
  const [website, setWebsite] = useState("");
  const isEntity = useSelector(state => getIsEntity(state));

  let formRef = React.createRef();

  const updateFields = () => {
    setOrganization(data.business_info.organizationName);
    setAbout(data.business_info.brief);
    setWebsite(data.business_info.website);
    setName(data.general_info.fullName ? data.general_info.fullName : "");
    setUri(data.general_info.photoUrl ? data.general_info.photoUrl.uri : null);

    formRef.current.setFieldsValue({
      organization: data.business_info.organizationName,
      about: data.business_info.brief,
      website: data.business_info.website,
      name: data.general_info.fullName ? data.general_info.fullName : ""
    });
  };

  useEffect(() => {
    setOrganization(data.business_info.organizationName);
    setAbout(data.business_info.brief);
    setWebsite(data.business_info.website);
    setName(data.general_info.fullName ? data.general_info.fullName : "");
    setUri(data.general_info.photoUrl ? data.general_info.photoUrl.uri : null);

    formRef.current.setFieldsValue({
      organization: data.business_info.organizationName,
      about: data.business_info.brief,
      website: data.business_info.website,
      name: data.general_info.fullName ? data.general_info.fullName : ""
    });
  }, [data]);

  const onPhotoChange = image => {
    let sizedPic = null;
    Resizer.imageFileResizer(
      image,
      612,
      612,
      "JPEG",
      100,
      0,
      picUri => {
        sizedPic = picUri;
        setImageName(image.name);
        setUri(sizedPic);
      },
      "base64"
    );
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    if (name === "organization") {
      setOrganization(value);
    } else if (name === "about") {
      setAbout(value);
    } else if (name === "website") {
      setWebsite(value);
    } else if (name === "name") {
      setName(value);
    }
    formRef.current.setFieldsValue({
      [name]: value
    });
  };

  const onDeclineClicked = () => {
    updateFields();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const values = formRef.current.getFieldsValue();
    const text = "Profile data was updated successfully!";
    Backend.updateAccount(
      values.name,
      imageName,
      uri,
      values.organization,
      values.about,
      values.website,
      null,
      null,
      null,
      text
    );
    setImageName(null);
  };

  const newOrganization = data.business_info
    ? data.business_info.organizationName
    : "";
  const newAbout = data.business_info ? data.business_info.brief : "";
  const newWebsite = data.business_info ? data.business_info.website : "";
  const newFullName = data.general_info ? data.general_info.fullName : "";
  const newUri =
    data.general_info && data.general_info.photoUrl
      ? data.general_info.photoUrl.uri
      : null;

  const isSame =
    newOrganization === organization &&
    newAbout === about &&
    newWebsite === website &&
    newFullName === name &&
    newUri === uri;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  return (
    <CategoryOuter>
      <CategoryInnerVertical>
        <CategoryCenter style={{ flexDirection: "column", padding: "20px" }}>
          <div className="title">
            <h1 className="fieldTitle">
              <IntlMessages id="app.settings.profile" />
            </h1>
          </div>
          <div className="innerContainer">
            <Form ref={formRef} onFinish={handleSubmit}>
              <FormItem
                {...formItemLayout}
                hasFeedback
                label={<IntlMessages id="app.settings.fullName" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your nickname!",
                    whitespace: true
                  }
                ]}
              >
                <Input onChange={e => handleInputChange(e, "name")} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label={<IntlMessages id="app.settings.organizationName" />}
                name="organization"
              >
                <Input
                  disabled={isEntity}
                  onChange={e => handleInputChange(e, "organization")}
                  type="textarea"
                  autosize={{ minRows: 2, maxRows: 6 }}
                />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label={<IntlMessages id="app.settings.aboutYourOrganization" />}
                name="about"
              >
                <Input
                  onChange={e => handleInputChange(e, "about")}
                  type="textarea"
                  autosize={{ minRows: 2, maxRows: 6 }}
                />
              </FormItem>
              <FormItem
                style={{ marginBottom: "0px" }}
                {...formItemLayout}
                label={<IntlMessages id="app.settings.website" />}
                name="website"
              >
                <Input
                  onChange={e => handleInputChange(e, "website")}
                  placeholder="website"
                />
              </FormItem>
            </Form>
            <Dropzone uri={uri} addImage={onPhotoChange} />
          </div>
        </CategoryCenter>
        <div className="buttonsContainer">
          <Button onClick={onDeclineClicked}>
            <IntlMessages id="app.buttons.cancel" />
          </Button>
          <Button
            style={{ margin: "0 1em" }}
            disabled={isSame}
            onClick={handleSubmit}
            type="primary"
          >
            <IntlMessages id="app.buttons.submit" />
          </Button>
        </div>
      </CategoryInnerVertical>
    </CategoryOuter>
  );
};

Profile.propTypes = {
  handleWebsiteChange: PropTypes.func,
  handleAboutChange: PropTypes.func,
  handlePhotoChange: PropTypes.func,
  handleOrganizationChange: PropTypes.func,
  handleNameChange: PropTypes.func,
  form: PropTypes.object,
  direction: PropTypes.string,
  data: PropTypes.object
};

export default Profile;
