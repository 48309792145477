import Auth from "./auth/reducer";
import App from "./app/reducer";
import MapBox from "./mapBox/reducer";
import LocalState from "./localState/localState";
import LanguageSwitcher from "./languageSwitcher/reducer";
import Firebase from "./firebase/index";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

export default history =>
  combineReducers({
    router: connectRouter(history),
    Firebase: Firebase,
    Auth,
    App,
    MapBox,
    LocalState,
    LanguageSwitcher
  });
