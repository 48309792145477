import {
  ADD_CIRCLE,
  ADD_CIRCLE_MEMBER,
  REMOVE_CIRCLE_MEMBER,
  CIRCLE_MEMBERS_FETCH_SUCCESS
} from "../../constants";

const INITIAL_STATE = {};

function members(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CIRCLE:
      return {
        ...state,
        [action.circleId]:
          state[action.circleId] !== undefined ? state[action.circleId] : []
      };

    case ADD_CIRCLE_MEMBER:
      return {
        ...state,
        [action.circleId]: [
          ...state[action.circleId].filter(
            member => member.id !== action.member.id
          ),
          action.member
        ]
      };

    case REMOVE_CIRCLE_MEMBER:
      return {
        ...state,
        [action.circleId]: state[action.circleId].filter(
          member => member.id !== action.id
        )
      };

    case CIRCLE_MEMBERS_FETCH_SUCCESS:
      return {
        ...state,
        [action.circleId]: action.circleMembers
      };
    default:
      return state;
  }
}

export default members;
