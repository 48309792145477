import styled from "styled-components";
import {palette} from "styled-theme";

const TopbarCircleDetailsWrapper = styled.div`
  display: flex;
  .topbarCircleImg {
    margin: 0 0.5em;
    img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
    }
    .circleIcon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px ${palette("title", 0)};
      background: ${palette("title", 0)};
      i {
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
  .topbarCircleNameWrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    .topbarCircleName {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
    }
    .topbarCircleMembersCount {
      margin-bottom: 0;
      line-height: normal;
      color: ${palette("mediumEmphasisWhiteText", 0)};
      font-size: 13px;
    }
  }
`;

export default TopbarCircleDetailsWrapper;
