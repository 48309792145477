import styled from "styled-components";
import { palette } from "styled-theme";

const ContactListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px 5px 0 5px;
  &.respondersList {
    min-width: 300px !important;
  }
  &.membersList {
    min-width: 300px !important;
  }
  .contactItem {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 15px 10px;
    text-align: ${props => (props["data-rtl"] === "rtl" ? "right" : "left")};
    position: relative;
    cursor: pointer;
    border-radius: 0.5em;
    margin-bottom: 5px;
    .contactAvatarContainer {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${props =>
        props["data-rtl"] === "rtl" ? "inherit" : "15px"};
      margin-left: ${props =>
        props["data-rtl"] === "rtl" ? "15px" : "inherit"};
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 18px;
      background: ${palette("iconBg", 0)};
      .anticon {
        color: ${palette("text", 0)};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contactDetailsContainer {
      flex: 1;
      margin-right: ${props =>
        props["data-rtl"] === "rtl" ? "inherit" : "20px"};
      margin-left: ${props =>
        props["data-rtl"] === "rtl" ? "20px" : "inherit"};
      .primaryDetail {
        text-align: start;
      }
      h3,
      p {
        margin: 0;
      }
    }
    .contactRightContainer {
      p {
        margin: 0;
      }
    }
    .activity {
      border-radius: 10px;
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: 20px;
      left: 40px;
      &.online {
        background-color: ${palette("color", 3)};
      }
      &.offline {
        background-color: ${palette("secondary", 0)};
      }
      &.active {
        background-color: ${palette("color", 3)};
      }
    }
    &.active {
      background-color: ${palette("primary", 0)};
      h3 {
        color: ${palette("onDarkBgText", 0)};
      }
      p {
        color: ${palette("onDarkBgLightText", 0)};
      }

      .contactAvatarContainer {
        background: ${palette("surfaceColor", 0)};
      }
      &:hover {
        background-color: ${palette("primary", 0)};
      }
    }
    &:hover {
      background-color: ${palette("selected", 0)};
    }
  }
`;

export default ContactListWrapper;
