import styled from "styled-components";
import { palette } from "styled-theme";
import { borderRadius } from "../../settings/style-util";

const TypingWrapper = styled.div`
  .chat-bubble-left-container {
    display: flex;
    padding: 0.5em 0;

    .isoAvatar {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      flex-shrink: 0;
      overflow: hidden;
      ${borderRadius("50%")};

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .chat-bubble-left {
      background-color: ${palette("grayscale", 5)};
      margin: 0 10px;
      border-radius: 1em;
      color: #323332;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    }
  }
`;

export default TypingWrapper;
