import { UPDATE_VENDOR_DATA } from '../../constants';
const INITIAL_STATE = {
  details: {}
};
const vendorDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_DATA:
      return {
        ...state,
        details: action.details
      };

    default:
      return state;
  }
};
export default vendorDetails;
