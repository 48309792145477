import React, { useState } from "react";
import IntlMessages from "../utility/intlMessages";
import { Button } from "antd";
import SessionsApi from "../../api/SessionsApi";
import Backend from "../../api/Backend";
import { PropTypes } from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import ContactListWrapper from "../utility/contactList.style";

const InviteList = props => {
  const [loading, setLoading] = useState(false);
  const { contacts, direction, sessionId, memberId } = props;

  const enterLoading = contact => {
    setLoading(true);
    SessionsApi.addSessionMember(contact.id, sessionId);
  };

  const singleContact = contact => {
    const activeClass = memberId === contact.id ? "active" : "";
    return (
      <div key={contact.id} className={`${activeClass} contactItem`}>
        <div className="contactAvatarContainer">
          {contact.photoUrl ? (
            <img alt="#" src={contact.photoUrl.uri} />
          ) : (
            <UserOutlined />
          )}
        </div>
        <div className="contactDetailsContainer" style={{ width: "100%" }}>
          <div className="primaryDetail">
            <h3>{contact.fullName ? contact.fullName : "No Name"}</h3>
          </div>
        </div>
        <div className="contactRightContainer">
          {contact.status ? (
            <p>{contact.status}</p>
          ) : (
            <Button
              className="contactInviteButton"
              disabled={contact.status ? true : false}
              type="primary"
              loading={loading && !contact.status}
              onClick={() => enterLoading(contact)}
            >
              {contact.status ? contact.status : "Invite"}
            </Button>
          )}
        </div>
      </div>
    );
  };
  const filtered = contacts.filter(contact => contact.id !== Backend.uid);
  return filtered && filtered.length > 0 ? (
    <ContactListWrapper data-rtl={direction}>
      {filtered.map(contact => singleContact(contact))}
    </ContactListWrapper>
  ) : (
    <span className="isoNoResultMsg">
      {<IntlMessages id="app.members.noContacts" />}
    </span>
  );
};

InviteList.propTypes = {
  direction: PropTypes.string,
  contacts: PropTypes.array,
  sessionId: PropTypes.string,
  memberId: PropTypes.string
};

export default InviteList;
