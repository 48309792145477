const authActons = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  checkAuthorization: () => ({type: authActons.CHECK_AUTHORIZATION}),
  login: () => ({
    type: authActons.LOGIN_REQUEST
  }),
  logout: () => ({
    type: authActons.LOGOUT
  })
};
export default authActons;
