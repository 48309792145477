import { createSelector } from "reselect";

const openSessions = state =>
  state.Firebase.openSessions[state.LocalState.activeCircleId]
    ? state.Firebase.openSessions[state.LocalState.activeCircleId]
    : [];

const circleOpenSessions = (state, props) =>
  state.Firebase.openSessions &&
  props.circle.id &&
  state.Firebase.openSessions[props.circle.id] &&
  state.Firebase.openSessions[props.circle.id].length > 0
    ? state.Firebase.openSessions[props.circle.id]
    : [];

const resolvedSessions = state =>
  state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    ? state.Firebase.resolvedSessions[state.LocalState.activeCircleId]
    : [];

const participants = state =>
  state.LocalState.activeSessionId
    ? state.Firebase.participants[state.LocalState.activeSessionId]
    : [];

const resolvedParticipants = state =>
  state.LocalState.activeResolvedSessionId
    ? state.Firebase.participants[state.LocalState.activeResolvedSessionId]
    : [];

const messages = state =>
  state.LocalState.activeSessionId
    ? state.Firebase.messages[state.LocalState.activeSessionId]
    : [];

const resolvedMessages = state =>
  state.LocalState.activeResolvedSessionId
    ? state.Firebase.messages[state.LocalState.activeResolvedSessionId]
    : [];

const priorities = state =>
  state.Firebase.priority[state.LocalState.activeSessionId] !== undefined
    ? state.Firebase.priority[state.LocalState.activeSessionId]
    : [];

const activeSessionId = state => state.LocalState.activeSessionId;
const activeResolvedSessionId = state =>
  state.LocalState.activeResolvedSessionId;

export const getOpenSessions = createSelector([openSessions], openSessions => {
  return openSessions;
});

export const getOpenSessionsCount = createSelector(
  [openSessions],
  openSessions => {
    return openSessions.length.toString();
  }
);

export const makeGetOpenSessionsCount = () => {
  return createSelector([circleOpenSessions], circleOpenSessions => {
    return circleOpenSessions.length.toString();
  });
};

export const getActiveSession = createSelector(
  [openSessions, activeSessionId],
  (openSessions, activeSessionId) => {
    const activeSession =
      openSessions && openSessions.length > 0
        ? activeSessionId
          ? openSessions.filter(item => item.sessionId === activeSessionId)[0]
          : openSessions[0]
        : null;
    return activeSession;
  }
);

export const getParticipants = createSelector([participants], participants => {
  return participants;
});

export const getMessages = createSelector([messages], messages => {
  return messages;
});

export const getPriorities = createSelector([priorities], priorities => {
  return priorities;
});

export const getStartOfSession = createSelector(
  [openSessions, activeSessionId],
  (openSessions, activeSessionId) => {
    const activeSession =
      openSessions && openSessions.length > 0
        ? activeSessionId
          ? openSessions.filter(item => item.sessionId === activeSessionId)[0]
          : openSessions[0]
        : null;
    const startedAt = activeSession ? activeSession.startedAt : null;
    return startedAt;
  }
);

export const getResolvedSessions = createSelector(
  [resolvedSessions],
  resolvedSessions => {
    return resolvedSessions;
  }
);

export const getResolvedActiveSession = createSelector(
  [resolvedSessions, activeResolvedSessionId],
  (resolvedSessions, activeResolvedSessionId) => {
    const activeSession =
      resolvedSessions && resolvedSessions.length > 0
        ? activeResolvedSessionId
          ? resolvedSessions.filter(
              item => item.sessionId === activeResolvedSessionId
            )[0]
          : resolvedSessions[0]
        : null;
    return activeSession;
  }
);

export const getResolvedParticipants = createSelector(
  [resolvedParticipants],
  resolvedParticipants => {
    return resolvedParticipants;
  }
);

export const getResolvedMessages = createSelector(
  [resolvedMessages],
  resolvedMessages => {
    return resolvedMessages;
  }
);

export const getStartOfResolvedSession = createSelector(
  [resolvedSessions, activeResolvedSessionId],
  (resolvedSessions, activeResolvedSessionId) => {
    const activeSession =
      resolvedSessions && resolvedSessions.length > 0
        ? activeResolvedSessionId
          ? resolvedSessions.filter(
              item => item.sessionId === activeResolvedSessionId
            )[0]
          : resolvedSessions[0]
        : null;
    const startedAt = activeSession ? activeSession.startedAt : null;
    return startedAt;
  }
);
