import styled from "styled-components";
import { palette } from "styled-theme";

const CategoryCenter = styled.div`
  background-color: ${palette("surfaceColor", 0)};
  flex: ${props => (props["nonStrech"] === true ? "none" : "1")};
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${palette("border", 0)};
  border-radius: 10px;
  margin: 10px;
  height: ${props => (props["autoHeight"] === true ? "auto" : "fit-content")};
  min-height: ${props => (props["screen"] === "overview" ? "300px" : "none")};
  @media only screen and (max-width: 1200px) {
    flex: ${props => (props["flex"] === true ? 1 : "none")};
  }

  margin-top: ${props => (props["withHeader"] === true ? "60px" : "none")};
`;

export default CategoryCenter;
