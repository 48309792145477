import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ListItem from "./listItem";
import { Link } from "react-router-dom";
import { SET_NEW_GROUP_TYPE } from "../../redux/constants";
import { store } from "../../redux/store";
import { CirclesListWrapper } from "./circleList.style";
import { ItemWrapper } from "./circleList.style";
import { useSelector } from "react-redux";

const CirclesList = props => {
  const circles = useSelector(state => state.Firebase.circles);
  const members = useSelector(state => state.Firebase.members);
  const localState = useSelector(state => state.LocalState);
  const { direction } = props;

  const renderArticles = () => {
    return circles.map((circle, i) => {
      return (
        <Col
          key={circle.id}
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          style={{ height: "360px", padding: "0 7px 15px" }}
        >
          <ListItem
            direction={direction}
            index={i}
            members={members}
            circle={circle}
            localState={localState}
            animate={true}
            {...circle}
          />
        </Col>
      );
    });
  };

  return (
    <CirclesListWrapper data-rtl={direction} className={`grid`}>
      <Row style={{ width: "100%" }}>
        {renderArticles()}
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          style={{ height: "360px", padding: "0 7px 15px" }}
        >
          <ItemWrapper data-rtl={direction} className="add">
            <Link
              className="newCircleLink"
              to={"/" + localState.uid + "/create"}
              onClick={() => {
                store.dispatch({
                  type: SET_NEW_GROUP_TYPE,
                  groupType: "public"
                });
              }}
            >
              <div className="newCircleTop">
                <div className="circleIcon">
                  <i className="ion-ios-people" />
                </div>
                <div className="addIcon">
                  <i className="ion-ios-add" />
                </div>
                <h1>New Circle</h1>
              </div>
            </Link>
          </ItemWrapper>
        </Col>
      </Row>
    </CirclesListWrapper>
  );
};

CirclesList.propTypes = {
  geoFences: PropTypes.array,
  direction: PropTypes.string,
  activeCircle: PropTypes.object,
  filteredAlerts: PropTypes.array,
  allAlerts: PropTypes.array,
  height: PropTypes.number,
  localState: PropTypes.object
};

export default CirclesList;
