import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Layout } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowSizeListener from "react-window-size-listener";
import { appActions } from "../../redux/reducers/app/reducer";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { AppLocale } from "../../dashApp";
import { withTheme } from "styled-components";
import "./App.less";
import Loading from "../App/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const App = props => {
  const dispatch = useDispatch();
  const [anim, setAnim] = useState(true);
  const { theme, match } = props;
  const { url } = match;
  const locale = useSelector(state => state.LanguageSwitcher.language.locale);

  useEffect(() => {
    setTimeout(() => {
      setAnim(true);
    }, 500);
  }, [anim]);

  const currentAppLocale = AppLocale[locale];
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.palette["surfaceColor"][0]
      }}
    >
      <AnimatePresence>{!anim && <Loading />}</AnimatePresence>
      <motion.div
        transition={{ duration: 0.1 }}
        key={"2"}
        initial="hidden"
        animate={anim ? "visible" : "hidden"}
        variants={variants}
      >
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Layout>
            <Debounce time="1000" handler="onResize">
              <WindowSizeListener
                onResize={windowSize => {
                  dispatch({
                    type: appActions.TOGGLE_ALL,
                    height: windowSize.windowHeight,
                    width: windowSize.windowWidth
                  });
                }}
              />
            </Debounce>
            <Topbar url={url} />
            <Layout
              style={{
                flexDirection: "row",
                overflowX: "hidden",
                marginTop: "64px"
              }}
            >
              <Sidebar url={url} />
              <Layout style={{ background: theme.palette["background"][5] }}>
                <AppRouter url={url} />
              </Layout>
            </Layout>
          </Layout>
        </IntlProvider>
      </motion.div>
    </div>
  );
};

App.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object
};

export default withTheme(App);
