import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { MenuItem } from "./menuItem";
import {
  AuditOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  MessageOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import IntlMessages from "../../utility/intlMessages";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    width: "100%"
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const SessionNavigation = props => (
  <motion.ul variants={variants} className="nav-list">
    {items.map(item => (
      <MenuItem
        active={props.currentView === item.key}
        toggle={props.toggle}
        key={item.key}
        func={props[item.func]}
        icon={item.icon}
        text={item.text}
      />
    ))}
  </motion.ul>
);

const items = [
  {
    key: "sessionMap",
    func: "showSessionMap",
    icon: <UserAddOutlined />,
    text: <IntlMessages id="app.sessions.map" />
  },
  {
    key: "chat",
    func: "showChat",
    icon: <MessageOutlined />,
    text: <IntlMessages id="app.sessions.chat" />
  },
  {
    key: "callerInfo",
    func: "showCallerInfo",
    icon: <AuditOutlined />,
    text: <IntlMessages id="app.sessions.callerInfo" />
  },
  {
    key: "participants",
    func: "showParticipants",
    icon: <TeamOutlined />,
    text: <IntlMessages id="app.sessions.participants" />
  },
  {
    key: "callerRouteMap",
    func: "showCallerRouteMap",
    icon: <EnvironmentOutlined />,
    text: <IntlMessages id="app.sessions.callerRoute" />
  },
  {
    key: "inviteList",
    func: "showInviteList",
    icon: <UserAddOutlined />,
    text: <IntlMessages id="app.sessions.inviteResponders" />
  }
];

SessionNavigation.propTypes = {
  url: PropTypes.string,
  toggle: PropTypes.func,
  changeCurrent: PropTypes.func,
  current: PropTypes.string,
  currentView: PropTypes.string
};
